// select component
$modules: () !default;

@mixin exports($name) {
  @if (not index($modules, $name)) {
    $modules: append($modules, $name) !global;
    @content;
  }
}





@include exports("borders.ios") {

  @media (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {

    .mbsc-fr-hb.mbsc-ios.mbsc-fr-inline .mbsc-fr-w,
    .mbsc-fr-hb.mbsc-ios.mbsc-fr-inline .mbsc-fr-c,
    .mbsc-fr-hb.mbsc-ios .mbsc-fr-hdr,
    .mbsc-fr-hb.mbsc-ios .mbsc-fr-btn-cont,
    .mbsc-fr-hb.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w,
    .mbsc-fr-hb.mbsc-ios.mbsc-fr-bottom .mbsc-fr-popup,
    /* Calendar */
    .mbsc-fr-hb.mbsc-ios.mbsc-cal-liq.mbsc-fr-center .mbsc-fr-btn-cont,
    .mbsc-fr-hb.mbsc-ios.mbsc-calendar .mbsc-fr-popup .mbsc-fr-btn-cont,
    .mbsc-fr-hb.mbsc-ios .mbsc-cal-day,
    .mbsc-fr-hb.mbsc-ios .mbsc-cal-sc-m-cell,
    /* Range */
    .mbsc-fr-hb.mbsc-ios .mbsc-range-btn-end,
    .mbsc-fr-hb.mbsc-ios .mbsc-range-btn-end .mbsc-range-btn:before,
    /* Numpad */
    .mbsc-fr-hb.mbsc-ios .mbsc-np-btn,
    /* Navigation */
    .mbsc-fr-hb.mbsc-ios.mbsc-ms-more .mbsc-ms-item:after,
    /* Listview */
    .mbsc-lv-hb.mbsc-ios .mbsc-lv-item:before,
    .mbsc-lv-hb.mbsc-ios .mbsc-lv-item:after,
    .mbsc-lv-hb.mbsc-ios .mbsc-lv-gr-title,
    .mbsc-lv-hb.mbsc-ios,
    /* Forms */
    .mbsc-form-hb.mbsc-ios .mbsc-control-w:before,
    .mbsc-form-hb.mbsc-ios .mbsc-control-w:after,
    .mbsc-form-hb.mbsc-ios .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w:before,
    .mbsc-form-hb.mbsc-ios .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w:after,
    .mbsc-form-hb.mbsc-ios .mbsc-divider,
    .mbsc-form-hb.mbsc-ios .mbsc-btn-group {
      border-width: .5px;
    }
  }
}


// Button color variables

$mbsc-button-color-light: null !default;
$mbsc-button-color-dark: null !default;
$mbsc-button-text-light: null !default;
$mbsc-button-text-dark: null !default;

// Forms color variables

$mbsc-form-background-light: null !default;
$mbsc-form-background-dark: null !default;
$mbsc-form-text-light: null !default;
$mbsc-form-text-dark: null !default;
$mbsc-form-accent-light: null !default;
$mbsc-form-accent-dark: null !default;

$mbsc-form-group-title-text-light: null !default;
$mbsc-form-group-title-text-dark: null !default;

// Input color variables

$mbsc-input-background-light: null !default;
$mbsc-input-background-dark: null !default;
$mbsc-input-border-light: null !default;
$mbsc-input-border-dark: null !default;
$mbsc-input-text-light: null !default;
$mbsc-input-text-dark: null !default;
$mbsc-input-accent-light: null !default;
$mbsc-input-accent-dark: null !default;
$mbsc-input-error-light: null !default;
$mbsc-input-error-dark: null !default;

// Navigation color variables

$mbsc-nav-background-light: null !default;
$mbsc-nav-background-dark: null !default;
$mbsc-nav-text-light: null !default;
$mbsc-nav-text-dark: null !default;
$mbsc-nav-accent-light: null !default;
$mbsc-nav-accent-dark: null !default;

// Card color variables

$mbsc-card-background-light: null !default;
$mbsc-card-background-dark: null !default;
$mbsc-card-text-light: null !default;
$mbsc-card-text-dark: null !default;

// Page color variables

$mbsc-page-background-light: null !default;
$mbsc-page-background-dark: null !default;
$mbsc-page-text-light: null !default;
$mbsc-page-text-dark: null !default;

// Listview color variables

$mbsc-listview-background-light: null !default;
$mbsc-listview-background-dark: null !default;
$mbsc-listview-text-light: null !default;
$mbsc-listview-text-dark: null !default;
$mbsc-listview-accent-light: null !default;
$mbsc-listview-accent-dark: null !default;
$mbsc-listview-header-background-light: null !default;
$mbsc-listview-header-background-dark: null !default;
$mbsc-listview-header-text-light: null !default;
$mbsc-listview-header-text-dark: null !default;

// Calendar/Range/Eventcalendar color variables

$mbsc-calendar-mark-light: null !default;
$mbsc-calendar-mark-dark: null !default;

// Frame color variables

$mbsc-frame-background-light: null !default;
$mbsc-frame-background-dark: null !default;
$mbsc-frame-text-light: null !default;
$mbsc-frame-text-dark: null !default;
$mbsc-frame-accent-light: null !default;
$mbsc-frame-accent-dark: null !default;
$mbsc-frame-overlay-light: null !default;
$mbsc-frame-overlay-dark: null !default;

@function get-contrast-color($color) {
  @if (lightness($color) > 65%) {
    @return #000;
  }

  @else {
    @return #fff;
  }
}


$mbsc-ios-button: #007bff !default;
$mbsc-ios-accent: #007bff !default;
$mbsc-ios-background: #f7f7f7 !default;
$mbsc-ios-text: #000000 !default;

$mbsc-ios-dark-button: #ff8400 !default;
$mbsc-ios-dark-accent: #ff8400 !default;
$mbsc-ios-dark-background: #000000 !default;
$mbsc-ios-dark-text: #ffffff !default;

/* Base colors */
$mbsc-ios-primary: #3f97f6 !default;
$mbsc-ios-secondary: #90979E !default;
$mbsc-ios-success: #43BE5F !default;
$mbsc-ios-danger: #f5504e !default;
$mbsc-ios-warning: #f8b042 !default;
$mbsc-ios-info: #5BB7C5 !default;
$mbsc-ios-light: #fff !default;
$mbsc-ios-dark: #47494A !default;

$mbsc-ios-colors: ( // Colors map
  'background': $mbsc-ios-background,
  'text': $mbsc-ios-text,
  'accent': $mbsc-ios-accent,
);
$mbsc-ios-dark-colors: ( // Colors map
  'background': $mbsc-ios-dark-background,
  'text': $mbsc-ios-dark-text,
  'accent': $mbsc-ios-dark-accent,
);

@function mbsc-ios-colors($params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $button: '';

  @if (map-get($params, 'button')) {
    $button: map-get($params, 'button');
  }

  @else {
    $button: map-get($params, 'accent');
  }

  $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
  $background-alt: lighten($background-limited, 3%);
  $card-shadow: darken($background, 17%);
  $cont-background: lighten($background, 6%);

  $border-color: '';
  $popup-border: '';
  $label-text: '';
  $button-disabled: '';
  $switch-background: '';
  $snackbar-button: '';
  $slider-tooltip: '';
  $slider-track-step: '';
  $text-alt: '';
  $background-overlay: '';
  $empty-color: '';
  $btn-cont-background: '';

  // Light background
  @if (lightness($background) > 50%) {
    $border-color: darken($background-limited, 17%);
    $popup-border: $border-color;
    $label-text: lighten($text, 67%);
    $button-disabled: darken($background, 13%);
    $switch-background: darken($background, 7%);
    $slider-tooltip: darken($background, 26%);
    $slider-track-step: darken($background, 10%);
    $text-alt: lighten($text, 53%);
    $background-overlay: rgba(#000, .2);
    $empty-color: lighten($text, 43%);
    $btn-cont-background: darken($background, 3%);
    $snackbar-button: hsl(hue($button), saturation($button), max(lightness($button), 80%));
  }

  // Dark background
  @else {
    $border-color: lighten($background, 20%);
    $popup-border: lighten($border-color, 13%);
    $label-text: $text;
    $button-disabled: lighten($background, 13%);
    $switch-background: #fff;
    $slider-tooltip: lighten($background, 70%);
    $slider-track-step: lighten($background, 27%);
    $text-alt: darken($text, 33%);
    $background-overlay: rgba(#fff, .1);
    $empty-color: $text;
    $btn-cont-background: lighten($background-limited, 8%);
    $snackbar-button: $button;
  }



  @return ('cont-background': $cont-background,
    'border-color': $border-color,
    'background-alt': $background-alt,
    'card-shadow': $card-shadow,
    'popup-border': $popup-border,
    'background-limited': $background-limited,
    'label-text': $label-text,
    'button-disabled': $button-disabled,
    'switch-background': $switch-background,
    'snackbar-button': $snackbar-button,
    'slider-tooltip': $slider-tooltip,
    'slider-track-step': $slider-track-step,
    'text-alt': $text-alt,
    'background-overlay': $background-overlay,
    'empty-color': $empty-color,
    'btn-cont-background': $btn-cont-background,
    'button': $button,
    /* static colors */
    'error': #d8332a,
    'white-text': #fff,
    'dark-text': #000);
}

@mixin mbsc-ios-common($theme, $params) {
  @include exports("common.#{$theme}.colors") {
    $colors: mbsc-ios-colors($params);
    $empty-color: map-get($colors, empty-color);

    .mbsc-#{$theme} {
      .mbsc-empty {
        color: $empty-color;
      }
    }
  }
}


// Theme specific variables - inherited from global variables

// Background
$mbsc-ios-frame-background: $mbsc-frame-background-light !default;
$mbsc-ios-dark-frame-background: $mbsc-frame-background-dark !default;
// Text
$mbsc-ios-frame-text: $mbsc-frame-text-light !default;
$mbsc-ios-dark-frame-text: $mbsc-frame-text-dark !default;
// Accent
$mbsc-ios-frame-accent: $mbsc-frame-accent-light !default;
$mbsc-ios-dark-frame-accent: $mbsc-frame-accent-dark !default;
// Overlay
$mbsc-ios-frame-overlay: $mbsc-frame-overlay-light !default;
$mbsc-ios-dark-frame-overlay: $mbsc-frame-overlay-dark !default;

$mbsc-ios-colors: map-merge($mbsc-ios-colors, (
  'frame-background': $mbsc-ios-frame-background,
  'frame-text': $mbsc-ios-frame-text,
  'frame-accent': $mbsc-ios-frame-accent,
  'frame-overlay': $mbsc-ios-frame-overlay,
));

$mbsc-ios-dark-colors: map-merge($mbsc-ios-dark-colors, (
  'frame-background': $mbsc-ios-dark-frame-background,
  'frame-text': $mbsc-ios-dark-frame-text,
  'frame-accent': $mbsc-ios-dark-frame-accent,
  'frame-overlay': $mbsc-ios-dark-frame-overlay,
));

@mixin mbsc-ios-frame($theme, $params) {
  @include exports("frame.#{$theme}.colors") {

    $background:map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $overlay-param: map-get($params, 'frame-overlay');
    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    $button: '';
    @if (map-get($params, 'button')) {
      $button: map-get($params, 'button');
    }
    @else {
      $button: $accent;
    }

    $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
    $cont-background: lighten($background, 6%);
    $background-alt: lighten($background-limited, 3%);

    $background-overlay: '';
    $btn-cont-background: '';
    $border-color: '';
    $top-bottom-border: '';
    $button-active: '';
    $background-desktop: '';
    $popup-shadow: '';
    $popup-arrow-shadow: '';
    $popup-border: '';
    @if (lightness($background) > 50%) {
      $background-overlay: rgba(#000, .2);
      $btn-cont-background: darken($background, 3%);
      $border-color: darken($background-limited, 17%);
      $top-bottom-border: darken($background, 41%);
      $button-active: darken($background, 5%);
      $background-desktop: $background-limited;
      $popup-shadow: rgba(0, 0, 0, .3);
      $popup-arrow-shadow: rgba(0, 0, 0, .2);
      $popup-border: darken($background-limited, 17%);
    }
    @else {
      $background-overlay: rgba(#fff, .1);
      $btn-cont-background: lighten($background-limited, 8%);
      $border-color: lighten($background, 20%);
      $top-bottom-border: lighten($background, 21%);
      $button-active: lighten($background, 12%);
      $background-desktop: lighten($background-limited, 17%);
      $popup-shadow: rgba(0, 0, 0, .8);
      $popup-arrow-shadow: rgba(0, 0, 0, .8);
      $border-color: lighten($background, 20%);
      $popup-border: lighten($border-color, 13%);
    }

    $background-overlay: if($overlay-param, $overlay-param, $background-overlay);

    .mbsc-#{$theme} {

      &.mbsc-fr-top .mbsc-fr-btn-cont,
      &.mbsc-fr-bottom .mbsc-fr-btn-cont {
        background: $btn-cont-background;
      }

      .mbsc-fr-overlay {
        background: $background-overlay;
      }

      .mbsc-fr-w {
        background: $background-limited;
        color: $text;
      }

      .mbsc-fr-hdr {
        color: $text;
      }

      &.mbsc-fr-nobtn .mbsc-fr-hdr,
      &.mbsc-fr-center:not(.mbsc-cal-liq) .mbsc-fr-hdr,
      .mbsc-fr-btn-cont {
        border-bottom: 1px solid $border-color;
      }

      .mbsc-fr-btn {
        color: $button;
      }

      /* Bubble arrow */

      .mbsc-fr-arr {
        background: $background-limited;
      }

      /* Top and bottom display */

      &.mbsc-fr-bottom .mbsc-fr-popup {
        border-top: 1px solid $top-bottom-border;
      }

      &.mbsc-fr-top .mbsc-fr-popup {
        border-bottom: 1px solid $top-bottom-border;
      }

      /* Center display button  */

      &.mbsc-fr-center .mbsc-fr-btn-w {
        border-top: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }

      &.mbsc-fr-center .mbsc-rtl .mbsc-fr-btn-w {
        border-right: 1px solid $border-color;
      }

      &.mbsc-fr-center.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
      &.mbsc-fr-center .mbsc-fr-btn.mbsc-active {
        background: $button-active;
      }

      /* Inline display */

      &.mbsc-fr-inline .mbsc-fr-w {
        background: $background-alt;
        border-top: 1px solid $border-color;
      }

      &.mbsc-fr-inline .mbsc-fr-c {
        border-bottom: 1px solid $border-color;
      }

      &.mbsc-fr-no-overlay {
        .mbsc-fr-arr {
          box-shadow: 0 0 1em $popup-arrow-shadow;
        }

        .mbsc-fr-w {
          box-shadow: 0 .125em 1em $popup-arrow-shadow;
        }
      }

      /* Desktop view */

      &.mbsc-fr-pointer {

        .mbsc-fr-persp .mbsc-fr-popup .mbsc-fr-w,
        .mbsc-fr-persp .mbsc-cal-picker {
          background: $background-desktop;
        }

        .mbsc-fr-hdr {
          border-color: $popup-border;
        }
      }
    }
  }
}




@include exports("common") {
  .mbsc-cloak {
    visibility: hidden !important;
  }

  /* Empty view */

  .mbsc-empty {
    text-align: center;
    margin: 3em;
    color: inherit;
  }

  .mbsc-empty h3 {
    margin: .666666em 0;
    padding: 0;
    color: inherit;
    font-size: 1.5em;
    font-weight: normal;
    font-family: inherit;
  }

  .mbsc-empty p {
    margin: 1em 0;
    padding: 0;
    font-size: 1em;
    line-height: 1.5;
  }
}



@include exports("animation") {

  .mbsc-anim-trans .mbsc-fr-scroll {
    overflow: hidden;
  }

  .mbsc-anim-trans-flip .mbsc-fr-persp,
  .mbsc-anim-trans-swing .mbsc-fr-persp {
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }

  .mbsc-anim-trans .mbsc-fr-popup,
  .mbsc-anim-trans .mbsc-fr-overlay {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .mbsc-anim-in .mbsc-fr-popup,
  .mbsc-anim-in .mbsc-fr-overlay {
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-duration: 225ms;
    animation-timing-function: ease-out;
    animation-duration: 225ms;
  }

  .mbsc-anim-out .mbsc-fr-popup,
  .mbsc-anim-out .mbsc-fr-overlay {
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-duration: 195ms;
    animation-timing-function: ease-in;
    animation-duration: 195ms;
  }

  .mbsc-anim-in .mbsc-fr-overlay {
    -webkit-animation-name: mbsc-anim-f-in;
    animation-name: mbsc-anim-f-in;
  }

  .mbsc-anim-out .mbsc-fr-overlay {
    -webkit-animation-name: mbsc-anim-f-out;
    animation-name: mbsc-anim-f-out;
  }

  .mbsc-anim-flip,
  .mbsc-anim-swing,
  .mbsc-anim-slidehorizontal,
  .mbsc-anim-slidevertical,
  .mbsc-anim-slidedown,
  .mbsc-anim-slideup,
  .mbsc-anim-fade {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateX(0);
    backface-visibility: hidden;
    transform: translateX(0);
  }

  .mbsc-anim-swing,
  .mbsc-anim-slidehorizontal,
  .mbsc-anim-slidevertical,
  .mbsc-anim-slidedown,
  .mbsc-anim-slideup,
  .mbsc-anim-fade {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  .mbsc-anim-flip,
  .mbsc-anim-pop {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  .mbsc-anim-in .mbsc-anim-pop {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-p-in;
    -webkit-animation-duration: 100ms;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-p-in;
    animation-duration: 100ms;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-pop {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-p-out;
    -webkit-animation-duration: 150ms;
    animation-name: mbsc-anim-p-out;
    animation-duration: 150ms;
  }

  .mbsc-anim-trans-pop .mbsc-fr-overlay {
    -webkit-animation-duration: 150ms;
    animation-duration: 150ms;
  }

  .mbsc-anim-in .mbsc-anim-flip {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-fl-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-fl-in;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-flip {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-fl-out;
    animation-name: mbsc-anim-fl-out;
  }

  .mbsc-anim-in .mbsc-anim-swing {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sw-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-sw-in;
  }

  .mbsc-anim-out .mbsc-anim-swing {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sw-out;
    animation-name: mbsc-anim-sw-out;
  }

  .mbsc-anim-in .mbsc-anim-slidehorizontal {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sh-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-sh-in;
  }

  .mbsc-anim-out .mbsc-anim-slidehorizontal {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sh-out;
    animation-name: mbsc-anim-sh-out;
  }

  .mbsc-anim-in .mbsc-anim-slidevertical {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sv-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-sv-in;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-slidevertical {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sv-out;
    animation-name: mbsc-anim-sv-out;
  }

  .mbsc-anim-in .mbsc-anim-slidedown {
    -webkit-animation-name: mbsc-anim-sd-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-sd-in;
    transform: scale(1);
  }

  .mbsc-anim-out .mbsc-anim-slidedown {
    -webkit-animation-name: mbsc-anim-sd-out;
    -webkit-transform: translateY(-100%);
    animation-name: mbsc-anim-sd-out;
  }

  .mbsc-anim-in .mbsc-anim-slideup {
    -webkit-animation-name: mbsc-anim-su-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-su-in;
  }

  .mbsc-anim-out .mbsc-anim-slideup {
    -webkit-animation-name: mbsc-anim-su-out;
    -webkit-transform: translateY(100%);
    animation-name: mbsc-anim-su-out;
  }

  .mbsc-anim-in .mbsc-anim-fade {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-f-in;
    animation-name: mbsc-anim-f-in;
  }

  .mbsc-anim-out .mbsc-anim-fade {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-f-out;
    animation-name: mbsc-anim-f-out;
  }

  .mbsc-fr-pointer {
    &.mbsc-anim-in .mbsc-anim-slidedown {
      -webkit-animation-name: mbsc-anim-sd-in, mbsc-anim-f-in;
      animation-name: mbsc-anim-sd-in, mbsc-anim-f-in;
    }

    &.mbsc-anim-out .mbsc-anim-slidedown {
      -webkit-animation-name: mbsc-anim-sd-out, mbsc-anim-f-out;
      animation-name: mbsc-anim-sd-out, mbsc-anim-f-out;
    }

    &.mbsc-anim-in .mbsc-anim-slideup {
      -webkit-animation-name: mbsc-anim-su-in, mbsc-anim-f-in;
      animation-name: mbsc-anim-su-in, mbsc-anim-f-in;
    }

    &.mbsc-anim-out .mbsc-anim-slideup {
      -webkit-animation-name: mbsc-anim-su-out, mbsc-anim-f-out;
      animation-name: mbsc-anim-su-out, mbsc-anim-f-out;
    }
  }

  /* Fade in */

  @keyframes mbsc-anim-f-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes mbsc-anim-f-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }


  /* Fade out */

  @keyframes mbsc-anim-f-out {
    from {
      visibility: visible;
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @-webkit-keyframes mbsc-anim-f-out {
    from {
      visibility: visible;
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }


  /* Pop in */

  @keyframes mbsc-anim-p-in {
    from {
      opacity: 0;
      transform: scale(0.8);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @-webkit-keyframes mbsc-anim-p-in {
    from {
      opacity: 0;
      -webkit-transform: scale(0.8);
    }

    to {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }


  /* Pop out */

  @keyframes mbsc-anim-p-out {
    from {
      opacity: 1;
      transform: scale(1);
    }

    to {
      opacity: 0;
      transform: scale(0.8);
    }
  }

  @-webkit-keyframes mbsc-anim-p-out {
    from {
      opacity: 1;
      -webkit-transform: scale(1);
    }

    to {
      opacity: 0;
      -webkit-transform: scale(0.8);
    }
  }


  /* Flip in */

  @keyframes mbsc-anim-fl-in {
    from {
      opacity: 0;
      transform: rotateY(90deg);
    }

    to {
      opacity: 1;
      transform: rotateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-fl-in {
    from {
      opacity: 0;
      -webkit-transform: rotateY(90deg);
    }

    to {
      opacity: 1;
      -webkit-transform: rotateY(0);
    }
  }


  /* Flip out */

  @keyframes mbsc-anim-fl-out {
    from {
      opacity: 1;
      transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      transform: rotateY(-90deg);
    }
  }

  @-webkit-keyframes mbsc-anim-fl-out {
    from {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }
  }


  /* Swing in */

  @keyframes mbsc-anim-sw-in {
    from {
      opacity: 0;
      transform: rotateY(-90deg);
    }

    to {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }

  @-webkit-keyframes mbsc-anim-sw-in {
    from {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }

    to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }
  }


  /* Swing out */

  @keyframes mbsc-anim-sw-out {
    from {
      opacity: 1;
      transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      transform: rotateY(-90deg);
    }
  }

  @-webkit-keyframes mbsc-anim-sw-out {
    from {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }

    to {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }
  }


  /* Slide horizontal in */

  @keyframes mbsc-anim-sh-in {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @-webkit-keyframes mbsc-anim-sh-in {
    from {
      opacity: 0;
      -webkit-transform: translateX(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }


  /* Slide horizontal out */

  @keyframes mbsc-anim-sh-out {
    from {
      opacity: 1;
      transform: translateX(0);
    }

    to {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  @-webkit-keyframes mbsc-anim-sh-out {
    from {
      opacity: 1;
      -webkit-transform: translateX(0);
    }

    to {
      opacity: 0;
      -webkit-transform: translateX(100%);
    }
  }


  /* Slide vertical in */

  @keyframes mbsc-anim-sv-in {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-sv-in {
    from {
      opacity: 0;
      -webkit-transform: translateY(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }


  /* Slide vertical out */

  @keyframes mbsc-anim-sv-out {
    from {
      opacity: 1;
      transform: translateY(0);
    }

    to {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  @-webkit-keyframes mbsc-anim-sv-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }

    to {
      opacity: 0;
      -webkit-transform: translateY(100%);
    }
  }


  /* Slide Down In */

  @keyframes mbsc-anim-sd-in {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-sd-in {
    from {
      opacity: 1;
      -webkit-transform: translateY(-100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }


  /* Slide down out */

  @keyframes mbsc-anim-sd-out {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-100%);
    }
  }

  @-webkit-keyframes mbsc-anim-sd-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(-100%);
    }
  }


  /* Slide Up In */

  @keyframes mbsc-anim-su-in {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes mbsc-anim-su-in {
    from {
      opacity: 1;
      -webkit-transform: translateY(100%);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }


  /* Slide up out */

  @keyframes mbsc-anim-su-out {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(100%);
    }
  }

  @-webkit-keyframes mbsc-anim-su-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }

    to {
      opacity: 1;
      -webkit-transform: translateY(100%);
    }
  }
}



$mbsc-font-path: '' !default;

@include exports("icons") {

    @font-face {
        font-family: 'icons_mobiscroll';
        src: 
        url($mbsc-font-path + 'icons_mobiscroll.woff?pjreyn') format('woff'),
        url($mbsc-font-path + 'icons_mobiscroll.woff') format('woff'),
        url($mbsc-font-path + 'icons_mobiscroll.ttf?pjreyn') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    .mbsc-ic:before {
        font-family: 'icons_mobiscroll';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

/* Icons */
 
.mbsc-ic-aid::before {
    content: "\ea01";
} 
.mbsc-ic-airplane::before {
    content: "\ea02";
} 
.mbsc-ic-alarm2::before {
    content: "\ea03";
} 
.mbsc-ic-arrow-down5::before {
    content: "\ea04";
} 
.mbsc-ic-arrow-left2::before {
    content: "\ea05";
} 
.mbsc-ic-arrow-left4::before {
    content: "\ea06";
} 
.mbsc-ic-arrow-left5::before {
    content: "\ea07";
} 
.mbsc-ic-arrow-left6::before {
    content: "\ea08";
} 
.mbsc-ic-arrow-right2::before {
    content: "\ea09";
} 
.mbsc-ic-arrow-right4::before {
    content: "\ea0a";
} 
.mbsc-ic-arrow-right5::before {
    content: "\ea0b";
} 
.mbsc-ic-arrow-right6::before {
    content: "\ea0c";
} 
.mbsc-ic-arrow-up5::before {
    content: "\ea0d";
} 
.mbsc-ic-attachment::before {
    content: "\ea0e";
} 
.mbsc-ic-backspace::before {
    content: "\ea0f";
} 
.mbsc-ic-backspace3::before {
    content: "\ea10";
} 
.mbsc-ic-backspace4::before {
    content: "\ea11";
} 
.mbsc-ic-bars::before {
    content: "\ea12";
} 
.mbsc-ic-book::before {
    content: "\ea13";
} 
.mbsc-ic-bubble::before {
    content: "\ea14";
} 
.mbsc-ic-bubbles::before {
    content: "\ea15";
} 
.mbsc-ic-bullhorn::before {
    content: "\ea16";
} 
.mbsc-ic-calendar::before {
    content: "\ea17";
} 
.mbsc-ic-camera::before {
    content: "\ea18";
} 
.mbsc-ic-cart::before {
    content: "\ea19";
} 
.mbsc-ic-checkmark::before {
    content: "\ea1a";
} 
.mbsc-ic-clock::before {
    content: "\ea1b";
} 
.mbsc-ic-close::before {
    content: "\ea1c";
} 
.mbsc-ic-cloud-download::before {
    content: "\ea1d";
} 
.mbsc-ic-cloud-upload::before {
    content: "\ea1e";
} 
.mbsc-ic-cogs::before {
    content: "\ea1f";
} 
.mbsc-ic-connection::before {
    content: "\ea20";
} 
.mbsc-ic-copy2::before {
    content: "\ea21";
} 
.mbsc-ic-copy3::before {
    content: "\ea22";
} 
.mbsc-ic-credit::before {
    content: "\ea23";
} 
.mbsc-ic-disk::before {
    content: "\ea24";
} 
.mbsc-ic-download::before {
    content: "\ea25";
} 
.mbsc-ic-drawer::before {
    content: "\ea26";
} 
.mbsc-ic-droplet::before {
    content: "\ea27";
} 
.mbsc-ic-earth::before {
    content: "\ea28";
} 
.mbsc-ic-eye-blocked::before {
    content: "\ea29";
} 
.mbsc-ic-eye::before {
    content: "\ea2a";
} 
.mbsc-ic-fa-globe::before {
    content: "\ea2b";
} 
.mbsc-ic-fa-leaf::before {
    content: "\ea2c";
} 
.mbsc-ic-fa-mail-reply::before {
    content: "\ea2d";
} 
.mbsc-ic-fa-retweet::before {
    content: "\ea2e";
} 
.mbsc-ic-fa-rotate-left::before {
    content: "\ea2f";
} 
.mbsc-ic-file4::before {
    content: "\ea30";
} 
.mbsc-ic-film::before {
    content: "\ea31";
} 
.mbsc-ic-flag::before {
    content: "\ea32";
} 
.mbsc-ic-folder::before {
    content: "\ea33";
} 
.mbsc-ic-forward::before {
    content: "\ea34";
} 
.mbsc-ic-foundation-mail::before {
    content: "\ea35";
} 
.mbsc-ic-foundation-minus-circle::before {
    content: "\ea36";
} 
.mbsc-ic-globe::before {
    content: "\ea37";
} 
.mbsc-ic-heart::before {
    content: "\ea38";
} 
.mbsc-ic-history::before {
    content: "\ea39";
} 
.mbsc-ic-home::before {
    content: "\ea3a";
} 
.mbsc-ic-image2::before {
    content: "\ea3b";
} 
.mbsc-ic-ion-android-system-windows::before {
    content: "\ea3c";
} 
.mbsc-ic-ion-bluetooth::before {
    content: "\ea3d";
} 
.mbsc-ic-ion-close-circled::before {
    content: "\ea3e";
} 
.mbsc-ic-ion-ios7-arrow-back::before {
    content: "\ea3f";
} 
.mbsc-ic-ion-ios7-arrow-forward::before {
    content: "\ea40";
} 
.mbsc-ic-ion-ios7-checkmark-empty::before {
    content: "\ea41";
} 
.mbsc-ic-ion-navigate::before {
    content: "\ea42";
} 
.mbsc-ic-ios-backspace::before {
    content: "\ea43";
} 
.mbsc-ic-key2::before {
    content: "\ea44";
} 
.mbsc-ic-library::before {
    content: "\ea45";
} 
.mbsc-ic-line-settings::before {
    content: "\ea46";
} 
.mbsc-ic-link::before {
    content: "\ea47";
} 
.mbsc-ic-location::before {
    content: "\ea48";
} 
.mbsc-ic-lock2::before {
    content: "\ea49";
} 
.mbsc-ic-loop2::before {
    content: "\ea4a";
} 
.mbsc-ic-map::before {
    content: "\ea4b";
} 
.mbsc-ic-material-arrow-back::before {
    content: "\ea4c";
} 
.mbsc-ic-material-backspace::before {
    content: "\ea4d";
} 
.mbsc-ic-material-brightness-medium::before {
    content: "\ea4e";
} 
.mbsc-ic-material-check-box-outline-blank::before {
    content: "\ea4f";
} 
.mbsc-ic-material-check::before {
    content: "\ea50";
} 
.mbsc-ic-material-close::before {
    content: "\ea51";
} 
.mbsc-ic-material-crop::before {
    content: "\ea52";
} 
.mbsc-ic-material-equalizer::before {
    content: "\ea53";
} 
.mbsc-ic-material-filter::before {
    content: "\ea54";
} 
.mbsc-ic-material-iso::before {
    content: "\ea55";
} 
.mbsc-ic-material-keyboard-arrow-down::before {
    content: "\ea56";
} 
.mbsc-ic-material-keyboard-arrow-left::before {
    content: "\ea57";
} 
.mbsc-ic-material-keyboard-arrow-right::before {
    content: "\ea58";
} 
.mbsc-ic-material-keyboard-arrow-up::before {
    content: "\ea59";
} 
.mbsc-ic-material-menu::before {
    content: "\ea5a";
} 
.mbsc-ic-material-more-horiz::before {
    content: "\ea5b";
} 
.mbsc-ic-material-palette::before {
    content: "\ea5c";
} 
.mbsc-ic-material-pause::before {
    content: "\ea5d";
} 
.mbsc-ic-material-people::before {
    content: "\ea5e";
} 
.mbsc-ic-material-photo-size-select-large::before {
    content: "\ea5f";
} 
.mbsc-ic-material-play-arrow::before {
    content: "\ea60";
} 
.mbsc-ic-material-repeat::before {
    content: "\ea61";
} 
.mbsc-ic-material-rotate-right::before {
    content: "\ea62";
} 
.mbsc-ic-material-shuffle::before {
    content: "\ea63";
} 
.mbsc-ic-material-skip-next::before {
    content: "\ea64";
} 
.mbsc-ic-material-skip-previous::before {
    content: "\ea65";
} 
.mbsc-ic-material-star-outline::before {
    content: "\ea66";
} 
.mbsc-ic-material-star::before {
    content: "\ea67";
} 
.mbsc-ic-material-stop::before {
    content: "\ea68";
} 
.mbsc-ic-material-texture::before {
    content: "\ea69";
} 
.mbsc-ic-material-wb-auto::before {
    content: "\ea6a";
} 
.mbsc-ic-meteo-cloud2::before {
    content: "\ea6b";
} 
.mbsc-ic-meteo-cloud3::before {
    content: "\ea6c";
} 
.mbsc-ic-meteo-cloudy::before {
    content: "\ea6d";
} 
.mbsc-ic-meteo-sun::before {
    content: "\ea6e";
} 
.mbsc-ic-minus::before {
    content: "\ea6f";
} 
.mbsc-ic-mobile::before {
    content: "\ea70";
} 
.mbsc-ic-music::before {
    content: "\ea71";
} 
.mbsc-ic-neutral::before {
    content: "\ea72";
} 
.mbsc-ic-newspaper::before {
    content: "\ea73";
} 
.mbsc-ic-office::before {
    content: "\ea74";
} 
.mbsc-ic-pause2::before {
    content: "\ea75";
} 
.mbsc-ic-pencil::before {
    content: "\ea76";
} 
.mbsc-ic-phone::before {
    content: "\ea77";
} 
.mbsc-ic-play::before {
    content: "\ea78";
} 
.mbsc-ic-play3::before {
    content: "\ea79";
} 
.mbsc-ic-plus::before {
    content: "\ea7a";
} 
.mbsc-ic-redo2::before {
    content: "\ea7b";
} 
.mbsc-ic-remove::before {
    content: "\ea7c";
} 
.mbsc-ic-reply::before {
    content: "\ea7d";
} 
.mbsc-ic-sad::before {
    content: "\ea7e";
} 
.mbsc-ic-sad2::before {
    content: "\ea7f";
} 
.mbsc-ic-share::before {
    content: "\ea80";
} 
.mbsc-ic-smiley::before {
    content: "\ea81";
} 
.mbsc-ic-smiley2::before {
    content: "\ea82";
} 
.mbsc-ic-stack::before {
    content: "\ea83";
} 
.mbsc-ic-star::before {
    content: "\ea84";
} 
.mbsc-ic-star3::before {
    content: "\ea85";
} 
.mbsc-ic-stop2::before {
    content: "\ea86";
} 
.mbsc-ic-stopwatch::before {
    content: "\ea87";
} 
.mbsc-ic-support::before {
    content: "\ea88";
} 
.mbsc-ic-tag::before {
    content: "\ea89";
} 
.mbsc-ic-thumbs-up::before {
    content: "\ea8a";
} 
.mbsc-ic-thumbs-up2::before {
    content: "\ea8b";
} 
.mbsc-ic-undo2::before {
    content: "\ea8c";
} 
.mbsc-ic-unlocked::before {
    content: "\ea8d";
} 
.mbsc-ic-upload::before {
    content: "\ea8e";
} 
.mbsc-ic-user4::before {
    content: "\ea8f";
} 
.mbsc-ic-volume-high::before {
    content: "\ea90";
} 
.mbsc-ic-volume-medium::before {
    content: "\ea91";
} 
.mbsc-ic-volume-mute2::before {
    content: "\ea92";
}

}

@include exports("frame") {

  .mbsc-fr-w,
  .mbsc-fr-overlay {
    -webkit-transform: translateZ(0);
  }

  .mbsc-fr {
    pointer-events: none;
    z-index: 99998;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
  }

  .mbsc-fr-focus {
    outline: 0;
  }

  .mbsc-fr-lock-ctx {
    position: relative;
  }

  .mbsc-fr-lock.mbsc-fr-lock-ios {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
  }

  .mbsc-fr-pos {
    visibility: hidden;
  }

  .mbsc-fr-scroll {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .mbsc-fr-popup {
    max-width: 98%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    font-size: 12px;
    text-shadow: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }

  .mbsc-rtl {
    direction: rtl;
  }


  /* Box sizing */

  .mbsc-fr-popup,
  .mbsc-fr-btn-cont,
  .mbsc-fr-arr {
    box-sizing: border-box;
  }

  .mbsc-fr .mbsc-fr-w {
    box-sizing: content-box;
  }

  .mbsc-fr-w {
    min-width: 256px;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    font-family: arial, verdana, sans-serif;
  }


  /* Modal overlay */

  .mbsc-fr,
  .mbsc-fr-persp,
  .mbsc-fr-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .mbsc-fr-lock .mbsc-fr-persp {
    -ms-touch-action: none;
    touch-action: none;
  }

  .mbsc-fr-lock-ctx > .mbsc-fr,
  .mbsc-fr-lock-ctx .mbsc-fr-persp,
  .mbsc-fr-lock-ctx .mbsc-fr-overlay {
    position: absolute;
  }

  .mbsc-fr-persp {
    pointer-events: auto;
    overflow: hidden;
  }

  .mbsc-fr-overlay {
    z-index: 1;
    background: rgba(0, 0, 0, .7);
  }


  /* Liquid mode */

  .mbsc-fr-liq .mbsc-fr-popup {
    max-width: 100%;
  }


  /* Top/Bottom mode */

  .mbsc-fr-top .mbsc-fr-popup,
  .mbsc-fr-bottom .mbsc-fr-popup {
    width: 100%;
    max-width: 100%;
  }

  .mbsc-fr-top .mbsc-fr-w,
  .mbsc-fr-bottom .mbsc-fr-w {
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
  }

  .mbsc-fr-bottom .mbsc-fr-w {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .mbsc-fr-top .mbsc-fr-popup {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }


  /* Inline mode */

  .mbsc-fr-inline {
    position: relative;
    pointer-events: auto;
    z-index: 0;
  }

  .mbsc-fr-inline .mbsc-fr-popup {
    position: static;
    max-width: 100%;
  }


  /* Bubble mode */

  .mbsc-fr-bubble,
  .mbsc-fr-bubble .mbsc-fr-persp {
    position: absolute;
  }

  .mbsc-fr-bubble .mbsc-fr-arr-w {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    overflow: hidden;
  }

  .mbsc-fr-bubble-top .mbsc-fr-arr-w {
    top: 100%;
  }

  .mbsc-fr-bubble-bottom .mbsc-fr-arr-w {
    bottom: 100%;
  }

  .mbsc-fr-bubble .mbsc-fr-arr-i {
    margin: 0 1.75em;
    position: relative;
    direction: ltr;
  }

  .mbsc-fr-bubble .mbsc-fr-arr {
    display: block;
  }

  .mbsc-fr-arr {
    display: none;
    position: relative;
    left: 0;
    width: 2em;
    height: 2em;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-left: -1em;
  }

  .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: 1.333334em;
  }

  .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: -1.333334em;
  }

  .mbsc-fr-hdn {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
  }


  /* Header */

  .mbsc-fr-hdr {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }


  /* Buttons */

  .mbsc-fr-btn {
    overflow: hidden;
    display: block;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
  }

  .mbsc-fr-btn-e {
    cursor: pointer;
  }

  .mbsc-fr-btn.mbsc-disabled {
    cursor: not-allowed;
  }


  /* Button container */

  .mbsc-fr-btn-cont {
    display: table;
    width: 100%;
    text-align: center;
    white-space: normal;
  }

  .mbsc-fr-btn-cont .mbsc-disabled {
    opacity: .3;
  }


  /* Button wrapper */

  .mbsc-fr-btn-w {
    vertical-align: top;
    display: table-cell;
    position: relative;
    z-index: 5;
  }

  .mbsc-fr-btn-w .mbsc-fr-btn:before {
    padding: .375em;
  }

  /* Desktop view */

  .mbsc-fr-pointer {

    /* Embedded components */
    &.mbsc-fr .mbsc-fr-w .mbsc-fr-inline .mbsc-fr-w {
      box-shadow: none;
      border-radius: 0;
    }

    .mbsc-ltr .mbsc-fr-w,
    .mbsc-ltr .mbsc-sc-whl .mbsc-sel-gr {
      text-align: left;
    }

    .mbsc-rtl .mbsc-fr-w,
    .mbsc-rtl .mbsc-sc-whl .mbsc-sel-gr {
      text-align: right;
    }

    &.mbsc-fr-top .mbsc-fr-w,
    &.mbsc-fr-bottom .mbsc-fr-w {
      pointer-events: auto;
      display: inline-block;
      margin-top: 3em;
      margin-bottom: 3em;
      max-width: 98%;
    }

    &.mbsc-fr-top .mbsc-fr-popup,
    &.mbsc-fr-bottom .mbsc-fr-popup {
      text-align: center;
      pointer-events: none;
    }

    &.mbsc-fr-bubble .mbsc-fr-arr-w {
      display: none;
    }

    .mbsc-sel-empty {
      text-align: center;
    }
  }
}


@include exports("frame.ios") {
  .mbsc-ios {

    &.mbsc-fr-top .mbsc-fr-btn-cont,
    &.mbsc-fr-bottom .mbsc-fr-btn-cont {
      padding-left: constant(safe-area-inset-left);
      padding-left: env(safe-area-inset-left);
      padding-right: constant(safe-area-inset-right);
      padding-right: env(safe-area-inset-right);
    }

    .mbsc-fr-w {
      position: relative;
      padding-top: 3.666667em;
      font-size: 12px;
      font-family: -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;
    }

    .mbsc-fr-has-hdr .mbsc-fr-w {
      min-width: 25em;
    }

    &.mbsc-fr-nobtn .mbsc-fr-w,
    &.mbsc-fr-center .mbsc-fr-w {
      min-width: 22.5em;
    }

    .mbsc-fr-hdr {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      left: 0;
      line-height: 1.25em;
      padding: .75em 4.375em;
      font-size: 1.333334em;
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.mbsc-fr-nobtn .mbsc-fr-hdr,
    &.mbsc-fr-center:not(.mbsc-cal-liq) .mbsc-fr-hdr {
      position: relative;
      padding: .75em .5em;
      margin-bottom: -1px;
      white-space: normal;
    }

    .mbsc-fr-btn-cont {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: block;
    }

    .mbsc-ltr .mbsc-fr-btn-cont {
      text-align: right;
    }

    .mbsc-rtl .mbsc-fr-btn-cont {
      text-align: left;
    }

    .mbsc-fr-btn {
      height: 2.75em;
      line-height: 2.75em;
      padding: 0 .625em;
      text-align: center;
      font-size: 1.333334em;
    }

    .mbsc-fr-btn.mbsc-active {
      opacity: .5;
    }

    .mbsc-fr-btn-w {
      display: inline-block;
    }

    .mbsc-ltr .mbsc-fr-btn-c {
      float: left;
    }

    .mbsc-rtl .mbsc-fr-btn-c {
      float: right;
    }

    .mbsc-fr-btn-s .mbsc-fr-btn {
      font-weight: bold;
    }

    &.mbsc-fr-bubble .mbsc-fr-w,
    &.mbsc-fr-center .mbsc-fr-w {
      border-radius: 8px;
    }

    &.mbsc-fr-inline .mbsc-fr-w {
      border-radius: 0;
    }

    /* Bubble arrow */

    .mbsc-fr-arr {
      border-radius: 6px;
    }

    /* Top and bottom display */

    &.mbsc-fr-bottom .mbsc-fr-overlay,
    &.mbsc-fr-top .mbsc-fr-overlay {
      background: none;
    }

    /* Center display button  */

    &.mbsc-fr-center .mbsc-fr-w {
      padding-top: 0;
      padding-bottom: 3.75em;
    }

    &.mbsc-fr-center.mbsc-fr-btn-block .mbsc-fr-w {
      padding: 0;
    }

    &.mbsc-fr-center .mbsc-fr-btn-cont {
      display: table;
      top: auto;
      bottom: 0;
      border: 0;
      table-layout: fixed;
    }

    &.mbsc-fr-center .mbsc-fr-btn-w {
      display: table-cell;
      float: none;
    }

    &.mbsc-fr-center .mbsc-fr-btn-w:first-child {
      border-left: 0;
    }

    &.mbsc-fr-center .mbsc-rtl .mbsc-fr-btn-w {
      border-left: 0;
    }

    &.mbsc-fr-center .mbsc-rtl .mbsc-fr-btn-w:first-child {
      border-right: 0;
    }

    &.mbsc-fr-center .mbsc-ltr .mbsc-fr-btn-w:first-child .mbsc-fr-btn,
    &.mbsc-fr-center .mbsc-rtl .mbsc-fr-btn-w:last-child .mbsc-fr-btn {
      border-radius: 0 0 0 .5em;
    }

    &.mbsc-fr-center .mbsc-ltr .mbsc-fr-btn-w:last-child .mbsc-fr-btn,
    &.mbsc-fr-center .mbsc-rtl .mbsc-fr-btn-w:first-child .mbsc-fr-btn {
      border-radius: 0 0 .5em 0;
    }

    &.mbsc-fr-center .mbsc-fr-btn.mbsc-active {
      opacity: 1;
    }

    &.mbsc-fr-center.mbsc-fr-btn-block .mbsc-fr-btn-cont {
      position: static;
      display: block;
    }

    &.mbsc-fr-center.mbsc-fr-btn-block .mbsc-fr-btn-w {
      display: block;
      border-left: 0;
      border-right: 0;
    }

    &.mbsc-fr-center.mbsc-fr-btn-block .mbsc-fr-w .mbsc-fr-btn-w .mbsc-fr-btn {
      border-radius: 0;
    }

    &.mbsc-fr-center.mbsc-fr-btn-block .mbsc-fr-btn-w:last-child .mbsc-fr-btn {
      border-radius: 0 0 .5em .5em;
    }

    /* Inline display */

    &.mbsc-fr-inline .mbsc-fr-w {
      margin-top: -1px;
    }

    /* No buttons */

    &.mbsc-fr-nobtn .mbsc-fr-w {
      padding: 0;
    }

    &.mbsc-fr-nobtn.mbsc-fr-bottom .mbsc-fr-w {
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
    }

    /* Desktop view */

    &.mbsc-fr-pointer {
      &.mbsc-fr .mbsc-fr-popup {
        border: 0;
      }

      .mbsc-fr-hdr {
        font-size: 1.166667em;
        line-height: 1.5em;
      }
    }
  }

  @include mbsc-ios-frame(ios, $mbsc-ios-colors);
}





@mixin mbsc-ios-scroller($theme, $params) {
  @include exports("scroller.#{$theme}.colors") {

    @include mbsc-ios-frame($theme, $params);

    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');
    $background: map-get($params, 'background');

    $button: '';
    @if (map-get($params, 'button')) {
      $button: map-get($params, 'button');
    }
    @else {
      $button: $accent;
    }

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    // calculated colors
    $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
    $background-alt: lighten($background-limited, 3%);
    $cont-background: lighten($background, 6%);
    $item-color: hsl(hue($text), saturation($text), 62%);

    $top-bottom-frame: '';
    $border-color: '';
    $item-3d: '';
    $popup-border: '';
    $label-text: '';
    $button-active: '';
    $top-bottom-wheel-border: '';
    @if (lightness($background) > 50%) {
      $top-bottom-frame: adjust-hue(darken(saturate($background, 12%), 13%), calc(216% / 1%));
      $border-color: darken($background-limited, 17%);
      $item-3d: darken($background-limited, 33%);
      $popup-border: $border-color;
      $label-text: lighten($text, 67%);
      $button-active: darken($background, 5%);
      $top-bottom-wheel-border: darken($background-limited, 30%);
    }
    @else {
      $top-bottom-frame: $background-limited;
      $border-color: lighten($background, 20%);
      $item-3d: lighten($background-limited, 40%);
      $popup-border: lighten($border-color, 13%);
      $label-text: $text;
      $button-active: lighten($background, 12%);
      $top-bottom-wheel-border: $border-color;
    }

    .mbsc-#{$theme} {
      /* Scroller */

      &.mbsc-sc.mbsc-fr-top .mbsc-fr-w,
      &.mbsc-sc.mbsc-fr-bottom .mbsc-fr-w {
        background: $top-bottom-frame;
      }

      &.mbsc-calendar .mbsc-fr-persp .mbsc-fr-w {
        background: $background-limited;
      }

      &.mbsc-calendar.mbsc-fr-top .mbsc-fr-btn-cont,
      &.mbsc-calendar.mbsc-fr-bottom .mbsc-fr-btn-cont {
        border-bottom: 1px solid $border-color;
      }

      /* Top / bottom color theme */

      &.mbsc-fr-top .mbsc-sc-whl-l,
      &.mbsc-fr-bottom .mbsc-sc-whl-l {
        border-top: 1px solid $top-bottom-wheel-border;
        border-bottom: 1px solid $top-bottom-wheel-border;
      }

      .mbsc-sc-whl-l,
      &.mbsc-calendar .mbsc-sc-whl-l {
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
      }

      &.mbsc-fr-top .mbsc-sc-whl-o,
      &.mbsc-fr-bottom .mbsc-sc-whl-o {
        background: -webkit-linear-gradient($top-bottom-frame, rgba($top-bottom-frame, 0) 52%, rgba($top-bottom-frame, 0) 48%, $top-bottom-frame);
        background: linear-gradient($top-bottom-frame, rgba($top-bottom-frame, 0) 52%, rgba($top-bottom-frame, 0) 48%, $top-bottom-frame);
      }

      .mbsc-sc-whl-o,
      &.mbsc-calendar .mbsc-sc-whl-o {
        background: -webkit-linear-gradient($background-limited, rgba($background-limited, 0) 52%, rgba($background-limited, 0) 48%, $background-limited);
        background: linear-gradient($background-limited, rgba($background-limited, 0) 52%, rgba($background-limited, 0) 48%, $background-limited);
      }

      &.mbsc-fr-top .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c,
      &.mbsc-fr-bottom .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
        background: $top-bottom-frame;
      }

      /* Inline color theme */

      &.mbsc-fr.mbsc-fr-inline .mbsc-sc-whl-o {
        background: -webkit-linear-gradient($background-alt, rgba($background-alt, 0) 52%, rgba($background-alt, 0) 48%, $background-alt);
        background: linear-gradient($background-alt, rgba($background-alt, 0) 52%, rgba($background-alt, 0) 48%, $background-alt);
      }

      &.mbsc-fr.mbsc-fr-inline .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
        background: $background-alt;
      }

      /* Wheel label */

      .mbsc-sc-lbl {
        color: $label-text;
      }

      .mbsc-sc-itm {
        color: $item-color;
      }

      &.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
      .mbsc-sc-itm:focus {
        background: rgba($button, .15);
      }

      &.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
        background: rgba($button, .2);
      }

      .mbsc-sc-itm-sel,
      .mbsc-sc-whl-gr-3d .mbsc-sc-itm {
        color: $text;
      }


      /* 3D */

      .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c,
      &.mbsc-calendar .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
        background: $background-limited;
      }

      .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
        color: $item-3d;
      }

      /* Clickpick mode */

      .mbsc-sc-btn {
        color: $button;
      }

      /* Multiple select */

      &.mbsc-sel-multi .mbsc-sc-itm {
        color: $text;
      }

      .mbsc-sc-whl-multi .mbsc-sc-itm-sel {
        color: $accent;
      }

      /* Desktop view */

      &.mbsc-fr-pointer {
        .mbsc-sc-whl-l {
          border-color: $popup-border;
        }

        .mbsc-sc-itm {
          color: $text;
        }

        .mbsc-sc-itm-sel {
          color: $accent;
        }
      }
    }
  }
}




@include exports("scroller") {

  .mbsc-sc-whl-o,
  .mbsc-sc-btn {
    /* Prevent flickering on animation */
    -webkit-transform: translateZ(0);
  }

  /* Force content box */

  .mbsc-sc .mbsc-sc-whl-c,
  .mbsc-sc .mbsc-sc-whl-l,
  .mbsc-sc .mbsc-sc-whl {
    box-sizing: content-box;
  }

  /* Force border box */

  .mbsc-sc-whl-gr-c,
  .mbsc-sc-itm {
    box-sizing: border-box;
  }

  .mbsc-sc-whl-gr-c {
    position: relative;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
  }

  .mbsc-fr-bottom .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-top .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-inline .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-liq .mbsc-sc-whl-gr-c {
    display: block;
  }

  .mbsc-sc-whl-gr {
    margin: 0 auto;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .mbsc-sc-whl-w {
    max-width: 100%;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
  }

  .mbsc-fr-pointer .mbsc-sc-whl-w,
  .mbsc-fr-liq .mbsc-sc-whl-w {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }

  .mbsc-sc-whl-o {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    display: none;
  }

  .mbsc-sc-whl-l {
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    pointer-events: none;
  }

  .mbsc-sc-whl-w .mbsc-sc-whl-l {
    display: block;
  }

  .mbsc-sc-whl {
    overflow: hidden;
    /* Forces IE to respect overflow hidden while animating */
    /* Looks like this is not needed, also, it brakes rendering on Samsung S5 Mini */
    /* border-radius: 1px; */
    /* Fixes Firefox rendering issues */
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    margin: -1px 0;
  }

  .mbsc-sc-whl-c {
    position: relative;
    z-index: 1;
    top: 50%;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  .mbsc-sc-whl-sc {
    position: relative;
  }

  .mbsc-sc-itm {
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbsc-sc-itm-inv,
  .mbsc-sc-itm-inv-h {
    opacity: .3;
  }

  .mbsc-sc-lbl {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    display: none;
  }

  .mbsc-sc-lbl-v .mbsc-sc-lbl {
    display: block;
  }

  .mbsc-sc-btn {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    cursor: pointer;
    opacity: 1;
    text-align: center;
    transition: opacity .2s linear;
  }

  .mbsc-sc-btn:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }

  .mbsc-sc-whl-a .mbsc-sc-btn {
    opacity: 0;
  }

  .mbsc-sc-btn-plus {
    bottom: 0;
  }

  .mbsc-sc-btn-minus {
    top: 0;
  }

  /* 3D */

  .mbsc-sc-whl-gr-3d {
    -webkit-perspective: 1200px;
    perspective: 1200px;
  }

  .mbsc-sc-whl-gr-3d .mbsc-sc-whl {
    /* For iOS to respect z-index */
    overflow: visible;
  }

  .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    overflow: hidden;
  }

  .mbsc-sc-whl-gr-3d .mbsc-sc-whl-w,
  .mbsc-sc-whl-gr-3d .mbsc-sc-whl {
    /* For Edge and Firefox */
    transform-style: preserve-3d;
  }

  .mbsc-sc-whl-3d {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .mbsc-sc-itm-3d {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .mbsc-sc-itm-del {
    display: none;
  }

  /* Multiline */

  .mbsc-sc-itm-ml {
    width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }

  /* Multiple */

  .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    text-align: center;
  }

  /* Desktop view */

  .mbsc-fr-pointer {

    .mbsc-fr-w .mbsc-sc-whl-gr-c,
    .mbsc-fr-w .mbsc-sc-whl-gr {
      padding: 0;
    }

    .mbsc-sc-whl-gr-c:first-child:last-child {
      display: block;
    }
  }

  .mbsc-sc-bar-c {
    position: absolute;
    z-index: 4;
    top: 0;
    bottom: 0;
    right: 0;
    width: 10px;
    opacity: 0;
    background: rgba(0, 0, 0, .05);
    transform: translateZ(0);
    transition: opacity .2s;
  }

  .mbsc-sc-bar {
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 100%;
  }

  .mbsc-sc-bar:after {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background: rgba(0, 0, 0, .5);
    border-radius: 3px;
  }

  .mbsc-sc-whl-w:hover .mbsc-sc-bar-c,
  .mbsc-sc-whl-anim .mbsc-sc-bar-c {
    opacity: 1;
  }
}


@include exports("scroller.ios") {
  .mbsc-ios {
    /* Scroller */

    &.mbsc-sc.mbsc-fr-top .mbsc-fr-btn-cont,
    &.mbsc-sc.mbsc-fr-bottom .mbsc-fr-btn-cont {
      border-bottom: 0;
    }

    /* Wheel label */

    .mbsc-sc-lbl {
      line-height: 2.5em;
    }

    .mbsc-sc-whl-gr-c {
      padding: 0 .833333em;
    }

    &.mbsc-fr-liq .mbsc-sc-whl-gr-3d-c {
      padding: 0 3%;
    }

    .mbsc-sc-whl-gr {
      padding: .833333em;
    }

    .mbsc-sc-lbl-v {
      margin-bottom: -1.666667em;
    }

    .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
      padding-top: 2.5em;
      padding-bottom: 2.5em;
    }

    .mbsc-sc-whl-l,
    &.mbsc-calendar .mbsc-sc-whl-l {
      display: block;
      margin: 0 -.833333em;
    }

    .mbsc-sc-whl-w .mbsc-sc-whl-l {
      display: none;
    }

    .mbsc-sc-whl-o,
    &.mbsc-calendar .mbsc-sc-whl-o {
      display: block;
    }

    .mbsc-sc-itm {
      padding: 0 .5em;
      font-size: 1.833333em;
    }

    &.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
    .mbsc-sc-itm:focus {
      outline: 0;
    }

    /* 3D */

    .mbsc-sc-whl-gr-3d-c .mbsc-sc-whl-l {
      z-index: 3;
    }

    .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
      margin: 0 -.166667em;
    }

    .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
      font-size: 1.666666em;
    }


    /* Clickpick mode */

    .mbsc-sc-cp .mbsc-sc-whl .mbsc-sc-itm {
      text-align: center;
    }

    .mbsc-sc-cp .mbsc-sc-whl-w {
      padding: 2.666667em 0;
    }

    .mbsc-sc-btn {
      height: 2.666667em;
      line-height: 2.666667em;
      opacity: 1;
    }

    .mbsc-sc-btn:before {
      font-size: 2em;
    }

    .mbsc-sc-btn.mbsc-active:before {
      opacity: .5;
    }


    /* Multiple select */

    &.mbsc-sel-multi .mbsc-sc-whl-l {
      display: none;
    }

    .mbsc-sc-whl-multi .mbsc-sc-whl-o {
      display: none;
    }

    .mbsc-sc-whl-multi .mbsc-sc-itm {
      padding: 0 1.818181em;
    }

    .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
      font-size: 1.818181em;
    }

    .mbsc-sc-whl-gr-3d .mbsc-sc-whl-multi .mbsc-sc-whl-o {
      display: block;
    }

    &.mbsc-sel-multi .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
      visibility: hidden;
    }

    /* Desktop view */

    &.mbsc-fr-pointer {
      .mbsc-sc-whl-o {
        display: none;
      }

      .mbsc-sc-lbl-v {
        margin-bottom: -2.5em;
      }

      .mbsc-sc-lbl {
        padding: 0 1.666667em;
      }

      .mbsc-sc-itm {
        font-size: 1.333334em;
        padding: 0 1.25em;
      }

      .mbsc-sc-whl-multi .mbsc-sc-itm {
        padding: 0 2.5em;
      }

      .mbsc-sc-itm-sel:before {
        font-size: 2em;
      }

      .mbsc-ltr .mbsc-sc-itm-sel:before {
        left: .125em;
      }

      .mbsc-rtl .mbsc-sc-itm-sel:before {
        right: .125em;
      }
    }
  }

  @include mbsc-ios-scroller(ios, $mbsc-ios-colors);
}





// Theme specific variables - inherited from global variables

// Background
$mbsc-ios-input-background: $mbsc-input-background-light !default;
$mbsc-ios-dark-input-background: $mbsc-input-background-dark !default;
// Border
$mbsc-ios-input-border: $mbsc-input-border-light !default;
$mbsc-ios-dark-input-border: $mbsc-input-border-dark !default;
// Text
$mbsc-ios-input-text: $mbsc-input-text-light !default;
$mbsc-ios-dark-input-text: $mbsc-input-text-dark !default;
// Error
$mbsc-ios-input-error: $mbsc-input-error-light !default;
$mbsc-ios-dark-input-error: $mbsc-input-error-dark !default;

$mbsc-ios-colors: map-merge($mbsc-ios-colors, (
  'input-background': $mbsc-ios-input-background,
  'input-border': $mbsc-ios-input-border,
  'input-text': $mbsc-ios-input-text,
  'input-error': $mbsc-ios-input-error,
));
$mbsc-ios-dark-colors: map-merge($mbsc-ios-dark-colors, (
  'input-background': $mbsc-ios-dark-input-background,
  'input-border': $mbsc-ios-dark-input-border,
  'input-text': $mbsc-ios-dark-input-text,
  'input-error': $mbsc-ios-dark-input-error,
));

@mixin mbsc-ios-input($theme, $params) {
  @include exports("input.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    // get custom params
    $input-background-param: map-get($params, 'input-background');
    $input-border-param: map-get($params, 'input-border');
    $input-text-param: map-get($params, 'input-text');
    $input-error-param: map-get($params, 'input-error');
    // static colors
    $colors: mbsc-ios-colors($params);
    $error: map-get($colors, error);
    // overwrite colors with custom colors
    $text: if($input-text-param, $input-text-param, $text);
    $border-origin: if($input-background-param, $input-background-param, $background);
    $background: if($input-background-param, $input-background-param, lighten($background, 6%));
    $error: if($input-error-param, $input-error-param, $error);
    // calculated colors
    $background-limited: hsl(hue($border-origin), saturation($border-origin), max(lightness($border-origin), 3%));
    $form-text: '';
    $border-color: '';
    @if (lightness($background) > 50%) {
      $form-text: hsl(hue($text), saturation($text), max(lightness($text), 80%));
      $border-color: darken($background-limited, 17%);
    }
    @else {
      $form-text: hsl(hue($text), saturation($text), min(lightness($text), 80%));
      $border-color: lighten($border-origin, 20%);
    }
    // overwrite colors with custom ones if provided
    $border-color:if($input-border-param, $input-border-param, $border-color);

    .mbsc-#{$theme} {
      &.mbsc-input {
        background: $background;
      }

      &.mbsc-input .mbsc-control::-webkit-input-placeholder {
        color: $form-text;
      }

      &.mbsc-input .mbsc-control::-ms-input-placeholder {
        color: $form-text;
      }

      &.mbsc-input .mbsc-control::-moz-placeholder {
        color: $form-text;
      }

      .mbsc-input-wrap input:-webkit-autofill ~ .mbsc-input-fill {
        background: #e8f0fe;
      }

      .mbsc-input-wrap input:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #e8f0fe inset;
      }

      &.mbsc-input .mbsc-label,
      &.mbsc-progress .mbsc-label,
      &.mbsc-input input,
      &.mbsc-input textarea {
        color: $text;
      }

      .mbsc-err-msg {
        color: $error;
      }

      &.mbsc-input:before,
      &.mbsc-input:after,
      &.mbsc-form .mbsc-control-w:before,
      &.mbsc-form .mbsc-control-w:after {
        border-color: $border-color;
      }

      &.mbsc-control-w.mbsc-err:after,
      &.mbsc-control-w.mbsc-err + .mbsc-err:before,
      &.mbsc-control-w.mbsc-err + .mbsc-divider,
      mbsc-input.mbsc-err + mbsc-input &.mbsc-err:before {
        border-top-color: $error;
      }

      /* Box Input and Outline */
      &.mbsc-input-box,
      &.mbsc-input-outline {
        &.mbsc-err {
          border-color: $error;
        }

        .mbsc-err-msg {
          color: $error;
        }
      }

      /* Outline */
      &.mbsc-input-outline {

        /* Row separator */
        &.mbsc-control-w {
          border: 1px solid $border-color;
        }

        &.mbsc-err.mbsc-control-w {
          border-color: $error;
        }
      }
    }
  }
}



@include exports("input") {
  @keyframes autofill {
    from {
      opacity: 1;
    }

    to {
      opacity: 1;
    }
  }

  .mbsc-input {
    position: relative;
    display: block;
    margin: 0;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .mbsc-input .mbsc-control:-webkit-autofill {
    animation-name: autofill;
  }

  .mbsc-input input,
  .mbsc-input select,
  .mbsc-input textarea {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .mbsc-input-wrap,
  .mbsc-input input,
  .mbsc-input textarea {
    box-sizing: border-box;
  }

  .mbsc-input input,
  .mbsc-input select,
  .mbsc-input textarea {
    display: block;
    width: 100%;
    font-size: 1em;
  }

  .mbsc-input input:focus,
  .mbsc-input select:focus,
  .mbsc-input textarea:focus {
    outline: 0;
  }

  .mbsc-input-wrap {
    position: relative;
    display: block;
  }

  /* Textbox, textarea, select */

  .mbsc-input .mbsc-label {
    font-size: .875em;
    white-space: nowrap;
  }

  .mbsc-input-ic {
    position: absolute;
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
  }

  .mbsc-input-toggle {
    cursor: pointer;
  }

  .mbsc-ic-right .mbsc-input-ic {
    right: .5em;
  }

  .mbsc-ic-left .mbsc-input-ic {
    left: .5em;
  }

  .mbsc-ic-right input {
    padding-right: 2.4em;
  }

  .mbsc-ic-left input {
    padding-left: 2.4em;
  }

  /* Inline labels */

  .mbsc-label-inline.mbsc-input.mbsc-control-w {

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    .mbsc-input-wrap {
      -webkit-box-flex: 1;
      -webkit-flex: 1 auto;
      -moz-box-flex: 1;
      -moz-flex: 1 auto;
      -ms-flex: 1 auto;
      flex: 1 auto;
    }

    .mbsc-label {
      position: static;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -moz-box-flex: 0;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      font-size: 1em;
      width: 30%;
      max-width: 12.5em;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: content-box;
    }
  }

  /* Floating labels */

  .mbsc-label-floating.mbsc-input {
    .mbsc-label {
      pointer-events: none;
      transition: transform .2s;
    }

    .mbsc-label~.mbsc-input-wrap {

      /* WebKit browsers */
      .mbsc-control::-webkit-input-placeholder {
        opacity: 0;
      }

      /* Mozilla Firefox 4 to 18 */
      .mbsc-control:-moz-placeholder {
        opacity: 0;
      }

      /* Mozilla Firefox 19+ */
      .mbsc-control::-moz-placeholder {
        opacity: 0;
      }

      /* Internet Explorer 10+ */
      .mbsc-control:-ms-input-placeholder {
        opacity: 0;
      }
    }

    &.mbsc-label-floating-active .mbsc-label~.mbsc-input-wrap {

      /* WebKit browsers */
      .mbsc-control::-webkit-input-placeholder {
        opacity: 1;
        transition: opacity .2s;
      }

      /* Mozilla Firefox 4 to 18 */
      .mbsc-control:-moz-placeholder {
        opacity: 1;
        transition: opacity .2s;
      }

      /* Mozilla Firefox 19+ */
      .mbsc-control::-moz-placeholder {
        opacity: 1;
        transition: opacity .2s;
      }

      /* Internet Explorer 10+ */
      .mbsc-control:-ms-input-placeholder {
        opacity: 1;
        transition: opacity .2s;
      }
    }
  }

  .mbsc-ltr.mbsc-label-floating.mbsc-input .mbsc-label {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }

  .mbsc-rtl.mbsc-label-floating.mbsc-input .mbsc-label {
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }

  /* file type */
  .mbsc-input-wrap .mbsc-control[type="file"] {
    position: absolute;
    left: 0;
    opacity: 0;
  }
}

@include exports("input.ios") {
  .mbsc-ios {
    &.mbsc-input {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      padding: 0;
    }

    &.mbsc-input input {
      height: 2.75em;
      padding: 0 1em;
      background: transparent;
    }

    .mbsc-input-wrap {
      position: static;
      -webkit-box-flex: 1;
      -webkit-flex: 1 auto;
      -moz-box-flex: 1;
      -moz-flex: 1 auto;
      -ms-flex: 1 auto;
      flex: 1 auto;
    }

    .mbsc-input-wrap .mbsc-input-fill {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
    }

    /* Textbox, select, textarea */
    &.mbsc-textarea textarea {
      height: 3em;
      padding: 0 1em;
      background: transparent;
    }

    &.mbsc-input .mbsc-label,
    &.mbsc-progress .mbsc-label {
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -moz-box-flex: 0;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 30%;
      max-width: 12.5em;
      overflow: hidden;
      font-size: 1em;
      line-height: 2.75em;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-sizing: content-box;
    }

    &.mbsc-ltr.mbsc-input .mbsc-label {
      padding-left: 1em;
    }

    &.mbsc-rtl.mbsc-input .mbsc-label {
      padding-right: 1em;
    }

    &.mbsc-input input:disabled,
    &.mbsc-input textarea:disabled,
    &.mbsc-input .mbsc-control:disabled ~ input,
    &.mbsc-input .mbsc-control:disabled ~ .mbsc-ic {
      opacity: .5;
    }

    .mbsc-err-msg {
      padding: .5em 1.3333em;
    }

    /* Select */
    &.mbsc-ltr.mbsc-select input {
      padding-right: 2.25em;
    }

    &.mbsc-rtl.mbsc-select input {
      padding-left: 2.25em;
    }

    .mbsc-select-ic {
      position: absolute;
      display: block;
      height: 1.25em;
      width: 1.25em;
      top: .875em;
      right: 1em;
      text-align: center;
    }

    &.mbsc-rtl .mbsc-select-ic {
      right: auto;
      left: 1em;
    }

    &.mbsc-ic-right .mbsc-select-ic {
      right: 3.4em;
    }

    &.mbsc-rtl .mbsc-ic-right .mbsc-select-ic {
      right: auto;
      left: 3.4em;
    }

    /* Select inline */
    &.mbsc-select-inline .mbsc-select-ic {
      display: none;
    }

    /* Textarea */
    &.mbsc-textarea .mbsc-input-wrap {
      padding: .625em 0;
    }

    /* Icons */
    .mbsc-input-ic {
      top: .375em;
      margin-top: -1px;
    }

    &.mbsc-ic-left {
      z-index: 1;
    }

    &.mbsc-ltr.mbsc-ic-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-ic-right .mbsc-input-wrap {
      padding-left: 2.25em;
    }

    &.mbsc-ltr.mbsc-ic-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-ic-left .mbsc-input-wrap {
      padding-right: 2.25em;
    }

    &.mbsc-ltr.mbsc-ic-left .mbsc-label {
      padding-left: 3.25em;
    }

    &.mbsc-rtl.mbsc-ic-left .mbsc-label {
      padding-right: 3.25em;
    }

    &.mbsc-ltr.mbsc-ic-left .mbsc-label ~ .mbsc-input-wrap {
      padding-left: 0;
    }

    &.mbsc-rtl.mbsc-ic-left .mbsc-label ~ .mbsc-input-wrap {
      padding-right: 0;
    }

    &.mbsc-ic-left .mbsc-left-ic,
    &.mbsc-rtl.mbsc-ic-right .mbsc-right-ic {
      right: auto;
      left: .625em;
    }

    &.mbsc-ic-right .mbsc-right-ic,
    &.mbsc-rtl.mbsc-ic-left .mbsc-left-ic {
      left: auto;
      right: .625em;
    }

    /* Row separator */
    &.mbsc-control-w {
      margin-top: -1px;
    }

    &.mbsc-control-w:before,
    &.mbsc-control-w:after {
      content: "";
      position: absolute;
      right: 0;
      left: 1em;
      border-top: 1px solid transparent;
    }

    &.mbsc-control-w:before {
      top: 0;
    }

    &.mbsc-control-w:after {
      bottom: 0;
    }

    .mbsc-form-group-title + .mbsc-control-w:before,
    .mbsc-control-w:first-child:before,
    .mbsc-control-w:last-child:after {
      left: 0;
    }

    .mbsc-control-ng .mbsc-control-w:before,
    .mbsc-control-ng .mbsc-control-w:after {
      left: 1em;
    }

    .mbsc-form-group-title + .mbsc-control-ng .mbsc-control-w:before,
    .mbsc-control-ng:first-child .mbsc-control-w:before,
    .mbsc-control-ng:last-child .mbsc-control-w:after {
      left: 0;
    }

    &.mbsc-ic-left:before,
    &.mbsc-ic-left:after {
      left: 3.25em;
    }

    .mbsc-err {
      z-index: 2;
    }

    .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-control-w,
    .mbsc-form-group-inset .mbsc-control-w:first-child {
      border-top-left-radius: .5em;
      border-top-right-radius: .5em;
    }

    .mbsc-form-group-inset .mbsc-control-w:last-child {
      border-bottom-left-radius: .5em;
      border-bottom-right-radius: .5em;
    }

    .mbsc-form-group-inset .mbsc-form-group-title,
    .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-control-w:before,
    .mbsc-form-group-inset .mbsc-control-w:first-child:before,
    .mbsc-form-group-inset .mbsc-control-w:last-child:after {
      border-width: 0;
    }

    .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w {
      border-radius: 0;
    }

    .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w:before,
    .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w:after {
      border-width: 1px;
    }

    &.mbsc-form .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-control-ng .mbsc-control-w:before,
    &.mbsc-form .mbsc-form-group-inset .mbsc-control-ng:first-child .mbsc-control-w:before,
    &.mbsc-form .mbsc-form-group-inset .mbsc-control-ng:last-child .mbsc-control-w:after {
      border-width: 0;
    }

    .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-control-ng .mbsc-control-w,
    .mbsc-form-group-inset .mbsc-control-ng:first-child .mbsc-control-w {
      border-top-left-radius: .5em;
      border-top-right-radius: .5em;
    }

    .mbsc-form-group-inset .mbsc-control-ng:last-child .mbsc-control-w {
      border-bottom-left-radius: .5em;
      border-bottom-right-radius: .5em;
    }

    /* Stacked label */
    &.mbsc-label-stacked.mbsc-input {
      .mbsc-label {
        font-size: .75em;
        position: absolute;
        top: 1em;
        line-height: 1em;
        padding: 0;
        width: auto;
        max-width: none;
      }

      .mbsc-label ~ .mbsc-input-wrap {

        input,
        .mbsc-color-input {
          height: 3.75em;
          padding-top: 1.5em;
        }

        .mbsc-input-ic {
          top: 1.625em;
        }
      }

      &.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
        padding-top: 1.625em;
      }

      &.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
        top: 1.4375em;
      }
    }

    &.mbsc-ltr.mbsc-label-stacked.mbsc-input {
      .mbsc-label {
        left: 1.333334em;
        right: auto;
      }

      &.mbsc-ic-left {
        .mbsc-input-wrap {
          padding-left: 2.375em;
        }

        .mbsc-label {
          left: 4.5em;
        }
      }
    }

    &.mbsc-rtl.mbsc-label-stacked.mbsc-input {
      .mbsc-label {
        left: auto;
        right: 1.333334em;
      }

      &.mbsc-ic-left {
        .mbsc-input-wrap {
          padding-right: 2.375em;
        }

        .mbsc-label {
          right: 4.5em;
        }
      }

      &.mbsc-control-w:before,
      &.mbsc-control-w:after {
        right: 0;
      }
    }

    /* Floating label */
    &.mbsc-label-floating.mbsc-input {
      .mbsc-label {
        position: absolute;
        padding: 0;
        font-size: 1em;
        line-height: 2em;
        top: .875em;
        width: auto;
        transform-origin: 0 0;
        transition: transform .2s;
      }

      &.mbsc-ic-left .mbsc-label,
      &.mbsc-ic-right .mbsc-label {
        top: 1.625em;
      }

      .mbsc-label ~ .mbsc-input-wrap {

        input,
        .mbsc-color-input {
          height: 3.75em;
          padding-top: 1.5em;
        }

        .mbsc-input-ic {
          top: 1.625em;
        }
      }

      &.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
        padding-top: 1.625em;
      }

      &.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
        top: 1.4375em;
      }

      &.mbsc-label-floating-active {
        .mbsc-label {
          -webkit-transform: translateY(-.5em) scale(.75);
          transform: translateY(-.5em) scale(.75);
        }

        &.mbsc-ic-left .mbsc-label,
        &.mbsc-ic-right .mbsc-label {
          -webkit-transform: translateY(-1.25em) scale(.75);
          transform: translateY(-1.25em) scale(.75);
        }
      }
    }

    &.mbsc-ltr.mbsc-label-floating.mbsc-input {
      .mbsc-label {
        left: 1em;
      }

      &.mbsc-ic-left {
        .mbsc-input-wrap {
          padding-left: 2.375em;
        }

        .mbsc-label {
          left: 3.375em;
        }
      }
    }

    &.mbsc-rtl.mbsc-label-floating.mbsc-input {
      .mbsc-label {
        right: 1em;
      }

      &.mbsc-ic-left {
        .mbsc-input-wrap {
          padding-right: 2.375em;
        }

        .mbsc-label {
          right: 3.375em;
        }
      }

      &.mbsc-control-w:before,
      &.mbsc-control-w:after {
        right: 0;
      }
    }

    /* Box Input and Outline */
    &.mbsc-input-box,
    &.mbsc-input-outline {

      /* Row separator */
      &.mbsc-control-w {
        margin-top: -1px;
        margin: 1.5em 1em;
        border-radius: 8px;
        border: 1px solid transparent;

        input {
          border-radius: 8px;
        }
      }

      &.mbsc-control-w:before,
      &.mbsc-control-w:after {
        border-top: 0;
      }

      .mbsc-err-msg {
        position: absolute;
        bottom: -1.5em;
        padding: 0;
      }

      &.mbsc-control-w.mbsc-err + .mbsc-divider {
        border-top: 0;
      }

      &.mbsc-label-stacked.mbsc-input {
        .mbsc-label {
          top: .75em;
        }

        .mbsc-label ~ .mbsc-input-wrap {

          input,
          .mbsc-color-input {
            height: 3.375em;
            padding-top: 1.25em;
          }

          .mbsc-input-ic {
            top: .75em;
          }
        }

        &.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
          padding-top: 1.75em;
        }

        &.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
          top: 1.25em;
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input {
        .mbsc-label {
          top: .6875em;
        }

        .mbsc-label ~ .mbsc-input-wrap {

          input,
          .mbsc-color-input {
            height: 3.375em;
            padding-top: 1.25em;
          }

          .mbsc-input-ic {
            top: .75em;
          }
        }

        &.mbsc-label-floating-active {
          .mbsc-label {
            -webkit-transform: translateY(-.5em) scale(.75);
            transform: translateY(-.5em) scale(.75);
          }
        }
      }

      .mbsc-input-wrap .mbsc-input-fill {
        border-radius: 8px;
      }
    }

    &.mbsc-ltr.mbsc-input-box .mbsc-err-msg,
    &.mbsc-ltr.mbsc-input-outline .mbsc-err-msg {
      left: 1.333334em;
    }

    &.mbsc-rtl.mbsc-input-box .mbsc-err-msg,
    &.mbsc-rtl.mbsc-input-outline .mbsc-err-msg {
      right: 1.333334em;
    }

    /* Box and outline inputs inside form group inset */
    .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w.mbsc-input-box,
    .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w.mbsc-input-outline {
      border-radius: .5em;
    }

    /* Outline */
    &.mbsc-input-outline.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
      top: .875em;
    }
  }

  @include mbsc-ios-input(ios, $mbsc-ios-colors);
}






@mixin mbsc-ios-select($theme, $params) {
  @include exports("select.#{$theme}.colors") {

    @include mbsc-ios-scroller($theme, $params);
    @include mbsc-ios-input($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');

    $colors: mbsc-ios-colors($params);
    $gray-background: map-get($colors, gray-background);
    $cont-background: map-get($colors, cont-background);
    $popup-border: map-get($colors, popup-border);

    $disabled-color: '';
    $button-active: '';
    @if (lightness($background) > 50%) {
      $disabled-color: darken($background, 20%);
      $button-active: darken($background, 5%);
    }
    @else {
      $disabled-color: lighten($background, 23%);
      $button-active: lighten($background, 12%);
    }

    .mbsc-#{$theme} {

      /* Group select */
      .mbsc-sc-whl .mbsc-sel-gr {
        color: #9d9d9d;
      }

      /* Filter */
      .mbsc-sel-filter-cont.mbsc-input {
        background: $disabled-color;
      }

      &.mbsc-fr-center .mbsc-sel-filter-cont {
        background: $button-active;
      }

      .mbsc-sel-filter-cont.mbsc-input input {
        background: $cont-background;
      }

      .mbsc-sel-filter-clear {
        color: #8c8c8c;
      }

      /* Desktop view */

      &.mbsc-fr-pointer {

        /* Group headers */
        .mbsc-sc-whl .mbsc-sel-gr {
          color: $text;
        }

        &.mbsc-sel .mbsc-ltr .mbsc-sc-whl-w + .mbsc-sc-whl-w {
          border-left: 1px solid $popup-border;
        }

        &.mbsc-sel .mbsc-rtl .mbsc-sc-whl-w + .mbsc-sc-whl-w {
          border-right: 1px solid $popup-border;
        }
      }
    }
  }
}





@include exports("select") {
  .mbsc-sel-hdn {
    position: absolute;
    height: 1px !important;
    width: 1px !important;
    margin: 0 !important;
    padding: 0 !important;
    left: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  .mbsc-sel .mbsc-sel-gr {
    padding: 0 .25em;
    opacity: 1;
    font-weight: bold;
    text-align: left;
  }

  .mbsc-sel .mbsc-fr-c {
    position: relative;
  }

  .mbsc-sel-multi .mbsc-sc-whl-w .mbsc-sc-whl-l {
    display: none;
  }

  .mbsc-sel-empty {
    display: none;
    opacity: .6;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2em;
    margin-top: -1em;
    font-size: 1.5em;
    line-height: 2em;
  }

  .mbsc-sel-empty-v {
    display: block;
  }

  .mbsc-sel-filter-clear {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1.5em;
    display: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.125em;
  }

  .mbsc-sel-filter-show-clear {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .mbsc-sel-input-wrap {
    position: relative;
  }

  .mbsc-sel .mbsc-ltr .mbsc-sel-filter-cont .mbsc-sel-filter-input {
    padding-right: 1.5em;
  }

  .mbsc-sel .mbsc-rtl .mbsc-sel-filter-cont .mbsc-sel-filter-input {
    padding-left: 1.5em;
  }

  .mbsc-ltr .mbsc-sel-filter-clear {
    right: 0;
  }

  .mbsc-rtl .mbsc-sel-filter-clear {
    left: 0;
  }

  .mbsc-sel.mbsc-fr-pointer.mbsc-no-touch .mbsc-sc-whl .mbsc-sc-itm.mbsc-sel-gr:hover {
    background: none;
  }
}


@include exports("select.ios") {
  .mbsc-ios {

    /* Group select */
    .mbsc-sc-whl .mbsc-sel-gr {
      padding-left: .277777em;
      font-weight: normal;
      font-size: 1.222222em;
    }

    /* Filter */
    .mbsc-sel-filter-cont.mbsc-input {
      font-size: 1.333334em;
      padding: .5em;
    }

    .mbsc-sel-filter-cont.mbsc-input input {
      height: 2em;
      border-radius: .325em;
    }

    .mbsc-sel-filter-clear {
      width: 1.75em;
      right: .5em;
      font-size: 1em;
    }

    &.mbsc-sel .mbsc-rtl .mbsc-sel-filter-clear {
      left: .5em;
    }

    .mbsc-sel-filter-cont.mbsc-control-w:before,
    .mbsc-sel-filter-cont.mbsc-control-w:after {
      border: none;
    }

    .mbsc-sel-gr-whl {
      .mbsc-sc-itm {
        padding: 0 .5em;
      }

      .mbsc-sc-itm::before {
        display: none;
      }
    }

    /* Desktop view */

    &.mbsc-fr-pointer {

      /* Group headers */
      .mbsc-sc-whl .mbsc-sel-gr {
        font-weight: 700;
        font-size: 1em;
        padding: 0 1.666667em;
      }

      .mbsc-sel-gr-whl .mbsc-sc-itm {
        padding: 0 1.25em;
      }
    }
  }

  @include mbsc-ios-select(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}




/* Frame */
.mbsc-bootstrap {
  .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }

  .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }

  .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }

  .mbsc-fr-overlay {
    background: rgba(0, 0, 0, .5);
  }

  .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }

  .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }


  /* Inline mode */

  &.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }


  /* Top/bottom mode */

  &.mbsc-fr-inline .mbsc-fr-popup,
  &.mbsc-fr-top .mbsc-fr-popup,
  &.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }


  /* Bubble mode */

  .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }

  .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }

  .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }

  .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }

  /* 4.x */

  &.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
}



/* Scroller */
.mbsc-bootstrap {
  .mbsc-sc-whl-gr-c {
    overflow: visible;
  }

  .mbsc-sc-whl-gr {
    padding: 4px 2px;
  }

  .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 30px;
  }

  .mbsc-sc-lbl {
    line-height: 30px;
  }

  .mbsc-sc-whl-w {
    margin: 0 2px;
  }

  .mbsc-sc-whl-l {
    margin: 0 -2px;
    background: rgba(0, 0, 0, .2);
  }

  .mbsc-ltr .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l,
  .mbsc-rtl .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l {
    margin-left: -4px;
  }

  .mbsc-ltr .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l,
  .mbsc-rtl .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l {
    margin-right: -4px;
  }

  .mbsc-sc-itm {
    padding: 0 5px;
    font-size: 18px;
  }

  .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, .1);
  }


  /* Clickpick mode */

  .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 30px 0;
  }

  .mbsc-sc-btn {
    height: 30px !important;
    line-height: 30px !important;
  }

  .mbsc-sc-btn-plus {
    top: auto;
  }

  .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, .1);
  }


  /* Multiple select */

  .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 40px;
  }

  .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 40px;
    font-size: 16px;
  }
}



/* Group headers */
.mbsc-bootstrap {
  .mbsc-sc-whl .mbsc-sel-gr {
    padding: 0 5px;
    font-size: 20px;
  }

  .mbsc-sel-filter-cont {
    padding: 4px;
  }
}







$mbsc-mobiscroll-accent: #4eccc4 !default;
$mbsc-mobiscroll-background: #f7f7f7 !default;
$mbsc-mobiscroll-text: #454545 !default;

$mbsc-mobiscroll-dark-accent: #4fccc4 !default;
$mbsc-mobiscroll-dark-background: #263238 !default;
$mbsc-mobiscroll-dark-text: #f7f7f7 !default;

/* Base colors */
$mbsc-mobiscroll-primary: #3f97f6 !default;
$mbsc-mobiscroll-secondary: #90979E !default;
$mbsc-mobiscroll-success: #43BE5F !default;
$mbsc-mobiscroll-danger: #f5504e !default;
$mbsc-mobiscroll-warning: #f8b042 !default;
$mbsc-mobiscroll-info: #5BB7C5 !default;
$mbsc-mobiscroll-light: darken(#fff, 10%) !default;
$mbsc-mobiscroll-dark: #47494A !default;

$mbsc-mobiscroll-colors: ( // Colors map
  'background': $mbsc-mobiscroll-background,
  'text': $mbsc-mobiscroll-text,
  'accent': $mbsc-mobiscroll-accent,
);
$mbsc-mobiscroll-dark-colors: ( // Colors map
  'background': $mbsc-mobiscroll-dark-background,
  'text': $mbsc-mobiscroll-dark-text,
  'accent': $mbsc-mobiscroll-dark-accent,
);

@function mbsc-mobiscroll-colors($params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $border: '';
  $empty-color: '';
  $input-disabled: '';
  $input-box: '';
  $tooltip: '';
  $empty-color: '';

  // Light background
  @if (lightness($background) > 50%) {
    $border: darken($background, 17%);
    $empty-color: lighten($text, 20%);
    $input-disabled: darken($background, 13%);
    $input-box: darken($background, 10%);
    $tooltip: darken($background, 4%);
  }

  // Dark background
  @else {
    $border: lighten($background, 17%);
    $empty-color: $text;
    $input-disabled: lighten($background, 17%);
    $input-box: lighten($background, 10%);
    $tooltip: $background;
  }

  @return ('border': $border,
  'empty-color': $empty-color,
  'input-disabled': $input-disabled,
  'input-box': $input-box,
  'tooltip': $tooltip,
  /* static colors */
  'textarea': #1f1f1f,
  'notification': #787878,
  'white-text': #ffffff,
  'error': #de3226,
  'dark-text': #000);
}

@mixin mbsc-mobiscroll-common($theme, $params) {
  @include exports("common.#{$theme}.colors") {
    $colors: mbsc-mobiscroll-colors($params);
    $empty-color: map-get($colors, empty-color);

    .mbsc-#{$theme} {
      .mbsc-empty {
        color: $empty-color;
      }
    }
  }
}


// Theme specific variables - inherited from global variables

// Background
$mbsc-mobiscroll-frame-background: $mbsc-frame-background-light !default;
$mbsc-mobiscroll-dark-frame-background: $mbsc-frame-background-dark !default;
// Text
$mbsc-mobiscroll-frame-text: $mbsc-frame-text-light !default;
$mbsc-mobiscroll-dark-frame-text: $mbsc-frame-text-dark !default;
// Accent
$mbsc-mobiscroll-frame-accent: $mbsc-frame-accent-light !default;
$mbsc-mobiscroll-dark-frame-accent: $mbsc-frame-accent-dark !default;
// Overlay
$mbsc-mobiscroll-frame-overlay: $mbsc-frame-overlay-light !default;
$mbsc-mobiscroll-dark-frame-overlay: $mbsc-frame-overlay-dark !default;

$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'frame-background': $mbsc-mobiscroll-frame-background,
  'frame-text': $mbsc-mobiscroll-frame-text,
  'frame-accent': $mbsc-mobiscroll-frame-accent,
  'frame-overlay': $mbsc-mobiscroll-frame-overlay,
));

$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'frame-background': $mbsc-mobiscroll-dark-frame-background,
  'frame-text': $mbsc-mobiscroll-dark-frame-text,
  'frame-accent': $mbsc-mobiscroll-dark-frame-accent,
  'frame-overlay': $mbsc-mobiscroll-dark-frame-overlay,
));

@mixin mbsc-mobiscroll-frame($theme, $params) {
  @include exports("frame.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $overlay-param: map-get($params, 'frame-overlay');
    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);
    $overlay: if($overlay-param, $overlay-param, rgba(0, 0, 0, .7));

    .mbsc-#{$theme} {
      .mbsc-fr-overlay {
        background: $overlay;
      }

      .mbsc-fr-w {
        background: $background;
        color: $text;
      }

      .mbsc-fr-hdr,
      .mbsc-fr-btn {
        color: $accent;
      }

      .mbsc-fr-btn.mbsc-active,
      &.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
        background: rgba($accent, .3);
      }

      .mbsc-fr-arr {
        background: $background;
      }
    }
  }
}



@include exports("frame.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-fr-w {
      min-width: 16em;
      font-size: 16px;
    }

    .mbsc-fr-hdr {
      padding: 0 .6666em;
      padding-top: .6666em;
      font-size: .75em;
      text-transform: uppercase;
      min-height: 2em;
      line-height: 2em;
    }

    .mbsc-fr-btn-cont {
      display: block;
      overflow: hidden;
      text-align: right;
      padding: 0 .5em .5em .5em;
    }

    .mbsc-ltr .mbsc-fr-btn-cont {
      text-align: right;
    }

    .mbsc-rtl .mbsc-fr-btn-cont {
      text-align: left;
    }

    .mbsc-fr-btn-w {
      display: inline-block;
    }

    .mbsc-fr-btn {
      height: 2.5em;
      line-height: 2.5em;
      padding: 0 1em;
      text-transform: uppercase;
    }

    /* Display modes */
    &.mbsc-fr-center .mbsc-fr-w,
    &.mbsc-fr-bubble .mbsc-fr-w {
      border-radius: .25em;
    }

    &.mbsc-fr-no-overlay {
      .mbsc-fr-arr {
        box-shadow: 0 0 1em rgba(0, 0, 0, .2);
      }

      .mbsc-fr-w {
        box-shadow: 0 .125em 1em rgba(0, 0, 0, .3);
      }

      &.mbsc-fr-bubble .mbsc-fr-w {
        border-radius: .25em;
      }
    }
  }

  @include mbsc-mobiscroll-frame(mobiscroll, $mbsc-mobiscroll-colors);
}





@mixin mbsc-mobiscroll-scroller($theme, $params) {
  @include exports("scroller.#{$theme}.colors") {

    @include mbsc-mobiscroll-frame($theme, $params);

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    .mbsc-#{$theme} {
      .mbsc-sc-lbl {
        color: $accent;
      }

      .mbsc-sc-whl-l {
        border-top: 1px solid $accent;
        border-bottom: 1px solid $accent;
      }

      .mbsc-sc-btn {
        color: $accent;
        background: $background;
      }

      &.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
      .mbsc-sc-itm:focus {
        background: rgba($text, .1);
      }

      &.mbsc-no-touch .mbsc-sc-btn:hover:before,
      &.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active,
      .mbsc-sc-btn.mbsc-active:before {
        background: rgba($accent, .3);
      }

      /* Multiple select */
      .mbsc-sc-whl-multi {
        .mbsc-sc-itm-sel:before {
          color: $accent;
        }
      }
    }
  }
}



@include exports("scroller.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-sc-whl-gr {
      padding: .5em .25em;
    }

    .mbsc-sc-whl-w {
      margin: 0 .25em;
    }

    .mbsc-sc-lbl-v .mbsc-sc-whl-w {
      margin-top: 1.875em;
    }

    .mbsc-sc-lbl {
      font-size: .75em;
      line-height: 2.5em;
      text-transform: uppercase;
    }

    .mbsc-sc-cp .mbsc-sc-whl-w {
      padding: 2em 0;
    }

    .mbsc-sc-btn {
      height: 2em;
      line-height: 2em;
    }

    .mbsc-sc-btn:before {
      font-size: 1.5em;
    }

    .mbsc-sc-itm {
      padding: 0 .25em;
      font-size: 1.375em;
    }

    &.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
    .mbsc-sc-itm:focus {
      outline: 0;
    }

    /* Multiple select */
    .mbsc-sc-whl-multi {
      .mbsc-sc-itm {
        padding: 0 1.818181em;
      }

      .mbsc-sc-itm-sel:before {
        font-size: 1.818181em;
      }
    }

    /* Desktop view */
    &.mbsc-fr-pointer {
      .mbsc-sc-lbl {
        padding-left: 1.666667em;
        padding-right: 1.666667em;
      }

      .mbsc-sc-whl-w {
        margin-left: 0;
        margin-right: 0;
      }

      .mbsc-sc-itm {
        font-size: 1em;
        padding: 0 1.25em;
      }

      .mbsc-sc-whl-multi {
        .mbsc-sc-itm {
          padding: 0 2.5em;
        }

        .mbsc-sc-itm-sel:before {
          font-size: 2em;
        }
      }

      .mbsc-ltr .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
        left: .125em;
      }

      .mbsc-rtl .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
        right: .125em;
      }
    }
  }

  @include mbsc-mobiscroll-scroller(mobiscroll, $mbsc-mobiscroll-colors);
}





// Theme specific variables - inherited from global variables

// Background
$mbsc-mobiscroll-input-background: $mbsc-input-background-light !default;
$mbsc-mobiscroll-dark-input-background: $mbsc-input-background-dark !default;
// Text
$mbsc-mobiscroll-input-text: $mbsc-input-text-light !default;
$mbsc-mobiscroll-dark-input-text: $mbsc-input-text-dark !default;
// Accent
$mbsc-mobiscroll-input-accent: $mbsc-input-accent-light !default;
$mbsc-mobiscroll-dark-input-accent: $mbsc-input-accent-dark !default;
// Error
$mbsc-mobiscroll-input-error: $mbsc-input-error-light !default;
$mbsc-mobiscroll-dark-input-error: $mbsc-input-error-dark !default;

$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'input-background': $mbsc-mobiscroll-input-background,
  'input-text': $mbsc-mobiscroll-input-text,
  'input-accent': $mbsc-mobiscroll-input-accent,
  'input-error': $mbsc-mobiscroll-input-error,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'input-background': $mbsc-mobiscroll-dark-input-background,
  'input-text': $mbsc-mobiscroll-dark-input-text,
  'input-accent': $mbsc-mobiscroll-dark-input-accent,
  'input-error': $mbsc-mobiscroll-dark-input-error,
));

@mixin mbsc-mobiscroll-input($theme, $params) {
  @include exports("input.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');
    // get custom params
    $input-background-param: map-get($params, 'input-background');
    $input-text-param: map-get($params, 'input-text');
    $input-accent-param: map-get($params, 'input-accent');
    $input-error-param: map-get($params, 'input-error');

    $text: if($input-text-param, $input-text-param, $text);
    $accent: if($input-accent-param, $input-accent-param, $accent);

    $input-box: '';
    @if (lightness($background) > 50%) {
      $input-box: darken($background, 10%);
    }
    @else {
      $input-box: lighten($background, 10%);
    }


    $colors: mbsc-mobiscroll-colors($params);
    $error: map-get($colors, error);
    $white-text: map-get($colors, 'white-text');
    $input-icon: lighten($text, 20%);
    $color-input: darken($text, 15%);

    $input-box: if($input-background-param, $input-background-param, $input-box);
    $error: if($input-error-param, $input-error-param, $error);

    .mbsc-#{$theme} {

      /* Textbox */
      &.mbsc-input .mbsc-control {
        border-bottom: 1px solid $input-icon;
        color: $color-input;
      }

      &.mbsc-input .mbsc-control:focus,
      &.mbsc-input select:focus ~ input .mbsc-input textarea:focus {
        border-color: $accent;
      }

      .mbsc-input-ic {
        color: $input-icon;
      }

      /* Box Input */
      &.mbsc-input-box {
        &.mbsc-input .mbsc-input-wrap {
          background: $input-box;
        }
      }

      /* Outline Input */
      &.mbsc-input-outline {

        &.mbsc-input .mbsc-control {
          border: 1px solid $input-icon;
        }

        &.mbsc-input .mbsc-label {
          background: $background;
        }

        &.mbsc-label-inline.mbsc-input .mbsc-label,
        &.mbsc-label-floating:not(.mbsc-label-floating-active).mbsc-input .mbsc-label {
          background: none;
        }

        &.mbsc-input .mbsc-control:focus,
        &.mbsc-input select:focus ~ input,
        &.mbsc-err input {
          border-color: $accent;
        }

        &.mbsc-input textarea:focus {
          border-color: $accent;
        }
      }

      &.mbsc-input.mbsc-err input,
      &.mbsc-input.mbsc-err textarea {
        border-color: $error;
      }
    }
  }
}



@include exports("input.mobiscroll") {
  .mbsc-mobiscroll {

    /* Textbox */
    &.mbsc-input input,
    &.mbsc-input textarea,
    &.mbsc-color-input {
      height: 2.125em;
      padding: 0 0 1px 0;
      background: transparent;
      font-size: 1em;
    }

    &.mbsc-input input:disabled,
    &.mbsc-input textarea:disabled,
    &.mbsc-input input:disabled ~ .mbsc-color-input,
    &.mbsc-input .mbsc-control:disabled ~ input,
    &.mbsc-input .mbsc-control:disabled ~ .mbsc-select-ic {
      opacity: .4;
    }

    /* Input */
    &.mbsc-input {
      margin: 1.5em 1em;

      .mbsc-label ~ .mbsc-input-wrap {
        padding-top: .875em;

        .mbsc-input-ic {
          top: .9375em;
        }
      }
    }

    .mbsc-input-ic {
      top: .0625em;
      margin: -2px 0 0 0;
    }

    /* Textarea */
    &.mbsc-input textarea {
      padding-top: .25em;
    }

    /* Select */
    .mbsc-select-ic {
      position: absolute;
      display: block;
      height: 1.25em;
      width: 1.25em;
      top: .5625em;
      text-align: center;
    }

    &.mbsc-ltr .mbsc-select-ic {
      left: auto;
      right: .6875em;
    }

    &.mbsc-rtl .mbsc-select-ic {
      right: auto;
      left: .6875em;
    }

    .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
      top: 1.4375em;
    }

    &.mbsc-ltr.mbsc-select {
      input {
        padding-right: 1.25em;
      }

      &.mbsc-ic-right input {
        padding-right: 3em;
      }

      .mbsc-input-wrap .mbsc-select-ic {
        left: auto;
        right: 0;
      }

      &.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
        right: 1.75em;
      }
    }

    &.mbsc-rtl.mbsc-select {
      input {
        padding-left: 1.25em;
      }

      &.mbsc-ic-right input {
        padding-left: 3em;
      }

      .mbsc-input-wrap .mbsc-select-ic {
        right: auto;
        left: 0;
      }

      &.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
        left: 1.75em;
      }
    }

    /* Select inline */
    .mbsc-select-inline .mbsc-select-ic {
      display: none;
    }

    /* LTR left icon */
    &.mbsc-ltr.mbsc-input.mbsc-ic-left {

      input,
      textarea {
        padding-left: 1.625em;
      }

      .mbsc-left-ic {
        right: auto;
        left: -.375em
      }

      .mbsc-label,
      .mbsc-err-msg {
        left: 2.166667em;
      }
    }

    /* LTR right icon */
    &.mbsc-ltr.mbsc-input.mbsc-ic-right {

      input,
      textarea {
        padding-right: 1.625em;
      }

      .mbsc-right-ic {
        left: auto;
        right: -.375em
      }
    }

    /* RTL left icon */
    &.mbsc-rtl.mbsc-input.mbsc-ic-left {

      input,
      textarea {
        padding-right: 1.625em;
      }

      .mbsc-left-ic {
        left: auto;
        right: -.375em
      }

      .mbsc-label,
      .mbsc-err-msg {
        right: 2.166667em;
      }
    }

    /* RTL right icon */
    &.mbsc-rtl.mbsc-input.mbsc-ic-right {

      input,
      textarea {
        padding-left: 1.625em;
      }

      .mbsc-right-ic {
        right: auto;
        left: -.375em
      }
    }

    &.mbsc-input .mbsc-label {
      position: absolute;
      top: 0;
      font-size: .75em;
    }

    /* Inline labels */
    &.mbsc-label-inline.mbsc-input {
      .mbsc-label {
        line-height: 2em;
      }

      .mbsc-label + .mbsc-input-wrap {
        padding-top: 0;

        .mbsc-input-ic {
          top: .0625em;
        }
      }

      &.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
        top: .5625em;
      }
    }

    /* Floating labels */
    &.mbsc-label-floating.mbsc-input {
      .mbsc-label {
        font-size: 1em;
        line-height: 2em;
        top: .875em;
      }

      &.mbsc-label-floating-active .mbsc-label {
        -webkit-transform: translateY(-1.125em) scale(.75);
        transform: translateY(-1.125em) scale(.75);
      }
    }

    &.mbsc-ltr.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
      left: 1.625em;
    }

    &.mbsc-rtl.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
      right: 1.625em;
    }

    /* Box Input */
    &.mbsc-input-box {
      &.mbsc-input .mbsc-input-wrap {
        border-radius: 4px 4px 0 0;
      }

      &.mbsc-input input,
      &.mbsc-input .mbsc-color-input {
        height: 2.25em;
        padding: .0625em 1em;
        border-radius: 4px 4px 0 0;
      }

      &.mbsc-input textarea {
        padding: .375em 1em .0625em 1em;
        height: 2.25em;
      }

      &.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
        padding-top: 0;

        input,
        .mbsc-color-input {
          padding-top: 1.25em;
          height: 3.5em;
        }

        textarea:focus {
          padding-bottom: 0;
        }

        .mbsc-input-ic {
          top: .875em;
        }
      }

      .mbsc-input-ic {
        top: .25em;
      }

      &.mbsc-input .mbsc-label {
        padding: .666667em 0;
        z-index: 1;
        line-height: normal;
      }

      &.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
        padding-top: 1.625em;

        textarea {
          height: 1.866667em;
          padding-top: 0;
        }
      }

      &.mbsc-select {
        .mbsc-input-wrap .mbsc-select-ic {
          top: .625em;
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
          top: 1.375em;
        }
      }

      /* Inline labels */
      &.mbsc-label-inline {
        .mbsc-label {
          padding-top: .125em;
          padding-bottom: .125em;
          line-height: 2em;
        }

        .mbsc-label ~ .mbsc-input-wrap {
          padding-top: 0;

          input,
          .mbsc-color-input {
            height: 2.25em;
            padding-top: .0625em;
          }
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
          top: .1875em;
        }

        &.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
          padding-top: .375em;
        }

        &.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
          top: .625em;
        }
      }

      /* Floating labels */
      &.mbsc-label-floating {
        .mbsc-label {
          top: .125em;
          line-height: 2em;
        }

        &.mbsc-label-floating-active .mbsc-label {
          line-height: normal;
          -webkit-transform: translateY(-.125em) scale(.75);
          transform: translateY(-.125em) scale(.75);
        }
      }
    }

    &.mbsc-ltr.mbsc-input-box {
      .mbsc-err-msg {
        left: 1.333334em;
      }

      .mbsc-label {
        left: 1.333334em;
        right: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          left: 4em;
        }

        .mbsc-label {
          left: 4em;
        }

        .mbsc-left-ic {
          left: .5em;
        }

        input,
        textarea {
          padding-left: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-right: 3em;
        }

        .mbsc-right-ic {
          right: .5em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          right: 2em;
        }
      }

      &.mbsc-select {
        input {
          padding-right: 2em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          right: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-right: 4em;
          }

          .mbsc-input-wrap .mbsc-select-ic {
            right: 2.75em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        left: 1em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        left: 3em;
      }
    }

    &.mbsc-rtl.mbsc-input-box {
      .mbsc-err-msg {
        right: 1.333334em;
      }

      .mbsc-label {
        right: 1.333334em;
        left: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          right: 4em;
        }

        .mbsc-label {
          right: 4em;
        }

        .mbsc-left-ic {
          right: .5em;
        }

        input,
        textarea {
          padding-right: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-left: 3em;
        }

        .mbsc-right-ic {
          left: .5em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          left: 2em;
        }
      }

      &.mbsc-select {
        input {
          padding-left: 2em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          left: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-left: 4em;
          }

          .mbsc-input-wrap .mbsc-select-ic {
            left: 2.75em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        right: 1em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        right: 3em;
      }
    }

    /* Outline Input */
    &.mbsc-input-outline {

      &.mbsc-input input,
      &.mbsc-input textarea,
      &.mbsc-input .mbsc-color-input {
        border-radius: 4px;
        height: 3.375em;
      }

      &.mbsc-input input,
      &.mbsc-input .mbsc-color-input {
        padding: .0625em 1em;
      }

      &.mbsc-input textarea {
        padding: .875em 1em .4375em 1em;
      }

      &.mbsc-input .mbsc-label {
        top: -.166667em;
        z-index: 1;
        padding: 0 .333334em;
      }

      .mbsc-input-ic {
        top: .8125em;
      }

      &.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
        padding-top: .25em;

        .mbsc-input-ic {
          top: 1.0625em;
        }
      }

      &.mbsc-input input:focus,
      &.mbsc-input select:focus ~ input,
      &.mbsc-err input {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.mbsc-err input,
      &.mbsc-err textarea {
        border-color: #de3226;
      }

      &.mbsc-select {
        .mbsc-input-wrap .mbsc-select-ic {
          top: 1.3125em;
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
          top: 1.5625em;
        }
      }

      /* Inline labels */
      &.mbsc-label-inline {
        .mbsc-label {
          line-height: 3.375em;
        }

        .mbsc-label ~ .mbsc-input-wrap {
          padding-top: 0;
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
          top: .8125em;
        }

        &.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
          top: 1.3125em;
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input {
        .mbsc-label {
          top: .9375em;
          padding: 0 .25em;
        }

        &.mbsc-label-floating-active .mbsc-label {
          -webkit-transform: translateY(-1.375em) scale(.75);
          transform: translateY(-1.375em) scale(.75);
        }
      }
    }

    &.mbsc-ltr.mbsc-input-outline {
      .mbsc-err-msg {
        left: 1.416667em;
      }

      .mbsc-label {
        left: 1.083334em;
        right: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          left: 4.083334em;
        }

        .mbsc-label {
          left: 3.75em;
        }

        .mbsc-left-ic {
          left: .5em;
        }

        input,
        textarea {
          padding-left: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-right: 3em;
        }

        .mbsc-right-ic {
          right: .5em;
        }
      }

      &.mbsc-select {
        input {
          padding-right: 2em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          right: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-right: 4em;
          }

          .mbsc-input-wrap .mbsc-select-ic {
            right: 3em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        left: .875em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        left: 2.875em;
      }
    }

    &.mbsc-rtl.mbsc-input-outline {
      .mbsc-err-msg {
        right: 1.416667em;
      }

      .mbsc-label {
        right: 1.083334em;
        left: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          right: 4.083334em;
        }

        .mbsc-label {
          right: 3.75em;
        }

        .mbsc-left-ic {
          right: .5em;
        }

        input,
        textarea {
          padding-right: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-left: 3em;
        }

        .mbsc-right-ic {
          left: .5em;
        }
      }

      &.mbsc-select {
        input {
          padding-left: 2em;
        }

        .mbsc-input-wrap .mbsc-select-ic {
          left: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-left: 4em;
          }

          .mbsc-input-wrap .mbsc-select-ic {
            left: 3em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        right: .875em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        right: 2.875em;
      }
    }
  }

  @include mbsc-mobiscroll-input(mobiscroll, $mbsc-mobiscroll-colors);
}






@mixin mbsc-mobiscroll-select($theme, $params) {
  @include exports("select.#{$theme}.colors") {

    @include mbsc-mobiscroll-scroller($theme, $params);
    @include mbsc-mobiscroll-input($theme, $params);

    $text: map-get($params, text);
    $accent: map-get($params, accent);

    .mbsc-#{$theme} {
      .mbsc-sel-gr-whl.mbsc-sel-one .mbsc-sc-itm-sel {
        color: $accent;
      }

      &.mbsc-fr-pointer .mbsc-sel-one.mbsc-sc-whl-multi .mbsc-sc-itm-sel {
        color: $text;
        background: rgba($accent, .3);
      }
    }
  }
}



@include exports("select.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-sc-whl .mbsc-sel-gr {
      padding: 0 .25em;
      font-size: 1.125em;
    }

    .mbsc-sel-gr-whl {
      .mbsc-sc-itm {
        padding: 0 .25em;
      }

      .mbsc-sc-itm::before {
        display: none;
      }
    }

    &.mbsc-fr-pointer {

      /* Single select */
      .mbsc-sel-one {
        .mbsc-sc-itm {
          padding: 0 1.25em;
        }

        .mbsc-sc-itm-sel:before {
          display: none;
        }
      }

      /* Group headers */
      .mbsc-sc-itm.mbsc-sel-gr-itm {
        padding: 0 2.5em;
      }
    }
  }

  @include mbsc-mobiscroll-select(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}







$mbsc-material-accent: #009688 !default;
$mbsc-material-background: #eee !default;
$mbsc-material-text: #5b5b5b !default;

$mbsc-material-dark-accent: #81ccc4 !default;
$mbsc-material-dark-background: #303030 !default;
$mbsc-material-dark-text: #c2c2c2 !default;

/* Base colors */
$mbsc-material-primary: #3f97f6 !default;
$mbsc-material-secondary: #90979E !default;
$mbsc-material-success: #43BE5F !default;
$mbsc-material-danger: #f5504e !default;
$mbsc-material-warning: #f8b042 !default;
$mbsc-material-info: #5BB7C5 !default;
$mbsc-material-light: #fff !default;
$mbsc-material-dark: #47494A !default;

$mbsc-material-colors: ( // Colors map
  'background': $mbsc-material-background,
  'text': $mbsc-material-text,
  'accent': $mbsc-material-accent,
);

$mbsc-material-dark-colors: ( // Colors map
  'background': $mbsc-material-dark-background,
  'text': $mbsc-material-dark-text,
  'accent': $mbsc-material-dark-accent,
);

@function mbsc-material-colors($params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $snackbar-btn: hsl(hue($accent), saturation($accent), max(lightness($accent), 34%));

  $border: '';
  $gray-background: '';
  $snackbar: '';
  $background-contrast: '';
  $lv-item: '';

  // Light background
  @if (lightness($background) > 50%) {
    $border: lighten($text, 40%);
    $gray-background: darken($background, 23%);
    $snackbar: #323232;
    $background-contrast: #000;
  }
  // Dark background
  @else {
    $border: darken($text, 40%);
    $gray-background: lighten($background, 17%);
    $snackbar: #5b5b5b;
    $background-contrast: #fff;
  }

  @return ('ripple': $background-contrast,
    'border': $border,
    'gray-background': $gray-background,
    'background-contrast': $background-contrast,
    'snackbar': $snackbar,
    'snackbar-btn': $snackbar-btn,
    // Static colors
    'white-text': #fff,
    'dark-text': #000,
    'light-text': #eee,
    'error': #de3226,
  );
}

@mixin mbsc-material-common($theme, $params) {
  @include exports("common.#{$theme}.colors") {

    $text: map-get($params, text);
    $colors: mbsc-material-colors($params);
    $ripple: map-get($colors, ripple);

    .mbsc-#{$theme} {
      .mbsc-ripple {
        background: $ripple;
      }

      .mbsc-empty {
        color: $text;
      }
    }
  }
}


// Theme specific variables - inherited from global variables

// Background
$mbsc-material-frame-background: $mbsc-frame-background-light !default;
$mbsc-material-dark-frame-background: $mbsc-frame-background-dark !default;
// Text
$mbsc-material-frame-text: $mbsc-frame-text-light !default;
$mbsc-material-dark-frame-text: $mbsc-frame-text-dark !default;
// Accent
$mbsc-material-frame-accent: $mbsc-frame-accent-light !default;
$mbsc-material-dark-frame-accent: $mbsc-frame-accent-dark !default;
// Overlay
$mbsc-material-frame-overlay: $mbsc-frame-overlay-light !default;
$mbsc-material-dark-frame-overlay: $mbsc-frame-overlay-dark !default;

$mbsc-material-colors: map-merge($mbsc-material-colors, (
  'frame-background': $mbsc-material-frame-background,
  'frame-text': $mbsc-material-frame-text,
  'frame-accent': $mbsc-material-frame-accent,
  'frame-overlay': $mbsc-material-frame-overlay,
));

$mbsc-material-dark-colors: map-merge($mbsc-material-dark-colors, (
  'frame-background': $mbsc-material-dark-frame-background,
  'frame-text': $mbsc-material-dark-frame-text,
  'frame-accent': $mbsc-material-dark-frame-accent,
  'frame-overlay': $mbsc-material-dark-frame-overlay,
));

@mixin mbsc-material-frame($theme, $params) {
  @include exports("frame.#{$theme}.colors") {
    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $overlay-param: map-get($params, 'frame-overlay');
    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);
    $overlay: if($overlay-param, $overlay-param, rgba(0, 0, 0, .6));

    $background-contrast: '';
    @if (lightness($background) > 50%) {
      $background-contrast: #000;
    }
    @else {
      $background-contrast: #fff;
    }

    .mbsc-#{$theme} {
      .mbsc-fr-overlay {
        background: $overlay;
      }

      .mbsc-fr-w {
        background: $background;
        color: $text;
        box-shadow: 0 0.25em 1.5em rgba(0, 0, 0, 0.3);
      }

      .mbsc-fr-hdr {
        color: $accent;
      }

      .mbsc-fr-btn {
        color: $accent;
      }

      &.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
      .mbsc-fr-btn.mbsc-active {
        background: rgba($background-contrast, .1);
      }

      .mbsc-fr-arr {
        box-shadow: 0 0 1.5em rgba(0, 0, 0, 0.2);
        background: $background;
      }
    }
  }
}



@include exports("ripple.material") {
  .mbsc-material {
    .mbsc-ripple {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      border-radius: 1000em;
      pointer-events: none;
      -webkit-transform: scale(0);
      transform: scale(0);
      -webkit-transition: -webkit-transform cubic-bezier(0, 0, 0.2, 1) .4s, opacity linear .1s;
      transition: transform cubic-bezier(0, 0, 0.2, 1) .4s, opacity linear .1s;
    }

    .mbsc-ripple-scaled {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    .mbsc-ripple-visible {
      opacity: .15;
    }
  }
}



@include exports("frame.material") {
  .mbsc-material {
    .mbsc-fr-w {
      border-radius: .1875em;
      min-width: 15em;
      font-size: 16px;
    }

    .mbsc-fr-hdr {
      padding: 0 .6666em;
      padding-top: .6666em;
      font-size: .75em;
      font-weight: bold;
      text-transform: uppercase;
      min-height: 2em;
      line-height: 2em;
    }

    .mbsc-fr-btn-cont {
      display: block;
      overflow: hidden;
      padding: 0 .5em .5em .5em;
    }

    .mbsc-ltr .mbsc-fr-btn-cont {
      text-align: right;
    }

    .mbsc-rtl .mbsc-fr-btn-cont {
      text-align: left;
    }

    .mbsc-fr-btn-w {
      display: inline-block;
    }

    .mbsc-fr-btn {
      position: relative;
      height: 2.4em;
      line-height: 2.4em;
      padding: 0 1em;
      border-radius: 2px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .mbsc-fr-btn-cont .mbsc-fr-btn {
      font-size: .9375em;
    }

    .mbsc-fr-btn-e {
      transition: background-color .2s ease-out;
    }

    /* Inline mode */

    &.mbsc-fr-inline .mbsc-fr-w {
      box-shadow: none;
    }


    /* Top, bottom mode */

    &.mbsc-fr-inline .mbsc-fr-w,
    &.mbsc-fr-top .mbsc-fr-w,
    &.mbsc-fr-bottom .mbsc-fr-w {
      border-radius: 0;
    }
  }

  @include mbsc-material-frame(material, $mbsc-material-colors);
}





@mixin mbsc-material-scroller($theme, $params) {
  @include exports("scroller.#{$theme}.colors") {

    @include mbsc-material-frame($theme, $params);

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $accent: if($accent-param, $accent-param, $accent);

    .mbsc-#{$theme} {
      .mbsc-sc-lbl {
        color: $accent;
      }

      &.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
      .mbsc-sc-itm:focus {
        background: rgba(0, 0, 0, .05);
      }

      &.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
        background: rgba(0, 0, 0, .1);
      }

      .mbsc-sc-whl-l {
        border-top: 2px solid $accent;
        border-bottom: 2px solid $accent;
      }

      /* Clickpick mode */

      .mbsc-sc-btn {
        color: $accent;
        background: $background;
      }

      &.mbsc-no-touch .mbsc-sc-btn:hover,
      .mbsc-sc-btn.mbsc-active {
        background: rgba(0, 0, 0, .1);
      }

      /* Multiple select */

      .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
        color: $accent;
      }
    }
  }
}



@include exports("scroller.material") {
  .mbsc-material {
    .mbsc-sc-whl-gr {
      padding: 2em .25em;
    }

    .mbsc-sc-cp {
      padding: .5em .25em;
    }

    .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
      padding-top: 2.5em;
      padding-bottom: 0;
    }

    .mbsc-sc-lbl {
      line-height: 2.666666em;
      font-size: .75em;
      font-weight: bold;
      text-transform: uppercase;
    }

    .mbsc-sc-whl-w {
      margin: 0 .25em;
      padding: .5em 0;
    }

    .mbsc-sc-itm {
      padding: 0 .272727em;
      font-size: 1.375em;
    }

    &.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
    .mbsc-sc-itm:focus {
      outline: 0;
    }

    &.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
      border-radius: 2px;
    }

    /* Clickpick mode */

    .mbsc-sc-cp .mbsc-sc-whl-w {
      padding: 2em 0;
    }

    .mbsc-sc-btn {
      height: 2em;
      line-height: 2em;
      overflow: hidden;
    }

    .mbsc-sc-btn:before {
      font-size: 1.5em;
    }

    /* Multiple select */

    .mbsc-sc-whl-multi .mbsc-sc-itm {
      padding: 0 1.818181em;
    }

    .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
      width: 1.818181em;
    }

    /* Desktop styling */

    &.mbsc-fr-pointer {
      .mbsc-sc-lbl {
        padding-left: 1.666667em;
        padding-right: 1.666667em;
      }

      .mbsc-sc-whl-w {
        margin: 0;
        padding: 0;
      }

      .mbsc-sc-itm {
        font-size: 1em;
        padding: 0 1.25em;
      }

      .mbsc-sc-whl-multi .mbsc-sc-itm {
        padding: 0 2.5em;
      }

      .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
        width: 2em;
      }

      .mbsc-ltr .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
        left: .25em;
      }

      .mbsc-rtl .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
        right: .25em;
      }
    }
  }

  @include mbsc-material-scroller(material, $mbsc-material-colors);
}





// Theme specific variables - inherited from global variables

// Background
$mbsc-material-input-background: $mbsc-input-background-light !default;
$mbsc-material-dark-input-background: $mbsc-input-background-dark !default;
// Border
$mbsc-material-input-border: $mbsc-input-border-light !default;
$mbsc-material-dark-input-border: $mbsc-input-border-dark !default;
// Text
$mbsc-material-input-text: $mbsc-input-text-light !default;
$mbsc-material-dark-input-text: $mbsc-input-text-dark !default;
// Accent
$mbsc-material-input-accent: $mbsc-input-accent-light !default;
$mbsc-material-dark-input-accent: $mbsc-input-accent-dark !default;
// Error
$mbsc-material-input-error: $mbsc-input-error-light !default;
$mbsc-material-dark-input-error: $mbsc-input-error-dark !default;

$mbsc-material-colors: map-merge($mbsc-material-colors, (
  'input-background': $mbsc-material-input-background,
  'input-border': $mbsc-material-input-border,
  'input-text': $mbsc-material-input-text,
  'input-accent': $mbsc-material-input-accent,
  'input-error': $mbsc-material-input-error,
));
$mbsc-material-dark-colors: map-merge($mbsc-material-dark-colors, (
  'input-background': $mbsc-material-dark-input-background,
  'input-border': $mbsc-material-dark-input-border,
  'input-text': $mbsc-material-dark-input-text,
  'input-accent': $mbsc-material-dark-input-accent,
  'input-error': $mbsc-material-dark-input-error,
));

@mixin mbsc-material-input($theme, $params) {
  @include exports("input.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');
    // custom colors
    $input-background-param: map-get($params, 'input-background');
    $input-border-param: map-get($params, 'input-border');
    $input-text-param: map-get($params, 'input-text');
    $input-accent-param: map-get($params, 'input-accent');
    $input-error-param: map-get($params, 'input-error');

    // overwrite colors with custom ones
    $background: if($input-background-param, $input-background-param, $background);
    $text: if($input-text-param, $input-text-param, $text);
    $accent: if($input-accent-param, $input-accent-param, $accent);

    $box-input: '';
    @if (lightness($background) > 50%) {
      $box-input: darken($background, 7%);
    }
    @else {
      $box-input: lighten($background, 7%);
    }

    // derived colors
    $colors: mbsc-material-colors($params);
    $error: map-get($colors, error);
    $border: if($input-border-param, $input-border-param, lighten($text, 7%));
    $error: if($input-error-param, $input-error-param, $error);
    $input-text: darken($text, 24%);
    .mbsc-#{$theme} {

      /* Textbox */
      &.mbsc-input .mbsc-control {
        border-bottom: 1px solid $border;
        color: $input-text;
      }

      /* Textbox, textarea, select */
      &.mbsc-input .mbsc-control:focus,
      &.mbsc-input select:focus ~ input {
        border-bottom: 2px solid $accent;
      }

      /* Input */
      &.mbsc-input.mbsc-err input,
      &.mbsc-input.mbsc-err textarea {
        border-bottom: 1px solid $error;
      }

      /* Select */
      &.mbsc-select .mbsc-input-wrap:after {
        border: 5px solid $border;
        border-color: $border transparent transparent transparent;
      }

      /* Box Input color */
      &.mbsc-input-box {
        &.mbsc-input .mbsc-input-wrap {
          background: $box-input;
        }
      }

      /* Outline Input */
      &.mbsc-input-outline {

        &.mbsc-input .mbsc-control {
          border: 1px solid $border;
        }

        &.mbsc-input .mbsc-label {
          background: $background;
        }

        &.mbsc-label-inline.mbsc-input .mbsc-label,
        &.mbsc-label-floating:not(.mbsc-label-floating-active).mbsc-input .mbsc-label {
          background: none;
        }

        &.mbsc-input input:focus,
        &.mbsc-input select:focus ~ input {
          border: 2px solid $accent;
        }

        &.mbsc-err input {
          border: 1px solid $error;
        }

        &.mbsc-err input:focus {
          border: 2px solid $error;
        }

        &.mbsc-input textarea:focus {
          border: 2px solid $accent;
        }

        &.mbsc-err textarea {
          border: 1px solid $error;
        }

        &.mbsc-err textarea:focus {
          border: 2px solid $error;
        }
      }
    }
  }
}



@include exports("input.material") {
  .mbsc-material {

    /* Textbox */
    &.mbsc-input input,
    &.mbsc-input textarea,
    &.mbsc-input .mbsc-color-input {
      height: 2.125em;
      padding: 0 0 1px 0;
      background-color: transparent;
      font-size: 1em;
      transition: border-color .2s;
    }

    /* Textbox, textarea, select */
    &.mbsc-input .mbsc-control:disabled ~ input,
    &.mbsc-input textarea:disabled,
    &.mbsc-input input:disabled,
    &.mbsc-input input:disabled ~ .mbsc-color-input {
      border-style: dotted;
      opacity: .5;
    }

    &.mbsc-input input:focus,
    &.mbsc-input textarea:focus,
    &.mbsc-input select:focus ~ input,
    &.mbsc-input .mbsc-color-input:focus {
      padding-bottom: 0;
    }

    /* Input */
    &.mbsc-input {
      margin: 1.5em 1em;

      .mbsc-label ~ .mbsc-input-wrap {
        padding-top: .875em;

        .mbsc-input-ic {
          top: .9375em;
        }
      }
    }

    &.mbsc-input.mbsc-err input:focus,
    &.mbsc-input.mbsc-err textarea:focus {
      padding-bottom: 0;
      border-width: 2px;
    }

    .mbsc-input-ic {
      top: .0625em;
      margin: -2px 0 0 0;
    }

    /* Textarea */
    &.mbsc-input textarea {
      padding-top: .25em;
    }

    /* Select */
    &.mbsc-select .mbsc-input-wrap:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      top: .875em;
    }

    &.mbsc-select .mbsc-label ~ .mbsc-input-wrap:after {
      top: 1.75em;
    }

    &.mbsc-ltr.mbsc-select {
      input {
        padding-right: 1em;
      }

      &.mbsc-ic-right input {
        padding-right: 3em;
      }

      .mbsc-input-wrap:after {
        left: auto;
        right: 0;
      }

      &.mbsc-ic-right .mbsc-input-wrap:after {
        right: 2em;
      }
    }

    &.mbsc-rtl.mbsc-select {
      input {
        padding-left: 1em;
      }

      &.mbsc-ic-right input {
        padding-left: 3em;
      }

      .mbsc-input-wrap:after {
        right: auto;
        left: 0;
      }

      &.mbsc-ic-right .mbsc-input-wrap:after {
        left: 2em;
      }
    }

    /* Inline select */
    .mbsc-select-inline .mbsc-input-wrap:after {
      display: none;
    }

    /* LTR left icon */
    &.mbsc-ltr.mbsc-input.mbsc-ic-left {

      input,
      textarea {
        padding-left: 1.625em;
      }

      .mbsc-left-ic {
        right: auto;
        left: -.375em
      }

      .mbsc-label,
      .mbsc-err-msg {
        left: 2.166667em;
      }
    }

    /* LTR right icon */
    &.mbsc-ltr.mbsc-input.mbsc-ic-right {

      input,
      textarea {
        padding-right: 1.625em;
      }

      .mbsc-right-ic {
        left: auto;
        right: -.375em
      }
    }

    /* RTL left icon */
    &.mbsc-rtl.mbsc-input.mbsc-ic-left {

      input,
      textarea {
        padding-right: 1.625em;
      }

      .mbsc-left-ic {
        left: auto;
        right: -.375em
      }

      .mbsc-label,
      .mbsc-err-msg {
        right: 2.166667em;
      }
    }

    /* RTL right icon */
    &.mbsc-rtl.mbsc-input.mbsc-ic-right {

      input,
      textarea {
        padding-left: 1.625em;
      }

      .mbsc-right-ic {
        right: auto;
        left: -.375em
      }
    }

    &.mbsc-input .mbsc-label {
      position: absolute;
      top: 0;
      font-size: .75em;
    }

    /* Inline labels */
    &.mbsc-label-inline.mbsc-input {
      .mbsc-label {
        line-height: 2em;
      }

      .mbsc-label ~ .mbsc-input-wrap {
        padding-top: 0;

        .mbsc-input-ic {
          top: .0625em;
        }
      }

      &.mbsc-select .mbsc-label ~ .mbsc-input-wrap:after {
        top: 1em;
      }
    }

    /* Floating labels */
    &.mbsc-label-floating.mbsc-input {
      .mbsc-label {
        font-size: 1em;
        line-height: 2em;
        top: .875em;
      }

      &.mbsc-label-floating-active {
        .mbsc-label {
          -webkit-transform: translateY(-1.125em) scale(.75);
          transform: translateY(-1.125em) scale(.75);
        }
      }
    }

    &.mbsc-ltr.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
      left: 1.625em;
    }

    &.mbsc-rtl.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
      right: 1.625em;
    }

    /* Box Input */
    &.mbsc-input-box {
      &.mbsc-input .mbsc-input-wrap {
        border-radius: 4px 4px 0 0;
      }

      &.mbsc-input input,
      &.mbsc-input .mbsc-color-input {
        height: 2.25em;
        padding: .0625em 1em;
        border-radius: 4px 4px 0 0;
      }

      &.mbsc-input input:focus,
      &.mbsc-input textarea:focus,
      &.mbsc-input select:focus ~ input,
      &.mbsc-input .mbsc-color-input:focus {
        padding-bottom: 0;
      }

      &.mbsc-input textarea {
        padding: .375em 1em .0625em 1em;
        height: 2.25em;
      }

      &.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
        padding-top: 0;

        input,
        .mbsc-color-input {
          padding-top: 1.25em;
          height: 3.5em;
        }

        textarea:focus {
          padding-bottom: 0;
        }

        .mbsc-input-ic {
          top: .875em;
        }
      }

      .mbsc-input-ic {
        top: .25em;
      }

      .mbsc-label {
        top: .666667em;
        z-index: 1;
      }

      &.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
        padding-top: 1.625em;

        textarea {
          height: 1.866667em;
          padding-top: 0;
        }
      }

      &.mbsc-select {
        .mbsc-input-wrap:after {
          top: 1em;
        }

        .mbsc-label ~ .mbsc-input-wrap:after {
          top: 1.625em;
        }
      }

      /* Inline labels */
      &.mbsc-label-inline {
        .mbsc-label {
          padding-top: .125em;
          padding-bottom: .125em;
        }

        .mbsc-label ~ .mbsc-input-wrap {
          padding-top: 0;

          input,
          .mbsc-color-input {
            height: 2.25em;
            padding-top: .0625em;
          }
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
          top: .1875em;
        }

        &.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
          padding-top: .375em;
        }
      }

      /* Floating labels */
      &.mbsc-label-floating {
        &.mbsc-label-floating-active .mbsc-label {
          -webkit-transform: translateY(-.625em) scale(.75);
          transform: translateY(-.625em) scale(.75);
        }
      }
    }

    &.mbsc-ltr.mbsc-input-box {
      .mbsc-err-msg {
        left: 1.333334em;
      }

      .mbsc-label {
        left: 1.333334em;
        right: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          left: 4em;
        }

        .mbsc-label {
          left: 4em;
        }

        .mbsc-left-ic {
          left: .5em;
        }

        input,
        textarea {
          padding-left: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-right: 3em;
        }

        .mbsc-right-ic {
          right: .5em;
        }

        .mbsc-input-wrap:after {
          right: 2em;
        }
      }

      &.mbsc-select {
        input {
          padding-right: 2em;
        }

        .mbsc-input-wrap:after {
          right: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-right: 4em;
          }

          .mbsc-input-wrap:after {
            right: 3em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        left: 1em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        left: 3em;
      }
    }

    &.mbsc-rtl.mbsc-input-box {
      .mbsc-err-msg {
        right: 1.333334em;
      }

      .mbsc-label {
        right: 1.333334em;
        left: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          right: 4em;
        }

        .mbsc-label {
          right: 4em;
        }

        .mbsc-left-ic {
          right: .5em;
        }

        input,
        textarea {
          padding-right: 3em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-left: 3em;
        }

        .mbsc-right-ic {
          left: .5em;
        }

        .mbsc-input-wrap:after {
          left: 2em;
        }
      }

      &.mbsc-select {
        input {
          padding-left: 2em;
        }

        .mbsc-input-wrap:after {
          left: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-left: 4em;
          }

          .mbsc-input-wrap:after {
            left: 3em;
          }
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        right: 1em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        right: 3em;
      }
    }

    /* Outline Input */
    &.mbsc-input-outline {

      &.mbsc-input input,
      &.mbsc-input textarea,
      &.mbsc-input .mbsc-color-input {
        border-radius: 4px;
      }

      &.mbsc-input input,
      &.mbsc-input .mbsc-color-input {
        height: 3.375em;
        padding: .0625em 1em;
      }

      &.mbsc-input textarea {
        height: 3.375em;
        padding: .875em 1em .4375em 1em;
      }

      &.mbsc-input .mbsc-label {
        top: -.166667em;
        z-index: 1;
        padding: 0 .333334em;
      }

      .mbsc-input-ic {
        top: .8125em;
      }

      &.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
        padding-top: .25em;

        .mbsc-input-ic {
          top: 1.0625em;
        }
      }

      &.mbsc-input input:focus,
      &.mbsc-input select:focus ~ input {
        padding: 0 .9375em;
      }

      &.mbsc-err input {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.mbsc-err input:focus {
        padding: 0 .9375em;
      }

      &.mbsc-input textarea:focus {
        padding: .8125em .9375em .375em .9375em;
      }

      &.mbsc-err textarea:focus {
        padding: .875em .9375em 0 .9375em;
      }

      &.mbsc-select {
        .mbsc-input-wrap:after {
          top: 1.5em;
        }

        .mbsc-label ~ .mbsc-input-wrap:after {
          top: 1.75em;
        }
      }

      /* Inline labels */
      &.mbsc-label-inline {
        .mbsc-label {
          line-height: 3.375em;
        }

        .mbsc-label ~ .mbsc-input-wrap {
          padding-top: 0;
        }

        .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
          top: .8125em;
        }

        &.mbsc-select .mbsc-label ~ .mbsc-input-wrap:after {
          top: 1.5em;
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input {
        .mbsc-label {
          top: .9375em;
          padding: 0 .25em;
        }

        &.mbsc-label-floating-active .mbsc-label {
          -webkit-transform: translateY(-1.375em) scale(.75);
          transform: translateY(-1.375em) scale(.75);
        }
      }
    }

    &.mbsc-ltr.mbsc-input-outline {
      .mbsc-err-msg {
        left: 1.416667em;
      }

      .mbsc-label {
        left: 1.083334em;
        right: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          left: 4.083334em;
        }

        .mbsc-label {
          left: 3.75em;
        }

        .mbsc-left-ic {
          left: .5em;
        }

        input,
        textarea {
          padding-left: 3em;
        }

        input:focus,
        textarea:focus,
        &.mbsc-err textarea {
          padding-left: 2.9375em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-right: 3em;
        }

        input:focus,
        textarea:focus {
          padding-right: 2.9375em;
        }

        .mbsc-right-ic {
          right: .5em;
        }
      }

      &.mbsc-select {
        input {
          padding-right: 2em;
        }

        select:focus ~ input {
          padding-right: 1.9375em;
        }

        .mbsc-input-wrap:after {
          right: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-right: 4em;
          }

          select:focus ~ input {
            padding-right: 3.9375em;
          }

          .mbsc-input-wrap:after {
            right: 3em;
          }
        }

        &.mbsc-ic-left select:focus ~ input {
          padding-left: 2.9375em;
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        left: .875em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        left: 2.875em;
      }
    }

    &.mbsc-rtl.mbsc-input-outline {
      .mbsc-err-msg {
        right: 1.416667em;
      }

      .mbsc-label {
        right: 1.083334em;
        left: auto;
      }

      &.mbsc-ic-left {
        .mbsc-err-msg {
          right: 4.083334em;
        }

        .mbsc-label {
          right: 3.75em;
        }

        .mbsc-left-ic {
          right: .5em;
        }

        input,
        textarea {
          padding-right: 3em;
        }

        input:focus,
        textarea:focus,
        &.mbsc-err textarea {
          padding-right: 2.9375em;
        }
      }

      &.mbsc-ic-right {

        input,
        textarea {
          padding-left: 3em;
        }

        input:focus,
        textarea:focus {
          padding-left: 2.9375em;
        }

        .mbsc-right-ic {
          left: .5em;
        }
      }

      &.mbsc-select {
        input {
          padding-left: 2em;
        }

        select:focus ~ input {
          padding-left: 1.9375em;
        }

        .mbsc-input-wrap:after {
          left: .6875em;
        }

        &.mbsc-ic-right {
          input {
            padding-left: 4em;
          }

          select:focus ~ input {
            padding-left: 3.9375em;
          }

          .mbsc-input-wrap:after {
            left: 3em;
          }
        }

        &.mbsc-ic-left select:focus ~ input {
          padding-right: 2.9375em;
        }
      }

      /* Floating labels */
      &.mbsc-label-floating.mbsc-input .mbsc-label {
        right: .875em;
      }

      &.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
        right: 2.875em;
      }
    }
  }

  @include mbsc-material-input(material, $mbsc-material-colors);
}






@mixin mbsc-material-select($theme, $params) {
  @include exports("select.#{$theme}.colors") {

    @include mbsc-material-scroller($theme, $params);
    @include mbsc-material-input($theme, $params);

    $accent: map-get($params, accent);

    $colors: mbsc-material-colors($params);
    $gray-background: map-get($colors, gray-background);

    .mbsc-#{$theme} {

      /* Filter style */
      .mbsc-sel-filter-cont {
        border-bottom: 1px solid $gray-background;
      }

      /* Desktop styling */
      &.mbsc-fr-pointer {

        /* Single select */
        .mbsc-sel-one {

          .mbsc-sc-itm-sel {
            color: $accent;
          }

          &.mbsc-sc-whl-multi .mbsc-sc-itm-sel {
            background: rgba(0, 0, 0, .1);
          }

          &.mbsc-no-touch.mbsc-sc-whl-multi .mbsc-sc-itm-sel:hover {
            background: rgba(0, 0, 0, .1);
          }
        }
      }
    }
  }
}



@include exports("select.material") {
  .mbsc-material {
    /* Group headers */

    .mbsc-sc-whl .mbsc-sel-gr {
      padding: 0 .333333em;
      font-size: 1.125em;
    }

    /* Filter style */

    .mbsc-sel-filter-cont {
      margin: .25em 1em;
    }

    .mbsc-input .mbsc-sel-filter-input:focus,
    .mbsc-input .mbsc-sel-filter-input {
      border-bottom: 0;
      padding: inherit;
    }

    .mbsc-rtl .mbsc-sel-filter-clear {
      left: -.5em;
    }

    .mbsc-ltr .mbsc-sel-filter-clear {
      right: -.5em;
    }

    .mbsc-sel-gr-whl {
      .mbsc-sc-itm {
        padding: 0 .272727em;
      }

      .mbsc-sc-itm::before {
        display: none;
      }

    }

    /* Desktop styling */

    &.mbsc-fr-pointer {

      /* Single select */
      .mbsc-sel-one {
        .mbsc-sc-itm {
          padding: 0 1.25em;
        }

        .mbsc-sc-itm-sel:before {
          display: none;
        }
      }

      /* Group headers */
      .mbsc-sc-whl .mbsc-sel-gr {
        padding: 0 1.25em;
      }

      .mbsc-sc-itm.mbsc-sel-gr-itm {
        padding: 0 2.5em;
      }
    }
  }

  @include mbsc-material-select(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}







$mbsc-windows-accent: #0078d7 !default;
$mbsc-windows-background: #f2f2f2 !default;
$mbsc-windows-text: #262626 !default;

$mbsc-windows-dark-accent: #0078d7 !default;
$mbsc-windows-dark-background: #1a1a1a !default;
$mbsc-windows-dark-text: #ffffff !default;

/* Base colors */
$mbsc-windows-primary: #3f97f6 !default;
$mbsc-windows-secondary: #90979E !default;
$mbsc-windows-success: #43BE5F !default;
$mbsc-windows-danger: #f5504e !default;
$mbsc-windows-warning: #f8b042 !default;
$mbsc-windows-info: #5BB7C5 !default;
$mbsc-windows-light: #fff !default;
$mbsc-windows-dark: #47494A !default;

$mbsc-windows-colors: ( // Colors map
  'background': $mbsc-windows-background,
  'text': $mbsc-windows-text,
  'accent': $mbsc-windows-accent,
);
$mbsc-windows-dark-colors: ( // Colors map
  'background': $mbsc-windows-dark-background,
  'text': $mbsc-windows-dark-text,
  'accent': $mbsc-windows-dark-accent,
);

@function mbsc-windows-colors($params) {
  $background: map-get($params, 'background');
  $text: map-get($params, 'text');
  $accent: map-get($params, 'accent');

  $frame-border: '';
  $input-hover: '';
  $empty-color: '';
  $input-border: '';
  $progress-background: '';
  $alt-background: '';
  $color-item: '';
  $color-preview-border: '';


  // Light background
  @if (lightness($background) > 50%) {
    $frame-border: darken($background, 15%);
    $input-hover: darken($background, 55%);
    $empty-color: lighten($text, 30%);
    $input-border: darken($background, 35%);

    $progress-background: hsl(hue($background), saturation($background), min(lightness($background), 80%));
    $alt-background: darken($background, 18%);
    $color-item: #ffffff;
    $color-preview-border: darken($background, 25%);
  }
  // Dark background
  @else {
    $frame-border: lighten($background, 15%);
    $input-hover: lighten($background, 55%);
    $empty-color: darken($text, 30%);
    $input-border: lighten($background, 35%);

    $progress-background: hsl(hue($background), saturation($background), max(lightness($background), 12%));
    $alt-background: lighten($background, 18%);
    $color-item: fade(#dfdede, 30%);
    $color-preview-border: lighten($background, 60%);
  }

  @return ('frame-border': $frame-border,
    'input-hover': $input-hover,
    'empty-color': $empty-color,
    'input-border': $input-border,
    'progress-background': $progress-background,
    'alt-background': $alt-background,
    'color-item': $color-item,
    'color-preview-border': $color-preview-border,
    /* static colors */
    'dark-text': darken($mbsc-windows-dark, 30%),
    'light-text': #efeff4,
    'error': #d30101);
}

@mixin mbsc-windows-common($theme, $params) {
  @include exports("common.#{$theme}.colors") {
    $colors: mbsc-windows-colors($params);
    $empty-color: map-get($colors, empty-color);

    .mbsc-#{$theme} {
      .mbsc-empty {
        color: $empty-color;
      }
    }
  }
}


// Theme specific variables - inherited from global variables

// Background
$mbsc-windows-frame-background: $mbsc-frame-background-light !default;
$mbsc-windows-dark-frame-background: $mbsc-frame-background-dark !default;
// Text
$mbsc-windows-frame-text: $mbsc-frame-text-light !default;
$mbsc-windows-dark-frame-text: $mbsc-frame-text-dark !default;
// Accent
$mbsc-windows-frame-accent: $mbsc-frame-accent-light !default;
$mbsc-windows-dark-frame-accent: $mbsc-frame-accent-dark !default;
// Overlay
$mbsc-windows-frame-overlay: $mbsc-frame-overlay-light !default;
$mbsc-windows-dark-frame-overlay: $mbsc-frame-overlay-dark !default;

$mbsc-windows-colors: map-merge($mbsc-windows-colors, (
  'frame-background': $mbsc-windows-frame-background,
  'frame-text': $mbsc-windows-frame-text,
  'frame-accent': $mbsc-windows-frame-accent,
  'frame-overlay': $mbsc-windows-frame-overlay,
));

$mbsc-windows-dark-colors: map-merge($mbsc-windows-dark-colors, (
  'frame-background': $mbsc-windows-dark-frame-background,
  'frame-text': $mbsc-windows-dark-frame-text,
  'frame-accent': $mbsc-windows-dark-frame-accent,
  'frame-overlay': $mbsc-windows-dark-frame-overlay,
));

@mixin mbsc-windows-frame($theme, $params) {
  @include exports("frame.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');

    $overlay-param: map-get($params, 'frame-overlay');
    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, $text);
    $overlay: if($overlay-param, $overlay-param, rgba(0, 0, 0, .7));

    $button-hover: '';
    $frame-border: '';
    @if(lightness($background) > 50%) {
      $button-hover: darken($background, 10%);
      $frame-border: darken($background, 15%);
    }
    @else {
      $button-hover: lighten($background, 10%);
      $frame-border: lighten($background, 15%);
    }

    .mbsc-#{$theme} {
      .mbsc-fr-overlay {
        background: $overlay;
      }

      .mbsc-fr-w {
        background: $background;
        color: $text;
        border: 1px solid $frame-border;
      }

      .mbsc-fr-hdr {
        border-bottom: 2px solid $frame-border;
      }

      .mbsc-fr-btn-cont {
        border-top: 2px solid $frame-border;
      }

      .mbsc-fr-btn-w {
        background: $background;
      }

      .mbsc-fr-btn {
        color: $text;
      }

      &.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
      .mbsc-fr-btn.mbsc-active {
        background: $button-hover;
      }

      .mbsc-fr-arr {
        background: $background;
        border: 1px solid $frame-border;
      }
    }
  }
}



@include exports("frame.windows") {
  .mbsc-windows {
    .mbsc-fr-w {
      font-size: 16px;
    }

    .mbsc-ltr .mbsc-fr-btn-w .mbsc-fr-btn:before {
      padding: 0 .375em 0 0;
    }

    .mbsc-rtl .mbsc-fr-btn-w .mbsc-fr-btn:before {
      padding: 0 0 0 .375em;
    }

    &.mbsc-fr-inline .mbsc-fr-w {
      border: 0;
    }

    .mbsc-fr-hdr {
      padding: .5em;
      font-weight: bold;
    }

    .mbsc-fr-btn {
      height: 2.5em;
      line-height: 2.5em;
      text-align: center;
      padding: 0 .375em;
    }

    .mbsc-fr-arr-w {
      margin: -1px 0;
    }
  }

  @include mbsc-windows-frame(windows, $mbsc-windows-colors);
}





@mixin mbsc-windows-scroller($theme, $params) {
  @include exports("scroller.#{$theme}.colors") {

    @include mbsc-windows-frame($theme, $params);

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');

    $background-param: map-get($params, 'frame-background');
    $accent-param: map-get($params, 'frame-accent');
    $text-param: map-get($params, 'frame-text');

    $background: if($background-param, $background-param, $background);
    $accent: if($accent-param, $accent-param, $accent);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));

    $button-hover: '';
    $frame-border: '';
    $wheel-button: '';
    @if(lightness($background) > 50%) {
      $button-hover: darken($background, 10%);
      $frame-border: darken($background, 15%);
      $wheel-button: darken($background, 5%);
    }
    @else {
      $button-hover: lighten($background, 10%);
      $frame-border: lighten($background, 15%);
      $wheel-button: lighten($background, 5%);
    }

    .mbsc-#{$theme} {
      .mbsc-sc-whl-gr-c {
        border-bottom: 2px solid $frame-border;
      }

      .mbsc-sc-whl-w {
        border-right: 2px solid $frame-border;
      }

      .mbsc-sc-lbl-v .mbsc-sc-whl-w {
        background: $background;
      }

      .mbsc-sc-lbl-v {
        background: $frame-border;
      }

      .mbsc-sc-whl-l {
        background: rgba($accent, .4);
      }

      &.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
      .mbsc-sc-itm:focus {
        background: rgba($text, .10);
      }

      &.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
        background: rgba($text, .20);
      }

      /* Clickpick mode */
      .mbsc-sc-btn {
        background: $wheel-button;
      }

      &.mbsc-no-touch .mbsc-sc-btn:hover,
      .mbsc-sc-btn.mbsc-active {
        background: $button-hover;
      }

      .mbsc-sc-whl-multi .mbsc-sc-itm-sel {
        color: $accent;
      }
    }
  }
}



@include exports("scroller.windows") {
  .mbsc-windows {
    .mbsc-sc-whl-gr-c {
      margin-bottom: -2px;
    }

    .mbsc-ltr .mbsc-sc-whl-w:last-child,
    .mbsc-rtl .mbsc-sc-whl-w:first-child {
      border-right: 0;
    }

    .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
      padding: 1.875em 0;
    }

    .mbsc-sc-lbl-v {
      border: 0;
      margin-bottom: -1.875em;
    }

    .mbsc-sc-lbl {
      font-size: .75em;
      line-height: 2.5em;
    }

    .mbsc-sc-whl-l {
      display: block;
      z-index: 1;
    }

    .mbsc-sc-whl-w .mbsc-sc-whl-l {
      display: none;
    }

    .mbsc-sc-itm {
      padding: 0 .5em;
    }

    /* Clickpick mode */
    .mbsc-sc-btn {
      opacity: 0;
      height: 1.375em;
      line-height: 1.375em;
      overflow: hidden;
    }

    &.mbsc-no-touch .mbsc-sc-whl-w:hover .mbsc-sc-btn {
      opacity: 1;
    }

    /* Multiple select */
    &.mbsc-sel-multi .mbsc-sc-whl-l {
      display: none;
    }

    .mbsc-sc-whl-multi .mbsc-sc-itm {
      padding: 0 2.5em;
    }

    .mbsc-sc-whl-multi .mbsc-sc-btn {
      display: none;
    }

    .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
      width: 2.5em;
    }

    /* Desktop styling */
    &.mbsc-fr-pointer {
      .mbsc-sc-lbl {
        padding: 0 1.666667em;
      }

      .mbsc-sc-itm {
        padding: 0 1.25em;
      }

      .mbsc-sc-whl-multi .mbsc-sc-itm {
        padding: 0 2.5em;
      }

      .mbsc-ltr .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
        left: 0;
      }

      .mbsc-rtl .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
        right: 0;
      }
    }

    /* Scrollbar */
    .mbsc-sc-bar:after {
      border-radius: 0;
    }
  }

  @include mbsc-windows-scroller(windows, $mbsc-windows-colors);
}





// Theme specific variables - inherited from global variables

// Background
$mbsc-windows-input-background: $mbsc-input-background-light !default;
$mbsc-windows-dark-input-background: $mbsc-input-background-dark !default;
// Border
$mbsc-windows-input-border: $mbsc-input-border-light !default;
$mbsc-windows-dark-input-border: $mbsc-input-border-dark !default;
// Text
$mbsc-windows-input-text: $mbsc-input-text-light !default;
$mbsc-windows-dark-input-text: $mbsc-input-text-dark !default;
// Accent
$mbsc-windows-input-accent: $mbsc-input-accent-light !default;
$mbsc-windows-dark-input-accent: $mbsc-input-accent-dark !default;
// Error
$mbsc-windows-input-error: $mbsc-input-error-light !default;
$mbsc-windows-dark-input-error: $mbsc-input-error-dark !default;

$mbsc-windows-colors: map-merge($mbsc-windows-colors, (
  'input-background': $mbsc-windows-input-background,
  'input-border': $mbsc-windows-input-border,
  'input-text': $mbsc-windows-input-text,
  'input-accent': $mbsc-windows-input-accent,
  'input-error': $mbsc-windows-input-error,
));
$mbsc-windows-dark-colors: map-merge($mbsc-windows-dark-colors, (
  'input-background': $mbsc-windows-dark-input-background,
  'input-border': $mbsc-windows-dark-input-border,
  'input-text': $mbsc-windows-dark-input-text,
  'input-accent': $mbsc-windows-dark-input-accent,
  'input-error': $mbsc-windows-dark-input-error,
));

@mixin mbsc-windows-input($theme, $params) {
  @include exports("input.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    // custom colors
    $input-background-param: map-get($params, 'input-background');
    $input-border-param: map-get($params, 'input-border');
    $input-text-param: map-get($params, 'input-text');
    $input-accent-param: map-get($params, 'input-accent');
    $input-error-param: map-get($params, 'input-error');

    // overwrite basic colors with custom ones
    $background: if($input-background-param, $input-background-param, $background);
    $accent: if($input-accent-param, $input-accent-param, $accent);

    $input-border: '';
    $input-hover: '';
    $frame-border: '';
    @if (lightness($background) > 50%) {
      $frame-border: darken($background, 15%);
      $input-border: darken($background, 35%);
      $input-hover: darken($background, 55%);
    }
    @else {
      $frame-border: lighten($background, 15%);
      $input-border: lighten($background, 35%);
      $input-hover: lighten($background, 55%);
    }

    // static colors
    $colors: mbsc-windows-colors($params);
    $error: map-get($colors, error);
    // overwrite colors with custom ones
    $input-border: if($input-border-param, $input-border-param, $input-border);
    $error: if($input-error-param, $input-error-param, $error);

    .mbsc-#{$theme} {

      &.mbsc-input input,
      &.mbsc-input textarea,
      &.mbsc-select input {
        border: .125em solid $input-border;
        color: $text;
      }

      &.mbsc-no-touch .mbsc-input input:hover,
      &.mbsc-no-touch .mbsc-input textarea:hover,
      &.mbsc-no-touch .mbsc-select:hover input {
        border-color: $input-hover;
      }

      &.mbsc-input input::-webkit-input-placeholder {
        color: #5c5c5c;
      }

      &.mbsc-input input::-ms-input-placeholder {
        color: #5c5c5c;
      }

      &.mbsc-input input::-moz-placeholder {
        color: #5c5c5c;
      }

      &.mbsc-input .mbsc-input-wrap input:focus,
      &.mbsc-input .mbsc-input-wrap select:focus+input,
      &.mbsc-input .mbsc-input-wrap textarea:focus,
      &.mbsc-alert.mbsc-input input:focus {
        background: #fff;
        border-color: $accent;
        color: #262626;

        ~.mbsc-input-ic {
          color: #262626;
        }
      }

      &.mbsc-input .mbsc-input-wrap .mbsc-control:disabled+input,
      &.mbsc-input .mbsc-input-wrap textarea:disabled,
      &.mbsc-input .mbsc-input-wrap input:disabled {
        border-color: $frame-border;
      }

      &.mbsc-input.mbsc-err input,
      &.mbsc-input.mbsc-err textarea {
        border-color: $error;
      }

      .mbsc-input-ic,
      &.mbsc-input .mbsc-label,
      &.mbsc-select .mbsc-input-wrap .mbsc-ic {
        color: $text;
      }

      .mbsc-err-msg {
        color: $error;
      }
    }
  }
}


@include exports("input.windows") {
  .mbsc-windows {
    &.mbsc-input {
      padding: .5em 1em 1em;
    }

    &.mbsc-input input,
    &.mbsc-input textarea,
    &.mbsc-select input {
      height: 2em;
      padding: 0 .3125em;
      font-size: 1em;
      background: transparent;
    }

    &.mbsc-ltr.mbsc-select input {
      padding-right: 1.75em;
    }

    &.mbsc-rtl.mbsc-select input {
      padding-left: 1.75em;
    }

    &.mbsc-input textarea {
      padding-top: .125em;
      padding-bottom: .125em;
    }

    .mbsc-input-ic {
      top: 0;
      margin-top: 0;
    }

    .mbsc-err-msg {
      position: absolute;
      padding-top: .333334em;
    }

    /* Textbox, textarea, select */
    &.mbsc-input.mbsc-ic-left input,
    &.mbsc-input.mbsc-ic-left textarea {
      padding-left: 2.25em;
    }

    &.mbsc-rtl.mbsc-input.mbsc-ic-left input,
    &.mbsc-rtl.mbsc-input.mbsc-ic-left textarea {
      padding-right: 2.25em;
      padding-left: .3125em;
    }

    &.mbsc-ltr.mbsc-input.mbsc-ic-right input,
    &.mbsc-ltr.mbsc-input.mbsc-ic-right textarea {
      padding-right: 2.25em;
    }

    &.mbsc-rtl.mbsc-input.mbsc-ic-right input,
    &.mbsc-rtl.mbsc-input.mbsc-ic-right textarea {
      padding-left: 2.25em;
    }

    &.mbsc-ltr.mbsc-ic-left .mbsc-left-ic,
    &.mbsc-rtl.mbsc-ic-right .mbsc-right-ic {
      right: auto;
      left: .25em;
    }

    &.mbsc-ltr.mbsc-ic-right .mbsc-right-ic,
    &.mbsc-rtl.mbsc-ic-left .mbsc-left-ic {
      left: auto;
      right: .25em;
    }

    &.mbsc-input .mbsc-label {
      padding-bottom: .3125em;
      font-size: .8125em;
    }

    &.mbsc-select .mbsc-select-ic {
      display: block;
      position: absolute;
      top: .5em;
    }

    &.mbsc-ltr.mbsc-select .mbsc-select-ic {
      right: .5em;
    }

    &.mbsc-rtl.mbsc-select .mbsc-select-ic {
      left: .5em;
    }

    &.mbsc-ic-right .mbsc-select-ic {
      display: none;
    }

    /* Inline labels */
    &.mbsc-label-inline.mbsc-input .mbsc-label {
      line-height: 2em;
      padding-bottom: 0;
    }

    /* Floating labels */
    &.mbsc-label-floating.mbsc-input {
      padding-top: 1.625em;

      .mbsc-label {
        font-size: 1em;
        position: absolute;
        top: 2.0625em;
      }

      &.mbsc-label-floating-active {
        .mbsc-label {
          -webkit-transform: translateY(-1.5em) scale(.75);
          transform: translateY(-1.5em) scale(.75);
        }
      }
    }

    /* Left to right */
    &.mbsc-ltr.mbsc-label-floating.mbsc-input {
      .mbsc-label {
        left: 1.375em;
      }

      &.mbsc-label-floating-active .mbsc-label {
        left: 1em;
      }

      &.mbsc-ic-left .mbsc-label {
        left: 3.375em;
      }

      &.mbsc-label-floating-active.mbsc-ic-left .mbsc-label {
        left: 1em;
      }
    }

    /* Right to left */
    &.mbsc-rtl.mbsc-label-floating.mbsc-input {
      .mbsc-label {
        right: 1.375em;
      }

      &.mbsc-label-floating-active .mbsc-label {
        right: 1em;
      }

      &.mbsc-ic-left .mbsc-label {
        right: 3.375em;
      }

      &.mbsc-label-floating-active.mbsc-ic-left .mbsc-label {
        right: 1em;
      }
    }
  }

  @include mbsc-windows-input(windows, $mbsc-windows-colors);
}





@mixin mbsc-windows-select($theme, $params) {
  @include exports("select.#{$theme}.colors") {

    @include mbsc-windows-scroller($theme, $params);
    @include mbsc-windows-input($theme, $params);

    $text: map-get($params, text);
    $accent: map-get($params, accent);

    .mbsc-#{$theme} {

      /* Group select */
      .mbsc-sel-gr-whl.mbsc-sel-one .mbsc-sc-itm-sel {
        color: $accent;
      }

      .mbsc-sel-filter-clear {
        color: $text;
      }

      /* Desktop styling */
      &.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-whl-multi .mbsc-sc-itm-sel {
        color: $text;
        background: rgba($accent, .2);
      }
    }
  }
}



@include exports("select.windows") {
  .mbsc-windows {

    /* Group select */
    .mbsc-sc-whl .mbsc-sel-gr {
      opacity: 1;
      font-size: 1.375em;
      font-weight: normal;
    }

    .mbsc-sc-whl .mbsc-sel-gr:after {
      display: none;
    }

    .mbsc-sel-gr-whl {
      .mbsc-sc-itm {
        padding: 0 .5em;
      }

      .mbsc-sc-itm::before {
        display: none;
      }
    }

    /* Filter */
    .mbsc-input.mbsc-sel-filter-cont {
      padding: .5em;
    }

    /* Desktop styling */
    &.mbsc-fr-pointer {

      /* Single select */
      .mbsc-sel-one {
        .mbsc-sc-itm {
          padding: 0 1.25em;
        }

        .mbsc-sc-itm-sel:before {
          display: none;
        }
      }

      /* Group headers */
      .mbsc-sc-whl .mbsc-sel-gr {
        padding: 0 0.90909em;
      }

      .mbsc-sc-itm.mbsc-sel-gr-itm {
        padding: 0 2.5em;
      }
    }
  }

  @include mbsc-windows-select(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}



// datetime component






@mixin mbsc-ios-datetime($theme, $params) {
  @include exports("datetime.#{$theme}.colors") {
    @include mbsc-ios-scroller($theme, $params);
  }
}





@include exports("datetime.ios") {
  .mbsc-ios {
    .mbsc-dt-whl-y .mbsc-sc-itm {
      min-width: 3.8em;
    }

    .mbsc-dt-whl-m .mbsc-sc-itm {
      text-align: left;
    }

    .mbsc-dt-whl-d .mbsc-sc-itm,
    .mbsc-dt-whl-h .mbsc-sc-itm,
    .mbsc-dt-whl-date .mbsc-sc-itm {
      text-align: right;
    }
  }

  @include mbsc-ios-datetime(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}










@mixin mbsc-mobiscroll-datetime($theme, $params) {
  @include exports("datetime.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}



@include exports("datetime.mobiscroll") {
  @include mbsc-mobiscroll-datetime(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}







@mixin mbsc-material-datetime($theme, $params) {
  @include exports("datetime.#{$theme}.colors") {
    @include mbsc-material-scroller($theme, $params);
  }
}



@include exports("datetime.material") {
  @include mbsc-material-datetime(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}







@mixin mbsc-windows-datetime($theme, $params) {
  @include exports("datetime.#{$theme}.colors") {
    @include mbsc-windows-scroller($theme, $params);
  }
}



@include exports("datetime.windows") {
  .mbsc-windows {

    .mbsc-ltr .mbsc-dt-whl-m .mbsc-sc-itm,
    .mbsc-ltr .mbsc-dt-whl-date .mbsc-sc-itm {
      text-align: left;
    }

    .mbsc-rtl .mbsc-dt-whl-m .mbsc-sc-itm,
    .mbsc-rtl .mbsc-dt-whl-date .mbsc-sc-itm {
      text-align: right;
    }

    .mbsc-dt-whl-date .mbsc-sc-whl {
      min-width: 120px;
    }
  }

  @include mbsc-windows-datetime(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}



// image component






@mixin mbsc-ios-image($theme, $params) {
  @include exports("image.#{$theme}.colors") {
    @include mbsc-ios-scroller($theme, $params);
  }
}




@include exports("image") {
  .mbsc-ltr .mbsc-img-w {
    text-align: left;
  }

  .mbsc-ltr .mbsc-img-txt {
    margin: 0 0 0 .5em;
  }

  .mbsc-rtl .mbsc-img-w {
    text-align: right;
  }

  .mbsc-rtl .mbsc-img-txt {
    margin: 0 .5em 0 0;
  }

  .mbsc-img-w .mbsc-ic {
    display: inline-block;
    margin: 0 .5em;
  }

  .mbsc-img-txt {
    display: inline-block;
  }

  .mbsc-img-c {
    min-width: 50px;
    height: 28px;
    margin-top: -2px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: normal;
  }

  .mbsc-img {
    max-height: 28px;
  }
}


@include exports("image.ios") {
  @include mbsc-ios-image(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}










@mixin mbsc-mobiscroll-image($theme, $params) {
  @include exports("image.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}



@include exports("image.mobiscroll") {
  @include mbsc-mobiscroll-image(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}







@mixin mbsc-material-image($theme, $params) {
  @include exports("image.#{$theme}.colors") {
    @include mbsc-material-scroller($theme, $params);
  }
}



@include exports("image.material") {
  @include mbsc-material-image(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}







@mixin mbsc-windows-image($theme, $params) {
  @include exports("image.#{$theme}.colors") {
    @include mbsc-windows-scroller($theme, $params);
  }
}



@include exports("image.windows") {
  @include mbsc-windows-image(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}



// treelist component






@mixin mbsc-ios-treelist($theme, $params) {
  @include exports("treelist.#{$theme}.colors") {
    @include mbsc-ios-scroller($theme, $params);
  }
}





@include exports("treelist.ios") {
  @include mbsc-ios-treelist(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}










@mixin mbsc-mobiscroll-treelist($theme, $params) {
  @include exports("treelist.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}



@include exports("treelist.mobiscroll") {
  @include mbsc-mobiscroll-treelist(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}







@mixin mbsc-material-treelist($theme, $params) {
  @include exports("treelist.#{$theme}.colors") {
    @include mbsc-material-scroller($theme, $params);
  }
}



@include exports("treelist.material") {
  @include mbsc-material-treelist(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}







@mixin mbsc-windows-treelist($theme, $params) {
  @include exports("treelist.#{$theme}.colors") {
    @include mbsc-windows-scroller($theme, $params);
  }
}



@include exports("treelist.windows") {
  @include mbsc-windows-treelist(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}



// calendar component







$mbsc-ios-calendar-mark: $mbsc-calendar-mark-light !default;
$mbsc-ios-dark-calendar-mark: $mbsc-calendar-mark-dark !default;

$mbsc-ios-colors: map-merge($mbsc-ios-colors, (
  'calendar-mark': $mbsc-ios-calendar-mark,
));
$mbsc-ios-dark-colors: map-merge($mbsc-ios-dark-colors, (
  'calendar-mark': $mbsc-ios-dark-calendar-mark,
));

@mixin mbsc-ios-calbase($theme, $params) {
  @include exports("calbase.#{$theme}.colors") {

    @include mbsc-ios-scroller($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $mark-param: map-get($params, 'calendar-mark');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background) ,$text));
    $accent: if($accent-param, $accent-param, $accent);

    $button: '';
    @if (map-get($params, 'button')) {
      $button: map-get($params, 'button');
    }
    @else {
      $button: $accent;
    }

    $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
    $background-alt: lighten($background-limited, 3%);
    $border-color: '';
    $top-bottom-frame: '';
    $btn-cont-background: '';
    $button-active: '';
    @if (lightness($background) > 50%) {
      $border-color: darken($background-limited, 17%);
      $top-bottom-frame: adjust-hue(darken(saturate($background, 12%), 13%), calc(216% / 1%));
      $btn-cont-background: darken($background, 3%);
      $button-active: darken($background, 5%);
    }
    @else {
      $border-color: lighten($background, 20%);
      $top-bottom-frame: $background-limited;
      $btn-cont-background: lighten($background-limited, 8%);
      $button-active: lighten($background, 12%);
    }

    $cal-text: '';
    $cal-text-contrast: '';
    $button-contrast: '';
    @if (lightness($button) > 50%) {
      $button-contrast: #000;
      $cal-text: lighten(saturate($button, 5%), 42%);
    }
    @else {
      $button-contrast: #fff;
      $cal-text: adjust-hue(lighten(desaturate($button, 42%), 35%), calc(3% / 1%));
    }

    @if (lightness($cal-text) > 50%) {
      $cal-text-contrast: #000;
    }
    @else {
      $cal-text-contrast: #fff;
    }

    $mark: if($mark-param, $mark-param, $border-color);

    .mbsc-#{$theme} {
      .mbsc-cal-picker {
        background: $background-limited;
      }

      .mbsc-cal-cell {
        border-top: 1px solid $border-color;
      }

      .mbsc-cal-today {
        color: $button;
      }

      /* Hover, focus */
      .mbsc-cal-cell:focus .mbsc-cal-cell-txt,
      &.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
      &.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover {
        background: rgba($button, .3);
      }

      /* Selected */
      .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
        border-color: $button;
        background: $button;
        color: $button-contrast;
      }

      .mbsc-cal-tab {
        border: 1px solid $button;
        color: $button;
      }

      .mbsc-fr-popup .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
        background: $button;
        color: $button-contrast;
      }

      /* Marks */
      .mbsc-cal-mark {
        background: $mark;
      }

      .mbsc-cal-txt {
        background: $cal-text;
        color: $cal-text-contrast;
      }

      /* Inline */
      &.mbsc-fr-inline .mbsc-cal-picker {
        background: $background-alt;
      }

      /* Re-position buttons to top */
      .mbsc-fr-btn-cont {
        border-bottom: 1px solid $border-color;
      }
    }
  }
}




@include exports("calbase") {
  /* Box sizing */

  .mbsc-cal-slide,
  .mbsc-cal-day,
  .mbsc-cal-day-i {
    box-sizing: border-box;
  }

  .mbsc-cal .mbsc-cal-day-date {
    text-align: center;
    box-sizing: content-box;
  }

  /* Flex layout */

  .mbsc-cal-hdr,
  .mbsc-cal-btn-w,
  .mbsc-cal-days-c,
  .mbsc-cal-days,
  .mbsc-cal-tabbed .mbsc-fr-c .mbsc-sc-whl-gr-c {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .mbsc-cal-btn-w,
  .mbsc-cal-month,
  .mbsc-cal-year,
  .mbsc-cal-days,
  .mbsc-cal-days > div {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }

  .mbsc-cal-c {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }

  /* Header */

  .mbsc-cal-btn-w {
    width: 50%;
  }

  .mbsc-cal-month,
  .mbsc-cal-year {
    width: 1%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }

  .mbsc-cal-btn.mbsc-disabled {
    opacity: .3;
    cursor: not-allowed;
  }

  /* Week days */

  .mbsc-cal-days {
    line-height: 1.875em;
    text-align: center;
  }

  .mbsc-cal-days > div {
    width: 14.285715%;
  }

  .mbsc-cal-body {
    position: relative;
  }

  .mbsc-cal-scroll-c {
    overflow: hidden;
    height: 100%;
  }

  .mbsc-cal-scroll {
    width: 100%;
    height: 100%;
  }

  .mbsc-cal-slide {
    position: absolute;
    z-index: 0; // Needed by Edge
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .mbsc-cal-slide:first-child {
    position: relative;
  }

  .mbsc-cal-table {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
    // Needed for iOS Safari to prevent animation flicker
    -webkit-transform: translateZ(0);
  }

  .mbsc-cal-row {
    display: table-row;
  }

  .mbsc-cal-cell {
    display: table-cell;
    vertical-align: middle;

    &:focus {
      outline: 0;
    }
  }

  .mbsc-cal-picker {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: .9375em 0;
    transition: all .2s ease-in-out;
    will-change: opacity;

    .mbsc-cal-cell {
      width: 33.333333%;
      height: 25%;
      cursor: pointer;
    }
  }

  .mbsc-cal-h {
    visibility: hidden;
    opacity: 0;
  }

  .mbsc-cal-day {
    max-width: 14.285715%; // if only width is used, layout is broken with week numbers displayed
    height: 16.666667%; // IE needs it in fullscreen
    position: relative;
    text-align: center;
    cursor: pointer;
  }

  .mbsc-cal-day-i {
    position: relative;
    height: 100%;
  }

  .mbsc-cal-day-markup {
    // Prevent margin-collapse
    overflow: hidden;
  }

  .mbsc-cal-day-diff .mbsc-cal-day-date {
    opacity: .5;
  }

  .mbsc-cal-week-view .mbsc-cal-day-date,
  .mbsc-cal-day-diff.mbsc-selected .mbsc-cal-day-date {
    opacity: 1;
  }

  .mbsc-cal-cell.mbsc-disabled {
    cursor: not-allowed;

    .mbsc-cal-cell-txt {
      opacity: .2;
    }
  }

  /* Hide outer days */

  .mbsc-cal-hide-diff {
    .mbsc-cal-day-diff {
      cursor: default;

      .mbsc-cal-day-i {
        visibility: hidden;
      }
    }
  }

  /* Week numbers */

  .mbsc-ltr .mbsc-cal-weeks .mbsc-cal-days {
    margin-left: 1.625em;
  }

  .mbsc-rtl .mbsc-cal-weeks .mbsc-cal-days {
    margin-right: 1.625em;
  }

  .mbsc-cal-week-nr {
    width: 1.625em;
    text-align: center;
  }

  /* Multiple month */

  .mbsc-cal-multi {
    .mbsc-cal-hdr {
      display: block;
    }

    .mbsc-cal-btn-w {
      width: auto;
    }

    .mbsc-cal-days,
    .mbsc-cal-slide {
      padding: 0 .5em;
    }
  }

  /* Liquid mode */

  .mbsc-fr-liq {
    .mbsc-cal-c {
      display: block;
    }
  }

  /* Full screen mode */

  .mbsc-calendar.mbsc-fr-liq.mbsc-fr-center {
    .mbsc-fr-overlay {
      display: none;
    }

    .mbsc-fr-w {
      border-radius: 0;
      padding-left: constant(safe-area-inset-left);
      padding-left: env(safe-area-inset-left);
      padding-right: constant(safe-area-inset-right);
      padding-right: env(safe-area-inset-right);
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
    }
  }

  /* Tabs */

  .mbsc-cal-tabs-c {
    display: none;
  }

  .mbsc-cal-tabs {
    display: table;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .mbsc-cal-tab {
    position: relative;
    width: 33.33%;
    display: table-cell;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
  }

  .mbsc-cal-tabbed {
    .mbsc-fr-c {
      position: relative;
    }

    .mbsc-w-p {
      transition: opacity .2s ease-in-out;
    }

    .mbsc-sc-whl-gr-c {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      max-width: none;
      height: 100%;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-box-align: center;
      align-items: center;
    }

    .mbsc-cal-c,
    .mbsc-cal-tabs-c {
      display: block;
    }

    /* During positioning */
    .mbsc-fr-pos {
      .mbsc-sc-whl-gr-c {
        width: auto;
      }
    }
  }

  /* Marks */

  .mbsc-cal-has-marks .mbsc-cal-day {
    padding-bottom: .25em;
  }

  .mbsc-cal-marks {
    position: absolute;
    left: 0;
    right: 0;
    height: .375em;
    margin-top: -.125em;
    overflow: hidden;
    white-space: nowrap;
  }

  .mbsc-cal-mark {
    display: inline-block;
    vertical-align: top;
    width: .25em;
    height: .25em;
    margin: .0625em;
    border-radius: .25em;
  }

  /* Text & icons */

  // .mbsc-cal-has-txt .mbsc-cal-day {
  //     padding-bottom: 1em;

  //     .mbsc-cal-icons {
  //         background: none;
  //     }
  // }

  .mbsc-cal-txt-w {
    position: absolute;
    z-index: 2;
    pointer-events: none;
  }

  .mbsc-ltr .mbsc-cal-txt {
    text-align: left;
  }

  .mbsc-rtl .mbsc-cal-txt {
    text-align: right;
  }

  .mbsc-cal-txt,
  .mbsc-cal-txt-ph,
  .mbsc-cal-txt-more {
    position: relative;
    height: 1.6em;
    line-height: 1.6em;
    overflow: hidden;
    padding: 0 .3em;
    font-size: .625em;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 .1em .2em .1em;
  }

  .mbsc-cal-day .mbsc-cal-txt.mbsc-cal-txt-only {
    color: inherit;
    text-align: center;
  }

  .mbsc-no-touch .mbsc-cal-txt:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color .2s;
  }

  .mbsc-no-touch .mbsc-cal-txt-only:before {
    display: none;
  }

  .mbsc-no-touch .mbsc-cal-txt.mbsc-hover:before {
    background-color: rgba(0, 0, 0, .2);
  }

  .mbsc-no-touch .mbsc-cal-txt-more:hover {
    background-color: rgba(0, 0, 0, .1);
  }

  .mbsc-cal-txt-more {
    text-align: left;
    transition: background-color .2s;
  }

  // .mbsc-cal-txt {
  //     position: absolute;
  //     left: .1em;
  //     right: .1em;
  //     height: 1.6em;
  //     line-height: 1.6em;
  //     overflow: hidden;
  //     padding: 0 .2em;
  //     font-size: .625em;
  //     white-space: nowrap;
  // }

  .mbsc-cal .mbsc-cal-btn {
    position: relative;
    z-index: 1;
  }
}


@include exports("calbase.ios") {
  .mbsc-ios {
    .mbsc-cal {
      font-size: 1.333334em;
    }

    .mbsc-cal-hdr {
      padding-top: .5em;
    }

    .mbsc-cal .mbsc-cal-btn {
      width: 2.086956em;
      height: 2.086956em;
      padding: 0;
      line-height: 2.086956em;
      font-size: 1.4375em;
      margin: -.173913em;
      border-radius: 2em;
    }

    .mbsc-cal-year,
    .mbsc-cal-month {
      font-size: 1.125em;
      line-height: 2.222223em;
    }

    .mbsc-cal-picker .mbsc-cal-cell-i {
      display: inline-block;
      height: 2em;
      padding: 0 1em;
      line-height: 2em;
      border-radius: 2em;
      white-space: nowrap;
    }

    .mbsc-cal-days > div {
      font-size: .75em;
    }

    .mbsc-cal-day-scroll-c .mbsc-cal-slide {
      padding-bottom: .5em;
    }

    .mbsc-cal-hide-diff .mbsc-cal-row:not(:first-child) .mbsc-cal-day-diff {
      border-top-color: transparent;
    }

    .mbsc-cal-day-date {
      display: inline-block;
      width: 1.625em;
      height: 1.625em;
      margin: .3125em 0;
      line-height: 1.625em;
      border: 2px solid transparent;
      border-radius: 2em;
    }

    /* Week numbers */
    .mbsc-cal-week-nr {
      width: 2.363636em;
      font-size: .6875em;
    }

    /* Tabs */
    .mbsc-cal-tabs-c {
      padding: .666667em .666667em 0 .666667em;
    }

    .mbsc-cal-tab {
      line-height: 1.928571em;
      font-size: 1.166667em;
      padding: 0 .714285em;
    }

    .mbsc-ltr .mbsc-cal-tab:first-child {
      border-right: 0;
      border-radius: .285714em 0 0 .285714em;
    }

    .mbsc-ltr .mbsc-cal-tab:last-child {
      border-left: 0;
      border-radius: 0 .285714em .285714em 0;
    }

    .mbsc-rtl .mbsc-cal-tab:last-child {
      border-right: 0;
      border-radius: .285714em 0 0 .285714em;
    }

    .mbsc-rtl .mbsc-cal-tab:first-child {
      border-left: 0;
      border-radius: 0 .285714em .285714em 0;
    }

    /* Marks */
    .mbsc-cal-has-marks .mbsc-cal-day {
      padding-bottom: .4375em;
    }

    .mbsc-cal-has-marks .mbsc-cal-day-date {
      margin: .1875em 0;
    }

    .mbsc-cal-marks {
      margin-top: -.0625em;
    }

    .mbsc-cal-mark {
      width: .375em;
      height: .375em;
      border-radius: .375em;
      margin: 0 .0625em;
    }

    /* Center, bubble */
    &.mbsc-fr-center .mbsc-cal-c,
    &.mbsc-fr-bubble .mbsc-cal-c {
      padding: 0 .666667em;
    }

    /* Full screen */
    &.mbsc-fr-liq.mbsc-calendar.mbsc-fr-center {
      .mbsc-fr-w {
        padding-bottom: 0;
        padding-top: 3.75em;
      }

      &.mbsc-fr-nobtn .mbsc-fr-w {
        padding: 0;
      }

      .mbsc-cal-c {
        padding: 0;
      }

      .mbsc-cal-day-scroll-c {
        padding: 0;
      }

      /* Re-position buttons to top */
      .mbsc-fr-btn-cont {
        position: absolute;
        top: 0;
        bottom: auto;
        display: block;
        padding-left: constant(safe-area-inset-left);
        padding-left: env(safe-area-inset-left);
        padding-right: constant(safe-area-inset-right);
        padding-right: env(safe-area-inset-right);
      }

      .mbsc-fr-btn-w {
        float: right;
        border: 0;
      }

      .mbsc-fr-w .mbsc-fr-btn-cont .mbsc-fr-btn-w .mbsc-fr-btn {
        background: none;
      }

      .mbsc-fr-btn-c {
        float: left;
      }

      .mbsc-fr-btn-s .mbsc-fr-btn {
        font-weight: bold;
      }

      .mbsc-fr-btn.mbsc-active {
        background: transparent;
        opacity: .5;
      }
    }
  }

  @include mbsc-ios-calbase(ios, $mbsc-ios-colors);
}





@mixin mbsc-ios-calendar($theme, $params) {
  @include exports("calendar.#{$theme}.colors") {
    @include mbsc-ios-calbase($theme, $params);
  }
}





@include exports("calendar.ios") {
  @include mbsc-ios-calendar(ios, $mbsc-ios-colors);
}




/* Calendar base */
.mbsc-bootstrap {
  .mbsc-cal-c {
    padding: 4px 2px 2px 2px;
  }

  .mbsc-cal-hdr {
    font-size: 18px;
    line-height: 40px;
  }

  .mbsc-cal .mbsc-cal-btn {
    width: 40px;
    height: 40px;
    background: none;
    color: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 40px;
  }

  .mbsc-cal-picker {
    max-width: none;
    display: block;
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    text-align: center;
  }

  .mbsc-cal-table {
    border-spacing: 2px;
  }

  .mbsc-cal-cell:focus {
    box-shadow: none;
  }

  .mbsc-cal-cell:focus .mbsc-cal-cell-i {
    background: rgba(0, 0, 0, .1);
  }

  .mbsc-cal-day-i {
    padding: 2px;
    line-height: 34px;
  }

  .mbsc-cal-hide-diff .mbsc-cal-day-diff {
    background: none;
  }

  .mbsc-cal-today.mbsc-selected {
    color: #fff !important;
  }

  /* Week numbers */
  .mbsc-ltr .mbsc-cal-weeks .mbsc-cal-days-c {
    padding-left: 26px;
  }

  .mbsc-rtl .mbsc-cal-weeks .mbsc-cal-days-c {
    padding-right: 26px;
  }

  .mbsc-cal-week-nr {
    width: 26px;
    font-weight: bold;
  }

  /* Tabs */
  .mbsc-cal-tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 4px 4px 0 4px;
    box-sizing: border-box;
  }

  .mbsc-cal-tab {
    -webkit-box-flex: 1 0;
    -webkit-flex: 1 0;
    -ms-flex: 1 0;
    flex: 1 0;
    margin-bottom: -1px;
  }

  /* Marks */
  .mbsc-cal-has-marks .mbsc-cal-day {
    padding-bottom: 0;
  }

  .mbsc-cal-has-txt .mbsc-cal-day-i {
    line-height: 30px;
    padding-top: 5px;
    padding-bottom: 15px;
  }

  .mbsc-cal-marks {
    height: 6px;
    margin-top: -9px;
  }

  .mbsc-cal-mark {
    width: 4px;
    height: 4px;
    margin: 1px;
    border-radius: 4px;
    background: #aaa;
  }

  .mbsc-cal-txt {
    left: 1px;
    right: 1px;
    height: 15px;
    margin-top: -1px;
    font-size: 10px;
    line-height: 15px;
  }

  /* Liquid mode */
  &.mbsc-fr-liq.mbsc-fr-center .mbsc-fr-popup {
    border: 0;
    border-radius: 0;
  }
}










$mbsc-mobiscroll-calendar-mark: $mbsc-calendar-mark-light !default;
$mbsc-mobiscroll-dark-calendar-mark: $mbsc-calendar-mark-dark !default;

$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'calendar-mark': $mbsc-mobiscroll-calendar-mark,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'calendar-mark': $mbsc-mobiscroll-dark-calendar-mark,
));

@mixin mbsc-mobiscroll-calbase($theme, $params) {
  @include exports("calbase.#{$theme}.colors") {

    @include mbsc-mobiscroll-scroller($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');
    $mark-param: map-get($params, 'calendar-mark');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);
    $mark: if($mark-param, $mark-param, $text);

    .mbsc-#{$theme} {
      .mbsc-cal-picker {
        background: $background;
      }

      .mbsc-cal-days > div {
        border-bottom: 1px solid $accent;
        color: $accent;
      }

      .mbsc-cal-today {
        color: $accent;
      }

      /* Hover, focus */
      &.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
      &.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
      .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
        background: rgba($accent, .3);
      }

      /* Selected */
      .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
        background: $accent;
        color: $background;
      }

      .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
        border-color: $accent;
      }

      /* Week numbers */
      .mbsc-cal-week-nr {
        color: $accent;
      }

      .mbsc-cal-tab {
        border: 1px solid $accent;
        color: $text;
      }

      .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
        background: $accent;
        color: $background;
      }

      /* Marks */
      .mbsc-cal-mark {
        background: $mark;
      }

      .mbsc-cal-txt {
        color: $background;
        background: $text;
      }

      .mbsc-cal-icons {
        color: $text;
      }

    }
  }
}




@include exports("calbase.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-cal-c {
      padding: .5em;
    }

    .mbsc-cal-btn-w {
      font-size: 1.125em;
      line-height: 2.222223em;
    }

    .mbsc-cal .mbsc-cal-btn {
      width: 2.666667em;
      height: 2.666667em;
      line-height: 2.666667em;
      padding: 0;
      border-radius: 2em;
      margin: -.222222em;
    }

    .mbsc-cal-picker {
      border-top: 1px solid transparent;

      .mbsc-cal-cell-i {
        display: inline-block;
        height: 2em;
        padding: 0 1em;
        line-height: 2em;
        border-radius: 2em;
        white-space: nowrap;
      }
    }

    .mbsc-cal-days > div {
      font-size: .6875em;
    }

    .mbsc-cal-day-date {
      display: inline-block;
      width: 2.153847em;
      height: 2.153847em;
      margin: .307692em 0;
      line-height: 2.153847em;
      font-size: .8125em;
      border: 2px solid transparent;
      border-radius: 2em;
    }

    /* Week numbers */
    .mbsc-cal-week-nr {
      width: 2.363637em;
      font-size: .6875em;
    }

    /* Tabs */
    .mbsc-cal-tabs-c {
      line-height: 1.875em;
      padding: .5em .5em 0 .5em;
      text-transform: uppercase;
    }

    .mbsc-cal-tab {
      font-size: .7em;
      padding: 0 1.2em;
    }
  }

  @include mbsc-mobiscroll-calbase(mobiscroll, $mbsc-mobiscroll-colors);
}





@mixin mbsc-mobiscroll-calendar($theme, $params) {
  @include exports("calendar.#{$theme}.colors") {
    @include mbsc-mobiscroll-calbase($theme, $params);
  }
}



@include exports("calendar.mobiscroll") {
  @include mbsc-mobiscroll-calendar(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}








$mbsc-material-calendar-mark: $mbsc-calendar-mark-light !default;
$mbsc-material-dark-calendar-mark: $mbsc-calendar-mark-dark !default;

$mbsc-material-colors: map-merge($mbsc-material-colors, (
  'calendar-mark': $mbsc-material-calendar-mark,
));
$mbsc-material-dark-colors: map-merge($mbsc-material-dark-colors, (
  'calendar-mark': $mbsc-material-dark-calendar-mark,
));

@mixin mbsc-material-calbase($theme, $params) {
  @include exports("calbase.#{$theme}.colors") {

    @include mbsc-material-scroller($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');
    $mark-param: map-get($params, 'calendar-mark');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);
    $mark: if($mark-param, $mark-param, $accent);

    $ripple: '';
    @if (lightness($background) > 50%) {
      $ripple: #000;
    }
    @else {
      $ripple: #fff;
    }

    .mbsc-#{$theme} {
      .mbsc-cal-picker {
        background: $background;
      }

      .mbsc-cal-today,
      .mbsc-cal-tab.mbsc-selected {
        color: $accent;
      }

      /* Hover, focus */
      &.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
      &.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
      .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
        background: rgba($ripple, .1);
      }

      /* Selected */
      .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
        background: rgba($accent, .3);
      }

      .mbsc-cal-c .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
        border-color: $accent;
      }

      .mbsc-cal-mark {
        background: $mark;
      }

      .mbsc-cal-txt {
        color: $background;
        background: lighten($text, 13%);
      }

      .mbsc-cal-icons {
        color: $text;
      }
    }
  }
}



@include exports("calbase.material") {
  .mbsc-material {
    .mbsc-cal-c {
      padding: .5em;
    }

    .mbsc-cal .mbsc-cal-btn {
      width: 1.5em;
      height: 1.5em;
      padding: 0;
      line-height: 1.5em;
      font-size: 2em;
      border-radius: 2em;
      margin: -.125em;
    }

    .mbsc-cal-year,
    .mbsc-cal-month {
      font-size: 1.125em;
      font-weight: bold;
      line-height: 2.222223em;
    }

    .mbsc-cal-picker {
      .mbsc-cal-cell-i {
        display: inline-block;
        height: 2em;
        padding: 0 1em;
        line-height: 2em;
        border-radius: 2em;
        white-space: nowrap;
      }
    }

    .mbsc-cal-days > div {
      font-size: .75em;
      font-weight: bold;
    }

    .mbsc-cal-day-date {
      display: inline-block;
      width: 2.461539em;
      height: 2.461539em;
      margin: .153846em 0;
      line-height: 2.461539em;
      font-size: .8125em;
      border: 2px solid transparent;
      border-radius: 2em;
    }

    /* Week numbers */
    .mbsc-cal-week-nr {
      width: 2.363636em;
      font-size: .6875em;
      font-weight: bold;
    }

    /* Tabs */
    .mbsc-cal-tabs-c {
      line-height: 1.875em;
      padding: .5em .5em 0 .5em;
      text-transform: uppercase;
    }

    .mbsc-cal-tab {
      font-size: .75em;
      padding: 0 .5em;
    }

    &.mbsc-cal-tabbed {
      .mbsc-fr-hdr {
        padding-top: 0;
      }

      .mbsc-cal-c {
        padding-top: 0;
      }
    }

    /* Marks */
    .mbsc-cal-marks {
      margin-top: -.5em;
      transition: transform .1s ease-out;
    }

    &.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled):hover .mbsc-cal-marks,
    .mbsc-selected .mbsc-cal-marks {
      -webkit-transform: translate3d(0, .375em, 0);
      transform: translate3d(0, .375em, 0);
    }

    .mbsc-cal-txt {
      border-radius: .2em;
    }
  }

  @include mbsc-material-calbase(material, $mbsc-material-colors);
}





@mixin mbsc-material-calendar($theme, $params) {
  @include exports("calendar.#{$theme}.colors") {
    @include mbsc-material-calbase($theme, $params);
  }
}



@include exports("calendar.material") {
  @include mbsc-material-calendar(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}









$mbsc-windows-calendar-mark: $mbsc-calendar-mark-light !default;
$mbsc-windows-dark-calendar-mark: $mbsc-calendar-mark-dark !default;

$mbsc-windows-colors: map-merge($mbsc-windows-colors, (
  'calendar-mark': $mbsc-windows-calendar-mark,
));
$mbsc-windows-dark-colors: map-merge($mbsc-windows-dark-colors, (
  'calendar-mark': $mbsc-windows-dark-calendar-mark,
));

@mixin mbsc-windows-calbase($theme, $params) {
  @include exports("calbase.#{$theme}.colors") {

    @include mbsc-windows-scroller($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');
    $mark-param: map-get($params, 'calendar-mark');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);
    $mark: if($mark-param, $mark-param, $text);

    $button-hover: '';
    $wheel-button: '';
    $frame-border: '';
    $alt-background: '';
    $calendar-day: '';
    @if(lightness($background) > 50%) {
      $button-hover: darken($background, 10%);
      $wheel-button: darken($background, 5%);
      $frame-border: darken($background, 15%);
      $alt-background: darken($background, 18%);
      $calendar-day: lighten($text, 30%);
    }
    @else {
      $button-hover: lighten($background, 10%);
      $wheel-button: lighten($background, 5%);
      $frame-border: lighten($background, 15%);
      $alt-background: lighten($background, 18%);
      $calendar-day: darken($text, 30%);
    }

    .mbsc-#{$theme} {
      .mbsc-cal-picker {
        background: $background;
      }

      .mbsc-cal-days > div {
        color: $calendar-day;
      }

      .mbsc-cal-today .mbsc-cal-day-date {
        color: $accent;
      }

      .mbsc-cal-day-diff .mbsc-cal-day-i {
        background: $wheel-button;
      }

      /* Focus */
      .mbsc-cal-cell:focus .mbsc-cal-day-i {
        background: $button-hover;
      }

      /* Hover style */
      &.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-day:not(.mbsc-disabled):hover .mbsc-cal-day-i,
      &.mbsc-no-touch .mbsc-cal-picker .mbsc-cal-cell:not(.mbsc-disabled):hover {
        background: $button-hover;
      }

      /* Selected style */
      .mbsc-cal .mbsc-cal-day.mbsc-selected:not(.mbsc-disabled) .mbsc-cal-cell-i.mbsc-cal-day-i,
      .mbsc-cal .mbsc-cal-picker .mbsc-cal-cell.mbsc-selected,
      &.mbsc-no-touch .mbsc-cal-picker .mbsc-cal-cell.mbsc-selected:hover {
        background: rgba($accent, .25);
      }

      /* Tab style */
      &.mbsc-no-touch .mbsc-cal-tab:hover {
        background: $button-hover;
        border-color: $button-hover;
      }

      .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected,
      &.mbsc-no-touch .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected:hover {
        border-bottom-color: $accent;
        border-top-color: transparent;
        color: $accent;
        background: $background;
      }

      /* Tabs */
      .mbsc-cal-tabs-c {
        border-bottom: 1px solid $frame-border;
      }

      /* Marks */
      .mbsc-cal-mark {
        background: $mark;
      }

      .mbsc-cal-txt {
        color: get-contrast-color($alt-background);
        background: $alt-background;
      }
    }
  }
}



@include exports("calbase.windows") {
  .mbsc-windows {

    .mbsc-cal-month,
    .mbsc-cal-year {
      font-size: 1.125em;
      line-height: 2.222223em;
    }

    .mbsc-cal .mbsc-cal-btn {
      width: 3em;
      height: 3em;
      line-height: 3em;
      padding: 0;
      margin: -.25em;
    }

    .mbsc-cal-c {
      padding: .5em;
    }

    .mbsc-cal-picker {
      padding: .167em;
    }

    .mbsc-cal-days > div {
      font-size: .9375em;
    }

    .mbsc-cal-day {
      padding: 0;
    }

    .mbsc-cal-day-date {
      /*height: 100%;*/
      font-size: .875em;
      line-height: 2.285714em;
      border: 2px solid transparent;
      box-sizing: border-box;
    }

    .mbsc-cal-day-diff .mbsc-cal-day-i {
      opacity: 1;
    }

    /* Week numbers */
    .mbsc-cal-week-nr {
      width: 2.166667em;
      font-size: .75em;
      font-weight: bold;
    }

    /* Tabs */
    .mbsc-cal-tabs {
      border-collapse: collapse;

      .mbsc-cal-tab {
        top: 0;
        font-size: .75em;
        line-height: 2.333334em;
        border-bottom: 4px solid transparent;
        border-top: 4px solid transparent;
      }
    }

    /* Marks */
    .mbsc-cal-marks {
      margin-top: -.625em;
    }
  }

  @include mbsc-windows-calbase(windows, $mbsc-windows-colors);
}





@mixin mbsc-windows-calendar($theme, $params) {
  @include exports("calendar.#{$theme}.colors") {
    @include mbsc-windows-calbase($theme, $params);
  }
}



@include exports("calendar.windows") {
  @include mbsc-windows-calendar(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}



// timespan component






@mixin mbsc-ios-timespan($theme, $params) {
  @include exports("timespan.#{$theme}.colors") {
    @include mbsc-ios-scroller($theme, $params);

    $accent: map-get($params, accent);
    $colors: mbsc-ios-colors($params);

    .mbsc-#{$theme} {
      &.mbsc-ts .mbsc-sc-lbl {
        color: $accent;
      }
    }
  }
}




@include exports("timespan") {
  .mbsc-ts .mbsc-ts-lbl {
    display: none;
  }
}


@include exports("timespan.ios") {
  .mbsc-ios {
    &.mbsc-ts .mbsc-sc-lbl-v {
      margin: 0;
    }

    &.mbsc-ts .mbsc-sc-lbl-v .mbsc-ts-lbl {
      display: inline;
      visibility: hidden;
      padding-left: .5em;
      font-size: .909090em;
      text-transform: lowercase;
    }

    &.mbsc-ts .mbsc-sc-whl-gr {
      padding: .833333em;
    }

    &.mbsc-ts .mbsc-sc-lbl {
      padding: 0 .55em;
      margin-top: -.75em;
      left: auto;
      top: 50%;
      right: 0;
      width: auto;
      z-index: 3;
      font-size: 1.666667em;
      line-height: 1.5em;
      text-transform: lowercase;
    }

    &.mbsc-ts .mbsc-rtl .mbsc-sc-lbl {
      left: 0;
      right: auto;
    }

    &.mbsc-ts .mbsc-sc-whl-gr-3d .mbsc-sc-lbl {
      -webklit-transform: translateZ(88px);
      transform: translateZ(88px);
    }

    /* Desktop view */
    &.mbsc-ts.mbsc-fr-pointer {
      .mbsc-sc-whl-gr {
        padding: 0;
      }

      .mbsc-sc-lbl {
        font-size: 1.333334em;
        padding: 0 .6875em;
      }
    }
  }

  @include mbsc-ios-timespan(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}










@mixin mbsc-mobiscroll-timespan($theme, $params) {
  @include exports("timespan.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}



@include exports("timespan.mobiscroll") {
  @include mbsc-mobiscroll-timespan(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}







@mixin mbsc-material-timespan($theme, $params) {
  @include exports("timespan.#{$theme}.colors") {
    @include mbsc-material-scroller($theme, $params);
  }
}



@include exports("timespan.material") {
  @include mbsc-material-timespan(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}







@mixin mbsc-windows-timespan($theme, $params) {
  @include exports("timespan.#{$theme}.colors") {
    @include mbsc-windows-scroller($theme, $params);

    $text: map-get($params, text);

    .mbsc-#{$theme} {
      &.mbsc-ts .mbsc-sc-lbl {
        color: $text;
      }
    }
  }
}



@include exports("timespan.windows") {
  .mbsc-windows {
    &.mbsc-ts .mbsc-sc-whl-gr {
      padding: 0;
    }

    &.mbsc-ts .mbsc-sc-lbl {
      line-height: 1.5em;
      z-index: 3;
      top: 50%;
      bottom: auto;
      right: 0;
      left: auto;
      width: auto;
      margin-top: -0.75em;
      padding: 0 .5em;
      font-size: 1em;
      text-transform: lowercase;
    }

    &.mbsc-ts .mbsc-ts-lbl {
      display: inline;
      visibility: hidden;
      padding-left: 0.5em;
      font-size: 1em;
      text-transform: lowercase;
    }

    &.mbsc-ts .mbsc-sc-lbl-v {
      margin-bottom: 0;
    }
  }

  @include mbsc-windows-timespan(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}



// timer component






@mixin mbsc-ios-timer($theme, $params) {
  @include exports("timer.#{$theme}.colors") {
    @include mbsc-ios-scroller($theme, $params);

    $accent: map-get($params, accent);

    .mbsc-#{$theme} {
      &.mbsc-timer .mbsc-sc-lbl {
        color: $accent;
      }
    }
  }
}




@include exports("timer") {
  .mbsc-timer .mbsc-timer-lbl {
    display: none;
  }
}


@include exports("timer.ios") {
  .mbsc-ios {
    &.mbsc-timer .mbsc-sc-whl-gr {
      padding: .833333em;
    }

    &.mbsc-timer .mbsc-sc-lbl {
      z-index: 3;
      top: 50%;
      bottom: auto;
      right: 0;
      left: auto;
      width: auto;
      margin-top: -.75em;
      padding: 0 .55em;
      font-size: 1.666667em;
      line-height: 1.5em;
      text-transform: lowercase;
    }

    &.mbsc-timer .mbsc-rtl .mbsc-sc-lbl {
      left: 0;
      right: auto;
    }

    &.mbsc-timer .mbsc-sc-whl-gr-3d .mbsc-sc-lbl {
      -webklit-transform: translateZ(88px);
      transform: translateZ(88px);
    }

    &.mbsc-timer .mbsc-sc-itm {
      text-overflow: clip;
    }

    &.mbsc-timer .mbsc-sc-lbl-v {
      margin: 0;
    }

    .mbsc-sc-lbl-v .mbsc-timer-lbl {
      display: inline;
      visibility: hidden;
      padding-left: .5em;
      font-size: .909090em;
      text-transform: lowercase;
    }

    /* Desktop view */
    &.mbsc-timer.mbsc-fr-pointer {
      .mbsc-sc-whl-gr {
        padding: 0;
      }

      .mbsc-sc-lbl {
        font-size: 1.333334em;
        padding: 0 .6875em;
      }
    }
  }

  @include mbsc-ios-timer(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}











@mixin mbsc-mobiscroll-timer($theme, $params) {
  @include exports("timer.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}



@include exports("timer.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-timer {
      .mbsc-fr-btn-cont {
        display: table;

        .mbsc-fr-btn-w {
          width: 1px;
          text-align: left;
          display: table-cell;
          float: none;
        }

        .mbsc-fr-btn-w:first-child {
          width: auto;
          text-align: left;
        }

        .mbsc-fr-btn-w .mbsc-fr-btn {
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          text-decoration: none;
        }
      }
    }
  }

  @include mbsc-mobiscroll-timer(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}







@mixin mbsc-material-timer($theme, $params) {
  @include exports("timer.#{$theme}.colors") {
    @include mbsc-material-scroller($theme, $params);
  }
}



@include exports("timer.material") {
  .mbsc-material.mbsc-timer {
    .mbsc-fr-btn-cont {
      display: table;
    }

    .mbsc-fr-btn-w {
      width: 1px;
      text-align: left;
      display: table-cell;
      float: none;
    }

    .mbsc-fr-btn-w:first-child {
      width: auto;
      text-align: left;
    }

    .mbsc-fr-btn-w .mbsc-fr-btn {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      text-decoration: none;
    }
  }

  @include mbsc-material-timer(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}







@mixin mbsc-windows-timer($theme, $params) {
  @include exports("timer.#{$theme}.colors") {
    @include mbsc-windows-scroller($theme, $params);

    $text: map-get($params, text);

    .mbsc-#{$theme} {
      &.mbsc-timer .mbsc-sc-lbl {
        color: $text;
      }
    }
  }
}



@include exports("timer.windows") {
  .mbsc-windows {
    &.mbsc-timer .mbsc-sc-whl-gr {
      padding: 0;
    }

    &.mbsc-timer .mbsc-sc-lbl {
      line-height: 1.5em;
      z-index: 3;
      top: 50%;
      bottom: auto;
      right: 0;
      left: auto;
      width: auto;
      margin-top: -0.75em;
      padding: 0 .5em;
      font-size: 1em;
      text-transform: lowercase;
    }

    &.mbsc-timer .mbsc-timer-lbl {
      display: inline;
      visibility: hidden;
      padding-left: 0.5em;
      font-size: 1em;
      text-transform: lowercase;
    }

    &.mbsc-timer .mbsc-sc-lbl-v {
      margin-bottom: 0;
    }
  }

  @include mbsc-windows-timer(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}



// color component








// Theme specific variables - inherited from global variables

// background
$mbsc-ios-page-background: $mbsc-page-background-light !default;
$mbsc-ios-dark-page-background: $mbsc-page-background-dark !default;

// text
$mbsc-ios-page-text: $mbsc-page-text-light !default;
$mbsc-ios-dark-page-text: $mbsc-page-text-dark !default;

// add variables to color maps
$mbsc-ios-colors: map-merge($mbsc-ios-colors, (
  'page-background': $mbsc-ios-page-background,
  'page-text': $mbsc-ios-page-text,
));
$mbsc-ios-dark-colors: map-merge($mbsc-ios-dark-colors, (
  'page-background': $mbsc-ios-dark-page-background,
  'page-text': $mbsc-ios-dark-page-text,
));

@mixin mbsc-ios-page($theme, $params) {
  @include exports("page.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');

    $colors: mbsc-ios-colors($params);
    $button: map-get($colors, 'button');

    @if (lightness($background) > 50%) {
      $background: adjust-hue(darken(saturate($background, 19%), 2%), calc(240% / 1%));
    }
    @else {
      $background: adjust-hue(lighten(desaturate($background, 19%), 10%), calc(240% / 1%));
    }

    // get custom params
    $text-param: map-get($params, 'page-text');
    $background-param: map-get($params, 'page-background');
    // overwrite params with custom variables
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));

    .mbsc-#{$theme} {
      &.mbsc-page {
        background: $background;
        color: $text;
      }

      a {
        color: $button;
      }
    }
  }
}




@include exports("page") {
  [mbsc-page]:not(.mbsc-page) {
    // Prevent FOUC
    visibility: hidden;
  }

  .mbsc-page-ctx {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .mbsc-page {
    min-height: 100%;
    font-size: 16px;
    font-family: arial, verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .mbsc-page,
  .mbsc-page * {
    box-sizing: border-box;
  }

  .mbsc-page:before,
  .mbsc-page:after {
    content: '';
    display: table;
  }


  /* RTL mode */

  .mbsc-rtl {
    direction: rtl;
  }


  /* Typography */

  .mbsc-page h1,
  .mbsc-page h2,
  .mbsc-page h3,
  .mbsc-page h4,
  .mbsc-page h5,
  .mbsc-page h6,
  .mbsc-h1,
  .mbsc-h2,
  .mbsc-h3,
  .mbsc-h4,
  .mbsc-h5,
  .mbsc-h6 {
    margin: 0;
    padding: 0;
    color: inherit;
    font-weight: normal;
    font-family: inherit;
  }

  .mbsc-page p,
  .mbsc-p {
    margin: 1em 0;
    padding: 0;
    line-height: 1.5;
  }

  .mbsc-page a,
  .mbsc-a {
    text-decoration: none;
  }

  .mbsc-page a:hover,
  .mbsc-a:hover {
    text-decoration: underline;
  }

  .mbsc-page a.mbsc-btn:hover,
  .mbsc-a.mbsc-btn:hover {
    text-decoration: none;
  }

  .mbsc-page h1,
  .mbsc-h1 {
    margin: .347826em 0;
    font-size: 2.875em;
  }

  .mbsc-page h2,
  .mbsc-h2 {
    margin: .470588em 0;
    font-size: 2.125em;
  }

  .mbsc-page h3,
  .mbsc-h3 {
    margin: .666666em 0;
    font-size: 1.5em;
  }

  .mbsc-page h4,
  .mbsc-h4 {
    margin: .8em 0;
    font-size: 1.25em;
  }

  .mbsc-page h5,
  .mbsc-h5 {
    margin: 1.066666em 0;
    font-size: .9375em;
  }

  .mbsc-page h6,
  .mbsc-h6 {
    margin: 1.333333em 0;
    font-size: .75em;
  }

  /* Padding, margin */

  .mbsc-padding {
    padding: 1em;
  }

  .mbsc-padding > p:first-child {
    margin-top: 0;
  }

  .mbsc-padding > p:last-child {
    margin-bottom: 0;
  }

  .mbsc-margin {
    margin: 1em 0;
  }

  .mbsc-margin:first-child {
    margin-top: 0;
  }

  .mbsc-margin:last-child {
    margin-bottom: 0;
  }

  /* Lists */

  .mbsc-page ul,
  .mbsc-page ol,
  .mbsc-ul,
  .mbsc-ol {
    padding: 0;
    margin: 1em 0 1em 1.25em;
    line-height: 1.5;
  }

  .mbsc-page ul ul,
  .mbsc-page ol ol,
  .mbsc-ul .mbsc-ul,
  .mbsc-ol .mbsc-ol {
    margin: 0 0 0 1.25em;
  }

  /* Font sizes */

  .mbsc-txt-xs {
    font-size: .625em
  }

  .mbsc-txt-s {
    font-size: .75em;
  }

  .mbsc-txt-m {
    font-size: 1.25em;
  }

  .mbsc-txt-l {
    font-size: 1.5em;
  }

  .mbsc-txt-xl {
    font-size: 2em;
  }

  .mbsc-txt-muted {
    opacity: .6;
  }

  /* Line heights */
  .mbsc-line-height-xs {
    line-height: 1;
  }

  .mbsc-line-height-s {
    line-height: 1.25;
  }

  .mbsc-line-height-m {
    line-height: 1.5;
  }

  .mbsc-line-height-l {
    line-height: 1.75;
  }

  .mbsc-line-height-xl {
    line-height: 2;
  }

  /* Font weights */

  .mbsc-ultra-bold {
    font-weight: 900;
  }

  .mbsc-bold {
    font-weight: bold;
  }

  .mbsc-medium {
    font-weight: 500;
  }

  .mbsc-light {
    font-weight: 300;
  }

  .mbsc-thin {
    font-weight: 100;
  }

  .mbsc-italic {
    font-style: italic;
  }

  /* Text align */

  .mbsc-align-left {
    text-align: left;
  }

  .mbsc-align-right {
    text-align: right;
  }

  .mbsc-align-center {
    text-align: center;
  }

  .mbsc-align-justify {
    text-align: justify;
  }

  /* Float */

  .mbsc-pull-right {
    float: right;
  }

  .mbsc-pull-left {
    float: left;
  }

  /* Image section */

  .mbsc-media-fluid {
    display: block;
    width: 100%;
  }

  .mbsc-img-thumbnail {
    width: 6em;
    height: 6em;
    margin: 1em;
  }

  /* Avatar image */

  .mbsc-avatar {
    width: 2.5em;
    height: 2.5em;
    padding: 0;
    border-radius: 1.25em;
  }

  /* Note */

  .mbsc-note {
    position: relative;
    padding: .75em 1.25em;
    margin: 1em;
    border: 1px solid transparent;
    font-size: .875em;
  }

  @media screen and (max-width: 600px) {
    .mbsc-note {
      text-align: center;
    }
  }
}


@include exports("page.ios") {
  .mbsc-ios {
    .mbsc-note {
      border-radius: .25em;
    }

    .mbsc-note,
    .mbsc-note-primary {
      color: darken($mbsc-ios-primary, 30%);
      background-color: lighten($mbsc-ios-primary, 20%);
    }

    .mbsc-note-secondary {
      color: darken($mbsc-ios-secondary, 30%);
      background-color: lighten($mbsc-ios-secondary, 20%);
    }

    .mbsc-note-success {
      color: darken($mbsc-ios-success, 30%);
      background-color: lighten($mbsc-ios-success, 20%);
    }

    .mbsc-note-danger {
      color: darken($mbsc-ios-danger, 30%);
      background-color: lighten($mbsc-ios-danger, 20%);
    }

    .mbsc-note-warning {
      color: darken($mbsc-ios-warning, 30%);
      background-color: lighten($mbsc-ios-warning, 20%);
    }

    .mbsc-note-info {
      color: darken($mbsc-ios-info, 30%);
      background-color: lighten($mbsc-ios-info, 20%);
    }

    .mbsc-note-light {
      color: darken($mbsc-ios-light, 70%);
      background-color: lighten($mbsc-ios-light, 20%);
    }

    .mbsc-note-dark {
      color: darken($mbsc-ios-dark, 30%);
      background-color: lighten($mbsc-ios-dark, 20%);
    }
  }

  @include mbsc-ios-page(ios, $mbsc-ios-colors);
}






@mixin mbsc-ios-progress($theme, $params) {
  @include exports("progress.#{$theme}.colors") {

    $accent: map-get($params, accent);

    $colors: mbsc-ios-colors($params);
    $cont-background: map-get($colors, cont-background);
    $slider-track-step: map-get($colors, slider-track-step);
    $slider-tooltip: map-get($colors, slider-tooltip);

    .mbsc-#{$theme} {

      &.mbsc-form .mbsc-progress,
      &.mbsc-input-box.mbsc-progress,
      &.mbsc-input-outline.mbsc-progress {
        background: $cont-background;
      }

      .mbsc-progress-track {
        background: $slider-track-step;
      }

      .mbsc-progress-bar {
        background: $accent;
      }

      .mbsc-progress-value,
      .mbsc-progress-step-label {
        color: $slider-tooltip;
      }
    }
  }
}




@include exports("progress") {
  .mbsc-progress {
    position: relative;
    display: block;
    margin: 0;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .mbsc-progress progress {
    display: none;
  }

  .mbsc-progress .mbsc-input-wrap {
    position: relative;
    display: block;
  }

  .mbsc-progress .mbsc-input-ic {
    position: absolute;
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
  }

  .mbsc-progress-cont {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }

  .mbsc-progress-track {
    position: relative;
    display: block;
    box-sizing: border-box;
  }

  .mbsc-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
  }

  .mbsc-rtl .mbsc-progress-bar {
    left: auto;
    right: 0;
  }

  .mbsc-progress-anim .mbsc-progress-bar {
    transition: width .1s ease-in-out;
  }

  .mbsc-progress-value {
    position: absolute;
    top: 50%;
    width: 3em;
    overflow: hidden;
    margin-top: -.5em;
    line-height: 1em;
  }

  .mbsc-progress-value-right .mbsc-progress-value {
    right: 0;
    text-align: right;
  }

  .mbsc-rtl.mbsc-progress-value-right .mbsc-progress-value {
    right: auto;
    left: 0;
    text-align: left;
  }

  .mbsc-progress-value-left .mbsc-progress-value {
    left: 0;
    text-align: left;
  }

  .mbsc-rtl.mbsc-progress-value-left .mbsc-progress-value {
    left: auto;
    right: 0;
    text-align: right;
  }

  .mbsc-progress-step-label {
    position: absolute;
    top: 1em;
    width: 3.5em;
    font-size: .75em;
    text-align: center;
    overflow: hidden;
  }

  .mbsc-ltr .mbsc-progress-step-label {
    margin-left: -1.75em;
  }

  .mbsc-rtl .mbsc-progress-step-label {
    margin-right: -1.75em;
  }

  /* Inline labels */

  .mbsc-label-inline.mbsc-progress.mbsc-control-w {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    .mbsc-input-wrap {
      -webkit-box-flex: 1;
      -webkit-flex: 1 auto;
      -moz-box-flex: 1;
      -moz-flex: 1 auto;
      -ms-flex: 1 auto;
      flex: 1 auto;
    }

    .mbsc-label {
      position: static;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -moz-box-flex: 0;
      -moz-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      font-size: 1em;
      line-height: 2.125em;
      width: 30%;
      max-width: 12.5em;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}


@include exports("progress.ios") {
  .mbsc-ios {
    &.mbsc-progress {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      min-height: 4em;
      margin-top: -1px;
      padding: .625em 1em;
      align-items: center;
      box-sizing: border-box;
    }

    &.mbsc-progress.mbsc-progress-w.mbsc-control-w .mbsc-label {
      padding: 0;
    }

    &.mbsc-progress .mbsc-input-ic {
      top: .0625em;
      margin: 0;
    }

    &.mbsc-progress.mbsc-ic-left .mbsc-left-ic,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-right-ic {
      right: auto;
      left: -.375em;
    }

    &.mbsc-progress.mbsc-ic-right .mbsc-right-ic,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-left-ic {
      left: auto;
      right: -.375em;
    }

    &.mbsc-progress .mbsc-input-wrap {
      position: relative;
      padding: 0 .75em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-w.mbsc-ic-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-w.mbsc-ic-right .mbsc-input-wrap {
      padding-left: 2.5em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-w.mbsc-ic-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-w.mbsc-ic-left .mbsc-input-wrap {
      padding-right: 2.5em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap {
      padding-left: 3.5em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap {
      padding-right: 3.5em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-left.mbsc-progress-value-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-right.mbsc-progress-value-right .mbsc-input-wrap {
      padding-left: 5.5em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-right.mbsc-progress-value-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-left.mbsc-progress-value-left .mbsc-input-wrap {
      padding-right: 5.5em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-left.mbsc-progress-value-left .mbsc-progress-value,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-right.mbsc-progress-value-right .mbsc-progress-value {
      left: 2.2857em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-right.mbsc-progress-value-right .mbsc-progress-value,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-left.mbsc-progress-value-left .mbsc-progress-value {
      right: 2.2857em;
    }

    .mbsc-progress-cont {
      padding: 1em 0;
    }

    .mbsc-progress-track {
      border-radius: 1em;
      height: .125em;
    }

    .mbsc-progress-bar {
      z-index: 1;
      border-radius: 1em;
    }

    .mbsc-progress-value {
      width: 3.715em;
      font-size: .875em;
    }

    .mbsc-progress-step-label {
      top: 1.333334em;
    }

    &.mbsc-progress.mbsc-ic-left:before,
    &.mbsc-progress.mbsc-ic-left:after {
      left: 1em;
    }

    /* Stacked label */
    .mbsc-label-stacked.mbsc-progress.mbsc-control-w,
    .mbsc-label-floating.mbsc-progress.mbsc-control-w {
      .mbsc-label {
        font-size: .75em;
        position: absolute;
        top: .666667em;
        line-height: 1em;
        padding: 0;
        overflow: visible;
      }

      &:not(.mbsc-slider) .mbsc-input-wrap {
        padding-left: 0;
        padding-right: 0;
      }

      .mbsc-label + .mbsc-input-wrap {
        padding-top: 1em;
        padding-bottom: 1em;

        .mbsc-input-ic {
          top: 1.0625em;
        }
      }

      &.mbsc-ltr.mbsc-ic-right .mbsc-input-wrap,
      &.mbsc-rtl.mbsc-ic-left .mbsc-input-wrap {
        padding-right: 2.5em;
      }

      &.mbsc-ltr.mbsc-ic-left .mbsc-input-wrap,
      &.mbsc-rtl.mbsc-ic-right .mbsc-input-wrap {
        padding-left: 2.5em;
      }

      &.mbsc-ltr.mbsc-progress-value-right .mbsc-input-wrap,
      &.mbsc-rtl.mbsc-progress-value-left .mbsc-input-wrap {
        padding-right: 3.5em;
      }

      &.mbsc-ltr.mbsc-progress-value-left .mbsc-input-wrap,
      &.mbsc-rtl.mbsc-progress-value-right .mbsc-input-wrap {
        padding-left: 3.5em;
      }

      &.mbsc-ltr.mbsc-ic-left.mbsc-progress-value-left .mbsc-input-wrap,
      &.mbsc-rtl.mbsc-ic-right.mbsc-progress-value-right .mbsc-input-wrap {
        padding-left: 5.5em;
      }

      &.mbsc-ltr.mbsc-ic-right.mbsc-progress-value-right .mbsc-input-wrap,
      &.mbsc-rtl.mbsc-ic-left.mbsc-progress-value-left .mbsc-input-wrap {
        padding-right: 5.5em;
      }
    }

    /* Progress color presets */
    .mbsc-progress-primary .mbsc-progress-bar {
      background: $mbsc-ios-primary;
    }

    .mbsc-progress-secondary .mbsc-progress-bar {
      background: $mbsc-ios-secondary;
    }

    .mbsc-progress-success .mbsc-progress-bar {
      background: $mbsc-ios-success;
    }

    .mbsc-progress-danger .mbsc-progress-bar {
      background: $mbsc-ios-danger;
    }

    .mbsc-progress-warning .mbsc-progress-bar {
      background: $mbsc-ios-warning;
    }

    .mbsc-progress-info .mbsc-progress-bar {
      background: $mbsc-ios-info;
    }
  }

  @include mbsc-ios-progress(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}






@mixin mbsc-ios-slider($theme, $params) {
  @include exports("slider.#{$theme}.colors") {

    @include mbsc-ios-page($theme, $params);
    @include mbsc-ios-progress($theme, $params);

    $colors: mbsc-ios-colors($params);
    $slider-tooltip: map-get($colors, slider-tooltip);
    $slider-track-step: map-get($colors, slider-track-step);
    $cont-background: map-get($colors, cont-background);

    .mbsc-#{$theme} {
      .mbsc-slider-handle {
        background: #fff;
      }

      .mbsc-slider-tooltip {
        color: $slider-tooltip;
        background: $cont-background;
      }

      .mbsc-slider-step {
        background: $slider-track-step;
      }
    }
  }
}





@include exports("slider") {
  .mbsc-progress.mbsc-slider input {
    display: none;
  }

  .mbsc-ltr .mbsc-progress-anim .mbsc-slider-handle-cont {
    transition: left .1s ease-in-out;
  }

  .mbsc-rtl .mbsc-progress-anim .mbsc-slider-handle-cont {
    transition: right .1s ease-in-out;
  }

  .mbsc-slider-handle-cont {
    position: absolute;
    width: 2em;
    height: 2em;
    top: .0625em;
    margin-top: -1em;
    cursor: pointer;
  }

  .mbsc-ltr .mbsc-slider-handle-cont {
    right: -1em;
  }

  .mbsc-rtl .mbsc-slider-handle-cont {
    left: -1em;
  }

  .mbsc-ltr .mbsc-slider-handle-cont.mbsc-slider-handle-left {
    left: 0;
  }

  .mbsc-rtl .mbsc-slider-handle-cont.mbsc-slider-handle-left {
    right: 0;
  }

  .mbsc-ltr .mbsc-progress-track .mbsc-slider-handle-cont {
    margin-left: -1em;
  }

  .mbsc-rtl .mbsc-progress-track .mbsc-slider-handle-cont {
    margin-right: -1em;
  }

  .mbsc-slider-handle {
    position: absolute;
    top: .125em;
    right: 50%;
    margin: -.5em -.5em 0 0;
    z-index: 2;
  }

  .mbsc-slider-handle-cont .mbsc-slider-handle {
    box-sizing: content-box;
  }

  .mbsc-slider .mbsc-slider-handle:focus,
  .mbsc-slider .mbsc-active .mbsc-slider-handle {
    outline: 0;
    z-index: 15;
  }

  .mbsc-slider .mbsc-handle-curr {
    z-index: 16;
  }

  .mbsc-slider-tooltip {
    position: absolute;
    right: 50%;
    opacity: 0;
    color: #000;
  }

  .mbsc-slider-step {
    position: absolute;
    top: 0;
    width: .125em;
    height: 100%;
    margin-left: -.0625em;
  }
}


@include exports("slider.ios") {
  .mbsc-ios {

    .mbsc-slider-handle {
      top: 50%;
      width: 1.75em;
      height: 1.75em;
      margin: -.875em -.875em 0 0;
      border-radius: 1.25em;
      box-shadow: 0 0 1em rgba(0, 0, 0, .1), 0 0 .0625em rgba(0, 0, 0, .15), 0 .125em .125em rgba(0, 0, 0, .15);
    }

    .mbsc-slider-tooltip {
      font-size: .875em;
      width: 3em;
      margin-right: -1.5em;
      top: -1em;
      text-align: center;
      opacity: 0;
      transition: opacity .2s ease-in-out;
    }

    .mbsc-slider-handle:focus ~ .mbsc-slider-tooltip,
    .mbsc-active .mbsc-slider-tooltip {
      opacity: 1;
    }

    .mbsc-slider-step {
      height: .5em;
      width: .0625em;
      margin-top: -.1875em;
      margin-left: -.0625em;
    }

    &.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
      opacity: .4;
    }

    &.mbsc-slider-primary .mbsc-progress-bar {
      background: $mbsc-ios-primary;
    }

    &.mbsc-slider-secondary .mbsc-progress-bar {
      background: $mbsc-ios-secondary;
    }

    &.mbsc-slider-success .mbsc-progress-bar {
      background: $mbsc-ios-success;
    }

    &.mbsc-slider-danger .mbsc-progress-bar {
      background: $mbsc-ios-danger;
    }

    &.mbsc-slider-warning .mbsc-progress-bar {
      background: $mbsc-ios-warning;
    }

    &.mbsc-slider-info .mbsc-progress-bar {
      background: $mbsc-ios-info;
    }
  }

  @include mbsc-ios-slider(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}






@mixin mbsc-ios-color($theme, $params) {
  @include exports("color.#{$theme}.colors") {
    @include mbsc-ios-frame($theme, $params);
    @include mbsc-ios-slider($theme, $params);

    $accent: map-get($params, accent);
    $colors: mbsc-ios-colors($params);

    .mbsc-#{$theme} {

      .mbsc-color-active,
      &.mbsc-no-touch .mbsc-color-item-c:hover {
        box-shadow: inset 0 0 0 .1875em $accent;
      }
    }
  }
}




@include exports("color") {
  .mbsc-color {
    font-size: 16px;
  }

  .mbsc-color-scroll-cont {
    padding: .125em 0 .1875em 0;
    overflow: hidden;
    box-sizing: content-box;
  }

  .mbsc-color-cont {
    display: table;
    table-layout: fixed;
    margin: 0 auto;
  }

  .mbsc-color-item-c {
    position: relative;
    display: table-cell;
    padding: .18755em;
    max-width: 4.25em;
    max-height: 4.25em;
  }

  .mbsc-color-clear-item,
  .mbsc-color-item {
    position: relative;
    min-width: 3.5em;
    min-height: 3.5em;
    margin: .1875em;
    cursor: pointer;
  }

  .mbsc-color-clear-item {
    background: rgba(223, 222, 222, .6);
  }

  .mbsc-color-clear-cross {
    position: absolute;
    height: 100%;
    width: 2px;
    top: 0;
    left: 50%;
    margin-left: -1px;
    background: red;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .mbsc-color-row {
    padding: 0 .5em;
  }

  .mbsc-color.mbsc-fr-bubble .mbsc-fr-w,
  .mbsc-color.mbsc-fr-center .mbsc-fr-w {
    max-width: 286px;
    min-width: 280px;
  }

  .mbsc-color .mbsc-fr-c {
    padding: .25em 0;
  }


  /* vertical scroll override */

  .mbsc-color-vertical .mbsc-color-cont {
    width: 100%;
    height: 100%;
    white-space: normal;
    text-align: start;
  }

  .mbsc-color-vertical .mbsc-color-item-c {
    display: inline-block;
    vertical-align: top;
    text-align: center;
  }


  /* slider overrides */

  .mbsc-color .mbsc-color-slider-cont.mbsc-progress {
    padding: 0;
  }

  .mbsc-color .mbsc-color-slider-cont .mbsc-progress-cont {
    padding: 1.5em 0;
  }

  .mbsc-color .mbsc-color-slider-cont .mbsc-progress-track {
    height: .25em;
    border: 0;
  }

  .mbsc-color .mbsc-color-slider-cont .mbsc-slider-handle-cont {
    top: .125em;
  }

  .mbsc-color .mbsc-color-slider-cont .mbsc-slider-step {
    display: none;
  }


  /* checkmark */

  .mbsc-color-clear-item:before,
  .mbsc-color-item:before {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 50%;
    left: 50%;
    margin-top: -.5em;
    margin-left: -.5em;
    color: #f7f7f7;
    display: none;
    font-size: 1.5em;
  }

  .mbsc-color-clear-item:before {
    display: block;
  }

  .mbsc-color-clear-item,
  .mbsc-color-input-item-close:before,
  .mbsc-color-selected::before {
    display: block;
    text-shadow: 0 0 .125em #000;
  }


  /* refine mode multiple select */

  .mbsc-color-refine-preview {
    display: table;
    padding: 0 .25em;
  }

  .mbsc-color-preview-cont {
    border-top: 1px solid #ccc;
    overflow: hidden;
    padding-top: .25em;
  }

  .mbsc-color-preview-item-c {
    display: table-cell;
  }

  .mbsc-color-preview-item {
    min-width: 1.875em;
    width: 1.875em;
    height: 1.875em;
    margin: .25em;
    cursor: pointer;
    background: transparent;
    box-sizing: border-box;
  }

  .mbsc-color-preview-item-empty {
    border: 1px dashed;
    border-color: #bbb;
  }


  /* input style */

  .mbsc-color-input {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #aaa;
    min-height: 2.125em;
    width: 100%;
    padding: .125em;
    box-sizing: border-box;
  }

  .mbsc-color-input:empty:not(:focus):before {
    content: attr(data-placeholder);
    position: absolute;
    top: 50%;
    overflow: hidden;
    margin-top: -1em;
    opacity: .6;
    font-size: 1em;
    line-height: 2em;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .mbsc-label-floating .mbsc-color-input:empty:not(:focus):before {
    opacity: 0;
  }

  .mbsc-label-floating-active .mbsc-color-input:empty:not(:focus):before {
    opacity: 1;
  }

  .mbsc-color-input-item {
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    position: relative;
    margin: .125em;
    height: 1.5em;
    width: 4.0625em;
    vertical-align: middle;
  }

  .mbsc-color-input-hdn {
    position: absolute;
    height: 1px !important;
    width: 1px !important;
    margin: 0 !important;
    padding: 0 !important;
    left: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  .mbsc-color-input-item-close {
    position: absolute;
    top: 10%;
    right: 0;
    height: 80%;
    width: 1.75em;
    cursor: pointer;
    color: #fff;
    border-left: 1px solid #fff;
    text-align: center;
  }

  .mbsc-color-input-item-close:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    margin: -.75em 0 0 -.75em;
  }

  .mbsc-input .mbsc-color-input {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
  }


  /* JQuery Mobile */

  .ui-input-text .mbsc-color-input {
    border-color: transparent;
  }
}


@include exports("color.ios") {
  .mbsc-ios {

    &.mbsc-color.mbsc-fr-bubble .mbsc-fr-w,
    &.mbsc-color.mbsc-fr-center .mbsc-fr-w {
      max-width: 286px;
      min-width: 280px;
    }

    &.mbsc-color .mbsc-fr-c {
      padding: .25em 0;
    }

    .mbsc-color-active,
    &.mbsc-no-touch .mbsc-color-item-c:hover {
      border-radius: 3em;
    }

    .mbsc-color-clear-item,
    .mbsc-color-item {
      border-radius: 1.75em;
    }

    &.mbsc-color .mbsc-fr-c {
      font-size: 16px;
    }

    .mbsc-fr-center .mbsc-color-preview-cont {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    .mbsc-color-preview-cont {
      bottom: 2.8125em;
    }

    &.mbsc-color .mbsc-slider-step {
      height: .25em;
      margin: 0;
    }

    .mbsc-color-slider-cont.mbsc-progress {
      border: 0;
      padding: 0 .5em;
    }

    .mbsc-color-slider-cont.mbsc-slider {
      background: transparent;
    }

    .mbsc-color-slider-cont.mbsc-slider:before,
    .mbsc-color-slider-cont.mbsc-slider:after {
      display: none;
    }

    .mbsc-color-slider-cont .mbsc-progress-cont {
      padding: 1.5em 0;
    }

    .mbsc-color-preview-item {
      border-radius: 1em;
      margin: .25em;
    }

    .mbsc-color-refine-preview {
      padding: .25em;
    }

    &.mbsc-input .mbsc-color-input {
      height: auto;
      min-height: 2.75em;
      padding: .1875em .8125em;
      background: transparent;
      text-indent: .1875em;
    }

    &.mbsc-input .mbsc-color-input-item {
      height: 2em;
      margin: .1875em;
      border-radius: .125em;
    }

    &.mbsc-label-stacked .mbsc-color-input-item,
    &.mbsc-label-floating .mbsc-color-input-item {
      height: 1.6875em;
    }

    &.mbsc-label-stacked .mbsc-color-input:empty:not(:focus):before {
      margin-top: -.1875em;
    }

    &.mbsc-input-box.mbsc-label-stacked .mbsc-color-input:empty:not(:focus):before,
    &.mbsc-input-outline.mbsc-label-stacked .mbsc-color-input:empty:not(:focus):before {
      margin-top: -.375em;
    }
  }

  @include mbsc-ios-color(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}














.mbsc-bootstrap {

  &.mbsc-color.mbsc-fr-bubble .mbsc-fr-w,
  &.mbsc-color.mbsc-fr-center .mbsc-fr-w {
    max-width: 286px;
    min-width: 280px;
  }

  &.mbsc-color .mbsc-fr-w {
    min-width: 230px;
  }

  &.mbsc-color .mbsc-ic-material-check:before {
    margin-top: -.65em;
  }

  &.mbsc-color .mbsc-fr-c {
    font-size: 16px;
  }

  &.mbsc-color .mbsc-color-item-c {
    padding: 0;
  }

  .mbsc-color-active {
    border-radius: 0;
    box-shadow: none;
  }

  .mbsc-color-active .mbsc-color-item,
  .mbsc-color-active .mbsc-color-preview-item {
    box-shadow: inset 0 0 0 .1875em #38c;
  }

  &.mbsc-color .mbsc-slider-handle {
    top: 50%;
    width: 1em;
    height: 1em;
    background: #337ab7;
    transition: transform .2s ease-in-out;
  }

  &.mbsc-color .mbsc-slider .mbsc-input-wrap {
    padding: 0 .75em;
  }

  &.mbsc-color .mbsc-color-slider-cont .mbsc-progress-track {
    background: #ccc;
  }
}









// Theme specific variables - inherited from global variables

// background
$mbsc-mobiscroll-page-background: $mbsc-page-background-light !default;
$mbsc-mobiscroll-dark-page-background: $mbsc-page-background-dark !default;

// text
$mbsc-mobiscroll-page-text: $mbsc-page-text-light !default;
$mbsc-mobiscroll-dark-page-text: $mbsc-page-text-dark !default;

// add variables to color maps
$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'page-background': $mbsc-mobiscroll-page-background,
  'page-text': $mbsc-mobiscroll-page-text,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'page-background': $mbsc-mobiscroll-dark-page-background,
  'page-text': $mbsc-mobiscroll-dark-page-text,
));

@mixin mbsc-mobiscroll-page($theme, $params) {
  @include exports("page.#{$theme}.colors") {
    @include mbsc-mobiscroll-common($theme, $params);

    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');
    $background: map-get($params, 'background');
    // get custom params
    $text-param: map-get($params, 'page-text');
    $background-param: map-get($params, 'page-background');
    // overwrite params with custom variables
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));

    .mbsc-#{$theme} {
      &.mbsc-page {
        background: $background;
        color: $text;
      }

      a {
        color: $accent;
      }
    }
  }
}



@include exports("page.mobiscroll") {
  .mbsc-mobiscroll {

    /* Note */
    .mbsc-note {
      border-radius: .1875em;
    }

    .mbsc-note,
    .mbsc-note-primary {
      color: darken($mbsc-mobiscroll-primary, 30%);
      background-color: lighten($mbsc-mobiscroll-primary, 20%);
    }

    .mbsc-note-secondary {
      color: darken($mbsc-mobiscroll-secondary, 30%);
      background-color: lighten($mbsc-mobiscroll-secondary, 20%);
    }

    .mbsc-note-success {
      color: darken($mbsc-mobiscroll-success, 30%);
      background-color: lighten($mbsc-mobiscroll-success, 20%);
    }

    .mbsc-note-danger {
      color: darken($mbsc-mobiscroll-danger, 30%);
      background-color: lighten($mbsc-mobiscroll-danger, 20%);
    }

    .mbsc-note-warning {
      color: darken($mbsc-mobiscroll-warning, 25%);
      background-color: lighten($mbsc-mobiscroll-warning, 20%);
    }

    .mbsc-note-info {
      color: darken($mbsc-mobiscroll-info, 30%);
      background-color: lighten($mbsc-mobiscroll-info, 20%);
    }

    .mbsc-note-light {
      color: darken($mbsc-mobiscroll-light, 60%);
      background-color: darken($mbsc-mobiscroll-light, 5%);
    }

    .mbsc-note-dark {
      color: darken($mbsc-mobiscroll-dark, 30%);
      background-color: lighten($mbsc-mobiscroll-dark, 40%);
    }
  }

  @include mbsc-mobiscroll-page(mobiscroll, $mbsc-mobiscroll-colors);
}






@mixin mbsc-mobiscroll-progress($theme, $params) {
  @include exports("progress.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');
    // derived colors
    $input-icon: lighten($text, 20%);

    $track: '';
    // Light background
    @if (lightness($background) > 50%) {
      $track: darken($background, 10%);
    }
    // Dark background
    @else {
      $track: lighten($background, 8%);
    }

    .mbsc-#{$theme} {
      &.mbsc-progress .mbsc-input-ic {
        color: $input-icon;
      }

      .mbsc-progress-track {
        background: $track;
      }

      .mbsc-progress-bar {
        background: $accent;
      }
    }
  }
}



@include exports("progress.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-progress {
      padding: 1em;
    }

    &.mbsc-progress .mbsc-label {
      font-size: .75em;
    }

    &.mbsc-progress .mbsc-input-ic {
      top: .0625em;
      margin: 0;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-input-wrap {
      padding-left: 2.75em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-input-wrap {
      padding-right: 2.75em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-left-ic,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-right-ic {
      right: auto;
      left: -.375em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-right-ic,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-left-ic {
      left: auto;
      right: -.375em;
    }

    .mbsc-progress-cont {
      padding: 1em 0;
    }

    .mbsc-progress-track {
      border-radius: 1em;
      height: .125em;
    }

    .mbsc-progress-value {
      width: 3.75em;
      font-size: .875em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap {
      padding-left: 3.5em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap {
      padding-right: 3.5em;
    }

    &.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap {
      padding-left: 5.5em;
    }

    &.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap {
      padding-right: 5.5em;
    }

    &.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value,
    &.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value {
      left: 2.25em;
      right: auto;
    }

    &.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value,
    &.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value {
      left: auto;
      right: 2.25em;
    }

    .mbsc-progress-primary .mbsc-progress-bar {
      background: $mbsc-mobiscroll-primary;
    }

    .mbsc-progress-secondary .mbsc-progress-bar {
      background: $mbsc-mobiscroll-secondary;
    }

    .mbsc-progress-success .mbsc-progress-bar {
      background: $mbsc-mobiscroll-success;
    }

    .mbsc-progress-danger .mbsc-progress-bar {
      background: $mbsc-mobiscroll-danger;
    }

    .mbsc-progress-warning .mbsc-progress-bar {
      background: $mbsc-mobiscroll-warning;
    }

    .mbsc-progress-info .mbsc-progress-bar {
      background: $mbsc-mobiscroll-info;
    }
  }

  @include mbsc-mobiscroll-progress(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}






@mixin mbsc-mobiscroll-slider($theme, $params) {
  @include exports("slider.#{$theme}.colors") {

    @include mbsc-mobiscroll-page($theme, $params);
    @include mbsc-mobiscroll-progress($theme, $params);

    $background: map-get($params, background);
    $accent: map-get($params, accent);

    $colors: mbsc-mobiscroll-colors($params);
    $tooltip: map-get($colors, tooltip);

    .mbsc-#{$theme} {
      .mbsc-slider-step {
        background: $background;
      }

      .mbsc-slider-handle {
        background: $accent;
      }

      .mbsc-slider-tooltip {
        color: $tooltip;
        background-color: $accent;
      }
    }
  }
}



@include exports("slider.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-slider .mbsc-input-wrap {
      padding: 0 .5em;
    }

    .mbsc-slider-handle {
      top: 50%;
      width: 1em;
      height: 1em;
      border-radius: 1.125em;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: -webkit-transform .2s ease-in-out;
      transition: transform .2s ease-in-out;
    }

    .mbsc-slider-handle:focus,
    .mbsc-active .mbsc-slider-handle {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }

    &.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
      opacity: .4;
    }

    &.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-handle {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    .mbsc-slider-tooltip {
      top: -1.91666em;
      width: 2.33333333em;
      height: 2.33333333em;
      border-radius: 2em;
      margin-right: -1.1666em;
      line-height: 2.33333333em;
      text-align: center;
      font-size: .75em;
      z-index: 5;
      -webkit-transform: scale(.4) translate3d(0, 5em, 0);
      transform: scale(.4) translate3d(0, 5em, 0);
      -webkit-transition: -webkit-transform .2s ease-in-out, opacity .2s ease-in-out;
      transition: transform .2s ease-in-out, opacity .2s ease-in-out;
    }

    .mbsc-slider-handle:focus ~ .mbsc-slider-tooltip,
    .mbsc-active .mbsc-slider-tooltip {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1);
      transform: translate3d(0, 0, 0) scale(1);
    }

    &.mbsc-slider-has-tooltip .mbsc-slider-handle:focus,
    &.mbsc-slider-has-tooltip .mbsc-active .mbsc-slider-handle {
      -webkit-transform: scale(.5);
      transform: scale(.5);
    }

    &.mbsc-slider-primary {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-primary;
      }
    }

    &.mbsc-slider-secondary {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-secondary;
      }
    }

    &.mbsc-slider-success {

      .mbsc-slider-tooltip,
      .mbsc-slider-handlem .mbsc-progress-bar {
        background: $mbsc-mobiscroll-success;
      }
    }

    &.mbsc-slider-danger {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-danger;
      }
    }

    &.mbsc-slider-warning {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-warning;
      }
    }

    &.mbsc-slider-info {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle,
      .mbsc-progress-bar {
        background: $mbsc-mobiscroll-info;
      }
    }
  }

  @include mbsc-mobiscroll-slider(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}






@mixin mbsc-mobiscroll-color($theme, $params) {
  @include exports("color.#{$theme}.colors") {

    @include mbsc-mobiscroll-frame($theme, $params);
    @include mbsc-mobiscroll-slider($theme, $params);

    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');
    // derived colors
    $input-icon: lighten($text, 20%);
    $color-input: darken($text, 15%);

    .mbsc-#{$theme} {

      .mbsc-color-active,
      &.mbsc-no-touch .mbsc-color-item-c:hover {
        box-shadow: inset 0 0 0 .1875em $accent;
      }

      &.mbsc-input .mbsc-color-input {
        border-bottom: 1px solid $input-icon;
        color: $color-input;
      }
    }
  }
}



@include exports("color.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-color-preview-item {
      border-radius: 1em;
    }

    .mbsc-color-clear-item,
    .mbsc-color-item {
      border-radius: 1.75em;
    }

    .mbsc-color-active,
    &.mbsc-no-touch .mbsc-color-item-c:hover {
      border-radius: 3em;
    }

    .mbsc-color-preview-cont {
      padding: .25em 0 0 .25em;
    }

    &.mbsc-input .mbsc-color-input {
      height: auto;
      min-height: 2.125em;
      padding: 0 0 1px 0;
      background: transparent;
      font-size: 1em;
    }

    &.mbsc-input .mbsc-color-input-item {
      border-radius: .125em;
    }

    &.mbsc-input-box.mbsc-label-stacked .mbsc-color-input:empty:not(:focus):before {
      margin-top: -.375em;
    }
  }

  @include mbsc-mobiscroll-color(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}









// Theme specific variables - inherited from global variables

// background
$mbsc-material-page-background: $mbsc-page-background-light !default;
$mbsc-material-dark-page-background: $mbsc-page-background-dark !default;
// text
$mbsc-material-page-text: $mbsc-page-text-light !default;
$mbsc-material-dark-page-text: $mbsc-page-text-dark !default;

// add variablest to color maps
$mbsc-material-colors: map-merge($mbsc-material-colors, (
  'page-background': $mbsc-material-page-background,
  'page-text': $mbsc-material-page-text,
));
$mbsc-material-dark-colors: map-merge($mbsc-material-dark-colors, (
  'page-background': $mbsc-material-dark-page-background,
  'page-text': $mbsc-material-dark-page-text,
));

@mixin mbsc-material-page($theme, $params) {
  @include exports("page.#{$theme}.colors") {
    @include mbsc-material-common($theme, $params);

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');

    // get custom params
    $text-param: map-get($params, 'page-text');
    $background-param: map-get($params, 'page-background');
    // overwrite params with custom variables
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), lighten($text, 7%)));

    .mbsc-#{$theme} {
      &.mbsc-page {
        background-color: $background;
        color: $text;
      }

      a {
        color: $accent;
      }
    }
  }
}



@include exports("page.material") {
  .mbsc-material {
    .mbsc-note {
      border-radius: .1875em;
    }

    /* Note */
    .mbsc-note,
    .mbsc-note-primary {
      color: darken($mbsc-material-primary, 30%);
      background-color: lighten($mbsc-material-primary, 15%);
    }

    .mbsc-note-secondary {
      color: darken($mbsc-material-secondary, 30%);
      background-color: lighten($mbsc-material-secondary, 15%);
    }

    .mbsc-note-success {
      color: darken($mbsc-material-success, 30%);
      background-color: lighten($mbsc-material-success, 15%);
    }

    .mbsc-note-danger {
      color: darken($mbsc-material-danger, 30%);
      background-color: lighten($mbsc-material-danger, 15%);
    }

    .mbsc-note-warning {
      color: darken($mbsc-material-warning, 30%);
      background-color: lighten($mbsc-material-warning, 15%);
    }

    .mbsc-note-info {
      color: darken($mbsc-material-info, 30%);
      background-color: lighten($mbsc-material-info, 15%);
    }

    .mbsc-note-light {
      color: darken($mbsc-material-light, 60%);
      background-color: lighten($mbsc-material-light, 10%);
    }

    .mbsc-note-dark {
      color: darken($mbsc-material-dark, 30%);
      background-color: lighten($mbsc-material-dark, 20%);
    }
  }

  @include mbsc-material-page(material, $mbsc-material-colors);
}






@mixin mbsc-material-progress($theme, $params) {
  @include exports("progress.#{$theme}.colors") {

    $accent: map-get($params, accent);

    $colors: mbsc-material-colors($params);
    $gray-background: map-get($colors, gray-background);

    .mbsc-#{$theme} {
      .mbsc-progress-track {
        background: $gray-background;
      }

      .mbsc-progress-bar {
        background: $accent;
      }
    }
  }
}



@include exports("progress.material") {
  .mbsc-material {
    &.mbsc-progress {
      padding: .75em 1em;
    }

    &.mbsc-progress .mbsc-input-wrap {
      padding: 0;
    }

    &.mbsc-progress .mbsc-input-ic {
      top: .3125em;
      margin: 0;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-input-wrap {
      padding-left: 2.25em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-input-wrap {
      padding-right: 2.25em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-left-ic,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-right-ic {
      right: auto;
      left: -0.375em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-right-ic,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-left-ic {
      left: auto;
      right: -0.375em;
    }

    &.mbsc-progress .mbsc-label {
      font-size: .75em;
    }

    .mbsc-progress-cont {
      padding: 1.25em 0;
    }

    .mbsc-progress-track {
      height: .125em;
    }

    .mbsc-progress-value {
      font-size: .75em;
      width: 4em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap {
      padding-right: 3.25em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap {
      padding-left: 3.25em;
    }

    &.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap {
      padding-left: 5.5em;
    }

    &.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap {
      padding-right: 5.5em;
    }

    &.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value,
    &.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value {
      left: 2.25em;
      right: auto;
    }

    &.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value,
    &.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value {
      left: auto;
      right: 2.25em;
    }

    /* Inline labels */
    .mbsc-label-inline.mbsc-progress.mbsc-control-w .mbsc-label {
      line-height: 2.625em;
    }

    /* Progress color presets */
    .mbsc-progress-primary .mbsc-progress-bar {
      background: $mbsc-material-primary;
    }

    .mbsc-progress-secondary .mbsc-progress-bar {
      background: $mbsc-material-secondary;
    }

    .mbsc-progress-success .mbsc-progress-bar {
      background: $mbsc-material-success;
    }

    .mbsc-progress-danger .mbsc-progress-bar {
      background: $mbsc-material-danger;
    }

    .mbsc-progress-warning .mbsc-progress-bar {
      background: $mbsc-material-warning;
    }

    .mbsc-progress-info .mbsc-progress-bar {
      background: $mbsc-material-info;
    }
  }

  @include mbsc-material-progress(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}






@mixin mbsc-material-slider($theme, $params) {
  @include exports("slider.#{$theme}.colors") {

    @include mbsc-material-page($theme, $params);
    @include mbsc-material-progress($theme, $params);

    $background: map-get($params, background);
    $accent: map-get($params, accent);

    $colors: mbsc-material-colors($params);
    $tooltip: map-get($colors, tooltip);
    $background-contrast: map-get($colors, background-contrast);
    $gray-background: map-get($colors, gray-background);

    .mbsc-#{$theme} {
      .mbsc-slider-step {
        background: $background-contrast;
      }

      .mbsc-slider-handle {
        border: 2px solid $accent;
        background: $accent;
      }

      .mbsc-slider-handle:before {
        background: rgba($background-contrast, .1);
      }

      .mbsc-slider-start .mbsc-slider-handle {
        border-color: $gray-background;
        background: $background;
      }

      .mbsc-slider-tooltip {
        color: $background;
        background-color: $accent;
      }

      .mbsc-slider-tooltip:before {
        border-top: 1em solid $accent;
      }

      &.mbsc-slider-has-tooltip .mbsc-slider-start .mbsc-slider-tooltip,
      &.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-bar {
        background: $gray-background;
      }

      &.mbsc-slider-has-tooltip .mbsc-slider-start .mbsc-slider-tooltip:before {
        border-top-color: $gray-background;
      }

      &.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-handle {
        background: $gray-background;
        border-color: $gray-background;
        box-shadow: 0 0 0 .3125em $background;
      }

      &.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-start .mbsc-slider-handle {
        border-color: $gray-background;
        background: $background;
      }
    }
  }
}



@include exports("slider.material") {
  .mbsc-material {
    &.mbsc-slider .mbsc-input-wrap {
      padding: 0 .5em;
    }

    .mbsc-slider-handle {
      top: 50%;
      width: .75em;
      height: .75em;
      margin: -.5em -.5em 0 0;
      border-radius: 1.125em;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: -webkit-transform .2s ease-in-out;
      transition: transform .2s ease-in-out;
    }

    .mbsc-slider-handle:focus,
    .mbsc-active .mbsc-slider-handle {
      -webkit-transform: scale(1.4);
      transform: scale(1.4);
    }

    .mbsc-slider-handle:before {
      content: '';
      position: absolute;
      z-index: -1;
      top: -.625em;
      left: -.625em;
      width: 2em;
      height: 2em;
      opacity: 0;
      border-radius: 2.875em;
      -webkit-transition: opacity .2s ease-in-out;
      transition: opacity .2s ease-in-out;
    }

    .mbsc-active .mbsc-slider-handle:before {
      opacity: 1;
    }

    .mbsc-slider-tooltip {
      margin-right: -1.16665em;
      top: -2.5em;
      width: 2.33333333em;
      height: 2.33333333em;
      border-radius: 2.33333333em;
      line-height: 2.33333333em;
      text-align: center;
      font-size: .75em;
      -webkit-transform: scale(.4) translate3d(0, 6em, 0);
      transform: scale(.4) translate3d(0, 6em, 0);
      -webkit-transition: -webkit-transform .2s ease-in-out, opacity .2s ease-in-out;
      transition: transform .2s ease-in-out, opacity .2s ease-in-out;
    }

    .mbsc-slider-tooltip:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-left: .8333em solid transparent;
      border-right: .8333em solid transparent;
      bottom: -.66666em;
      left: .3333em;
    }

    &.mbsc-slider-has-tooltip .mbsc-slider-handle:focus ~ .mbsc-slider-tooltip,
    &.mbsc-slider-has-tooltip .mbsc-active .mbsc-slider-tooltip {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0) scale(1);
      transform: translate3d(0, 0, 0) scale(1);
    }

    &.mbsc-slider-has-tooltip .mbsc-slider-handle:focus,
    &.mbsc-slider-has-tooltip .mbsc-active .mbsc-slider-handle {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    &.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
      opacity: .4;
    }

    &.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-handle {
      -webkit-transform: scale(.7);
      transform: scale(.7);
    }

    /* color presets */
    &.mbsc-slider-primary {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle {
        background: $mbsc-material-primary;
        border-color: $mbsc-material-primary;
      }

      .mbsc-slider-tooltip:before {
        border-top-color: $mbsc-material-primary;
      }

      .mbsc-progress-bar {
        background: $mbsc-material-primary;
      }
    }

    &.mbsc-slider-secondary {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle {
        background: $mbsc-material-secondary;
        border-color: $mbsc-material-secondary;
      }

      .mbsc-slider-tooltip:before {
        border-top-color: $mbsc-material-secondary;
      }

      .mbsc-progress-bar {
        background: $mbsc-material-secondary;
      }
    }

    &.mbsc-slider-success {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle {
        background: $mbsc-material-success;
        border-color: $mbsc-material-success;
      }

      .mbsc-slider-tooltip:before {
        border-top-color: $mbsc-material-success;
      }

      .mbsc-progress-bar {
        background: $mbsc-material-success;
      }
    }

    &.mbsc-slider-danger {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle {
        background: $mbsc-material-danger;
        border-color: $mbsc-material-danger;
      }

      .mbsc-slider-tooltip:before {
        border-top-color: $mbsc-material-danger;
      }

      .mbsc-progress-bar {
        background: $mbsc-material-danger;
      }
    }

    &.mbsc-slider-warning {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle {
        background: $mbsc-material-warning;
        border-color: $mbsc-material-warning;
      }

      .mbsc-slider-tooltip:before {
        border-top-color: $mbsc-material-warning;
      }

      .mbsc-progress-bar {
        background: $mbsc-material-warning;
      }
    }

    &.mbsc-slider-info {

      .mbsc-slider-tooltip,
      .mbsc-slider-handle {
        background: $mbsc-material-info;
        border-color: $mbsc-material-info;
      }

      .mbsc-slider-tooltip:before {
        border-top-color: $mbsc-material-info;
      }

      .mbsc-progress-bar {
        background: $mbsc-material-info;
      }
    }
  }

  @include mbsc-material-slider(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}






@mixin mbsc-material-color($theme, $params) {
  @include exports("color.#{$theme}.colors") {

    @include mbsc-material-frame($theme, $params);
    @include mbsc-material-slider($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $border: lighten($text, 7%);

    $input-text: darken($text, 24%);

    .mbsc-#{$theme} {
      .mbsc-color-item:before {
        color: $background;
      }

      &.mbsc-input .mbsc-color-input {
        background-color: transparent;
        border-bottom: 1px solid $border;
        color: $input-text;
      }
    }
  }
}



@include exports("color.material") {
  .mbsc-material {

    &.mbsc-color.mbsc-fr-bubble .mbsc-fr-w,
    &.mbsc-color.mbsc-fr-center .mbsc-fr-w {
      max-width: 248px;
      min-width: 246px;
    }

    &.mbsc-color .mbsc-fr-c {
      padding: .5em;
    }

    .mbsc-color-clear-item,
    .mbsc-color-item {
      margin: 0;
      border: 0;
      box-shadow: none;
      overflow: hidden;
    }

    .mbsc-color-clear-cross {
      height: 140%;
      top: -11px;
      left: 50%;
    }

    .mbsc-color-active {
      border-radius: 0;
      box-shadow: none;
    }

    .mbsc-color-row {
      padding: 0;
    }

    .mbsc-color-item-c {
      padding: 0;
      border: 1px solid transparent;
    }

    .mbsc-color-preview-item {
      margin: .25em .125em;
    }

    &.mbsc-color .mbsc-color-slider-cont .mbsc-slider-handle-cont {
      top: .15625em;
    }

    &.mbsc-input .mbsc-color-input {
      height: auto;
      min-height: 2.125em;
      padding: 0 0 1px 0;
      font-size: 1em;
    }

    &.mbsc-input-box.mbsc-label-stacked .mbsc-color-input:empty:not(:focus):before {
      margin-top: -.375em;
    }
  }

  @include mbsc-material-color(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}









// Theme specific variables - inherited from global variables

// background
$mbsc-windows-page-background: $mbsc-page-background-light !default;
$mbsc-windows-dark-page-background: $mbsc-page-background-dark !default;

// text
$mbsc-windows-page-text: $mbsc-page-text-light !default;
$mbsc-windows-dark-page-text: $mbsc-page-text-dark !default;

// add variables to color maps
$mbsc-windows-colors: map-merge($mbsc-windows-colors, (
  'page-background': $mbsc-windows-page-background,
  'page-text': $mbsc-windows-page-text,
));
$mbsc-windows-dark-colors: map-merge($mbsc-windows-dark-colors, (
  'page-background': $mbsc-windows-dark-page-background,
  'page-text': $mbsc-windows-dark-page-text,
));

@mixin mbsc-windows-page($theme, $params) {
  @include exports("page.#{$theme}.colors") {
    @include mbsc-windows-common($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    @if (lightness($background) > 50%) {
      $background: lighten($background, 13%);
    }
    @else {
      $background: darken($background, 12%);
    }


    // get custom params
    $text-param: map-get($params, 'page-text');
    $background-param: map-get($params, 'page-background');
    // overwrite params with custom variables
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));

    .mbsc-#{$theme} {
      &.mbsc-page {
        background-color: $background;
        color: $text;
      }

      a {
        color: $accent;
      }
    }
  }
}



@include exports("page.windows") {
  .mbsc-windows {

    /* Note */
    .mbsc-note,
    .mbsc-note-primary {
      color: darken($mbsc-windows-primary, 30%);
      background-color: lighten($mbsc-windows-primary, 10%);
    }

    .mbsc-note-secondary {
      color: darken($mbsc-windows-secondary, 30%);
      background-color: lighten($mbsc-windows-secondary, 20%);
    }

    .mbsc-note-success {
      color: darken($mbsc-windows-success, 30%);
      background-color: lighten($mbsc-windows-success, 20%);
    }

    .mbsc-note-danger {
      color: darken($mbsc-windows-danger, 40%);
      background-color: lighten($mbsc-windows-danger, 10%);
    }

    .mbsc-note-warning {
      color: darken($mbsc-windows-warning, 35%);
      background-color: lighten($mbsc-windows-warning, 5%);
    }

    .mbsc-note-info {
      color: darken($mbsc-windows-info, 30%);
      background-color: lighten($mbsc-windows-info, 20%);
    }

    .mbsc-note-light {
      color: darken($mbsc-windows-light, 70%);
      background-color: lighten($mbsc-windows-light, 10%);
    }

    .mbsc-note-dark {
      color: darken($mbsc-windows-dark, 30%);
      background-color: lighten($mbsc-windows-dark, 10%);
    }
  }

  @include mbsc-windows-page(windows, $mbsc-windows-colors);
}






@mixin mbsc-windows-progress($theme, $params) {
  @include exports("progress.#{$theme}.colors") {

    $text: map-get($params, text);
    $accent: map-get($params, accent);

    $colors: mbsc-windows-colors($params);
    $gray-background: map-get($colors, gray-background);
    $progress-background: map-get($colors, progress-background);

    .mbsc-#{$theme} {

      &.mbsc-progress .mbsc-label,
      &.mbsc-progress .mbsc-input-ic {
        color: $text;
      }

      .mbsc-progress-track {
        background: $progress-background;
      }

      .mbsc-progress-bar {
        background: $accent;
      }
    }
  }
}



@include exports("progress.windows") {
  .mbsc-windows {
    &.mbsc-progress {
      padding: .75em 1em;
    }

    &.mbsc-progress .mbsc-label {
      padding-bottom: .3125em;
      font-size: 1em;
    }

    &.mbsc-progress .mbsc-input-wrap {
      padding: 0;
    }

    &.mbsc-progress .mbsc-input-ic {
      top: -.125em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-input-wrap {
      padding-left: 2.25em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-input-wrap {
      padding-right: 2.25em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-left-ic,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-right-ic {
      right: auto;
      left: -0.375em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-right-ic,
    &.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-left-ic {
      left: auto;
      right: -0.375em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap {
      padding-right: 3.75em;
    }

    &.mbsc-ltr.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap {
      padding-left: 3.75em;
    }

    &.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap {
      padding-left: 5.75em;
    }

    &.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap,
    &.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap {
      padding-right: 5.75em;
    }

    &.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value,
    &.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value {
      left: 1.875em;
      right: auto;
    }

    &.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value,
    &.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value {
      left: auto;
      right: 1.875em;
    }

    .mbsc-progress-cont {
      padding: .75em 0;
    }

    .mbsc-progress-track {
      height: .25em;
    }

    .mbsc-progress-value {
      margin-top: -.4375em;
      width: 3.5em;
    }

    .mbsc-progress-step-label {
      top: auto;
      bottom: -2em;
      line-height: normal;
    }

    /* Inline labels */
    .mbsc-label-inline.mbsc-progress.mbsc-control-w .mbsc-label {
      line-height: 1.875em;
      padding-bottom: 0;
    }

    /* Progress color presets */
    .mbsc-progress-primary .mbsc-progress-bar {
      background: $mbsc-windows-primary;
    }

    .mbsc-progress-secondary .mbsc-progress-bar {
      background: $mbsc-windows-secondary;
    }

    .mbsc-progress-success .mbsc-progress-bar {
      background: $mbsc-windows-success;
    }

    .mbsc-progress-danger .mbsc-progress-bar {
      background: $mbsc-windows-danger;
    }

    .mbsc-progress-warning .mbsc-progress-bar {
      background: $mbsc-windows-warning;
    }

    .mbsc-progress-info .mbsc-progress-bar {
      background: $mbsc-windows-info;
    }
  }

  @include mbsc-windows-progress(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}






@mixin mbsc-windows-slider($theme, $params) {
  @include exports("slider.#{$theme}.colors") {

    @include mbsc-windows-page($theme, $params);
    @include mbsc-windows-progress($theme, $params);

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');

    $colors: mbsc-windows-colors($params);
    $input-border: map-get($colors, 'input-border');
    $frame-border: map-get($colors, 'frame-border');

    $form-background: '';
    $input-hover: '';
    @if (lightness($background) > 50%) {
      $form-background: lighten($background, 13%);
      $input-hover: darken($background, 55%);
    }
    @else {
      $form-background: darken($background, 12%);
      $input-hover: lighten($background, 55%);
    }

    .mbsc-#{$theme} {
      &.mbsc-slider .mbsc-progress-track {
        background: $input-border;
        border-top: 2px solid $background;
        border-bottom: 2px solid $background;
      }

      &.mbsc-slider .mbsc-progress-track {
        border-top-color: $form-background;
        border-bottom-color: $form-background;
      }

      &.mbsc-slider:hover .mbsc-progress-track {
        background: $input-hover;

        .mbsc-slider-handle {
          background: $text;
        }
      }

      &.mbsc-slider .mbsc-active~.mbsc-progress-cont .mbsc-progress-track {
        background: $input-border;

        .mbsc-slider-handle {
          background: $frame-border;
        }
      }

      .mbsc-slider-handle {
        background: $accent;
      }

      .mbsc-slider-tooltip {
        border: 1px solid $frame-border;
        background: $background;
        color: $text;
      }

      .mbsc-slider-step {
        background: $input-border;
      }
    }
  }
}


@include exports("slider.windows") {
  .mbsc-windows {
    &.mbsc-slider .mbsc-input-wrap {
      padding: 0 .3125em;
    }

    &.mbsc-slider .mbsc-input-ic {
      top: -.0625em;
    }

    &.mbsc-slider .mbsc-progress-track {
      height: 6px;
    }

    .mbsc-slider-handle {
      top: 50%;
      width: .5em;
      border-radius: 6px;
      height: 1.5em;
      margin: -0.75em -0.25em 0 0;
    }

    .mbsc-slider-tooltip {
      height: 1.875em;
      padding: 0 .75em;
      top: -2.75em;
      line-height: 1.875em;
      text-align: center;
      transition: opacity .2s ease-in-out;
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
    }

    .mbsc-slider-handle:focus ~ .mbsc-slider-tooltip,
    .mbsc-active .mbsc-slider-tooltip {
      opacity: 1;
    }

    .mbsc-slider-step {
      height: 16px;
      width: 1px;
      top: -7px;
      z-index: -1;
    }

    /* fix for first tick */
    .mbsc-progress-bar + .mbsc-slider-step {
      margin: 0;
    }

    .mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
      opacity: .4;
    }

    /* Slider color presets */
    &.mbsc-slider-primary .mbsc-progress-bar {
      background: $mbsc-windows-primary;
    }

    &.mbsc-slider-secondary .mbsc-progress-bar {
      background: $mbsc-windows-secondary;
    }

    &.mbsc-slider-success .mbsc-progress-bar {
      background: $mbsc-windows-success;
    }

    &.mbsc-slider-danger .mbsc-progress-bar {
      background: $mbsc-windows-danger;
    }

    &.mbsc-slider-warning .mbsc-progress-bar {
      background: $mbsc-windows-warning;
    }

    &.mbsc-slider-info .mbsc-progress-bar {
      background: $mbsc-windows-info;
    }
  }

  @include mbsc-windows-slider(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}






@mixin mbsc-windows-color($theme, $params) {
  @include exports("color.#{$theme}.colors") {

    @include mbsc-windows-frame($theme, $params);
    @include mbsc-windows-slider($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $colors: mbsc-windows-colors($params);
    $color-item: map-get($colors, 'color-item');
    $color-preview-border: map-get($colors, 'color-preview-border');
    $input-border: map-get($colors, 'input-border');

    $border-color: '';
    @if(lightness($background) > 50%) {
      $border-color: darken($background, 19%);
    }
    @else {
      $border-color: lighten($background, 19%);
    }

    .mbsc-#{$theme} {

      .mbsc-color-active,
      &.mbsc-no-touch .mbsc-color-item-c:hover {
        box-shadow: inset 0 0 0 .1875em $accent;
      }

      .mbsc-color-clear-item {
        background: $color-item;
      }

      &.mbsc-color .mbsc-color-slider-cont .mbsc-progress-track {
        background: $border-color;
      }

      .mbsc-color-preview-item-empty,
      .mbsc-color-preview-cont {
        border-color: $color-preview-border;
      }

      &.mbsc-input .mbsc-color-input {
        border: .125em solid $input-border;
        color: $text;
      }
    }
  }
}



@include exports("color.windows") {
  .mbsc-windows {
    &.mbsc-color .mbsc-fr-w {
      min-width: 230px;
    }

    .mbsc-color-cont {
      padding: .25em 0;
    }

    .mbsc-color-active,
    &.mbsc-no-touch .mbsc-color-item-c:hover {
      border-radius: 3em;
    }

    .mbsc-color-clear-item,
    .mbsc-color-item {
      border-radius: 1.75em;
    }

    .mbsc-color-preview-item {
      border-radius: 1em;
      margin: .25em;
    }

    .mbsc-color-input:empty:not(:focus):before {
      padding: 0 .3125em;
    }

    &.mbsc-input {
      .mbsc-color-input {
        height: auto;
        min-height: 2em;
        padding: 0;
        font-size: 1em;
        background: transparent;
      }

      .mbsc-color-input-item {
        margin: .125em;
      }
    }
  }

  @include mbsc-windows-color(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}



// range component






@mixin mbsc-ios-range($theme, $params) {
  @include exports("range.#{$theme}.colors") {

    @include mbsc-ios-calbase($theme, $params);

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $accent: if($accent-param, $accent-param, $accent);

    $button: '';
    @if (map-get($params, 'button')) {
      $button: map-get($params, 'button');
    }
    @else {
      $button: $accent;
    }

    $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
    $border-color: '';
    @if (lightness($background) > 50%) {
      $border-color: darken($background-limited, 17%);
    }
    @else {
      $border-color: lighten($background, 20%);
    }

    .mbsc-#{$theme} {
      .mbsc-range-btn-end {
        border-bottom: 1px solid $border-color;
      }

      .mbsc-range-btn-end .mbsc-range-btn:before {
        border-top: 1px solid $border-color;
      }

      .mbsc-range-btn.mbsc-selected .mbsc-range-btn-v {
        color: $button;
      }

      &.mbsc-range .mbsc-cal .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date,
      &.mbsc-range .mbsc-cal-day.mbsc-selected:after {
        background: $button;
      }
    }
  }
}




@include exports("range") {

  .mbsc-range-btn,
  .mbsc-range-btn-t,
  .mbsc-range-btn-c {
    box-sizing: border-box;
  }

  .mbsc-range-btn-t {
    display: table;
    width: 100%;
  }

  .mbsc-range-btn-c {
    display: table-cell;
    width: 50%;
  }

  .mbsc-range-btn {
    text-decoration: none;
    text-align: center;
    display: block;
    cursor: pointer;
  }

  .mbsc-cal-hide-diff .mbsc-cal-day-diff:after {
    display: none;
  }
}


@include exports("range.ios") {
  .mbsc-ios {

    .mbsc-range-btn-t,
    .mbsc-range-btn-c {
      width: auto;
      display: block;
    }

    .mbsc-range-btn {
      position: relative;
      min-height: 2.75em;
      padding: .75em 1em;
      font-size: 1.333333em;
    }

    .mbsc-range-btn-v {
      position: absolute;
      top: .75em;
      font-weight: normal;
    }

    .mbsc-ltr .mbsc-range-btn {
      text-align: left;
    }

    .mbsc-ltr .mbsc-range-btn-v {
      right: 1em;
    }

    .mbsc-rtl .mbsc-range-btn {
      text-align: right;
    }

    .mbsc-rtl .mbsc-range-btn-v {
      left: 1em;
      right: auto;
    }

    .mbsc-range-btn-end .mbsc-range-btn:before {
      content: '';
      position: absolute;
      right: 0;
      left: 1em;
      top: 0;
    }

    &.mbsc-range {
      .mbsc-cal .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
        background: none;
      }


      .mbsc-cal-day.mbsc-selected:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: .3125em;
        left: 0;
        right: 0;
        height: 1.875em;
      }

      .mbsc-cal-has-marks .mbsc-cal-day.mbsc-selected:after {
        top: .1875em;
      }

      .mbsc-ltr .mbsc-cal-day:first-child:after,
      .mbsc-rtl .mbsc-cal-day:last-child:after {
        left: .25em;
      }

      .mbsc-ltr .mbsc-cal-day:last-child:after,
      .mbsc-rtl .mbsc-cal-day:first-child:after {
        right: .25em;
      }

      .mbsc-ltr .mbsc-cal-day.mbsc-cal-sel-start:after,
      .mbsc-rtl .mbsc-cal-day.mbsc-cal-sel-end:after {
        left: 50%;
        margin-left: -.9375em;
      }

      .mbsc-ltr .mbsc-cal-day.mbsc-cal-sel-end:after,
      .mbsc-rtl .mbsc-cal-day.mbsc-cal-sel-start:after {
        right: 50%;
        margin-right: -.9375em;
      }

      .mbsc-ltr .mbsc-cal-sel-start:after,
      .mbsc-ltr .mbsc-cal-day:first-child:after,
      .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-first:after,
      .mbsc-rtl .mbsc-cal-sel-end:after,
      .mbsc-rtl .mbsc-cal-day:last-child:after,
      .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-last:after {
        border-top-left-radius: 2em;
        border-bottom-left-radius: 2em;
      }

      .mbsc-ltr .mbsc-cal-sel-end:after,
      .mbsc-ltr .mbsc-cal-day:last-child:after,
      .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-last:after,
      .mbsc-rtl .mbsc-cal-sel-start:after,
      .mbsc-rtl .mbsc-cal-day:first-child:after,
      .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-first:after {
        border-top-right-radius: 2em;
        border-bottom-right-radius: 2em;
      }
    }
  }

  @include mbsc-ios-range(ios, $mbsc-ios-colors);
}




/* Range */
.mbsc-bootstrap {
  .mbsc-range-btn-t {
    padding: 4px 2px 0 2px;
  }

  .mbsc-range-btn {
    margin: 0 2px;
    line-height: 20px;
  }

  &.mbsc-range .mbsc-cal-day-hl .mbsc-cal-day-i {
    background: rgba(0, 0, 0, .3);
  }
}







@mixin mbsc-mobiscroll-range($theme, $params) {
  @include exports("range.#{$theme}.colors") {

    @include mbsc-mobiscroll-calbase($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    .mbsc-#{$theme} {
      .mbsc-range-btn {
        border: 1px solid $accent;
      }

      &.mbsc-range {
        .mbsc-range-btn-t .mbsc-range-btn.mbsc-selected {
          background: $accent;
          color: $background;
        }

        .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
          background: none;
          border-color: transparent;
          color: $text;
        }

        .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date {
          background: $accent;
          border-color: $accent;
          color: $background;
        }

        .mbsc-cal-day.mbsc-selected:after {
          background: rgba($accent, .3);
        }
      }
    }
  }
}



@include exports("range.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-range-btn-t {
      padding: .5em .25em 0 .25em;
    }

    .mbsc-range-btn {
      margin: 0 .3333em;
      padding: .3333em;
      font-size: .75em;
      line-height: 1.666667em;
    }

    &.mbsc-range {
      .mbsc-fr-w {
        padding: 0;
      }

      .mbsc-fr-hdr {
        padding-top: .6666em;
      }

      .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
        background: none;
        border-color: transparent;
      }

      .mbsc-cal-day.mbsc-selected:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: .25em;
        left: 0;
        right: 0;
        height: 2em;
      }

      .mbsc-ltr .mbsc-cal-sel-start:after,
      .mbsc-rtl .mbsc-cal-sel-end:after {
        left: 50%;
        margin-left: -1em;
      }

      .mbsc-ltr .mbsc-cal-sel-end:after,
      .mbsc-rtl .mbsc-cal-sel-start:after {
        right: 50%;
        margin-right: -1em;
      }

      .mbsc-ltr .mbsc-cal-sel-start:after,
      .mbsc-ltr .mbsc-cal-day:first-child:after,
      .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-first:after,
      .mbsc-rtl .mbsc-cal-sel-end:after,
      .mbsc-rtl .mbsc-cal-day:last-child:after,
      .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-last:after {
        border-top-left-radius: 2em;
        border-bottom-left-radius: 2em;
      }

      .mbsc-ltr .mbsc-cal-sel-end:after,
      .mbsc-ltr .mbsc-cal-day:last-child:after,
      .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-last:after,
      .mbsc-rtl .mbsc-cal-sel-start:after,
      .mbsc-rtl .mbsc-cal-day:first-child:after,
      .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-first:after {
        border-top-right-radius: 2em;
        border-bottom-right-radius: 2em;
      }
    }
  }

  @include mbsc-mobiscroll-range(mobiscroll, $mbsc-mobiscroll-colors);
}







@mixin mbsc-material-range($theme, $params) {
  @include exports("range.#{$theme}.colors") {

    @include mbsc-material-calbase($theme, $params);

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    $border: '';
    @if (lightness($background) > 50%) {
      $border: lighten($text, 40%);
    }
    @else {
      $border: darken($text, 40%);
    }

    .mbsc-#{$theme} {
      .mbsc-range-btn-t {
        border-bottom: 1px solid $border;
      }

      .mbsc-range-btn {
        &.mbsc-selected {
          border-color: $accent;
          color: $accent;
        }
      }

      &.mbsc-range {
        .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
          background: none;
          border-color: transparent;
        }

        .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date {
          background: $accent;
          border-color: $accent;
          color: $background;
        }

        .mbsc-cal-day.mbsc-selected:after {
          background: rgba($accent, .1);
        }
      }
    }
  }
}



@include exports("range.material") {
  .mbsc-material {
    .mbsc-range-btn {
      padding: .571428em .285714em;
      border-bottom: 2px solid transparent;
      margin-bottom: -1px;
      font-size: .875em;
      line-height: 1.428571em;
    }

    &.mbsc-range {
      .mbsc-cal-day.mbsc-selected:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: .125em;
        left: 0;
        right: 0;
        height: 2.25em;
      }

      .mbsc-ltr .mbsc-cal-sel-start:after,
      .mbsc-rtl .mbsc-cal-sel-end:after {
        left: 50%;
        margin-left: -1.125em;
      }

      .mbsc-ltr .mbsc-cal-sel-end:after,
      .mbsc-rtl .mbsc-cal-sel-start:after {
        right: 50%;
        margin-right: -1.125em;
      }

      .mbsc-ltr .mbsc-cal-sel-start:after,
      .mbsc-ltr .mbsc-cal-day:first-child:after,
      .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-first:after,
      .mbsc-rtl .mbsc-cal-sel-end:after,
      .mbsc-rtl .mbsc-cal-day:last-child:after,
      .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-last:after {
        border-top-left-radius: 2em;
        border-bottom-left-radius: 2em;
      }

      .mbsc-ltr .mbsc-cal-sel-end:after,
      .mbsc-ltr .mbsc-cal-day:last-child:after,
      .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-last:after,
      .mbsc-rtl .mbsc-cal-sel-start:after,
      .mbsc-rtl .mbsc-cal-day:first-child:after,
      .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-first:after {
        border-top-right-radius: 2em;
        border-bottom-right-radius: 2em;
      }
    }
  }

  @include mbsc-material-range(material, $mbsc-material-colors);
}







@mixin mbsc-windows-range($theme, $params) {
  @include exports("range.#{$theme}.colors") {

    @include mbsc-windows-calbase($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    $frame-border: '';
    @if (lightness($background) > 50%) {
      $frame-border: darken($background, 15%);
    }
    @else {
      $frame-border: lighten($background, 15%);
    }

    .mbsc-#{$theme} {
      .mbsc-range-btn {
        color: $text;
        background: $frame-border;
        border: 2px solid $frame-border;
      }

      &.mbsc-no-touch .mbsc-range-btn.mbsc-fr-btn-e:not(.mbsc-disabled):hover {
        border-color: darken($frame-border, 30%);
        background: $frame-border;
      }

      .mbsc-range-btn.mbsc-selected,
      &.mbsc-no-touch .mbsc-range-btn.mbsc-selected:not(.mbsc-disabled):hover {
        background: $accent;
        border-color: $accent;
        color: get-contrast-color($accent);
      }

      &.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-i,
      &.mbsc-range .mbsc-cal .mbsc-cal-today.mbsc-cal-day-hl .mbsc-cal-day-date {
        // Overrides hover as well
        background: $accent;
        color: $background;
      }
    }
  }
}



@include exports("range.windows") {
  .mbsc-windows {
    .mbsc-range-btn-t {
      padding: .5em .25em;
    }

    .mbsc-range-btn {
      margin: 0 .3333em;
      font-size: .75em;
      line-height: 1.666667em;
      border-width: 1px;
    }
  }

  @include mbsc-windows-range(windows, $mbsc-windows-colors);
}



// scroller component







// listview component






// Theme specific variables - inherited from global variables

// background
$mbsc-ios-listview-background: $mbsc-listview-background-light !default;
$mbsc-ios-dark-listview-background: $mbsc-listview-background-dark !default;
// text
$mbsc-ios-listview-text: $mbsc-listview-text-light !default;
$mbsc-ios-dark-listview-text: $mbsc-listview-text-dark !default;
// accent
$mbsc-ios-listview-accent: $mbsc-listview-accent-light !default;
$mbsc-ios-dark-listview-accent: $mbsc-listview-accent-dark !default;
// header background
$mbsc-ios-listview-header-background: $mbsc-listview-header-background-light !default;
$mbsc-ios-dark-listview-header-background: $mbsc-listview-header-background-dark !default;
// header text
$mbsc-ios-listview-header-text: $mbsc-listview-header-text-light !default;
$mbsc-ios-dark-listview-header-text: $mbsc-listview-header-text-dark !default;

// add variables to the color maps
$mbsc-ios-colors: map-merge($mbsc-ios-colors, (
  'listview-background': $mbsc-ios-listview-background,
  'listview-text': $mbsc-ios-listview-text,
  'listview-accent': $mbsc-ios-listview-accent,
  'listview-header-background': $mbsc-ios-listview-header-background,
  'listview-header-text': $mbsc-ios-listview-header-text,
));
$mbsc-ios-dark-colors: map-merge($mbsc-ios-dark-colors, (
  'listview-background': $mbsc-ios-dark-listview-background,
  'listview-text': $mbsc-ios-dark-listview-text,
  'listview-accent': $mbsc-ios-dark-listview-accent,
  'listview-header-background': $mbsc-ios-dark-listview-header-background,
  'listview-header-text': $mbsc-ios-dark-listview-header-text,
));

@mixin mbsc-ios-listview-base($theme, $params) {
  @include exports("listview-base.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');

    // get custom variables
    $background-param: map-get($params, 'listview-background');
    $text-param: map-get($params, 'listview-text');
    $header-background-param: map-get($params, 'listview-header-background');
    $header-text-param: map-get($params, 'listview-header-text');


    // overwrite variables with custom ones when provided
    $li-background: '';
    @if($background-param) {
      $li-background: $background-param;
      $background: darken($background-param, 6%);
    }
    @else {
      $li-background: lighten($background, 6%);
    }
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));

    $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
    $header-background: '';
    $title-color: '';
    $border-color: '';
    $background-contrast: '';
    @if (lightness($background) > 50%) {
      $header-background: adjust-hue(darken(saturate($background, 19%), 2%), calc(240% / 1%));
      $title-color: adjust-hue(lighten(saturate($text, 2%), 44%), calc(240% / 1%));
      $border-color: darken($background-limited, 17%);
      $background-contrast: #000;
    }
    @else {
      $header-background: adjust-hue(lighten(desaturate($background, 19%), 10%), calc(240% / 1%));
      $title-color: adjust-hue(darken(desaturate($text, 2%), 44%), calc(240% / 1%));
      $border-color: lighten($background, 20%);
      $background-contrast: #fff;
    }

    $header-background: if($header-background-param, $header-background-param, $header-background);
    $header-text: if($header-text-param, $header-text-param, $title-color);

    .mbsc-#{$theme} {
      .mbsc-lv {
        background: $border-color;
      }

      .mbsc-lv-item {
        background: $li-background;
        border-color: $border-color;
        color: $text;
      }

      .mbsc-lv-item:before,
      .mbsc-lv-item:after {
        border-top: 1px solid $border-color;
      }

      .mbsc-lv-gr-title {
        background: $header-background;
        color: $header-text;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
      }

      &.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
      .mbsc-lv-item-actionable.mbsc-focus:before {
        background: rgba($background-contrast, .05);
      }

      .mbsc-lv-item-actionable.mbsc-lv-item-active:before {
        background: rgba(0, 0, 0, .15);
      }
    }
  }
}



@include exports("listview-base") {
  .mbsc-lv-cont {
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -ms-touch-action: pan-y;
    user-select: none;
    touch-action: pan-y;
    font-size: 16px;
    font-family: arial, verdana, sans-serif;
    padding-top: 1px;
    /* Needed by iPad OS 13, if list is inside a scrollable container */
    will-change: opacity;
  }

  /* List */

  .mbsc-lv-cont .mbsc-lv {
    list-style: none;
    line-height: normal;
    margin: 0;
    padding: 0;
  }

  .mbsc-lv {
    display: none;
    position: relative;
  }

  .mbsc-lv-v {
    display: block;
  }

  /* Group title */

  .mbsc-lv-gr-title {
    position: relative;
    z-index: 3;
    display: block;
  }

  /* Items */

  .mbsc-lv .mbsc-lv-item {
    position: relative;
    z-index: 2;
    list-style: none;
    display: block;
    box-sizing: border-box;
  }

  .mbsc-lv-item-actionable {
    cursor: pointer;
    outline: 0;
  }

  .mbsc-lv .mbsc-lv-item.mbsc-input {
    margin: -1px 0 0 0;
  }

  .mbsc-lv-item-actionable:before,
  .mbsc-lv-item.mbsc-selected:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transition: background-color .2s ease-out;
  }
}


@include exports("listview-base.ios") {
  .mbsc-ios {
    &.mbsc-lv-cont {
      margin-top: -1px;
    }

    .mbsc-lv-item {
      margin-top: -1px;
      padding: 1em;
      font-weight: normal;
      text-shadow: none;
    }

    .mbsc-lv-item:before,
    .mbsc-lv-item:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
    }

    &.mbsc-ltr .mbsc-lv-item:before,
    &.mbsc-ltr .mbsc-lv-item:after {
      left: 1em;
    }

    &.mbsc-rtl .mbsc-lv-item:before,
    &.mbsc-rtl .mbsc-lv-item:after {
      right: 1em;
    }

    .mbsc-lv-item:before {
      top: 0;
    }

    .mbsc-lv-item:after {
      bottom: 0;
    }

    .mbsc-lv-gr-title {
      z-index: 4;
      margin-top: -1px;
      padding: 2.333334em 1.333334em .5em 1.333334em;
      font-size: .75em;
      line-height: 1.5em;
      text-transform: uppercase;
    }

    .mbsc-lv-gr-title:first-child {
      margin-top: 0;
      border-top: 0;
    }

    .mbsc-lv-item-actionable:before {
      transition: none;
    }

    &.mbsc-no-touch .mbsc-lv-item-actionable:hover,
    .mbsc-lv-item-actionable.mbsc-focus,
    .mbsc-lv-item.mbsc-selected {
      z-index: 3;
    }

    &.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
    &.mbsc-no-touch .mbsc-lv-item-actionable:hover:after,
    .mbsc-lv-item-actionable.mbsc-focus:before,
    .mbsc-lv-item-actionable.mbsc-focus:after,
    .mbsc-lv .mbsc-lv-item-actionable.mbsc-lv-item-active:before,
    .mbsc-lv .mbsc-lv-item-actionable.mbsc-lv-item-active:after,
    .mbsc-lv .mbsc-lv-item.mbsc-selected:before,
    .mbsc-lv .mbsc-lv-item.mbsc-selected:after,
    .mbsc-lv .mbsc-lv-item:first-child:before,
    .mbsc-lv .mbsc-lv-item:last-child:after,
    .mbsc-lv .mbsc-lv-item.mbsc-lv-back:after {
      left: 0;
      right: 0;
    }

  }

  @include mbsc-ios-listview-base(ios, $mbsc-ios-colors);
}





@mixin mbsc-ios-listview($theme, $params) {
  @include exports("listview.#{$theme}.colors") {

    @include mbsc-ios-listview-base($theme, $params);
    @include mbsc-ios-common($theme, $params);

    $text: map-get($params, 'text');
    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    
    // get custom variables
    $background-param: map-get($params, 'listview-background');
    $text-param: map-get($params, 'listview-text');
    $accent-param: map-get($params, 'listview-accent');
    // overwrite variables with custom ones when provided
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    // derived parameters
    $lv-handle: '';
    $lv-item: '';
    $lv-icon: $text;
    $item-active: '';
    $item-selected: '';
    @if (lightness($background) > 50%) {
      $lv-handle: darken($background, 17%);
      $lv-item: darken($background, 1%);
      $lv-icon: lighten($text, 47%);
      $item-active: darken($background, 12%);
      $item-selected: rgba($accent, .1);
    }
    @else {
      $lv-handle: lighten($background, 43%);
      $lv-item: lighten($background, 13%);
      $item-active: lighten($background, 12%);
      $item-selected: rgba($accent, .3);
    }

    .mbsc-#{$theme} {
      &.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
        background: $lv-item;
      }

      .mbsc-lv-item-hl,
      .mbsc-lv .mbsc-lv-item.mbsc-lv-item-active {
        background: $item-active;
      }

      .mbsc-lv-handle-bar {
        background: $lv-handle;
      }

      .mbsc-lv-arr {
        color: $lv-handle;
      }

      &.mbsc-lv-has-fixed-header .mbsc-lv-gr-title,
      &.mbsc-lv-fixed-header .mbsc-lv-gr-title {
        color: $text;
      }

      .mbsc-lv-ic-m {
        color: $lv-icon;
      }

      /* Item borders */
      .mbsc-lv-loading .mbsc-ic {
        color: $text;
      }

      &.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
        background: $item-selected;
      }
    }
  }
}






@include exports("listview") {
  /* Animations */

  @-webkit-keyframes mbsc-lv-spin {
    from {
      -webkit-transform: rotate(180deg);
      opacity: 0;
    }

    to {
      -webkit-transform: rotate(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes mbsc-lv-remove-right {
    from {}

    /* Needed by Android 2.3 */
    to {
      -webkit-transform: translateX(100%);
    }
  }

  @-webkit-keyframes mbsc-lv-remove-left {
    from {}

    /* Needed by Android 2.3 */
    to {
      -webkit-transform: translateX(-100%);
    }
  }

  @-webkit-keyframes mbsc-lv-add-right {
    from {
      -webkit-transform: translateX(100%);
    }

    to {
      -webkit-transform: translateX(0);
    }
  }

  @-webkit-keyframes mbsc-lv-add-left {
    from {
      -webkit-transform: translateX(-100%);
    }

    to {
      -webkit-transform: translateX(0);
    }
  }

  @-webkit-keyframes mbsc-lv-pop-in {
    from {
      -webkit-transform: scale(0);
      opacity: 0;
    }

    to {
      -webkit-transform: scale(1);
      opacity: 1;
    }
  }

  @-webkit-keyframes mbsc-lv-pop-out {
    from {
      -webkit-transform: scale(1);
      opacity: 1;
    }

    to {
      -webkit-transform: scale(0);
      opacity: 0;
    }
  }

  @-webkit-keyframes mbsc-lv-collapse {
    from {
      padding: 0;
      border: 0;
    }

    to {
      padding: 0;
      border: 0;
      height: 0;
    }
  }

  @-webkit-keyframes mbsc-lv-expand {
    from {
      padding: 0;
      border: 0;
      height: 0;
    }

    to {
      padding: 0;
      border: 0;
    }
  }

  @-webkit-keyframes mbsc-lv-fill {
    from {
      -webkit-transform: scale(1, 0);
    }

    to {
      -webkit-transform: scale(1, 1);
    }
  }

  @keyframes mbsc-lv-spin {
    from {
      transform: rotate(180deg);
      opacity: 0;
    }

    to {
      transform: rotate(0);
      opacity: 1;
    }
  }

  @keyframes mbsc-lv-remove-right {
    to {
      transform: translateX(100%);
    }
  }

  @keyframes mbsc-lv-remove-left {
    to {
      transform: translateX(-100%);
    }
  }

  @keyframes mbsc-lv-add-right {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes mbsc-lv-add-left {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes mbsc-lv-pop-in {
    from {
      transform: scale(0);
      opacity: 0;
    }

    to {
      transform: scale(1);
    }
  }

  @keyframes mbsc-lv-pop-out {
    from {
      transform: scale(1);
    }

    to {
      transform: scale(0);
      opacity: 0;
    }
  }

  @keyframes mbsc-lv-collapse {
    from {
      padding: 0;
      border: 0;
    }

    to {
      padding: 0;
      border: 0;
      height: 0;
    }
  }

  @keyframes mbsc-lv-expand {
    from {
      padding: 0;
      border: 0;
      height: 0;
    }

    to {
      padding: 0;
      border: 0;
    }
  }

  @keyframes mbsc-lv-fill {
    from {
      transform: scale(1, 0);
    }

    to {
      transform: scale(1, 1);
    }
  }

  /* Loading animation */

  @keyframes mbsc-lv-rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes mbsc-lv-rotating {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(360deg);
    }
  }

  /* Backface visibility */

  .mbsc-lv-stage-c-v,
  .mbsc-lv-item,
  .mbsc-lv-gr-title,
  .mbsc-lv-ic-text {
    -webkit-transform: translateZ(0);
  }

  .mbsc-lv,
  .mbsc-lv-fill-item {
    -webkit-backface-visibility: hidden;
  }

  .mbsc-rtl {
    direction: rtl;
  }

  /* List */

  .mbsc-lv.mbsc-lv-dummy {
    display: block;
    margin-top: -1px;
    -webkit-backface-visibility: visible;
  }

  /* Fixed header */

  .mbsc-lv-fixed-header {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    list-style: none;
    width: 100%;
    z-index: 10;
  }

  .mbsc-lv-fixed-header-ctx {
    position: absolute;
  }

  /* Items */

  .mbsc-lv .mbsc-lv-item.mbsc-lv-item-swiping {
    z-index: 1;
    overflow: visible;
  }

  .mbsc-lv-stage-c {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
  }

  .mbsc-lv-stage-c-v {
    display: block;
  }

  .mbsc-lv-item-new-left {
    -webkit-transform: translateX(-100%);
  }

  .mbsc-lv-item-new-right {
    -webkit-transform: translateX(100%);
  }

  .mbsc-lv-item-remove-right {
    -webkit-animation: mbsc-lv-remove-right 125ms;
    -webkit-animation-delay: 1ms;
    /* Needed for iOS8 to prevent flicker */
    animation: mbsc-lv-remove-right 125ms;
  }

  .mbsc-lv-item-remove-left {
    -webkit-animation: mbsc-lv-remove-left 125ms;
    -webkit-animation-delay: 1ms;
    /* Needed for iOS8 to prevent flicker */
    animation: mbsc-lv-remove-left 125ms;
  }

  .mbsc-lv-item-add-right {
    -webkit-animation: mbsc-lv-add-right 125ms;
    animation: mbsc-lv-add-right 125ms;
  }

  .mbsc-lv-item-add-left {
    -webkit-animation: mbsc-lv-add-left 125ms;
    animation: mbsc-lv-add-left 125ms;
  }

  .mbsc-lv-item-pop-in {
    -webkit-animation: mbsc-lv-pop-in 200ms;
    animation: mbsc-lv-pop-in 200ms;
  }

  .mbsc-lv-item-pop-out {
    -webkit-animation: mbsc-lv-pop-out 200ms;
    animation: mbsc-lv-pop-out 200ms;
  }

  .mbsc-lv-item-collapse {
    visibility: hidden;
    -webkit-animation: mbsc-lv-collapse 125ms;
    animation: mbsc-lv-collapse 125ms;
  }

  .mbsc-lv-item-expand {
    visibility: hidden;
    -webkit-animation: mbsc-lv-expand 125ms;
    animation: mbsc-lv-expand 125ms;
  }

  .mbsc-lv .mbsc-lv-item.mbsc-lv-item-undo {
    position: absolute;
    left: 0;
    right: 0;
  }

  /* Reorder */

  .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-item-dragging {
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    opacity: .9;
    box-shadow: 0 0 0.625em rgba(0, 0, 0, .5);
    cursor: pointer;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .mbsc-lv-item-dragging .mbsc-lv-handle-c {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .mbsc-lv .mbsc-lv-item.mbsc-lv-ph {
    padding: 0;
    border: 0;
    visibility: hidden;
  }

  .mbsc-lv-fill-item {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .1;
  }

  /* Sort handle */

  .mbsc-lv-handle-c {
    position: absolute;
    z-index: 4;
    top: 0;
    height: 100%;
    width: 2.5em;
    -ms-touch-action: none;
    touch-action: none;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .mbsc-lv-item-h-right {
    right: 0;
  }

  .mbsc-lv-item-h-left {
    left: 0;
  }

  .mbsc-lv-cont.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item {
    padding-left: 2.5em;
  }

  .mbsc-lv-cont.mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item {
    padding-right: 2.5em;
  }

  .mbsc-lv-handle-bar-c {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -7px;
  }

  .mbsc-lv-handle-bar {
    position: relative;
    height: 2px;
    margin: 2px .625em;
    background: #888;
  }

  /* Icon and text */

  .mbsc-lv-ic,
  .mbsc-lv-ic-m {
    top: 0;
    text-align: center;
    text-shadow: none;
    font-size: .75em;
  }

  /* Stage icon */

  .mbsc-lv-ic-s {
    position: absolute;
    top: 50%;
    margin-top: -0.9375em;
    width: 2.8em;
    height: 1.875em;
    line-height: 1.875em;
    font-size: 1.25em;
  }

  /* Stage icon container */

  .mbsc-lv-ic-c {
    position: absolute;
    top: 0;
    height: 100%;
    line-height: 1.875em;
    color: #fff;
    cursor: pointer;
  }

  /* Stage text */

  .mbsc-lv-ic-text {
    text-shadow: none;
    font-size: 1em;
  }

  /* Empty icon */

  .mbsc-ic-none {
    border: 0;
  }

  .mbsc-ic-none:before {
    content: "0";
    font-size: 0;
    color: rgba(0, 0, 0, 0);
  }

  /* Stage icon animation */

  .mbsc-lv-ic-anim .mbsc-lv-ic-s {
    opacity: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .mbsc-lv-ic-anim .mbsc-lv-ic-a {
    opacity: 1;
    -webkit-animation: mbsc-lv-spin 200ms;
    -webkit-transform: rotate(0);
    animation: mbsc-lv-spin 200ms;
    transform: rotate(0);
  }

  .mbsc-lv-ic-anim .mbsc-lv-ic-v {
    opacity: 1;
    -webkit-animation: none;
    -webkit-transform: rotate(0);
    animation: none;
    transform: rotate(0);
  }

  /* Stage icon and text left and right positioning */

  .mbsc-lv-ic-left {
    left: 0;
  }

  .mbsc-lv-ic-move-left {
    left: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .mbsc-lv-ic-right .mbsc-lv-ic,
  .mbsc-lv-ic-move-left .mbsc-lv-ic {
    right: 0;
  }

  .mbsc-lv-ic-right .mbsc-lv-ic-text,
  .mbsc-lv-ic-move-left .mbsc-lv-ic-text {
    padding: 0 3.5em 0 0.625em;
  }

  .mbsc-lv-ic-right {
    right: 0;
  }

  .mbsc-lv-ic-move-right {
    right: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  .mbsc-lv-ic-left .mbsc-lv-ic,
  .mbsc-lv-ic-move-right .mbsc-lv-ic {
    left: 0;
  }

  .mbsc-lv-ic-left .mbsc-lv-ic-text,
  .mbsc-lv-ic-move-right .mbsc-lv-ic-text {
    padding: 0 0.625em 0 3.5em;
  }

  .mbsc-lv-ic-c .mbsc-lv-ic-only {
    width: 0;
    padding: 0 0 0 3.5em;
  }

  .mbsc-lv-ic-c .mbsc-lv-ic-text-only {
    padding: 0 0.625em;
  }

  /* Multiple icons */

  .mbsc-lv-multi-c {
    height: 100%;
  }

  .mbsc-lv-multi {
    display: none;
    width: 90%;
    height: 100%;
    table-layout: fixed;
  }

  .mbsc-lv-multi-ic-right {
    float: right;
  }

  .mbsc-lv-multi-ic-left {
    float: left;
  }

  .mbsc-lv-right .mbsc-lv-multi-ic-right {
    display: table;
  }

  .mbsc-lv-left .mbsc-lv-multi-ic-left {
    display: table;
  }

  .mbsc-lv-ic-m {
    display: table-cell;
    vertical-align: middle;
    padding: 0 .25em;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbsc-lv-ic-m:before {
    display: block;
    padding: .25em 0;
    font-size: 1.333334em;
  }

  .mbsc-lv-ic-disabled {
    opacity: .5;
  }

  /* Hierarchy slide containers */

  .mbsc-lv-sl-c {
    position: relative;
    -webkit-animation-fill-mode: forwards;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .mbsc-lv-sl-r {
    -webkit-animation: mbsc-lv-remove-left 300ms;
    animation: mbsc-lv-remove-left 300ms;
  }

  .mbsc-lv-sl-l {
    -webkit-animation: mbsc-lv-remove-right 300ms;
    animation: mbsc-lv-remove-right 300ms;
  }

  .mbsc-lv-sl-l .mbsc-lv-sl-curr,
  .mbsc-lv-sl-r .mbsc-lv-sl-curr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .mbsc-lv-sl-r .mbsc-lv-sl-new {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  .mbsc-lv-sl-l .mbsc-lv-sl-new {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  /* Hierarchy arrows */

  .mbsc-lv-arr {
    position: absolute;
    top: 50%;
    width: 2em;
    height: 2em;
    font-size: 1em;
    line-height: 2em;
    margin-top: -1em;
  }

  .mbsc-lv .mbsc-lv-back {
    z-index: 3;
  }

  .mbsc-ltr .mbsc-lv-item.mbsc-lv-parent {
    padding-right: 2.25em;
  }

  .mbsc-ltr .mbsc-lv-item.mbsc-lv-back {
    padding-left: 2.25em;
  }

  .mbsc-ltr .mbsc-lv-parent .mbsc-lv-arr {
    right: 0;
  }

  .mbsc-ltr.mbsc-lv-handle-right .mbsc-lv-parent .mbsc-lv-arr {
    right: 40px;
  }

  .mbsc-ltr .mbsc-lv-back .mbsc-lv-arr {
    left: 0;
  }

  .mbsc-rtl .mbsc-lv-arr {
    -webkit-transform: scale(-1, 1);
    transform: scale(-1, 1);
  }

  .mbsc-rtl .mbsc-lv-item.mbsc-lv-parent {
    padding-left: 2.25em;
  }

  .mbsc-rtl .mbsc-lv-item.mbsc-lv-back {
    padding-right: 2.25em;
  }

  .mbsc-rtl .mbsc-lv-parent .mbsc-lv-arr {
    left: 0;
  }

  .mbsc-rtl.mbsc-lv-handle-left .mbsc-lv-parent .mbsc-lv-arr {
    left: 40px;
  }

  .mbsc-rtl .mbsc-lv-back .mbsc-lv-arr {
    right: 0;
  }

  /* Text enhance */

  .mbsc-lv .mbsc-lv-txt {
    margin: 0;
    font-weight: normal;
  }

  .mbsc-lv h1.mbsc-lv-txt {
    font-size: 2em;
  }

  .mbsc-lv h2.mbsc-lv-txt {
    font-size: 1.5em;
  }

  .mbsc-lv h3.mbsc-lv-txt {
    font-size: 1.125em;
  }

  .mbsc-lv h4.mbsc-lv-txt {
    font-size: 1em;
  }

  .mbsc-lv h5.mbsc-lv-txt {
    font-size: .875em;
  }

  .mbsc-lv h6.mbsc-lv-txt {
    font-size: .625em;
  }

  .mbsc-lv p.mbsc-lv-txt {
    font-size: .75em;
  }

  /* Image and text enhance */

  .mbsc-lv-img {
    position: absolute;
    max-height: 2.5em;
    max-width: 2.5em;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-img-left {
    padding-left: 5em;
  }

  .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-img-right {
    padding-right: 5em;
  }

  .mbsc-lv-img-left .mbsc-lv-img {
    left: 2.5em;
  }

  .mbsc-lv-img-right .mbsc-lv-img {
    right: 0;
  }

  .mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item.mbsc-lv-img-left {
    padding-left: 6.25em;
  }

  .mbsc-lv-handle-left .mbsc-lv-img-left .mbsc-lv-img {
    left: 3.75em;
  }

  .mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item.mbsc-lv-img-right {
    padding-right: 6.25em;
  }

  .mbsc-lv-handle-right .mbsc-lv-img-right .mbsc-lv-img {
    right: 1.25em;
  }

  /* Icon and text enhance */

  .mbsc-lv-item-ic {
    position: absolute;
    top: 50%;
    margin-top: -1em;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
  }

  .mbsc-lv-cont .mbsc-lv .mbsc-lv-item.mbsc-lv-item-ic-left {
    padding-left: 4em;
  }

  .mbsc-lv-cont .mbsc-lv .mbsc-lv-item.mbsc-lv-item-ic-right {
    padding-right: 4em;
  }

  .mbsc-lv-item-ic-left .mbsc-lv-item-ic {
    left: 1em;
  }

  .mbsc-lv-item-ic-right .mbsc-lv-item-ic {
    right: 1em;
  }

  .mbsc-lv-handle-left.mbsc-lv-cont .mbsc-lv .mbsc-lv-item-ic-left {
    padding-left: 5.5em;
  }

  .mbsc-lv-handle-left .mbsc-lv-item-ic-left .mbsc-lv-item-ic {
    left: 2.5em;
  }

  .mbsc-lv-handle-right.mbsc-lv-cont .mbsc-lv .mbsc-lv-item-ic-right {
    padding-right: 5.5em;
  }

  .mbsc-lv-handle-right .mbsc-lv-item-ic-right .mbsc-lv-item-ic {
    right: 2.5em;
  }

  /* Loading animation */

  .mbsc-lv-loading {
    text-align: center;
    opacity: 0;
    height: 0;

    .mbsc-ic {
      font-size: 1.375em;
    }

    .mbsc-ic:before {
      display: inline-block;
      -webkit-animation: mbsc-lv-rotating .6s linear infinite;
      animation: mbsc-lv-rotating .6s linear infinite;
    }
  }

  .mbsc-show-lv-loading.mbsc-lv-loading {
    opacity: 1;
    height: auto;
    padding: 1em;
  }
}


@include exports("listview.ios") {
  .mbsc-ios {
    .mbsc-lv-item.mbsc-lv-item-dragging {
      margin: 0;
    }

    .mbsc-lv-handle-c {
      width: 4em;
    }

    .mbsc-lv-handle-bar {
      margin: .125em 1em;
    }

    &.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item {
      padding-left: 4em;
    }

    &.mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item {
      padding-right: 4em;
    }

    &.mbsc-lv-handle-right.mbsc-ltr .mbsc-lv-parent .mbsc-lv-arr {
      right: 2.380952em;
    }

    &.mbsc-lv-handle-left.mbsc-rtl .mbsc-lv-parent .mbsc-lv-arr {
      left: 2.380952em;
    }

    &.mbsc-lv-handle-left .mbsc-lv-item.mbsc-checkbox.mbsc-rtl {
      padding-left: 5.75em;

      .mbsc-checkbox-box {
        left: 3.5em;
      }
    }

    &.mbsc-lv-has-fixed-header .mbsc-lv-gr-title,
    &.mbsc-lv-fixed-header .mbsc-lv-gr-title {
      line-height: 1.125em;
      font-size: 1em;
      padding: .4em 1em;
      border: 0;
      font-weight: bold;
    }

    .mbsc-lv-item.mbsc-lv-img-left {
      padding-left: 4.5em;
    }

    .mbsc-lv-item.mbsc-lv-img-right {
      padding-right: 4.5em;
    }

    .mbsc-lv-img-left .mbsc-lv-img {
      left: 2.25em;
    }

    .mbsc-lv-img-right .mbsc-lv-img {
      right: -.25em;
    }

    &.mbsc-lv-handle-right .mbsc-lv-img-right .mbsc-lv-img {
      right: 2.75em;
    }

    &.mbsc-lv-handle-left .mbsc-lv-img-left .mbsc-lv-img {
      left: 5.25em;
    }

    &.mbsc-lv-handle-right .mbsc-lv-item-ic-right .mbsc-lv-item-ic {
      right: 4em;
    }

    &.mbsc-lv-handle-left .mbsc-lv-item-ic-left .mbsc-lv-item-ic {
      left: 4em;
    }

    &.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item.mbsc-lv-img-left {
      padding-left: 7.5em;
    }

    &.mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item.mbsc-lv-img-right {
      padding-right: 7.5em;
    }

    &.mbsc-lv-handle-left .mbsc-lv-item-ic-left {
      padding-left: 7em;
    }

    &.mbsc-lv-handle-right .mbsc-lv-item-ic-right {
      padding-right: 7em;
    }

    .mbsc-lv-arr {
      font-size: 1.3125em;
    }

    /* Item borders */

    &.mbsc-ltr .mbsc-lv-item-ic-left:before,
    &.mbsc-ltr .mbsc-lv-item-ic-left:after {
      left: 4em;
    }

    &.mbsc-ltr .mbsc-lv-img-left:before,
    &.mbsc-ltr .mbsc-lv-img-left:after {
      left: 4.5em;
    }

    &.mbsc-rtl .mbsc-lv-item-ic-right:before,
    &.mbsc-rtl .mbsc-lv-item-ic-right:after {
      right: 4em;
    }

    &.mbsc-rtl .mbsc-lv-img-right:before,
    &.mbsc-rtl .mbsc-lv-img-right:after {
      right: 4.5em;
    }
  }

  @include mbsc-ios-listview(ios, $mbsc-ios-colors);
}








// Theme specific variables - inherited from global variables

// background
$mbsc-mobiscroll-listview-background: $mbsc-listview-background-light !default;
$mbsc-mobiscroll-dark-listview-background: $mbsc-listview-background-dark !default;
// text
$mbsc-mobiscroll-listview-text: $mbsc-listview-text-light !default;
$mbsc-mobiscroll-dark-listview-text: $mbsc-listview-text-dark !default;
// accent
$mbsc-mobiscroll-listview-accent: $mbsc-listview-accent-light !default;
$mbsc-mobiscroll-dark-listview-accent: $mbsc-listview-accent-dark !default;
// header background
$mbsc-mobiscroll-listview-header-background: $mbsc-listview-header-background-light !default;
$mbsc-mobiscroll-dark-listview-header-background: $mbsc-listview-header-background-dark !default;
// header text
$mbsc-mobiscroll-listview-header-text: $mbsc-listview-header-text-light !default;
$mbsc-mobiscroll-dark-listview-header-text: $mbsc-listview-header-text-dark !default;

// add variables to the color maps
$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'listview-background': $mbsc-mobiscroll-listview-background,
  'listview-text': $mbsc-mobiscroll-listview-text,
  'listview-accent': $mbsc-mobiscroll-listview-accent,
  'listview-header-background': $mbsc-mobiscroll-listview-header-background,
  'listview-header-text': $mbsc-mobiscroll-listview-header-text,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'listview-background': $mbsc-mobiscroll-dark-listview-background,
  'listview-text': $mbsc-mobiscroll-dark-listview-text,
  'listview-accent': $mbsc-mobiscroll-dark-listview-accent,
  'listview-header-background': $mbsc-mobiscroll-dark-listview-header-background,
  'listview-header-text': $mbsc-mobiscroll-dark-listview-header-text,
));

@mixin mbsc-mobiscroll-listview-base($theme, $params) {
  @include exports("listview-base.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    // get custom variables
    $background-param: map-get($params, 'listview-background');
    $text-param: map-get($params, 'listview-text');
    $accent-param: map-get($params, 'listview-accent');
    $header-background-param: map-get($params, 'listview-header-background');
    $header-text-param: map-get($params, 'listview-header-text');
    // overwrite variables with custom ones when provided
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);
    $header-background: if($header-background-param, $header-background-param, $accent);
    $header-text: if($header-text-param, $header-text-param, $background);

    $lv-background: '';
    // Light background
    @if (lightness($background) > 50%) {
      $lv-background: darken($background, 19%);
    }
    // Dark background
    @else {
      $lv-background: lighten($background, 14%);
    }

    .mbsc-#{$theme} {
      .mbsc-lv {
        background: $lv-background;
      }

      .mbsc-lv-item {
        background: $background;
        color: $text;
      }

      .mbsc-lv-gr-title {
        background: $header-background;
        color: $header-text;
      }

      &.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
      .mbsc-lv-item-active.mbsc-lv-item-actionable:before,
      .mbsc-lv-item-actionable.mbsc-focus:before {
        background: rgba($accent, .15);
      }
    }
  }
}



@include exports("listview-base.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-lv-cont {
      font-family: arial, verdana, sans-serif;
      padding-top: 1px;
    }

    .mbsc-lv-item {
      padding: 1.25em;
      font-weight: normal;
    }

    .mbsc-lv-gr-title {
      padding: 0 1.25em;
      font-size: .75em;
      text-transform: uppercase;
      line-height: 2em;
      margin-top: -1px;
    }
  }

  @include mbsc-mobiscroll-listview-base(mobiscroll, $mbsc-mobiscroll-colors);
}





@mixin mbsc-mobiscroll-listview($theme, $params) {
  @include exports("listview.#{$theme}.colors") {

    @include mbsc-mobiscroll-listview-base($theme, $params);
    @include mbsc-mobiscroll-common($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $colors: mbsc-mobiscroll-colors($params);
    $white-text: map-get($colors, 'white-text');

    // get custom variables
    $background-param: map-get($params, 'listview-background');
    $text-param: map-get($params, 'listview-text');
    $accent-param: map-get($params, 'listview-accent');
    // overwrite variables with custom ones when provided
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    // derived colors
    $lv-item: darken($background, 4%);

    .mbsc-#{$theme} {
      &.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
        background: $lv-item;
      }

      .mbsc-lv-item-hl:before {
        background: rgba($accent, .15);
      }

      .mbsc-lv-ic-m {
        color: $white-text;
      }

      .mbsc-lv-arr {
        color: $accent;
      }

      .mbsc-lv-handle-bar {
        background: $accent;
      }

      .mbsc-lv-item.mbsc-lv-item-dragging {
        background: $accent;
        color: $background;
      }

      .mbsc-lv-item-dragging .mbsc-lv-handle-bar {
        background: $background;
      }

      .mbsc-lv-item-dragging .mbsc-lv-arr {
        color: $background;
      }

      .mbsc-lv-loading .mbsc-ic {
        color: $text;
      }

      &.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
        background: rgba($accent, .3);
      }
    }
  }
}



@include exports("listview.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-lv-item.mbsc-lv-item-dragging {
      margin: 0;
      box-shadow: none;
    }

    &.mbsc-lv-handle-left .mbsc-lv-item.mbsc-checkbox.mbsc-rtl {
      padding-left: 4.25em;

      .mbsc-checkbox-box {
        left: 2.5em;
      }
    }
  }

  @include mbsc-mobiscroll-listview(mobiscroll, $mbsc-mobiscroll-colors);
}






// Theme specific variables - inherited from global variables

// background
$mbsc-material-listview-background: $mbsc-listview-background-light !default;
$mbsc-material-dark-listview-background: $mbsc-listview-background-dark !default;
// text
$mbsc-material-listview-text: $mbsc-listview-text-light !default;
$mbsc-material-dark-listview-text: $mbsc-listview-text-dark !default;
// accent
$mbsc-material-listview-accent: $mbsc-listview-accent-light !default;
$mbsc-material-dark-listview-accent: $mbsc-listview-accent-dark !default;
// header background
$mbsc-material-listview-header-background: $mbsc-listview-header-background-light !default;
$mbsc-material-dark-listview-header-background: $mbsc-listview-header-background-dark !default;
// header text
$mbsc-material-listview-header-text: $mbsc-listview-header-text-light !default;
$mbsc-material-dark-listview-header-text: $mbsc-listview-header-text-dark !default;

// add variables to the color maps
$mbsc-material-colors: map-merge($mbsc-material-colors, (
  'listview-background': $mbsc-material-listview-background,
  'listview-text': $mbsc-material-listview-text,
  'listview-accent': $mbsc-material-listview-accent,
  'listview-header-background': $mbsc-material-listview-header-background,
  'listview-header-text': $mbsc-material-listview-header-text,
));
$mbsc-material-dark-colors: map-merge($mbsc-material-dark-colors, (
  'listview-background': $mbsc-material-dark-listview-background,
  'listview-text': $mbsc-material-dark-listview-text,
  'listview-accent': $mbsc-material-dark-listview-accent,
  'listview-header-background': $mbsc-material-dark-listview-header-background,
  'listview-header-text': $mbsc-material-dark-listview-header-text,
));

@mixin mbsc-material-listview-base($theme, $params) {
  @include exports("listview-base.#{$theme}.colors") {

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    // get custom variables
    $background-param: map-get($params, 'listview-background');
    $text-param: map-get($params, 'listview-text');
    $accent-param: map-get($params, 'listview-accent');
    $header-background-param: map-get($params, 'listview-header-background');
    $header-text-param: map-get($params, 'listview-header-text');
    // overwrite variables with custom ones when provided
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);
    $header-background: if($header-background-param, $header-background-param, $background);
    $header-text: if($header-text-param, $header-text-param, $accent);

    $gray-background: '';
    $background-contrast: '';
    @if (lightness($background) > 50%) {
      $gray-background: darken($background, 23%);
      $background-contrast: #000;
    }
    @else {
      $gray-background: lighten($background, 17%);
      $background-contrast: #fff;
    }

    .mbsc-#{$theme} {
      .mbsc-lv {
        background: $gray-background;
      }

      .mbsc-lv-item {
        background: $background;
        color: $text;
      }

      .mbsc-lv-gr-title {
        background: $header-background;
        color: $header-text;
      }

      &.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
      .mbsc-lv-item-actionable.mbsc-lv-item-active:before {
        background: rgba($background-contrast, .05);
      }
    }
  }
}



@include exports("listview-base.material") {
  .mbsc-material {
    .mbsc-lv-item {
      overflow: hidden;
      padding: 1.1875em 1em;
      font-weight: normal;
    }

    .mbsc-lv-gr-title {
      margin-top: -1px;
      font-size: .875em;
      font-weight: bold;
      padding: .5714em 1.14285em;
    }
  }

  @include mbsc-material-listview-base(material, $mbsc-material-colors);
}





@mixin mbsc-material-listview($theme, $params) {
  @include exports("listview.#{$theme}.colors") {

    @include mbsc-material-listview-base($theme, $params);
    @include mbsc-material-common($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $colors: mbsc-material-colors($params);
    $white-text: map-get($colors, 'white-text');

    // get custom variables
    $background-param: map-get($params, 'listview-background');
    $text-param: map-get($params, 'listview-text');
    $accent-param: map-get($params, 'listview-accent');
    // overwrite variables with custom ones when provided
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);
    // derived colors
    $lv-text: lighten($text, 10%);
    $lv-item: if(lightness($background) > 50%, lighten($background, 4%), lighten($background, 3%));
    $background-contrast: '';
    @if (lightness($background) > 50%) {
      $background-contrast: #000;
    }
    @else {
      $background-contrast: #fff;
    }
    .mbsc-#{$theme} {

      .mbsc-lv-item-hl:before,
      .mbsc-lv-item-dragging:before {
        background: rgba($background-contrast, .05);
      }

      &.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
        background: $lv-item;
      }

      .mbsc-lv-ic-m {
        color: $white-text;
      }

      .mbsc-lv-arr {
        color: $accent;
      }

      .mbsc-lv-handle-bar {
        background: $accent;
      }

      p.mbsc-lv-txt {
        color: $lv-text;
      }

      .mbsc-lv-loading .mbsc-ic {
        color: $text;
      }

      &.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
        background: rgba($background-contrast, .1);
      }
    }
  }
}




@include exports("listview.material") {
  .mbsc-material {
    .mbsc-lv-item-dragging {
      margin: 0;
    }

    .mbsc-lv-item-swiping {
      overflow: visible;
    }

    &.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item {
      padding-left: 3.125em;
    }

    &.mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item {
      padding-right: 3.125em;
    }

    .mbsc-lv-arr:before {
      font-size: 1.5em;
    }

    .mbsc-lv-handle-c {
      width: 3.125em;
    }

    .mbsc-lv-handle-bar {
      margin: .125em 1em;
    }

    .mbsc-lv-img-left .mbsc-lv-img {
      left: 2.25em;
    }

    .mbsc-lv-img-right .mbsc-lv-img {
      right: -.25em;
    }

    &.mbsc-lv-handle-left .mbsc-lv-img-left .mbsc-lv-img {
      left: 4.375em;
    }

    &.mbsc-lv-handle-right .mbsc-lv-img-right .mbsc-lv-img {
      right: 1.875em;
    }

    &.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item.mbsc-lv-img-left {
      padding-left: 6.625em;
    }

    &.mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item.mbsc-lv-img-right {
      padding-right: 6.625em;
    }

    &.mbsc-lv-handle-left .mbsc-lv-item-ic-left .mbsc-lv-item-ic {
      left: 3.125em;
    }

    &.mbsc-lv-handle-right .mbsc-lv-item-ic-right .mbsc-lv-item-ic {
      right: 3.125em;
    }

    &.mbsc-lv-handle-left .mbsc-lv-item-ic-left {
      padding-left: 6.125em;
    }

    &.mbsc-lv-handle-right .mbsc-lv-item-ic-right {
      padding-right: 6.125em;
    }

    &.mbsc-lv-handle-left .mbsc-lv-item.mbsc-checkbox.mbsc-rtl {
      padding-left: 5.25em;

      .mbsc-checkbox-box {
        left: 3.125em;
      }
    }
  }

  @include mbsc-material-listview(material, $mbsc-material-colors);
}






// Theme specific variables - inherited from global variables

// background
$mbsc-windows-listview-background: $mbsc-listview-background-light !default;
$mbsc-windows-dark-listview-background: $mbsc-listview-background-dark !default;
// text
$mbsc-windows-listview-text: $mbsc-listview-text-light !default;
$mbsc-windows-dark-listview-text: $mbsc-listview-text-dark !default;
// accent
$mbsc-windows-listview-accent: $mbsc-listview-accent-light !default;
$mbsc-windows-dark-listview-accent: $mbsc-listview-accent-dark !default;
// header background
$mbsc-windows-listview-header-background: $mbsc-listview-header-background-light !default;
$mbsc-windows-dark-listview-header-background: $mbsc-listview-header-background-dark !default;
// header text
$mbsc-windows-listview-header-text: $mbsc-listview-header-text-light !default;
$mbsc-windows-dark-listview-header-text: $mbsc-listview-header-text-dark !default;

// add variables to the color maps
$mbsc-windows-colors: map-merge($mbsc-windows-colors, (
  'listview-background': $mbsc-windows-listview-background,
  'listview-text': $mbsc-windows-listview-text,
  'listview-accent': $mbsc-windows-listview-accent,
  'listview-header-background': $mbsc-windows-listview-header-background,
  'listview-header-text': $mbsc-windows-listview-header-text,
));
$mbsc-windows-dark-colors: map-merge($mbsc-windows-dark-colors, (
  'listview-background': $mbsc-windows-dark-listview-background,
  'listview-text': $mbsc-windows-dark-listview-text,
  'listview-accent': $mbsc-windows-dark-listview-accent,
  'listview-header-background': $mbsc-windows-dark-listview-header-background,
  'listview-header-text': $mbsc-windows-dark-listview-header-text,
));

@mixin mbsc-windows-listview-base($theme, $params) {
  @include exports("listview-base.#{$theme}.colors") {
    $background: map-get($params, 'background');
    $text: map-get($params, 'text');

    // get custom variables
    $background-param: map-get($params, 'listview-background');
    $text-param: map-get($params, 'listview-text');
    // overwrite variables with custom ones when provided
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));

    // derived params
    $lv-background: darken($background, 34%);
    $scrollbar-track: '';
    @if(lightness($background) > 50%) {
      $scrollbar-track: rgba(0, 0, 0, .05);
    }
    @else {
      $scrollbar-track: rgba(255, 255, 255, .2);
    }

    .mbsc-#{$theme} {
      .mbsc-lv {
        background: $lv-background;
      }

      .mbsc-lv-item,
      .mbsc-lv-gr-title {
        background: $background;
        color: $text;
      }

      &.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
      .mbsc-lv-item-actionable.mbsc-lv-item-active:before,
      .mbsc-lv-item-actionable.mbsc-focus:before {
        background: $scrollbar-track;
      }
    }
  }
}



@include exports("listview-base.windows") {
  .mbsc-windows {
    .mbsc-lv-item {
      padding: 1.25em;
      font-family: Segoe UI, arial, verdana, sans-serif;
      font-weight: normal;
      text-shadow: none;
    }

    .mbsc-lv-gr-title {
      font-size: 1.3125em;
      padding: 1em 0.625em 0 0.625em;
      line-height: 2em;
    }
  }

  @include mbsc-windows-listview-base(windows, $mbsc-windows-colors);
}





@mixin mbsc-windows-listview($theme, $params) {
  @include exports("listview.#{$theme}.colors") {

    @include mbsc-windows-listview-base($theme, $params);
    @include mbsc-windows-common($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');
    // get custom variables
    $background-param: map-get($params, 'listview-background');
    $text-param: map-get($params, 'listview-text');
    $accent-param: map-get($params, 'listview-accent');
    // overwrite variables with custom ones when provided
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    // derived params
    $alt-row: '';
    $frame-border: '';
    @if(lightness($background) > 50%) {
      $alt-row: darken($background, 5%);
      $frame-border: darken($background, 15%);
    }
    @else {
      $alt-row: lighten($background, 12%);
      $frame-border: lighten($background, 15%);
    }

    .mbsc-#{$theme} {
      .mbsc-lv-handle-bar {
        background: #888;
      }

      .mbsc-lv-ic-m {
        color: $background;
      }

      &.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
        background: $alt-row;
      }

      .mbsc-lv-item.mbsc-lv-item-hl {
        background: $frame-border;
      }

      .mbsc-lv-loading .mbsc-ic {
        color: $text;
      }

      &.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
        background: rgba($accent, .2);
      }
    }
  }
}



@include exports("listview.windows") {
  .mbsc-windows {
    .mbsc-lv-fixed-header {
      font-size: 16px;
    }

    .mbsc-lv .mbsc-lv-item.mbsc-lv-item-dragging {
      margin: 0;
      opacity: .7;
      box-shadow: none;
    }

    &.mbsc-lv-handle-left .mbsc-lv-item.mbsc-checkbox.mbsc-ltr {
      padding-left: 4.375em;

      .mbsc-checkbox-box {
        left: 2.5em;
      }
    }

    .mbsc-lv .mbsc-lv-item.mbsc-lv-item-ic-left.mbsc-checkbox {
      padding-left: 5.625em;

      .mbsc-checkbox-box {
        left: 3.25em;
      }
    }
  }

  @include mbsc-windows-listview(windows, $mbsc-windows-colors);
}



// number component






@mixin mbsc-ios-number($theme, $params) {
  @include exports("number.#{$theme}.colors") {
    @include mbsc-ios-scroller($theme, $params);
  }
}





@include exports("number.ios") {
  @include mbsc-ios-number(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}










@mixin mbsc-mobiscroll-number($theme, $params) {
  @include exports("number.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}



@include exports("number.mobiscroll") {
  @include mbsc-mobiscroll-number(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}







@mixin mbsc-material-number($theme, $params) {
  @include exports("number.#{$theme}.colors") {
    @include mbsc-material-scroller($theme, $params);
  }
}



@include exports("number.material") {
  @include mbsc-material-number(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}







@mixin mbsc-windows-number($theme, $params) {
  @include exports("number.#{$theme}.colors") {
    @include mbsc-windows-scroller($theme, $params);
  }
}



@include exports("number.windows") {
  @include mbsc-windows-number(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}



// numpad component






@mixin mbsc-ios-numpad($theme, $params) {
  @include exports("numpad.#{$theme}.colors") {

    @include mbsc-ios-frame($theme, $params);

    $background: map-get($params, 'background');
    $background-param: map-get($params, 'frame-background');
    $background: if($background-param, $background-param, $background);

    // calculated colors
    $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
    $top-bottom-frame: '';
    $button-disabled: '';
    $border-color: '';
    @if (lightness($background) > 50%) {
      $top-bottom-frame: adjust-hue(darken(saturate($background, 12%), 13%), calc(216% / 1%));
      $button-disabled: darken($background, 13%);
      $border-color: darken($background-limited, 17%);
    }
    @else {
      $top-bottom-frame: $background-limited;
      $button-disabled: lighten($background, 13%);
      $border-color: lighten($background, 20%);
    }

    .mbsc-#{$theme} {

      .mbsc-np-btn {
        border-top: 1px solid $border-color;
        border-left: 1px solid $border-color;
      }

      .mbsc-np-btn.mbsc-active,
      .mbsc-np-btn-empty {
        background: $top-bottom-frame;
      }

      .mbsc-np-btn.mbsc-disabled {
        color: $button-disabled;
      }
    }
  }
}




@include exports("numpad") {
  .mbsc-np-hdr {
    position: relative;
  }

  .mbsc-np-dsp {
    direction: ltr;
    padding: .3125em 1.75em .3125em .5em;
    text-align: left;
    font-size: 2em;
    line-height: normal;
  }

  .mbsc-np-sup {
    display: inline-block;
    font-size: .375em;
    vertical-align: top;
  }

  .mbsc-np-del {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3.5em;
    text-align: center;
  }

  .mbsc-np-del:before {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -.5em;
    font-size: 2em;
    line-height: 1;
  }

  .mbsc-np-ph {
    display: inline-block;
    min-width: .5625em;
    text-align: center;
    vertical-align: top;
  }

  .mbsc-np-tbl-c {
    min-width: 15em;
    /*display: inline-block;*/
  }

  .mbsc-fr-top .mbsc-np-tbl-c,
  .mbsc-fr-bottom .mbsc-np-tbl-c {
    display: block;
  }

  .mbsc-np-tbl {
    direction: ltr;
    display: table;
    width: 100%;
    font-size: 1.375em;
  }

  .mbsc-np-row {
    display: table-row;
  }

  .mbsc-np-btn {
    position: relative;
    z-index: 0;
    width: 33.3333333333%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    height: 3em;
    -webkit-user-select: none;
  }

  .mbsc-np-btn.mbsc-active {
    opacity: 1;
  }


  /* Timespan */

  .mbsc-np-time {
    text-transform: uppercase;
  }

  .mbsc-np-ts-h,
  .mbsc-np-ts-m {
    font-weight: bold;
  }
}


@include exports("numpad.ios") {
  .mbsc-ios {
    &.mbsc-np .mbsc-fr-c {
      font-size: 1.333334em;
    }

    .mbsc-np-btn {
      font-size: 1.363637em;
      height: 2.2em;
    }

    .mbsc-np-btn:first-child {
      border-left: 0;
    }

    .mbsc-np-ts-h,
    .mbsc-np-ts-m {
      font-weight: normal;
    }

    .mbsc-np-del:before {
      font-size: 1.125em;
    }
  }

  @include mbsc-ios-numpad(ios, $mbsc-ios-colors);
}




/* Numpad */
.mbsc-bootstrap {
  &.mbsc-np .mbsc-fr-w {
    font-size: 16px;
  }

  &.mbsc-np .mbsc-fr-btn-cont {
    padding: 4px 2px;
  }

  .mbsc-np-del.mbsc-active {
    opacity: .5;
  }

  .mbsc-np-btn {
    border-top-width: 0;
    border-right-width: 0;
    font-size: inherit;
    border-radius: 0;
  }

  .mbsc-np-row:first-child .mbsc-np-btn {
    border-top-width: 1px;
  }

  .mbsc-np-btn:first-child {
    border-left-width: 0;
  }

  &.mbsc-inline .mbsc-np-row:last-child .mbsc-np-btn {
    border-bottom-width: 0;
  }

  .mbsc-np-row .mbsc-np-btn.mbsc-np-btn-empty {
    background: none;
  }
}







@mixin mbsc-mobiscroll-numpad($theme, $params) {
  @include exports("numpad.#{$theme}.colors") {

    @include mbsc-mobiscroll-frame($theme, $params);

    $accent: map-get($params, 'accent');
    $accent-param: map-get($params, 'frame-accent');
    $accent: if($accent-param, $accent-param, $accent);

    .mbsc-#{$theme} {
      .mbsc-np-del {
        color: $accent;
      }
    }
  }
}



@include exports("numpad.mobiscroll") {
  .mbsc-mobiscroll {
    .mbsc-np-tbl-c {
      padding: .5em;
    }

    .mbsc-np-btn.mbsc-disabled {
      opacity: .5;
    }
  }

  @include mbsc-mobiscroll-numpad(mobiscroll, $mbsc-mobiscroll-colors);
}







@mixin mbsc-material-numpad($theme, $params) {
  @include exports("numpad.#{$theme}.colors") {

    @include mbsc-material-frame($theme, $params);

    $accent: map-get($params, 'accent');
    $accent-param: map-get($params, 'frame-accent');
    $accent: if($accent-param, $accent-param, $accent);


    .mbsc-#{$theme} {
      .mbsc-np-del {
        color: $accent;
      }
    }
  }
}



@include exports("numpad.material") {
  .mbsc-material {

    .mbsc-np-del,
    .mbsc-np-btn {
      overflow: hidden;
    }

    .mbsc-np-btn {
      font-size: 1.4545em;
      height: 2.0625em;
    }

    .mbsc-np-del {
      font-size: .875em;
    }

    .mbsc-np-tbl-c {
      padding: .5em;
    }

    .mbsc-np-btn.mbsc-disabled {
      opacity: .5;
    }
  }

  @include mbsc-material-numpad(material, $mbsc-material-colors);
}







@mixin mbsc-windows-numpad($theme, $params) {
  @include exports("numpad.#{$theme}.colors") {

    @include mbsc-windows-frame($theme, $params);

    $text: map-get($params, text);

    $colors: mbsc-windows-colors($params);
    $frame-border: map-get($colors, frame-border);
    $input-border: map-get($colors, input-border);

    .mbsc-#{$theme} {
      &.mbsc-fr .mbsc-fr-w .mbsc-np-del.mbsc-active {
        background: $frame-border;
      }

      .mbsc-np-del.mbsc-active:before {
        color: $text;
      }

      &.mbsc-fr .mbsc-fr-w .mbsc-np-btn.mbsc-active {
        background: $frame-border;
        color: get-contrast-color($frame-border);
      }

      .mbsc-np-btn.mbsc-disabled {
        color: $input-border;
      }

    }
  }
}



@include exports("numpad.windows") {
  .mbsc-windows {
    &.mbsc-fr .mbsc-fr-w .mbsc-np-del.mbsc-active {
      top: 0;
    }

    .mbsc-np-del.mbsc-active:before {
      background: none;
    }

    &.mbsc-no-touch .mbsc-fr-c .mbsc-np-del:hover:before {
      background: transparent;
    }

    .mbsc-np-btn {
      top: 0;
    }

    .mbsc-np-btn-empty {
      background: none;
    }
  }

  @include mbsc-windows-numpad(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}



// forms component





@include exports("collapsible") {
  .mbsc-collapsible.mbsc-form-group {
    margin: 0;
  }

  .mbsc-collapsible-header {
    position: relative;
    padding-right: 3em;
  }

  .mbsc-collapsible .mbsc-collapsible-icon {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 50%;
    right: 1em;
    margin-top: -.5em;
    line-height: 1em;
    font-size: 1em;
    transition: transform .125s ease-out;
  }

  .mbsc-collapsible .mbsc-collapsible-content {
    overflow: hidden;
    transition: height .125s ease;
    box-sizing: content-box;
  }

  .mbsc-collapsible:not(.mbsc-collapsible-open) .mbsc-collapsible-content {
    height: 0;
    padding-bottom: 0;
  }

  .mbsc-collapsible-open > .mbsc-collapsible-header .mbsc-collapsible-icon {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }

  /* Collapsible rtl */

  .mbsc-rtl {

    .mbsc-collapsible.mbsc-form-group,
    .mbsc-collapsible.mbsc-card {

      .mbsc-collapsible-header,
      .mbsc-collapsible-header.mbsc-form-group-title {
        padding-right: 1em;
        padding-left: 3em;

        .mbsc-collapsible-icon {
          right: auto;
          left: 1em;
        }
      }
    }
  }
}


@include exports("collapsible.ios") {
  .mbsc-ios .mbsc-collapsible {
    .mbsc-collapsible-header {
      padding-right: 3em;
    }

    .mbsc-form-group-title {
      padding-top: 2.5em;
      margin: 0;

      .mbsc-collapsible-icon {
        margin-top: .5em;
      }
    }
  }
}











@mixin mbsc-ios-rating($theme, $params) {
  @include exports("rating.#{$theme}.colors") {

    @include mbsc-ios-progress($theme, $params);

    $accent: map-get($params, accent);

    .mbsc-#{$theme}.mbsc-rating {
      .mbsc-progress-track {
        color: $accent;
      }
    }
  }
}




@include exports("rating") {
  .mbsc-rating {
    &.mbsc-progress .mbsc-progress-cont {
      display: inline-block;
      width: auto;
      padding: 0 .5em;
      margin: 0 -.681818em;
      cursor: pointer;
    }

    .mbsc-progress-cont .mbsc-progress-track {
      height: auto;
      background: none;
    }

    .mbsc-ic {
      font-size: 1.375em;
      margin: 0 0.181818em;
    }

    &.mbsc-progress input {
      display: none;
    }

    &.mbsc-progress .mbsc-progress-cont .mbsc-slider-handle {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      top: 0;
      left: 0;
      right: 0;
      background: none;
      box-shadow: none;
      border: 0;
      -webkit-transform: none;
      transform: none;
    }

    .mbsc-progress-cont .mbsc-progress-bar {
      background: none;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}


@include exports("rating.ios") {
  .mbsc-ios.mbsc-rating {
    &.mbsc-progress .mbsc-input-wrap {
      padding: 0;
    }

    input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
      opacity: .4;
    }

    /* Color presets */
    &.mbsc-rating-primary .mbsc-progress-track {
      color: $mbsc-ios-primary;
    }

    &.mbsc-rating-secondary .mbsc-progress-track {
      color: $mbsc-ios-secondary;
    }

    &.mbsc-rating-success .mbsc-progress-track {
      color: $mbsc-ios-success;
    }

    &.mbsc-rating-danger .mbsc-progress-track {
      color: $mbsc-ios-danger;
    }

    &.mbsc-rating-warning .mbsc-progress-track {
      color: $mbsc-ios-warning;
    }

    &.mbsc-rating-info .mbsc-progress-track {
      color: $mbsc-ios-info;
    }
  }

  @include mbsc-ios-rating(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}








@mixin mbsc-ios-popup($theme, $params) {
  @include exports("popup.#{$theme}.colors") {

    @include mbsc-ios-frame($theme, $params);
  }
}




@include exports("popup") {

  .mbsc-wdg .mbsc-wdg-c {
    position: relative;
    z-index: 0;
    font-size: 14px;
    white-space: normal;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .mbsc-wdg .mbsc-ltr .mbsc-wdg-c {
    text-align: left;
  }

  .mbsc-wdg .mbsc-rtl .mbsc-wdg-c {
    text-align: right;
  }

  .mbsc-wdg .mbsc-wdg-c.mbsc-w-p {
    padding: 1em;
  }

  .mbsc-no-padding.mbsc-wdg .mbsc-wdg-c.mbsc-w-p {
    padding: 0;
  }
}


@include exports("popup.ios") {
  @include mbsc-ios-popup(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}





@mixin mbsc-ios-notifications($theme, $params) {
  @include exports("notifications.#{$theme}.colors") {

    @include mbsc-ios-popup($theme, $params);

    $colors: mbsc-ios-colors($params);
    $dark-text: map-get($colors, dark-text);
    $light-text: map-get($colors, light-text);
    $snackbar-button: map-get($colors, snackbar-button);

    .mbsc-#{$theme} {
      &.mbsc-alert .mbsc-input {
        border: 1px solid #ccc;
      }

      /* Snackbar */

      &.mbsc-snackbar .mbsc-fr-w {
        background: #646464;
        color: #fff;
      }

      &.mbsc-snackbar .mbsc-snackbar-btn {
        color: $snackbar-button;
      }

      /* Toast */

      &.mbsc-toast .mbsc-toast-msg {
        background: #646464;
        color: #fff;
      }

      /* Snackbar and Toast color presets */

      &.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
      &.mbsc-toast.mbsc-primary .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
      &.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-success .mbsc-fr-w,
      &.mbsc-toast.mbsc-success .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
      &.mbsc-toast.mbsc-danger .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
      &.mbsc-toast.mbsc-warning .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-info .mbsc-fr-w,
      &.mbsc-toast.mbsc-info .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
        color: $light-text;
      }
    }
  }
}




@include exports("notifications") {
  /* Toast, snackbar */

  .mbsc-toast .mbsc-fr-persp,
  .mbsc-snackbar .mbsc-fr-persp {
    pointer-events: none;
  }

  .mbsc-toast .mbsc-fr-overlay,
  .mbsc-snackbar .mbsc-fr-overlay {
    display: none;
  }

  .mbsc-toast.mbsc-fr .mbsc-fr-persp .mbsc-fr-popup,
  .mbsc-snackbar.mbsc-fr .mbsc-fr-persp .mbsc-fr-popup {
    border: 0;
    line-height: 1.428572;
  }

  /* Snackbar */

  .mbsc-snackbar.mbsc-fr .mbsc-fr-w {
    min-width: 18em;
    max-width: 36em;
    margin: 0 auto;
    pointer-events: auto;
    box-shadow: none;
  }

  .mbsc-snackbar-cont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mbsc-snackbar-msg {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0 1em;
  }

  .mbsc-snackbar .mbsc-snackbar-cont .mbsc-snackbar-btn {
    margin: -1em 0;
    padding: 1em;
    line-height: 1.428572;
    font-size: 1em;
    font-weight: 400;
  }

  .mbsc-snackbar.mbsc-no-touch.mbsc-fr .mbsc-fr-c .mbsc-btn-flat:not(:disabled):hover {
    background: none;
    box-shadow: none;
  }


  /* Toast */

  .mbsc-toast.mbsc-fr .mbsc-fr-w {
    background: none;
    box-shadow: none;
  }

  .mbsc-toast.mbsc-fr .mbsc-fr-c.mbsc-wdg-c {
    text-align: center;
    padding-bottom: 4em;
  }

  .mbsc-toast .mbsc-toast-msg {
    display: inline-block;
    min-width: 10em;
    max-width: 50em;
    padding: .857143em 2em;
  }

  /* Alert, confirm, prompt */

  .mbsc-alert.mbsc-fr .mbsc-fr-c.mbsc-wdg-c {
    padding: 1.714286em;
    max-width: 20em;
  }

  .mbsc-alert .mbsc-fr-c .mbsc-input {
    padding: 0;
    margin: 0;
  }

  .mbsc-alert.mbsc-fr h2 {
    margin: 0 0 1em 0;
    padding: 0;
    font-size: 1.428572em;
    font-weight: bold;
  }

  .mbsc-alert.mbsc-fr p {
    margin: 1em 0;
    padding: 0;
    font-size: 1em;
  }
}

@include exports("notifications.ios") {
  .mbsc-ios {
    &.mbsc-alert .mbsc-fr-c.mbsc-wdg-c {
      padding: 1.142857em;
      line-height: 1.285714;
      text-align: center;
    }

    &.mbsc-alert h2 {
      margin: 0.277778em 0;
      font-size: 1.285715em;
    }

    &.mbsc-alert p {
      margin: 0.357143em 0;
    }

    &.mbsc-alert .mbsc-input {
      margin-top: 1.142857em;
      border-radius: 0;
    }

    &.mbsc-alert .mbsc-input input {
      height: 1.714286em;
      padding: 0 0.357143em;
    }

    /* Toast */

    &.mbsc-toast .mbsc-toast-msg {
      border-radius: .5em;
      opacity: .9;
    }

    /* Snackbar and Toast color presets */

    &.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
    &.mbsc-toast.mbsc-primary .mbsc-toast-msg {
      background: $mbsc-ios-primary;
    }

    &.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
    &.mbsc-toast.mbsc-secondary .mbsc-toast-msg {
      background: $mbsc-ios-secondary;
    }

    &.mbsc-snackbar.mbsc-success .mbsc-fr-w,
    &.mbsc-toast.mbsc-success .mbsc-toast-msg {
      background: $mbsc-ios-success;
    }

    &.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
    &.mbsc-toast.mbsc-danger .mbsc-toast-msg {
      background: $mbsc-ios-danger;
    }

    &.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
    &.mbsc-toast.mbsc-warning .mbsc-toast-msg {
      background: $mbsc-ios-warning;
    }

    &.mbsc-snackbar.mbsc-info .mbsc-fr-w,
    &.mbsc-toast.mbsc-info .mbsc-toast-msg {
      background: $mbsc-ios-info;
    }
  }

  @include mbsc-ios-notifications(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}









// Theme specific variables - inherited from global variables

// General colors for the form
// Background
$mbsc-ios-form-background: $mbsc-form-background-light !default;
$mbsc-ios-dark-form-background: $mbsc-form-background-dark !default;
// Text
$mbsc-ios-form-text: $mbsc-form-text-light !default;
$mbsc-ios-dark-form-text: $mbsc-form-text-dark !default;
// Accent
$mbsc-ios-form-accent: $mbsc-form-accent-light !default;
$mbsc-ios-dark-form-accent: $mbsc-form-accent-dark !default;

// Button
// button background
$mbsc-ios-button-color: $mbsc-button-color-light !default;
$mbsc-ios-dark-button-color: $mbsc-button-color-dark !default;
// button text
$mbsc-ios-button-text: $mbsc-button-text-light !default;
$mbsc-ios-dark-button-text: $mbsc-button-text-dark !default;

// Form Group
// text
$mbsc-ios-form-group-title-text: $mbsc-form-group-title-text-light !default;
$mbsc-ios-dark-form-group-title-text: $mbsc-form-group-title-text-dark !default;

// add variables to light and dark color maps
$mbsc-ios-colors: map-merge($mbsc-ios-colors, (
  'form-background': $mbsc-ios-form-background,
  'form-text': $mbsc-ios-form-text,
  'form-accent': $mbsc-ios-form-accent,
  'button-color': $mbsc-ios-button-color,
  'button-text': $mbsc-ios-button-text,
  'form-group-title-text': $mbsc-ios-form-group-title-text,
));
$mbsc-ios-dark-colors: map-merge($mbsc-ios-dark-colors, (
  'form-background': $mbsc-ios-dark-form-background,
  'form-text': $mbsc-ios-dark-form-text,
  'form-accent': $mbsc-ios-dark-form-accent,
  'button-color': $mbsc-ios-dark-button-color,
  'button-text': $mbsc-ios-dark-button-text,
  'form-group-title-text': $mbsc-ios-dark-form-group-title-text,
));

@mixin mbsc-ios-forms($theme, $params) {
  @include exports("forms.#{$theme}.colors") {

    @include mbsc-ios-page($theme, $params);
    @include mbsc-ios-input($theme, $params);
    @include mbsc-ios-slider($theme, $params);
    @include mbsc-ios-rating($theme, $params);
    @include mbsc-ios-notifications($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');
    // Generic
    $background-param: map-get($params, 'form-background');
    $text-param: map-get($params, 'form-text');
    $accent-param: map-get($params, 'form-accent');
    // Button custom variables
    $button-background-param: map-get($params, 'button-color');
    $button-text-param: map-get($params, 'button-text');
    // Form Group custom variables
    $group-title-param: map-get($params, 'form-group-title-text');


    // default background color
    @if (lightness($background) > 50%) {
      $background: adjust-hue(darken(saturate($background, 19%), 2%), calc(240% / 1%));
    }
    @else {
      $background: adjust-hue(lighten(desaturate($background, 19%), 10%), calc(240% / 1%));
    }

    // overwrite custom variables
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    $button: '';
    @if (map-get($params, 'button')) {
      $button: map-get($params, 'button');
    }
    @else {
      $button: $accent;
    }

    $colors: mbsc-ios-colors($params);
    $dark-text: map-get($colors, 'dark-text');
    $white-text: map-get($colors, 'white-text');

    $cont-background: lighten($background, 6%);
    $form-selection: '';
    @if (lightness($button) > 50%) {
      $form-selection: lighten(saturate($accent, 15%), 3%);
    }
    @else {
      $form-selection: darken(desaturate($accent, 15%), 3%);
    }
    $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
    $border-color: '';
    $button-background: '';
    $disabled-background: '';
    $disabled-color: '';
    $form-text: '';
    $icon-color: lighten(saturate($text, 10%), 55%);
    $switch-track: #4cd764;
    $title-color: '';
    $track-background: '';
    $track-border: '';
    @if (lightness($background) > 50%) {
      $border-color: darken($background-limited, 17%);
      $button-background: lighten($background, 10%);
      $disabled-background: darken($background, 10%);
      $disabled-color: darken($background, 20%);
      $form-text: hsl(hue($text), saturation($text), max(lightness($text), 80%));
      $title-color: lighten(saturate($text, 2%), 44%);
      $track-background: darken($background, 7%);
      $track-border: darken($background, 7%);
    }
    @else {
      $border-color: lighten($background, 20%);
      $button-background: lighten($background, 23%);
      $disabled-background: lighten($background, 13%);
      $disabled-color: lighten($background, 23%);
      $form-text: hsl(hue($text), saturation($text), min(lightness($text), 80%));
      $switch-track: $accent;
      $title-color: darken(desaturate($text, 2%), 44%);
      $track-background: #fff;
      $track-border: lighten($background, 17%);
    }

    // overwrite button color variables
    $button-background: if($button-background-param, $button-background-param, $button-background);
    $button-text: if($button-text-param, $button-text-param, if($button-background-param, get-contrast-color($button-background-param), $accent));
    $title-color: if($group-title-param, $group-title-param, $title-color);

    .mbsc-#{$theme} {


      &.mbsc-form {
        background: $background;
        color: $text;
      }

      &.mbsc-form *::-moz-selection,
      &.mbsc-control-w *::-moz-selection,
      &.mbsc-form *::selection,
      &.mbsc-control-w *::selection {
        color: $form-text;
        background: $form-selection;
      }

      .mbsc-input-ic {
        color: $icon-color;
      }

      .mbsc-divider,
      .mbsc-form-group-title {
        border-bottom: 1px solid $border-color;
        color: $title-color;
      }

      .mbsc-divider {
        border-top: 1px solid $border-color;
      }

      /* Backgrounds */
      &.mbsc-form .mbsc-checkbox,
      &.mbsc-form .mbsc-radio,
      &.mbsc-form .mbsc-switch,
      &.mbsc-form .mbsc-segmented,
      &.mbsc-form .mbsc-stepper-cont,
      &.mbsc-input-box.mbsc-checkbox,
      &.mbsc-input-box.mbsc-radio,
      &.mbsc-input-box.mbsc-switch,
      &.mbsc-input-box.mbsc-stepper-cont,
      &.mbsc-input-outline.mbsc-checkbox,
      &.mbsc-input-outline.mbsc-radio,
      &.mbsc-input-outline.mbsc-switch,
      &.mbsc-input-outline.mbsc-stepper-cont {
        background: $cont-background;
      }

      &.mbsc-form .mbsc-input-box.mbsc-segmented,
      &.mbsc-form .mbsc-input-box.mbsc-segmented {
        background: transparent;
      }

      /* Checkbox */
      .mbsc-checkbox-box {
        border: .125em solid $form-selection;
      }

      .mbsc-checkbox-box:after {
        border: .125em solid $form-selection;
        border-top: 0;
        border-right: 0;
      }

      /* Radio */
      .mbsc-radio-box:after {
        border: .125em solid $form-selection;
        border-top: 0;
        border-right: 0;
      }

      &.mbsc-radio input:disabled ~ .mbsc-radio-label {
        color: $disabled-color;
      }

      /* Buttons */
      &.mbsc-btn {
        background: $button-background;
        color: $button-text;
      }

      &.mbsc-btn:not(.mbsc-btn-flat):disabled {
        background: $disabled-background;
        color: $disabled-color;
      }

      &.mbsc-btn.mbsc-btn-flat {
        background: transparent;
        border-color: transparent;
      }

      &.mbsc-btn-flat:disabled {
        background: transparent;
        color: $disabled-color;
      }

      /* Button color presets */
      &.mbsc-btn-primary.mbsc-btn,
      &.mbsc-btn-secondary.mbsc-btn,
      &.mbsc-btn-success.mbsc-btn,
      &.mbsc-btn-danger.mbsc-btn,
      &.mbsc-btn-warning.mbsc-btn,
      &.mbsc-btn-info.mbsc-btn,
      &.mbsc-btn-dark.mbsc-btn {
        color: $white-text;
      }

      &.mbsc-btn-light.mbsc-btn {
        color: $dark-text;
      }

      /* Flat buttons */
      &.mbsc-btn-flat.mbsc-btn {
        background: transparent;
      }

      /* Outline buttons */
      &.mbsc-btn-outline.mbsc-btn {
        background: transparent;
        border: 1px solid $button-text;
        color: $button-text;

        &.mbsc-active {
          background: $button-text;
          color: $button-background;
        }
      }

      &.mbsc-btn.mbsc-btn-outline:disabled {
        color: $disabled-color;
        border-color: $disabled-color;
        background: transparent;
      }

      /* Switch */
      .mbsc-switch-track {
        background: $track-background;
        border: .0625em solid $track-border;
      }

      .mbsc-switch-track:after {
        background: $cont-background;
      }

      .mbsc-switch-handle {
        background: $white-text;
      }

      &.mbsc-switch input:checked + .mbsc-switch-track {
        background: $switch-track;
        border-color: $switch-track;
      }

      /* Segmented & Stepper */
      .mbsc-segmented-content {
        color: $form-selection;
        border: .07142857em solid $form-selection;
        background: $cont-background;
      }

      &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content {
        background: $form-selection;
        color: $cont-background;
      }

      &.mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($accent, .3);
        color: $form-selection;
      }

      &.mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
        color: $disabled-color;
        border-color: $disabled-color;
      }

      .mbsc-stepper input:disabled {
        color: $disabled-color;
        -webkit-text-fill-color: $disabled-color;
      }

      &.mbsc-segmented input:disabled:checked + .mbsc-segmented-content {
        background: $disabled-background;
      }

      .mbsc-stepper input {
        color: $text;
      }

      &.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
        background: rgba($accent, .15);
      }
    }
  }
}











@include exports("forms") {
  /* Forms */

  [mbsc-form]:not(.mbsc-form) {
    // Prevent FOUC
    visibility: hidden;
  }

  .mbsc-form,
  .mbsc-control-w,
  .mbsc-btn,
  .mbsc-segmented {
    font-size: 16px;
    font-family: arial, verdana, sans-serif;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  .mbsc-form:before,
  .mbsc-form:after {
    content: '';
    display: table;
  }

  .mbsc-form-group {
    margin: 1.5em 0;
  }

  .mbsc-form-group-inset {
    margin: 2em 1.5em;
  }

  .mbsc-form-group,
  .mbsc-form-group-inset {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  /* No background inside widget */

  .mbsc-wdg .mbsc-w-p .mbsc-form {
    background: none;
  }

  /* Other mobiscroll components inline */

  .mbsc-input .mbsc-fr-inline .mbsc-fr-popup {
    display: block;
  }

  .mbsc-err-msg {
    display: block;
    font-size: .75em;
  }

  .mbsc-checkbox,
  .mbsc-switch,
  .mbsc-radio,
  .mbsc-stepper-cont {
    line-height: 1.25em;
  }

  .mbsc-checkbox,
  .mbsc-switch,
  .mbsc-btn,
  .mbsc-radio,
  .mbsc-segmented,
  .mbsc-stepper-cont {
    position: relative;
    display: block;
    margin: 0;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .mbsc-checkbox input,
  .mbsc-switch input,
  .mbsc-radio input,
  .mbsc-segmented input,
  .mbsc-btn {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .mbsc-checkbox input,
  .mbsc-switch input,
  .mbsc-radio input,
  .mbsc-select select,
  .mbsc-segmented input {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    opacity: 0;
    margin: 0;
  }

  .mbsc-btn,
  .mbsc-checkbox-box,
  .mbsc-checkbox-box:after,
  .mbsc-radio-box,
  .mbsc-radio-box:after,
  .mbsc-switch-track,
  .mbsc-segmented,
  .mbsc-progress progress,
  .mbsc-stepper-cont {
    box-sizing: border-box;
  }

  .mbsc-segmented .mbsc-segmented-content,
  .mbsc-btn-flat .mbsc-btn-ic {
    box-sizing: content-box;
  }

  .mbsc-desc {
    display: block;
    font-size: .75em;
    opacity: .6;
  }

  label.mbsc-input,
  .mbsc-label {
    margin: 0;
    display: block;
    font-weight: normal;
  }

  .mbsc-control-w {
    // Override bootstrap defaults
    max-width: none;
    margin: 0;
    font-size: 1em;
    font-weight: normal;
  }

  /* Textarea */

  .mbsc-input textarea {
    resize: none;
    overflow: hidden;
    line-height: 1.5em;
  }

  .mbsc-input .mbsc-textarea-scroll {
    overflow: auto;
  }

  /* Select */

  .mbsc-select select,
  .mbsc-select input {
    cursor: pointer;
  }

  .mbsc-select select option {
    color: initial;
  }

  .mbsc-select-ic {
    display: none;
  }

  /* Checkbox */

  .mbsc-checkbox {
    padding: .5em;
  }

  .mbsc-checkbox-box {
    position: absolute;
    top: 50%;
    display: block;
    width: 1.375em;
    height: 1.375em;
  }

  .mbsc-checkbox-box:after {
    content: '';
    position: absolute;
    display: block;
    opacity: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .mbsc-checkbox input:checked + .mbsc-checkbox-box:after {
    opacity: 1;
  }

  /* Radio */

  .mbsc-radio {
    padding: .5em;
  }

  .mbsc-radio-box {
    position: absolute;
    top: 50%;
    display: block;
    width: 1.25em;
    height: 1.25em;
    border-radius: 1.25em;
  }

  .mbsc-radio-box:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: .625em;
    height: .625em;
    margin-top: -.3125em;
    margin-left: -.3125em;
    border-radius: .625em;
    opacity: 0;
  }

  .mbsc-radio input:checked + .mbsc-radio-box:after {
    opacity: 1;
  }

  /* Switch */

  .mbsc-switch {
    padding: .5em;
  }

  .mbsc-switch-track {
    position: absolute;
    top: 50%;
    display: block;
    width: 3.375em;
    height: 1.625em;
    transition: background-color .2s ease-in-out, border .2s ease-in-out;
    z-index: 4;
  }

  .mbsc-switch .mbsc-switch-track .mbsc-progress-track {
    height: 100%;
    background: none;
  }

  .mbsc-switch .mbsc-switch-track .mbsc-slider-handle-cont {
    top: 50%;
  }

  .mbsc-switch-handle {
    position: absolute;
    display: block;
  }

  .mbsc-switch-txt-off,
  .mbsc-switch-txt-on {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    font-size: .625em;
    text-align: center;
    line-height: 2em;
  }

  /* Segmented control & Stepper */

  .mbsc-segmented {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .mbsc-segmented .mbsc-segmented-item {
    margin: 0;
    display: table-cell;
    position: relative;
    vertical-align: top;
    text-align: center;
    font-size: 1em;
  }

  .mbsc-segmented-content {
    position: relative;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: .875em;
    font-weight: normal;
    z-index: 2;
  }

  // Angular + Bootstrap compatibility
  .mbsc-segmented-item label {
    display: block;
    margin: 0;
  }

  .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-disabled .mbsc-segmented-content,
  .mbsc-segmented input:disabled + .mbsc-segmented-content {
    z-index: 0;
  }

  .mbsc-stepper {
    position: absolute;
    display: block;
    width: auto;
    right: 1em;
    top: 50%;
  }

  .mbsc-rtl .mbsc-stepper {
    right: auto;
    left: 1em;
  }

  .mbsc-stepper-cont .mbsc-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .mbsc-segmented-item:focus {
    outline: 0;
  }

  .mbsc-stepper input {
    position: absolute;
    left: 4.142857em;
    width: 4.142857em;
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
    font-size: .875em;
    text-align: center;
    opacity: 1;
    z-index: 4;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
  }

  .mbsc-stepper input::-webkit-outer-spin-button,
  .mbsc-stepper input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .mbsc-ltr.mbsc-stepper-val-right .mbsc-stepper input {
    left: auto;
    right: 0;
  }

  .mbsc-rtl.mbsc-stepper-val-right .mbsc-stepper input {
    right: auto;
    left: 0;
  }

  .mbsc-ltr.mbsc-stepper-val-left .mbsc-stepper input {
    left: 0;
  }

  .mbsc-rtl.mbsc-stepper-val-left .mbsc-stepper input {
    right: 0;
  }

  .mbsc-stepper .mbsc-segmented-item {
    width: 3.625em;
  }

  .mbsc-stepper-cont.mbsc-stepper-val-left .mbsc-stepper .mbsc-segmented-item:nth-child(2) .mbsc-segmented-content,
  .mbsc-stepper-cont.mbsc-stepper-val-right .mbsc-stepper .mbsc-segmented-item:last-child .mbsc-segmented-content {
    /* Strong rule is needed to override disabled styling */
    border: 0;
    background: transparent;
  }

  .mbsc-control-w.mbsc-stepper-cont .mbsc-stepper {
    padding: 0;
  }

  .mbsc-segmented-item .mbsc-control,
  .mbsc-stepper .mbsc-segmented-content {
    cursor: pointer;
  }

  .mbsc-disabled .mbsc-segmented-content,
  .mbsc-segmented input:disabled,
  .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content {
    cursor: not-allowed;
  }

  /* Buttons */

  .mbsc-btn {
    position: relative;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 1em;
    font-family: arial, verdana, sans-serif;
    cursor: pointer;
  }

  .mbsc-btn:disabled {
    cursor: not-allowed;

    * {
      /* Prevents click event firing for the Angular mbsc-button component, when disabled */
      pointer-events: none;
    }
  }

  .mbsc-btn:focus {
    outline: 0;
  }

  .mbsc-btn-ic {
    line-height: 1;
  }

  .mbsc-btn-group,
  .mbsc-btn-group-block,
  .mbsc-btn-group-justified {
    // Prevent margin collision
    border: 1px solid transparent;
  }

  .mbsc-btn-group-block .mbsc-btn,
  .mbsc-btn-block {
    display: block;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mbsc-btn-group-justified,
  .mbsc-btn-group-justified mbsc-button {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .mbsc-btn-group-justified .mbsc-btn,
  .mbsc-btn-group-justified mbsc-button {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }

  input:disabled + span .mbsc-slider-handle-cont {
    cursor: not-allowed;
  }

  /* Form in grid */

  .mbsc-form .mbsc-form-grid .mbsc-form-group-title {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}


@include exports("forms.ios") {
  .mbsc-ios {
    .mbsc-desc {
      opacity: .5;
    }

    .mbsc-divider,
    .mbsc-form-group-title {
      position: relative;
      z-index: 2;
      margin-top: -1px;
      font-size: 0.75em;
      line-height: 1.5em;
      text-transform: uppercase;
    }

    .mbsc-divider {
      padding: 2.333334em 1.333334em .5em 1.333334em;
    }

    .mbsc-form-group-title {
      padding: .5em 1.333334em;
    }

    &.mbsc-form-outline .mbsc-divider,
    &.mbsc-form-outline .mbsc-form-group-title,
    &.mbsc-form-box .mbsc-divider,
    &.mbsc-form-box .mbsc-form-group-title {
      border: 0;
    }

    /* Form grid */

    .mbsc-form-grid {
      margin-top: .75em;
      margin-bottom: .75em;
    }

    .mbsc-form-grid .mbsc-input-box.mbsc-control-w,
    .mbsc-form-grid .mbsc-input-outline.mbsc-control-w {
      margin: .75em 1em;
    }

    /* Checkbox, switch */
    &.mbsc-checkbox .mbsc-err-msg,
    &.mbsc-switch .mbsc-err-msg,
    &.mbsc-radio .mbsc-err-msg {
      padding: 0;
      margin-bottom: -1em;
    }

    /* Checkbox, radio */
    &.mbsc-ltr.mbsc-checkbox,
    &.mbsc-ltr.mbsc-radio {
      padding: .875em 3.75em .875em 1em;
    }

    &.mbsc-rtl.mbsc-checkbox,
    &.mbsc-rtl.mbsc-radio {
      padding: .875em 1em .875em 3.75em;
    }

    &.mbsc-checkbox input:disabled + .mbsc-checkbox-box,
    &.mbsc-checkbox input:disabled ~ .mbsc-label,
    &.mbsc-checkbox input:disabled ~ .mbsc-desc,
    &.mbsc-radio input:disabled + .mbsc-radio-box,
    &.mbsc-radio input:disabled ~ .mbsc-label,
    &.mbsc-radio input:disabled ~ .mbsc-desc,
    &.mbsc-switch input:disabled + .mbsc-switch-track,
    &.mbsc-switch input:disabled ~ .mbsc-label,
    &.mbsc-switch input:disabled ~ .mbsc-desc {
      opacity: .3;
    }

    .mbsc-checkbox-box:after,
    .mbsc-radio-box:after {
      transition: opacity .2s ease-in-out;
    }

    /* Checkbox */
    .mbsc-checkbox-box {
      right: 1em;
      width: 1.75em;
      height: 1.75em;
      margin-top: -.875em;
      background: transparent;
      border-radius: 2em;
    }

    &.mbsc-rtl .mbsc-checkbox-box {
      right: auto;
      left: 1em;
    }

    .mbsc-checkbox-box:after {
      top: 32%;
      left: 26%;
      width: .75em;
      height: .375em;
    }

    /* Checkbox color presets */
    &.mbsc-checkbox-primary {

      .mbsc-checkbox-box,
      .mbsc-checkbox-box:after {
        border-color: $mbsc-ios-primary;
      }
    }

    &.mbsc-checkbox-secondary {

      .mbsc-checkbox-box,
      .mbsc-checkbox-box:after {
        border-color: $mbsc-ios-secondary;
      }
    }

    &.mbsc-checkbox-success {

      .mbsc-checkbox-box,
      .mbsc-checkbox-box:after {
        border-color: $mbsc-ios-success;
      }
    }

    &.mbsc-checkbox-danger {

      .mbsc-checkbox-box,
      .mbsc-checkbox-box:after {
        border-color: $mbsc-ios-danger;
      }
    }

    &.mbsc-checkbox-warning {

      .mbsc-checkbox-box,
      .mbsc-checkbox-box:after {
        border-color: $mbsc-ios-warning;
      }
    }

    &.mbsc-checkbox-info {

      .mbsc-checkbox-box,
      .mbsc-checkbox-box:after {
        border-color: $mbsc-ios-info;
      }
    }

    /* Radio */
    .mbsc-radio-box {
      right: 1.125em;
      margin-top: -.625em;
      background: transparent;
    }

    &.mbsc-rtl .mbsc-radio-box {
      right: auto;
      left: 1.125em;
    }

    .mbsc-radio-box:after {
      position: absolute;
      top: 44%;
      left: 23%;
      width: 1em;
      height: .5em;
      border-radius: 0;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    /* Radio color presets */
    &.mbsc-radio-primary .mbsc-radio-box:after {
      border-color: $mbsc-ios-primary;
    }

    &.mbsc-radio-secondary .mbsc-radio-box:after {
      border-color: $mbsc-ios-secondary;
    }

    &.mbsc-radio-success .mbsc-radio-box:after {
      border-color: $mbsc-ios-success;
    }

    &.mbsc-radio-danger .mbsc-radio-box:after {
      border-color: $mbsc-ios-danger;
    }

    &.mbsc-radio-warning .mbsc-radio-box:after {
      border-color: $mbsc-ios-warning;
    }

    &.mbsc-radio-info .mbsc-radio-box:after {
      border-color: $mbsc-ios-info;
    }

    /* Buttons */
    &.mbsc-btn {
      margin: .5em .25em;
      padding: .625em 1.375em;
      border: 0;
      border-radius: .25em;
    }

    .mbsc-btn-ic {
      padding-right: .625em;
    }

    .mbsc-btn-icon-only .mbsc-btn-ic {
      padding: 0;
    }

    &.mbsc-no-touch.mbsc-btn:not(:disabled):not(.mbsc-active):hover {
      opacity: .8;
    }

    &.mbsc-btn.mbsc-active {
      opacity: .6;
    }

    &.mbsc-btn-flat.mbsc-active {
      opacity: .6;
    }

    &.mbsc-btn-flat:disabled {
      opacity: .8;
    }

    .mbsc-btn-group,
    .mbsc-btn-group-justified,
    .mbsc-btn-group-block {
      margin: .5em .75em;
    }

    .mbsc-btn-group-block {
      margin: .5em 1em;
    }

    /* Button color presets */
    &.mbsc-btn-primary.mbsc-btn {
      background: $mbsc-ios-primary;

      &.mbsc-btn-flat {
        color: $mbsc-ios-primary;
      }
    }

    &.mbsc-btn-secondary.mbsc-btn {
      background: $mbsc-ios-secondary;

      &.mbsc-btn-flat {
        color: $mbsc-ios-secondary;
      }
    }

    &.mbsc-btn-success.mbsc-btn {
      background: $mbsc-ios-success;

      &.mbsc-btn-flat {
        color: $mbsc-ios-success;
      }
    }

    &.mbsc-btn-danger.mbsc-btn {
      background: $mbsc-ios-danger;

      &.mbsc-btn-flat {
        color: $mbsc-ios-danger;
      }
    }

    &.mbsc-btn-warning.mbsc-btn {
      background: $mbsc-ios-warning;

      &.mbsc-btn-flat {
        color: $mbsc-ios-warning;
      }
    }

    &.mbsc-btn-info.mbsc-btn {
      background: $mbsc-ios-info;

      &.mbsc-btn-flat {
        color: $mbsc-ios-info;
      }
    }

    &.mbsc-btn-light.mbsc-btn {
      background: $mbsc-ios-light;

      &.mbsc-btn-flat {
        color: darken($mbsc-ios-light, 20%);
      }
    }

    &.mbsc-btn-dark.mbsc-btn {
      background: $mbsc-ios-dark;

      &.mbsc-btn-flat {
        color: $mbsc-ios-dark;
      }
    }

    /* Flat buttons */
    &.mbsc-btn-flat.mbsc-btn {
      background: transparent;
    }

    /* Outline buttons */
    &.mbsc-btn-outline.mbsc-btn.mbsc-active {
      opacity: 1;
    }

    /* Outline buttons */
    &.mbsc-btn-outline.mbsc-btn {
      &.mbsc-btn-primary {
        border-color: $mbsc-ios-primary;
        color: $mbsc-ios-primary;

        &.mbsc-active {
          background: $mbsc-ios-primary;
          color: $mbsc-ios-light;
        }
      }

      &.mbsc-btn-secondary {
        border-color: $mbsc-ios-secondary;
        color: $mbsc-ios-secondary;

        &.mbsc-active {
          background: $mbsc-ios-secondary;
          color: $mbsc-ios-light;
        }
      }

      &.mbsc-btn-success {
        border-color: $mbsc-ios-success;
        color: $mbsc-ios-success;

        &.mbsc-active {
          background: $mbsc-ios-success;
          color: $mbsc-ios-light;
        }
      }

      &.mbsc-btn-danger {
        border-color: $mbsc-ios-danger;
        color: $mbsc-ios-danger;

        &.mbsc-active {
          background: $mbsc-ios-danger;
          color: $mbsc-ios-light;
        }
      }

      &.mbsc-btn-warning {
        border-color: $mbsc-ios-warning;
        color: $mbsc-ios-warning;

        &.mbsc-active {
          background: $mbsc-ios-warning;
          color: $mbsc-ios-light;
        }
      }

      &.mbsc-btn-info {
        border-color: $mbsc-ios-info;
        color: $mbsc-ios-info;

        &.mbsc-active {
          background: $mbsc-ios-info;
          color: $mbsc-ios-light;
        }
      }

      &.mbsc-btn-light {
        border-color: darken($mbsc-ios-light, 25%);
        color: darken($mbsc-ios-light, 25%);

        &.mbsc-active {
          background: darken($mbsc-ios-light, 25%);
          color: $mbsc-ios-light;
        }
      }

      &.mbsc-btn-dark {
        border-color: $mbsc-ios-dark;
        color: $mbsc-ios-dark;

        &.mbsc-active {
          background: $mbsc-ios-dark;
          color: $mbsc-ios-light;
        }
      }
    }

    /* Switch */
    &.mbsc-ltr.mbsc-switch {
      padding: .875em 5em .875em 1em;
    }

    &.mbsc-rtl.mbsc-switch {
      padding: .875em 1em .875em 5em;
    }

    .mbsc-switch-track {
      right: 1em;
      width: 3em;
      height: 1.75em;
      padding: 0;
      margin-top: -.96875em;
      border-radius: 1.25em;
      box-sizing: content-box;
    }

    &.mbsc-rtl .mbsc-switch-track {
      right: auto;
      left: 1em;
    }

    .mbsc-switch-track:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 1.25em;
      -webkit-transform: scale(1);
      transform: scale(1);
      transition: transform .2s ease-out;
    }

    .mbsc-switch-track .mbsc-progress-track {
      margin: 0 .875em;
    }

    .mbsc-switch-handle {
      z-index: 2;
      top: 50%;
      left: 50%;
      width: 1.75em;
      height: 1.75em;
      margin: -.875em 0 0 -.875em;
      border-radius: 1.75em;
      box-shadow: 0 0 1em rgba(0, 0, 0, .1), 0 0 .0625em rgba(0, 0, 0, .15), 0 .125em .125em rgba(0, 0, 0, .15);
      transition: transform .2s ease-out;
    }

    &.mbsc-rtl .mbsc-switch-handle {
      margin: -.875em -.875em 0 0;
    }

    &.mbsc-switch input:checked + .mbsc-switch-track:after {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    /* swithc color presets */
    &.mbsc-switch-primary.mbsc-switch input:checked + .mbsc-switch-track {
      border-color: $mbsc-ios-primary;
      background: $mbsc-ios-primary;
    }

    &.mbsc-switch-secondary.mbsc-switch input:checked + .mbsc-switch-track {
      border-color: $mbsc-ios-secondary;
      background: $mbsc-ios-secondary;
    }

    &.mbsc-switch-success.mbsc-switch input:checked + .mbsc-switch-track {
      border-color: $mbsc-ios-success;
      background: $mbsc-ios-success;
    }

    &.mbsc-switch-danger.mbsc-switch input:checked + .mbsc-switch-track {
      border-color: $mbsc-ios-danger;
      background: $mbsc-ios-danger;
    }

    &.mbsc-switch-warning.mbsc-switch input:checked + .mbsc-switch-track {
      border-color: $mbsc-ios-warning;
      background: $mbsc-ios-warning;
    }

    &.mbsc-switch-info.mbsc-switch input:checked + .mbsc-switch-track {
      border-color: $mbsc-ios-info;
      background: $mbsc-ios-info;
    }

    /* Segmented & Stepper */
    &.mbsc-segmented {
      padding: .75em;
    }

    .mbsc-stepper {
      display: block;
    }

    .mbsc-segmented-content {
      height: 2.142857142em;
      margin-left: -.07142857em;
      line-height: 2.142857142857143em;
      padding: 0 .42857em;
    }

    &.mbsc-ltr .mbsc-stepper-minus .mbsc-segmented-content,
    &.mbsc-ltr .mbsc-segmented-item:first-child .mbsc-segmented-content,
    &.mbsc-rtl .mbsc-stepper-plus .mbsc-segmented-content,
    &.mbsc-rtl .mbsc-segmented-item:last-child .mbsc-segmented-content {
      border-top-left-radius: .25em;
      border-bottom-left-radius: .25em;
    }

    .mbsc-segmented.mbsc-stepper.mbsc-control-w:before,
    .mbsc-segmented.mbsc-stepper.mbsc-control-w:after {
      border: 0;
    }

    &.mbsc-ltr .mbsc-stepper-plus .mbsc-segmented-content,
    &.mbsc-ltr .mbsc-segmented-item:last-child .mbsc-segmented-content,
    &.mbsc-rtl .mbsc-stepper-minus .mbsc-segmented-content,
    &.mbsc-rtl .mbsc-segmented-item:first-child .mbsc-segmented-content {
      border-top-right-radius: .25em;
      border-bottom-right-radius: .25em;
    }

    &.mbsc-ltr.mbsc-stepper-cont {
      padding: 1.5em 11.75em 1.5em 1em;
    }

    &.mbsc-rtl.mbsc-stepper-cont {
      padding: 1.5em 1em 1.5em 11.75em;
    }

    .mbsc-stepper {
      right: 1em;
      margin-top: -1em;
    }

    &.mbsc-rtl .mbsc-stepper {
      right: auto;
      left: 1em;
    }

    &.mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
    &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
    &.mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
      background: none;
    }

    .mbsc-stepper .mbsc-segmented-item {
      width: 3.25em;
    }

    .mbsc-stepper input {
      width: 3.714286em;
      left: 3.714286em;
      z-index: 3;
    }

    &.mbsc-segmented {

      /* Stepper color presets */
      .mbsc-segmented-primary {
        .mbsc-segmented-content {
          border-color: $mbsc-ios-primary;
          color: $mbsc-ios-primary;
        }

        &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
          background: $mbsc-ios-primary;
        }

        &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
          color: $mbsc-ios-primary;
          background: rgba($mbsc-ios-primary, .45);
        }
      }

      .mbsc-segmented-secondary {
        .mbsc-segmented-content {
          border-color: $mbsc-ios-secondary;
          color: $mbsc-ios-secondary;
        }

        &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
          background: $mbsc-ios-secondary;
        }

        &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
          color: $mbsc-ios-secondary;
          background: rgba($mbsc-ios-secondary, .45);
        }
      }

      .mbsc-segmented-success {
        .mbsc-segmented-content {
          border-color: $mbsc-ios-success;
          color: $mbsc-ios-success;
        }

        &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
          background: $mbsc-ios-success;
        }

        &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
          color: $mbsc-ios-success;
          background: rgba($mbsc-ios-success, .45);
        }
      }

      .mbsc-segmented-danger {
        .mbsc-segmented-content {
          border-color: $mbsc-ios-danger;
          color: $mbsc-ios-danger;
        }

        &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
          background: $mbsc-ios-danger;
        }

        &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
          color: $mbsc-ios-danger;
          background: rgba($mbsc-ios-danger, .45);
        }
      }

      .mbsc-segmented-warning {
        .mbsc-segmented-content {
          border-color: $mbsc-ios-warning;
          color: $mbsc-ios-warning;
        }

        &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
          background: $mbsc-ios-warning;
        }

        &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
          color: $mbsc-ios-warning;
          background: rgba($mbsc-ios-warning, .45);
        }
      }

      .mbsc-segmented-info {
        .mbsc-segmented-content {
          border-color: $mbsc-ios-info;
          color: $mbsc-ios-info;
        }

        &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
          background: $mbsc-ios-info;
        }

        &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
          color: $mbsc-ios-info;
          background: rgba($mbsc-ios-info, .45);
        }
      }
    }

    &.mbsc-no-touch {

      /* Segmented hover color presets*/
      .mbsc-segmented-primary {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-ios-primary, .15);
        }
      }

      .mbsc-segmented-secondary {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-ios-secondary, .15);
        }
      }

      .mbsc-segmented-success {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-ios-success, .15);
        }
      }

      .mbsc-segmented-danger {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-ios-danger, .15);
        }
      }

      .mbsc-segmented-warning {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-ios-warning, .15);
        }
      }

      .mbsc-segmented-info {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-ios-info, .15);
        }
      }

      /* Stepper color presets*/
      &.mbsc-stepper-primary {
        .mbsc-segmented .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-ios-primary, .15);
        }
      }

      &.mbsc-stepper-secondary {
        .mbsc-segmented .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-ios-secondary, .15);
        }
      }

      &.mbsc-stepper-success {
        .mbsc-segmented .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-ios-success, .15);
        }
      }

      &.mbsc-stepper-danger {
        .mbsc-segmented .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-ios-danger, .15);
        }
      }

      &.mbsc-stepper-warning {
        .mbsc-segmented .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-ios-warning, .15);
        }
      }

      &.mbsc-stepper-info {
        .mbsc-segmented .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-ios-info, .15);
        }
      }
    }

    /* Stepper color presets */
    &.mbsc-stepper-primary {
      .mbsc-segmented-content {
        border-color: $mbsc-ios-primary;
        color: $mbsc-ios-primary;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-ios-primary;
      }
    }

    &.mbsc-stepper-secondary {
      .mbsc-segmented-content {
        border-color: $mbsc-ios-secondary;
        color: $mbsc-ios-secondary;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-ios-secondary;
      }
    }

    &.mbsc-stepper-success {
      .mbsc-segmented-content {
        border-color: $mbsc-ios-success;
        color: $mbsc-ios-success;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-ios-success;
      }
    }

    &.mbsc-stepper-danger {
      .mbsc-segmented-content {
        border-color: $mbsc-ios-danger;
        color: $mbsc-ios-danger;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-ios-danger;
      }
    }

    &.mbsc-stepper-warning {
      .mbsc-segmented-content {
        border-color: $mbsc-ios-warning;
        color: $mbsc-ios-warning;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-ios-warning;
      }
    }

    &.mbsc-stepper-info {
      .mbsc-segmented-content {
        border-color: $mbsc-ios-info;
        color: $mbsc-ios-info;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-ios-info;
      }
    }
  }

  @include mbsc-ios-forms(ios, $mbsc-ios-colors);
}














@include exports("collapsible.mobiscroll") {
  .mbsc-mobiscroll .mbsc-collapsible {
    .mbsc-form-group-title {
      padding: 1em;
    }

    .mbsc-collapsible-header {
      padding-right: 3em;
    }
  }
}










@mixin mbsc-mobiscroll-rating($theme, $params) {
  @include exports("rating.#{$theme}.colors") {

    @include mbsc-mobiscroll-progress($theme, $params);

    $accent: map-get($params, accent);

    .mbsc-#{$theme}.mbsc-rating {
      .mbsc-progress-track {
        color: $accent;
      }
    }
  }
}



@include exports("rating.mobiscroll") {
  .mbsc-mobiscroll.mbsc-rating {
    .mbsc-label {
      padding-bottom: .5em;
    }

    input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
      opacity: .4;
    }

    &.mbsc-rating-primary .mbsc-progress-track {
      color: $mbsc-mobiscroll-primary;
    }

    &.mbsc-rating-secondary .mbsc-progress-track {
      color: $mbsc-mobiscroll-secondary;
    }

    &.mbsc-rating-success .mbsc-progress-track {
      color: $mbsc-mobiscroll-success;
    }

    &.mbsc-rating-danger .mbsc-progress-track {
      color: $mbsc-mobiscroll-danger;
    }

    &.mbsc-rating-warning .mbsc-progress-track {
      color: $mbsc-mobiscroll-warning;
    }

    &.mbsc-rating-info .mbsc-progress-track {
      color: $mbsc-mobiscroll-info;
    }
  }

  @include mbsc-mobiscroll-rating(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}








@mixin mbsc-mobiscroll-popup($theme, $params) {
  @include exports("popup.#{$theme}.colors") {

    @include mbsc-mobiscroll-frame($theme, $params);
  }
}



@include exports("popup.mobiscroll") {
  @include mbsc-mobiscroll-popup(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}





@mixin mbsc-mobiscroll-notifications($theme, $params) {
  @include exports("notifications.#{$theme}.colors") {

    @include mbsc-mobiscroll-popup($theme, $params);

    $background: map-get($params, background);

    $colors: mbsc-mobiscroll-colors($params);
    $notification: map-get($colors, notification);
    $white-text: map-get($colors, white-text);

    .mbsc-#{$theme} {

      &.mbsc-snackbar .mbsc-fr-w,
      &.mbsc-toast .mbsc-toast-msg {
        background: $notification;
        color: $white-text;
      }

      /* Snackbar and Toast color presets */
      &.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
      &.mbsc-toast.mbsc-primary .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
      &.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-success .mbsc-fr-w,
      &.mbsc-toast.mbsc-success .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
      &.mbsc-toast.mbsc-danger .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
      &.mbsc-toast.mbsc-warning .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-info .mbsc-fr-w,
      &.mbsc-toast.mbsc-info .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
        color: $background;
      }
    }
  }
}



@include exports("notifications.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-toast .mbsc-toast-msg {
      opacity: .9;
      border-radius: .5em;
    }

    /* Snackbar and Toast color presets */
    &.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
    &.mbsc-toast.mbsc-primary .mbsc-toast-msg {
      background: $mbsc-mobiscroll-primary;
    }

    &.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
    &.mbsc-toast.mbsc-secondary .mbsc-toast-msg {
      background: $mbsc-mobiscroll-secondary;
    }

    &.mbsc-snackbar.mbsc-success .mbsc-fr-w,
    &.mbsc-toast.mbsc-success .mbsc-toast-msg {
      background: $mbsc-mobiscroll-success;
    }

    &.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
    &.mbsc-toast.mbsc-danger .mbsc-toast-msg {
      background: $mbsc-mobiscroll-danger;
    }

    &.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
    &.mbsc-toast.mbsc-warning .mbsc-toast-msg {
      background: $mbsc-mobiscroll-warning;
    }

    &.mbsc-snackbar.mbsc-info .mbsc-fr-w,
    &.mbsc-toast.mbsc-info .mbsc-toast-msg {
      background: $mbsc-mobiscroll-info;
    }
  }

  @include mbsc-mobiscroll-notifications(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}









// Theme specific variables - inherited from global variables

// General colors for the form
// Background
$mbsc-mobiscroll-form-background: $mbsc-form-background-light !default;
$mbsc-mobiscroll-dark-form-background: $mbsc-form-background-dark !default;
// Text
$mbsc-mobiscroll-form-text: $mbsc-form-text-light !default;
$mbsc-mobiscroll-dark-form-text: $mbsc-form-text-dark !default;
// Accent
$mbsc-mobiscroll-form-accent: $mbsc-form-accent-light !default;
$mbsc-mobiscroll-dark-form-accent: $mbsc-form-accent-dark !default;

// Button
// Button background
$mbsc-mobiscroll-button-color: $mbsc-button-color-light !default;
$mbsc-mobiscroll-dark-button-color: $mbsc-button-color-dark !default;
// Button text
$mbsc-mobiscroll-button-text: $mbsc-button-text-light !default;
$mbsc-mobiscroll-dark-button-text: $mbsc-button-text-dark !default;

// Form Group
// text
$mbsc-mobiscroll-form-group-title-text: $mbsc-form-group-title-text-light !default;
$mbsc-mobiscroll-dark-form-group-title-text: $mbsc-form-group-title-text-dark !default;

// add variables to light and dark color maps
$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'form-background': $mbsc-mobiscroll-form-background,
  'form-text': $mbsc-mobiscroll-form-text,
  'form-accent': $mbsc-mobiscroll-form-accent,
  'button-color': $mbsc-mobiscroll-button-color,
  'button-text': $mbsc-mobiscroll-button-text,
  'form-group-title-text': $mbsc-mobiscroll-form-group-title-text,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'form-background': $mbsc-mobiscroll-dark-form-background,
  'form-text': $mbsc-mobiscroll-dark-form-text,
  'form-accent': $mbsc-mobiscroll-dark-form-accent,
  'button-color': $mbsc-mobiscroll-dark-button-color,
  'button-text': $mbsc-mobiscroll-dark-button-text,
  'form-group-title-text': $mbsc-mobiscroll-dark-form-group-title-text,
));

@mixin mbsc-mobiscroll-forms($theme, $params) {
  @include exports("forms.#{$theme}.colors") {

    @include mbsc-mobiscroll-page($theme, $params);
    @include mbsc-mobiscroll-input($theme, $params);
    @include mbsc-mobiscroll-slider($theme, $params);
    @include mbsc-mobiscroll-rating($theme, $params);
    @include mbsc-mobiscroll-notifications($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');
    // Generic
    $background-param: map-get($params, 'form-background');
    $text-param: map-get($params, 'form-text');
    $accent-param: map-get($params, 'form-accent');
    // Button
    $button-background-param: map-get($params, 'button-color');
    $button-text-param: map-get($params, 'button-text');
    // Form group
    $group-title-param: map-get($params, 'form-group-title-text');

    $colors: mbsc-mobiscroll-colors($params);
    $input-disabled: map-get($colors, 'input-disabled');
    // static colors
    $white-text: map-get($colors, 'white-text');
    $dark-text: map-get($colors, 'dark-text');
    $error: map-get($colors, 'error');

    // overwrite custom variables
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    // derived colors
    $btn-light: '';
    $btn-disabled: '';
    $switch: '';
    $handle: '';
    $checkbox: '';
    $checked: desaturate(lighten($accent, 25%), 6%);
    // Light background
    @if (lightness($background) > 50%) {
      $btn-light: darken($background, 3%);
      $btn-disabled: darken($background, 10%);
      $switch: darken($background, 19%);
      $handle: darken($background, 8%);
      $checkbox: lighten($background, 3%);
    }
    // Dark background
    @else {
      $btn-light: $background;
      $btn-disabled: lighten($background, 8%);
      $switch: lighten($background, 14%);
      $handle: lighten($background, 7%);
      $checkbox: $background;
    }

    $button-background: if($button-background-param, $button-background-param, $accent);
    $button-text: if($button-text-param, $button-text-param, if($button-background-param, get-contrast-color($button-background-param), $btn-light));
    $button-active: darken($button-background, 4%);
    $group-title: if($group-title-param, $group-title-param, $accent);

    .mbsc-#{$theme} {

      &.mbsc-form {
        background: $background;
        color: $text;
      }

      // when controls are outside of form
      &.mbsc-control-w {
        color: $text;
      }

      &.mbsc-form *::-moz-selection,
      &.mbsc-control-w *::-moz-selection,
      &.mbsc-form *::selection,
      &.mbsc-control-w *::selection {
        color: $white-text;
        background: $accent;
      }

      .mbsc-divider,
      .mbsc-form-group-title {
        background: $background;
        color: $group-title;
      }

      .mbsc-err-msg,
      .mbsc-err .mbsc-label {
        color: $error;
      }

      /* Form grid */
      .mbsc-checkbox-box {
        background: $accent;
      }

      .mbsc-checkbox-box:after {
        border-bottom: .125em solid $checkbox;
        border-left: .125em solid $checkbox;
      }

      &.mbsc-checkbox input:disabled+.mbsc-checkbox-box {
        background: $input-disabled;
      }

      /* Radio */
      .mbsc-radio-box {
        border: .125em solid $accent;
      }

      .mbsc-radio-box:after {
        background: $accent;
      }

      &.mbsc-radio input:checked+.mbsc-radio-box {
        background: transparent;
      }

      &.mbsc-radio input:disabled+.mbsc-radio-box {
        border-color: $input-disabled;
      }

      &.mbsc-radio input:disabled+.mbsc-radio-box:after {
        background: $input-disabled;
      }

      /* Buttons */
      &.mbsc-btn {
        background: $button-background;
        color: $button-text;
      }

      &.mbsc-btn:disabled {
        background: $btn-disabled;
      }

      &.mbsc-no-touch.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
        background: rgba($background, .1);
      }

      &.mbsc-btn-flat {
        background: transparent;
        color: $button-background;
        border-color: transparent;
      }

      &.mbsc-btn-flat.mbsc-btn.mbsc-active {
        background: rgba($button-background, .3)
      }

      &.mbsc-btn-flat:disabled {
        color: $switch;
        background: transparent;
      }

      &.mbsc-btn-light.mbsc-btn {
        color: $dark-text;
      }

      /* Outline buttons */
      &.mbsc-btn-outline.mbsc-btn {
        border: 1px solid $button-background;
        color: $button-background;

        &.mbsc-active {
          background: $button-background;
          color: $background;
        }

        &.mbsc-btn-primary.mbsc-active,
        &.mbsc-btn-secondary.mbsc-active,
        &.mbsc-btn-success.mbsc-active,
        &.mbsc-btn-danger.mbsc-active,
        &.mbsc-btn-warning.mbsc-active,
        &.mbsc-btn-info.mbsc-active,
        &.mbsc-btn-dark.mbsc-active {
          color: $background;
        }
      }

      &.mbsc-btn.mbsc-btn-outline:disabled {
        color: $btn-disabled;
        border-color: $btn-disabled;
        background: transparent;
      }

      /* Switch */
      .mbsc-switch-track {
        background: $btn-disabled;
      }

      .mbsc-switch-handle {
        background: $switch;
      }

      &.mbsc-switch input:checked+.mbsc-switch-track {
        background: $checked;
      }

      &.mbsc-switch input:checked+.mbsc-switch-track .mbsc-switch-handle {
        background: $accent;
      }

      &.mbsc-switch input:disabled+.mbsc-switch-track {
        background: $input-disabled;
      }

      &.mbsc-switch input:disabled+.mbsc-switch-track .mbsc-switch-handle {
        background: $handle;
      }

      /* Stepper and Segmented */
      .mbsc-segmented-content {
        border: .142857em solid $accent;
        color: $accent;
      }

      &.mbsc-stepper input {
        color: $text;
      }

      &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item input:checked+.mbsc-segmented-content {
        background: $accent;
        color: $background;
      }

      &.mbsc-segmented .mbsc-segmented-item input.mbsc-active+.mbsc-segmented-content {
        background: rgba($accent, .3);
      }

      &.mbsc-segmented input:disabled~.mbsc-segmented-item .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item input:disabled+.mbsc-segmented-content {
        color: $input-disabled;
        border-color: $input-disabled;
      }

      .mbsc-stepper input:disabled {
        color: $input-disabled;
        -webkit-text-fill-color: $input-disabled;
      }

      &.mbsc-segmented input:disabled:checked+.mbsc-segmented-content {
        border-color: $btn-disabled;
        background: $btn-disabled;
        color: $btn-light;
      }

      &.mbsc-stepper .mbsc-active.mbsc-disabled .mbsc-segmented-content {
        background: transparent;
        color: $input-disabled;
      }

      &.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
        background: rgba($accent, .1)
      }
    }
  }
}


@include exports("forms.mobiscroll") {

  .mbsc-mobiscroll {

    .mbsc-divider,
    .mbsc-form-group-title {
      padding: .5em 1em;
    }

    .mbsc-err-msg {
      position: absolute;
      padding-top: .333334em;
      font-size: .75em;
    }

    /* Form grid */

    .mbsc-form-grid {
      margin-top: .75em;
      margin-bottom: .75em;
    }

    .mbsc-form-grid .mbsc-input-box,
    .mbsc-form-grid .mbsc-input-outline {
      margin: .75em 1em;
    }

    /* Checkbox, switch, radio */
    &.mbsc-checkbox .mbsc-err-msg,
    &.mbsc-switch .mbsc-err-msg,
    &.mbsc-radio .mbsc-err-msg {
      padding-top: 0;
    }

    /* Checkbox */
    &.mbsc-ltr.mbsc-checkbox {
      padding: 1em 3.125em 1em 1em;
    }

    &.mbsc-rtl.mbsc-checkbox {
      padding: 1em 1em 1em 3.125em;
    }

    .mbsc-checkbox-box {
      margin-top: -.5625em;
      width: 1.125em;
      height: 1.125em;
      right: 1em;
    }

    &.mbsc-rtl .mbsc-checkbox-box {
      right: auto;
      left: 1em;
    }

    .mbsc-checkbox-box:after {
      top: 0.25em;
      left: .185em;
      width: .8125em;
      height: .4375em;
    }

    /* Checkbox color presets */
    &.mbsc-checkbox-primary .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-primary;
    }

    &.mbsc-checkbox-secondary .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-secondary;
    }

    &.mbsc-checkbox-success .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-success;
    }

    &.mbsc-checkbox-danger .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-danger;
    }

    &.mbsc-checkbox-warning .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-warning;
    }

    &.mbsc-checkbox-info .mbsc-checkbox-box {
      background: $mbsc-mobiscroll-info;
    }

    /* Radio */
    &.mbsc-radio {
      padding: 1em 3.125em 1em 1em;
    }

    &.mbsc-rtl.mbsc-radio {
      padding: 1em 1em 1em 3.125em;
    }

    .mbsc-radio-box {
      right: 1em;
      width: 1.125em;
      height: 1.125em;
      margin-top: -.5625em;
      background: transparent;
    }

    &.mbsc-rtl .mbsc-radio-box {
      right: auto;
      left: 1em;
    }

    .mbsc-radio-box:after {
      width: .5em;
      height: .5em;
      margin-top: -.25em;
      margin-left: -.25em;
      border-radius: .625em;
    }

    &.mbsc-radio input:checked + .mbsc-radio-box {
      background: transparent;
    }

    /* Radio color presets */
    &.mbsc-radio-primary {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-primary;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-primary;
      }
    }

    &.mbsc-radio-secondary {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-secondary;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-secondary;
      }
    }

    &.mbsc-radio-success {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-success;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-success;
      }
    }

    &.mbsc-radio-danger {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-danger;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-danger;
      }
    }

    &.mbsc-radio-warning {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-warning;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-warning;
      }
    }

    &.mbsc-radio-info {
      .mbsc-radio-box {
        border-color: $mbsc-mobiscroll-info;
      }

      .mbsc-radio-box:after {
        background: $mbsc-mobiscroll-info;
      }
    }

    /* Checkbox, Switch, Radio */
    &.mbsc-checkbox input:disabled ~ .mbsc-label,
    &.mbsc-checkbox input:disabled ~ .mbsc-desc,
    &.mbsc-radio input:disabled ~ .mbsc-label,
    &.mbsc-radio input:disabled ~ .mbsc-desc,
    &.mbsc-switch input:disabled ~ .mbsc-label,
    &.mbsc-switch input:disabled ~ .mbsc-desc {
      opacity: .4;
    }

    .mbsc-checkbox-box:after,
    .mbsc-radio-box:after {
      opacity: 1;
      -webkit-transform: scale(0) rotate(-45deg);
      transform: scale(0) rotate(-45deg);
      transition: transform .1s ease-out;
    }

    &.mbsc-checkbox input:checked + .mbsc-checkbox-box:after,
    &.mbsc-radio input:checked + .mbsc-radio-box:after {
      opacity: 1;
      -webkit-transform: scale(1) rotate(-45deg);
      transform: scale(1) rotate(-45deg);
    }

    /* Buttons */
    &.mbsc-btn {
      margin: .5em;
      padding: .6875em;
      font-size: 1em;
      text-transform: uppercase;
    }

    .mbsc-btn-ic {
      padding-right: .6875em;
    }

    .mbsc-btn-icon-only .mbsc-btn-ic {
      padding: 0 .5em;
    }

    &.mbsc-no-touch.mbsc-btn:not(:disabled):not(.mbsc-active):hover {
      opacity: .8;
    }

    &.mbsc-btn.mbsc-active {
      opacity: .6;
    }

    &.mbsc-no-touch.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
      opacity: 1;
    }

    &.mbsc-btn-flat {
      background: transparent;
      border-color: transparent;
    }

    &.mbsc-btn-flat.mbsc-btn.mbsc-active {
      opacity: 1;
    }

    &.mbsc-btn-flat:disabled {
      background: transparent;
    }

    /* Button color presets */
    &.mbsc-btn-primary.mbsc-btn {
      background: $mbsc-mobiscroll-primary;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-primary;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-primary, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-primary, .3);
        }
      }
    }

    &.mbsc-btn-secondary.mbsc-btn {
      background: $mbsc-mobiscroll-secondary;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-secondary;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-secondary, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-secondary, .3);
        }
      }
    }

    &.mbsc-btn-success.mbsc-btn {
      background: $mbsc-mobiscroll-success;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-success;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-success, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-success, .3);
        }
      }
    }

    &.mbsc-btn-danger.mbsc-btn {
      background: $mbsc-mobiscroll-danger;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-danger;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-danger, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-danger, .3);
        }
      }
    }

    &.mbsc-btn-warning.mbsc-btn {
      background: $mbsc-mobiscroll-warning;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-warning;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-warning, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-warning, .3);
        }
      }
    }

    &.mbsc-btn-info.mbsc-btn {
      background: $mbsc-mobiscroll-info;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-info;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-info, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-info, .3);
        }
      }
    }

    &.mbsc-btn-light.mbsc-btn {
      background: $mbsc-mobiscroll-light;

      &.mbsc-btn-flat {
        color: darken($mbsc-mobiscroll-light, 25%);

        &:not(:disabled):not(.mbsc-active):hover {
          color: darken($mbsc-mobiscroll-light, 25%);
          background: rgba(darken($mbsc-mobiscroll-light, 25%), .2);
        }

        &.mbsc-active {
          background: rgba(darken($mbsc-mobiscroll-light, 25%), .3);
        }
      }
    }

    &.mbsc-btn-dark.mbsc-btn {
      background: $mbsc-mobiscroll-dark;

      &.mbsc-btn-flat {
        color: $mbsc-mobiscroll-dark;

        &:not(:disabled):not(.mbsc-active):hover {
          background: rgba($mbsc-mobiscroll-dark, .2);
        }

        &.mbsc-active {
          background: rgba($mbsc-mobiscroll-dark, .3);
        }
      }
    }

    &.mbsc-btn-flat.mbsc-btn,
    &.mbsc-btn-outline.mbsc-btn {
      background: transparent;
    }

    .mbsc-btn-group,
    .mbsc-btn-group-justified {
      margin: .5em;
    }

    .mbsc-btn-group-block {
      margin: .5em 1em;
    }

    /* Outline buttons */
    &.mbsc-btn-outline.mbsc-btn.mbsc-active {
      opacity: 1;
    }

    /* Outline buttons */
    &.mbsc-btn-outline.mbsc-btn {
      &.mbsc-btn-primary {
        border-color: $mbsc-mobiscroll-primary;
        color: $mbsc-mobiscroll-primary;

        &.mbsc-active {
          background: $mbsc-mobiscroll-primary;
        }
      }

      &.mbsc-btn-secondary {
        border-color: $mbsc-mobiscroll-secondary;
        color: $mbsc-mobiscroll-secondary;

        &.mbsc-active {
          background: $mbsc-mobiscroll-secondary;
        }
      }

      &.mbsc-btn-success {
        border-color: $mbsc-mobiscroll-success;
        color: $mbsc-mobiscroll-success;

        &.mbsc-active {
          background: $mbsc-mobiscroll-success;
        }
      }

      &.mbsc-btn-danger {
        border-color: $mbsc-mobiscroll-danger;
        color: $mbsc-mobiscroll-danger;

        &.mbsc-active {
          background: $mbsc-mobiscroll-danger;
        }
      }

      &.mbsc-btn-warning {
        border-color: $mbsc-mobiscroll-warning;
        color: $mbsc-mobiscroll-warning;

        &.mbsc-active {
          background: $mbsc-mobiscroll-warning;
        }
      }

      &.mbsc-btn-info {
        border-color: $mbsc-mobiscroll-info;
        color: $mbsc-mobiscroll-info;

        &.mbsc-active {
          background: $mbsc-mobiscroll-info;
        }
      }

      &.mbsc-btn-light {
        border-color: darken($mbsc-mobiscroll-light, 25%);
        color: darken($mbsc-mobiscroll-light, 25%);

        &.mbsc-active {
          background: darken($mbsc-mobiscroll-light, 25%);
          color: $mbsc-mobiscroll-light;
        }
      }

      &.mbsc-btn-dark {
        border-color: $mbsc-mobiscroll-dark;
        color: $mbsc-mobiscroll-dark;

        &.mbsc-active {
          background: $mbsc-mobiscroll-dark;
        }
      }
    }

    /* Switch */
    &.mbsc-ltr.mbsc-switch {
      padding: 1em 4.375em 1em 1em;
    }

    &.mbsc-rtl.mbsc-switch {
      padding: 1em 1em 1em 4.375em;
    }

    .mbsc-switch-track {
      right: 1em;
      width: 2.375em;
      height: .875em;
      padding: 0 .75em;
      margin-top: -.4375em;
      border-radius: 1.25em;
    }

    &.mbsc-rtl .mbsc-switch-track {
      right: auto;
      left: 1em;
    }

    .mbsc-switch-handle {
      top: .25em;
      left: .25em;
      margin: 0;
      width: 1.5em;
      height: 1.5em;
      border-radius: 1.25em;
    }

    &.mbsc-rtl .mbsc-switch-handle {
      right: .25em;
    }

    .mbsc-active .mbsc-switch-handle {
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    &.mbsc-switch input:disabled + .mbsc-switch-track {
      opacity: .7;
    }

    /* Switch color presets */
    &.mbsc-switch.mbsc-switch-primary input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-primary, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-primary;
      }
    }

    &.mbsc-switch.mbsc-switch-secondary input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-secondary, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-secondary;
      }
    }

    &.mbsc-switch.mbsc-switch-success input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-success, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-success;
      }
    }

    &.mbsc-switch.mbsc-switch-danger input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-danger, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-danger;
      }
    }

    &.mbsc-switch.mbsc-switch-warning input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-warning, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-warning;
      }
    }

    &.mbsc-switch.mbsc-switch-info input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-mobiscroll-info, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-mobiscroll-info;
      }
    }

    /* Stepper and Segmented */
    &.mbsc-segmented {
      padding: .5em 1em;
    }

    .mbsc-segmented-content {
      height: 2.28571428em;
      margin: 0 -.071428em;
      line-height: 2.28575em;
      padding: 0 .285714em;
      text-transform: uppercase;
    }

    &.mbsc-ltr.mbsc-stepper-cont {
      padding: 1.75em 12.875em 1.75em 1em;
    }

    &.mbsc-rtl.mbsc-stepper-cont {
      padding: 1.75em 1em 1.75em 12.875em;
    }

    .mbsc-stepper {
      margin-top: -1.125em;
    }

    &.mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
    &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
    &.mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
      background: transparent;
    }

    /* Segmented color presets */
    .mbsc-segmented-primary {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-primary;
        color: $mbsc-mobiscroll-primary;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-primary;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-primary, .35)
      }
    }

    .mbsc-segmented-secondary {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-secondary;
        color: $mbsc-mobiscroll-secondary;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-secondary;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-secondary, .35)
      }
    }

    .mbsc-segmented-success {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-success;
        color: $mbsc-mobiscroll-success;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-success;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-success, .35)
      }
    }

    .mbsc-segmented-danger {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-danger;
        color: $mbsc-mobiscroll-danger;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-danger;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-danger, .35)
      }
    }

    .mbsc-segmented-warning {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-warning;
        color: $mbsc-mobiscroll-warning;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-warning;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-warning, .35)
      }
    }

    .mbsc-segmented-info {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-info;
        color: $mbsc-mobiscroll-info;
      }

      &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
        background: $mbsc-mobiscroll-info;
      }

      &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
        background: rgba($mbsc-mobiscroll-info, .35)
      }
    }

    /* Stepper color presets */
    &.mbsc-stepper-primary {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-primary;
        color: $mbsc-mobiscroll-primary;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-primary;
      }
    }

    &.mbsc-stepper-secondary {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-secondary;
        color: $mbsc-mobiscroll-secondary;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-secondary;
      }
    }

    &.mbsc-stepper-success {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-success;
        color: $mbsc-mobiscroll-success;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-success;
      }
    }

    &.mbsc-stepper-danger {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-danger;
        color: $mbsc-mobiscroll-danger;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-danger;
      }
    }

    &.mbsc-stepper-warning {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-warning;
        color: $mbsc-mobiscroll-warning;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-warning;
      }
    }

    &.mbsc-stepper-info {
      .mbsc-segmented-content {
        border-color: $mbsc-mobiscroll-info;
        color: $mbsc-mobiscroll-info;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content {
        background: $mbsc-mobiscroll-info;
      }
    }

    &.mbsc-no-touch {

      /* Segmented hover color styles */
      .mbsc-segmented-primary {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-primary, .35)
        }
      }

      .mbsc-segmented-secondary {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-secondary, .35)
        }
      }

      .mbsc-segmented-success {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-success, .35)
        }
      }

      .mbsc-segmented-danger {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-danger, .35)
        }
      }

      .mbsc-segmented-warning {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-warning, .35)
        }
      }

      .mbsc-segmented-info {
        &.mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-info, .35)
        }
      }

      /* Stepper hover color styles */
      &.mbsc-stepper-primary {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-primary, .35)
        }
      }

      &.mbsc-stepper-secondary {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-secondary, .35)
        }
      }

      &.mbsc-stepper-success {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-success, .35)
        }
      }

      &.mbsc-stepper-danger {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-danger, .35)
        }
      }

      &.mbsc-stepper-warning {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-warning, .35)
        }
      }

      &.mbsc-stepper-info {
        .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
          background: rgba($mbsc-mobiscroll-info, .35)
        }
      }
    }
  }

  @include mbsc-mobiscroll-forms(mobiscroll, $mbsc-mobiscroll-colors);
}






@include exports("collapsible.material") {
  .mbsc-material .mbsc-collapsible {
    .mbsc-form-group-title {
      padding: 1em;
    }

    .mbsc-collapsible-header {
      padding-right: 3em;
    }
  }
}










@mixin mbsc-material-rating($theme, $params) {
  @include exports("rating.#{$theme}.colors") {

    @include mbsc-material-progress($theme, $params);

    $accent: map-get($params, accent);

    .mbsc-#{$theme}.mbsc-rating {
      .mbsc-progress-track {
        color: $accent;
      }
    }
  }
}



@include exports("rating.material") {
  .mbsc-material.mbsc-rating {
    .mbsc-slider-handle:before {
      display: none;
    }

    .mbsc-label {
      padding-bottom: .5em;
    }

    input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
      opacity: .4;
    }

    &.mbsc-rating-primary .mbsc-progress-track {
      color: $mbsc-material-primary;
    }

    &.mbsc-rating-secondary .mbsc-progress-track {
      color: $mbsc-material-secondary;
    }

    &.mbsc-rating-success .mbsc-progress-track {
      color: $mbsc-material-success;
    }

    &.mbsc-rating-danger .mbsc-progress-track {
      color: $mbsc-material-danger;
    }

    &.mbsc-rating-warning .mbsc-progress-track {
      color: $mbsc-material-warning;
    }

    &.mbsc-rating-info .mbsc-progress-track {
      color: $mbsc-material-info;
    }
  }

  @include mbsc-material-rating(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}








@mixin mbsc-material-popup($theme, $params) {
  @include exports("popup.#{$theme}.colors") {

    @include mbsc-material-frame($theme, $params);
  }
}



@include exports("popup.material") {
  @include mbsc-material-popup(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}





@mixin mbsc-material-notifications($theme, $params) {
  @include exports("notifications.#{$theme}.colors") {

    @include mbsc-material-popup($theme, $params);

    $accent: map-get($params, accent);

    $colors: mbsc-material-colors($params);
    $white-text: map-get($colors, white-text);
    $light-text: map-get($colors, light-text);
    $snackbar: map-get($colors, snackbar);
    $snackbar-btn: map-get($colors, snackbar-btn);

    .mbsc-#{$theme} {

      /* Snackbar */

      &.mbsc-snackbar .mbsc-fr-w {
        background: $snackbar;
        color: $white-text;
      }

      &.mbsc-snackbar .mbsc-snackbar-btn {
        color: $snackbar-btn;
      }

      /* Toast */

      &.mbsc-toast .mbsc-toast-msg {
        background: #505050;
        color: $white-text;
      }

      /* Snackbar and Toast color presets */
      &.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
      &.mbsc-toast.mbsc-primary .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
      &.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-success .mbsc-fr-w,
      &.mbsc-toast.mbsc-success .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
      &.mbsc-toast.mbsc-danger .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
      &.mbsc-toast.mbsc-warning .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-info .mbsc-fr-w,
      &.mbsc-toast.mbsc-info .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
        color: $light-text;
      }
    }
  }
}



@include exports("notifications.material") {
  .mbsc-material {
    &.mbsc-toast .mbsc-toast-msg {
      opacity: .9;
      border-radius: 1.571429em;
    }

    /* Snackbar and Toast color presets */

    &.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
    &.mbsc-toast.mbsc-primary .mbsc-toast-msg {
      background: $mbsc-material-primary;
    }

    &.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
    &.mbsc-toast.mbsc-secondary .mbsc-toast-msg {
      background: $mbsc-material-secondary;
    }

    &.mbsc-snackbar.mbsc-success .mbsc-fr-w,
    &.mbsc-toast.mbsc-success .mbsc-toast-msg {
      background: $mbsc-material-success;
    }

    &.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
    &.mbsc-toast.mbsc-danger .mbsc-toast-msg {
      background: $mbsc-material-danger;
    }

    &.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
    &.mbsc-toast.mbsc-warning .mbsc-toast-msg {
      background: $mbsc-material-warning;
    }

    &.mbsc-snackbar.mbsc-info .mbsc-fr-w,
    &.mbsc-toast.mbsc-info .mbsc-toast-msg {
      background: $mbsc-material-info;
    }
  }

  @include mbsc-material-notifications(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}









// Theme specific variables - inherited from global variables

// General colors for the form
// Background
$mbsc-material-form-background: $mbsc-form-background-light !default;
$mbsc-material-dark-form-background: $mbsc-form-background-dark !default;
// Text
$mbsc-material-form-text: $mbsc-form-text-light !default;
$mbsc-material-dark-form-text: $mbsc-form-text-dark !default;
// Accent
$mbsc-material-form-accent: $mbsc-form-accent-light !default;
$mbsc-material-dark-form-accent: $mbsc-form-accent-dark !default;

// Button
// button background
$mbsc-material-button-color: $mbsc-button-color-light !default;
$mbsc-material-dark-button-color: $mbsc-button-color-dark !default;
// button text
$mbsc-material-button-text: $mbsc-button-text-light !default;
$mbsc-material-dark-button-text: $mbsc-button-text-dark !default;

// Form Group
// text
$mbsc-material-form-group-title-text: $mbsc-form-group-title-text-light !default;
$mbsc-material-dark-form-group-title-text: $mbsc-form-group-title-text-dark !default;


// add variables to light and dark color maps
$mbsc-material-colors: map-merge($mbsc-material-colors, (
  'form-background': $mbsc-material-form-background,
  'form-text': $mbsc-material-form-text,
  'form-accent': $mbsc-material-form-accent,
  'button-color': $mbsc-material-button-color,
  'button-text': $mbsc-material-button-text,
  'form-group-title-text': $mbsc-material-form-group-title-text,
));
$mbsc-material-dark-colors: map-merge($mbsc-material-dark-colors, (
  'form-background': $mbsc-material-dark-form-background,
  'form-text': $mbsc-material-dark-form-text,
  'form-accent': $mbsc-material-dark-form-accent,
  'button-color': $mbsc-material-dark-button-color,
  'button-text': $mbsc-material-dark-button-text,
  'form-group-title-text': $mbsc-material-dark-form-group-title-text,
));

@mixin mbsc-material-forms($theme, $params) {
  @include exports("forms.#{$theme}.colors") {

    @include mbsc-material-page($theme, $params);
    @include mbsc-material-input($theme, $params);
    @include mbsc-material-slider($theme, $params);
    @include mbsc-material-rating($theme, $params);
    @include mbsc-material-notifications($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');
    // Generic
    $background-param: map-get($params, 'form-background');
    $text-param: map-get($params, 'form-text');
    $accent-param: map-get($params, 'form-accent');
    // Button
    $button-background-param: map-get($params, 'button-color');
    $button-text-param: map-get($params, 'button-text');
    // Group Title
    $group-title-param: map-get($params, 'form-group-title-text');

    $colors: mbsc-material-colors($params);
    // stati colors
    $white-text: map-get($colors, 'white-text');
    $dark-text: map-get($colors, 'dark-text');
    $light-text: map-get($colors, 'light-text');
    $error: map-get($colors, 'error');
    $background-contrast: map-get($colors, 'background-contrast');

    // overwrite custom variables
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    // derived variables
    $form-text: lighten($text, 7%);
    $checkbox: $background;
    $gray-background: '';
    $input-color: '';
    $switch-disabled: '';
    $switch: '';
    $button-background: '';
    $button-text: '';
    @if (lightness($background) > 50%) {
      $checkbox: lighten($background, 7%);
      $gray-background: darken($background, 23%);
      $input-color: lighten($text, 29%);
      $switch-disabled: darken($background, 20%);
      $switch: lighten($background, 5%);
      $button-background: darken($background, 9%);
      $button-text: darken($text, 36%);
    }
    @else {
      $gray-background: lighten($background, 17%);
      $input-color: darken($text, 19%);
      $switch-disabled: lighten($background, 16%);
      $switch: lighten($background, 53%);
      $button-background: lighten($background, 17%);
      $button-text: lighten($text, 24%);
    }

    // Switch
    $switch-track-disabled: $button-background;
    // Segmented
    $segmented-background-disabled: $button-background;
    $segmented-text: $button-text;
    $segmented-active: darken($button-background, 4%);
    // Stepper
    $stepper-background: $button-background;

    // Button specific vars overwritten
    $button-background: if($button-background-param, $button-background-param, $button-background);
    $button-text: if($button-text-param, $button-text-param, if($button-background-param, get-contrast-color($button-background-param), $button-text));
    $button-active: darken($button-background, 4%);

    // Group title
    $group-title: if($group-title-param, $group-title-param, $accent);

    .mbsc-#{$theme} {

      &.mbsc-form {
        background-color: $background;
        color: $form-text;
      }

      &.mbsc-control-w {
        color: $form-text;
      }

      &.mbsc-form *::-moz-selection,
      &.mbsc-control-w *::-moz-selection,
      &.mbsc-form *::selection,
      &.mbsc-control-w *::selection {
        color: $white-text;
        background: $accent;
      }

      .mbsc-divider,
      .mbsc-form-group-title {
        color: $group-title;
      }

      .mbsc-err-msg,
      .mbsc-err .mbsc-label {
        color: $error;
      }

      /* Checkbox, radio */
      .mbsc-checkbox-box,
      .mbsc-radio-box {
        border: .125em solid $form-text;
      }


      .mbsc-checkbox-box:before,
      .mbsc-radio-box:before {
        background: rgba($background-contrast, .1);
      }

      /* Checkbox */
      .mbsc-checkbox-box:after {
        border: .125em solid $checkbox;
        border-top: 0;
        border-right: 0;
      }

      &.mbsc-checkbox input:checked + .mbsc-checkbox-box {
        background: $accent;
        border-color: $accent;
      }

      /* Radio */
      .mbsc-radio-box:after {
        background: $accent;
      }

      &.mbsc-radio input:checked + .mbsc-radio-box {
        border-color: $accent;
      }

      /* Button */
      &.mbsc-btn {
        background: $button-background;
        color: $button-text;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .25);
      }

      &.mbsc-no-touch.mbsc-btn:not(:disabled):hover,
      .mbsc-btn.mbsc-active {
        background: $button-active;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
      }


      &.mbsc-no-touch.mbsc-btn-flat:not(:disabled):hover,
      .mbsc-btn-flat.mbsc-active {
        background-color: $gray-background;
      }

      &.mbsc-btn:disabled {
        color: $input-color;
      }

      &.mbsc-btn.mbsc-btn-outline:disabled {
        color: $input-color;
        border-color: $input-color;
      }

      &.mbsc-btn-light.mbsc-btn {
        color: $dark-text;
      }

      &.mbsc-btn-dark.mbsc-btn {
        color: $light-text;
      }

      /* Flat buttons */
      &.mbsc-btn-flat.mbsc-btn {
        background: transparent;
        box-shadow: none;
      }

      &.mbsc-no-touch.mbsc-btn-flat:not(:disabled):hover,
      .mbsc-btn-flat.mbsc-active {
        box-shadow: none;
      }

      /* Outline buttons */
      &.mbsc-btn-outline.mbsc-btn {
        background: transparent;
        border: 1px solid $form-text;
        color: $form-text;
        box-shadow: none;

        &.mbsc-active.mbsc-control {
          background: $form-text;
          color: $background;
          opacity: 1;
        }
      }

      /* Switch */
      .mbsc-switch-track {
        background: $gray-background;
      }

      &.mbsc-switch .mbsc-switch-handle {
        background: $switch;
        box-shadow: 0 3px 1px -2px rgba($background-contrast, 0.2), 0 1px 5px 0 rgba($background-contrast, 0.12);
      }

      &.mbsc-switch input:checked + .mbsc-switch-track {
        background-color: rgba($accent, .3);
      }

      &.mbsc-switch input:checked + .mbsc-switch-track .mbsc-switch-handle {
        background: $accent;
      }

      &.mbsc-switch input:disabled + .mbsc-switch-track {
        background: $switch-track-disabled;
      }

      &.mbsc-switch input:disabled + .mbsc-switch-track .mbsc-switch-handle {
        background: $switch-disabled;
      }

      /* Segmented control and Stepper */
      .mbsc-segmented-content {
        border: .142858em solid $accent;
        color: $segmented-text;
      }

      &.mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item input:checked ~ .mbsc-segmented-content {
        background: $accent;
        color: $background;
      }

      &.mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item .mbsc-active .mbsc-segmented-content {
        background: $segmented-active;
        color: $segmented-text;
      }

      &.mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
        color: $input-color;
        border-color: $gray-background;
        background: transparent;
      }

      &.mbsc-segmented .mbsc-segmented-item input:disabled:checked + .mbsc-segmented-content {
        background: $segmented-background-disabled;
        color: $input-color;
        border-color: $segmented-background-disabled;
      }

      .mbsc-stepper .mbsc-segmented-content {
        border-color: $stepper-background;
      }

      .mbsc-stepper-control .mbsc-segmented-content {
        background: $stepper-background;
      }

      &.mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item.mbsc-disabled .mbsc-segmented-content {
        background: $segmented-background-disabled;
        color: $input-color;
      }

      &.mbsc-segmented .mbsc-segmented-item input:disabled ~ .mbsc-segmented-item .mbsc-stepper-val {
        background: $background;
      }

      .mbsc-stepper input {
        color: $text;
      }

      .mbsc-stepper input:disabled {
        color: $input-color;
        -webkit-text-fill-color: $input-color;
      }

      &.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
        background: $segmented-active;
        color: $segmented-text;
      }

      /* Segmented color presets */
      &.mbsc-stepper-primary.mbsc-stepper-cont {
        .mbsc-stepper .mbsc-segmented-content {
          color: $background;
        }

        .mbsc-stepper .mbsc-stepper-val {
          background: transparent;
        }
      }

      &.mbsc-stepper-secondary.mbsc-stepper-cont {
        .mbsc-stepper .mbsc-segmented-content {
          color: $background;
        }

        .mbsc-stepper .mbsc-stepper-val {
          background: transparent;
        }
      }

      &.mbsc-stepper-success.mbsc-stepper-cont {
        .mbsc-stepper .mbsc-segmented-content {
          color: $background;
        }

        .mbsc-stepper .mbsc-stepper-val {
          background: transparent;
        }
      }

      &.mbsc-stepper-danger.mbsc-stepper-cont {
        .mbsc-stepper .mbsc-segmented-content {
          color: $background;
        }

        .mbsc-stepper .mbsc-stepper-val {
          background: transparent;
        }
      }

      &.mbsc-stepper-warning.mbsc-stepper-cont {
        .mbsc-stepper .mbsc-segmented-content {
          color: $background;
        }

        .mbsc-stepper .mbsc-stepper-val {
          background: transparent;
        }
      }

      &.mbsc-stepper-info.mbsc-stepper-cont {
        .mbsc-stepper .mbsc-segmented-content {
          color: $background;
        }

        .mbsc-stepper .mbsc-stepper-val {
          background: transparent;
        }
      }
    }
  }
}




@include exports("forms.material") {
  .mbsc-material {
    .mbsc-desc {
      opacity: .7;
    }

    .mbsc-divider,
    .mbsc-form-group-title {
      padding: .5em 1em;
    }

    .mbsc-err-msg {
      position: absolute;
      padding-top: .333334em;
      font-size: .75em;
    }

    &.mbsc-ltr .mbsc-err-msg {
      left: 0;
    }

    &.mbsc-rtl .mbsc-err-msg {
      right: 0;
    }

    /* Form grid */

    .mbsc-form-grid {
      margin-top: .75em;
      margin-bottom: .75em;
    }

    .mbsc-form-grid .mbsc-input-box,
    .mbsc-form-grid .mbsc-input-outline {
      margin: .75em 1em;
    }

    /* Checkbox, switch, radio */
    &.mbsc-checkbox .mbsc-err-msg,
    &.mbsc-switch .mbsc-err-msg,
    &.mbsc-radio .mbsc-err-msg {
      padding-top: 0;
    }

    &.mbsc-ltr.mbsc-checkbox .mbsc-err-msg,
    &.mbsc-ltr.mbsc-switch .mbsc-err-msg,
    &.mbsc-ltr.mbsc-radio .mbsc-err-msg {
      left: 1.3333em;
    }

    &.mbsc-rtl.mbsc-checkbox .mbsc-err-msg,
    &.mbsc-rtl.mbsc-switch .mbsc-err-msg,
    &.mbsc-rtl.mbsc-radio .mbsc-err-msg {
      right: 1.3333em;
    }

    /* Checkbox, radio */
    &.mbsc-ltr.mbsc-checkbox,
    &.mbsc-ltr.mbsc-radio {
      padding: .9375em 3.5em .9375em 1em;
      line-height: 1.25em;
    }

    &.mbsc-rtl.mbsc-checkbox,
    &.mbsc-rtl.mbsc-radio {
      padding: .9375em 1em .9375em 3.5em;
    }

    .mbsc-checkbox-box,
    .mbsc-radio-box {
      right: 1.25em;
      width: 1.125em;
      height: 1.125em;
      margin-top: -.5625em;
      background: transparent;
      -webkit-transition: background-color .1s ease-out;
      transition: background-color .1s ease-out;
    }

    &.mbsc-rtl .mbsc-checkbox-box,
    &.mbsc-rtl .mbsc-radio-box {
      right: auto;
      left: 1.25em;
    }

    .mbsc-checkbox-box:after,
    .mbsc-radio-box:after {
      opacity: 1;
      -webkit-transform: scale(0) rotate(-45deg);
      transform: scale(0) rotate(-45deg);
      -webkit-transition: -webkit-transform .1s ease-out;
      transition: transform .1s ease-out;
    }

    &.mbsc-checkbox input:checked + .mbsc-checkbox-box:after,
    &.mbsc-radio input:checked + .mbsc-radio-box:after {
      -webkit-transform: scale(1) rotate(-45deg);
      transform: scale(1) rotate(-45deg);
    }

    .mbsc-checkbox-box:before,
    .mbsc-radio-box:before {
      content: '';
      position: absolute;
      top: -1em;
      left: -1em;
      z-index: -1;
      width: 2.875em;
      height: 2.875em;
      opacity: 0;
      border-radius: 2.875em;
      -webkit-transition: opacity .2s ease-in-out;
      transition: opacity .2s ease-in-out;
    }

    &.mbsc-checkbox input:disabled + .mbsc-checkbox-box,
    &.mbsc-checkbox input:disabled ~ .mbsc-label,
    &.mbsc-checkbox input:disabled ~ .mbsc-desc,
    &.mbsc-radio input:disabled + .mbsc-radio-box,
    &.mbsc-radio input:disabled ~ .mbsc-label,
    &.mbsc-radio input:disabled ~ .mbsc-desc,
    &.mbsc-switch input:disabled ~ .mbsc-label,
    &.mbsc-switch input:disabled ~ .mbsc-desc {
      opacity: .3;
    }

    &.mbsc-checkbox input.mbsc-active + .mbsc-checkbox-box:before,
    &.mbsc-radio input.mbsc-active + .mbsc-radio-box:before,
    &.mbsc-switch input.mbsc-active + .mbsc-switch-track .mbsc-switch-handle:before {
      opacity: 1;
    }

    /* Checkbox */
    .mbsc-checkbox-box {
      border-radius: .1875em;
    }

    .mbsc-checkbox-box:after {
      top: .125em;
      left: .0625em;
      width: .8125em;
      height: .4375em;
    }

    /* Checkbox color presets */
    &.mbsc-checkbox-primary.mbsc-checkbox {
      input:checked + .mbsc-checkbox-box {
        background: $mbsc-material-primary;
        border-color: $mbsc-material-primary;
      }
    }

    &.mbsc-checkbox-secondary.mbsc-checkbox {
      input:checked .mbsc-checkbox-box {
        background: $mbsc-material-secondary;
        border-color: $mbsc-material-secondary;
      }
    }

    &.mbsc-checkbox-success.mbsc-checkbox {
      input:checked + .mbsc-checkbox-box {
        background: $mbsc-material-success;
        border-color: $mbsc-material-success;
      }
    }

    &.mbsc-checkbox-danger.mbsc-checkbox {
      input:checked + .mbsc-checkbox-box {
        background: $mbsc-material-danger;
        border-color: $mbsc-material-danger;
      }
    }

    &.mbsc-checkbox-warning.mbsc-checkbox {
      input:checked + .mbsc-checkbox-box {
        background: $mbsc-material-warning;
        border-color: $mbsc-material-warning;
      }
    }

    &.mbsc-checkbox-info.mbsc-checkbox {
      input:checked + .mbsc-checkbox-box {
        background: $mbsc-material-info;
        border-color: $mbsc-material-info;
      }
    }

    /* Radio */
    .mbsc-radio-box:after {
      width: .625em;
      height: .625em;
      margin-top: -.3125em;
      margin-left: -.3125em;
      border-radius: .625em;
    }

    /* Radio color presets */
    &.mbsc-radio-primary.mbsc-radio input:checked {
      + .mbsc-radio-box {
        border-color: $mbsc-material-primary;
      }

      + .mbsc-radio-box:after {
        background: $mbsc-material-primary;
      }
    }

    &.mbsc-radio-secondary.mbsc-radio input:checked {
      + .mbsc-radio-box {
        border-color: $mbsc-material-secondary;
      }

      + .mbsc-radio-box:after {
        background: $mbsc-material-secondary;
      }
    }

    &.mbsc-radio-success.mbsc-radio input:checked {
      + .mbsc-radio-box {
        border-color: $mbsc-material-success;
      }

      + .mbsc-radio-box:after {
        background: $mbsc-material-success;
      }
    }

    &.mbsc-radio-danger.mbsc-radio input:checked {
      + .mbsc-radio-box {
        border-color: $mbsc-material-danger;
      }

      + .mbsc-radio-box:after {
        background: $mbsc-material-danger;
      }
    }

    &.mbsc-radio-warning.mbsc-radio input:checked {
      + .mbsc-radio-box {
        border-color: $mbsc-material-warning;
      }

      + .mbsc-radio-box:after {
        background: $mbsc-material-warning;
      }
    }

    &.mbsc-radio-info.mbsc-radio input:checked {
      + .mbsc-radio-box {
        border-color: $mbsc-material-info;
      }

      + .mbsc-radio-box:after {
        background: $mbsc-material-info;
      }
    }

    /* Button */
    &.mbsc-btn {
      margin: .5em;
      padding: .7143em;
      border-radius: .2143em;
      font-size: .875em;
      font-weight: bold;
      text-transform: uppercase;
      transition: box-shadow .2s ease-out, background-color .2s ease-out;
    }

    .mbsc-btn-ic {
      padding: 0 .5em 0 0;
    }

    .mbsc-btn-icon-only .mbsc-btn-ic {
      padding: 0 .5em;
    }

    .mbsc-btn-group,
    .mbsc-btn-group-justified {
      margin: .5em;
    }

    .mbsc-btn-group-block {
      margin: .5em 1em;
    }

    &.mbsc-btn:disabled {
      box-shadow: none;
    }

    /* Button color presets */
    &.mbsc-btn-primary.mbsc-btn {
      background: $mbsc-material-primary;
      color: $mbsc-material-light;

      &.mbsc-active {
        background: darken($mbsc-material-primary, 10%);
      }

      &.mbsc-btn-flat {
        color: $mbsc-material-primary;

        &.mbsc-active {
          background: rgba($mbsc-material-primary, .3);
        }
      }
    }

    &.mbsc-btn-secondary.mbsc-btn {
      background: $mbsc-material-secondary;
      color: $mbsc-material-light;

      &.mbsc-active {
        background: darken($mbsc-material-secondary, 10%);
      }

      &.mbsc-btn-flat {
        color: $mbsc-material-secondary;

        &.mbsc-active {
          background: rgba($mbsc-material-secondary, .3);
        }
      }
    }

    &.mbsc-btn-success.mbsc-btn {
      background: $mbsc-material-success;
      color: $mbsc-material-light;

      &.mbsc-active {
        background: darken($mbsc-material-success, 10%);
      }

      &.mbsc-btn-flat {
        color: $mbsc-material-success;

        &.mbsc-active {
          background: rgba($mbsc-material-success, .3);
        }
      }
    }

    &.mbsc-btn-danger.mbsc-btn {
      background: $mbsc-material-danger;
      color: $mbsc-material-light;

      &.mbsc-active {
        background: darken($mbsc-material-danger, 10%);
      }

      &.mbsc-btn-flat {
        color: $mbsc-material-danger;

        &.mbsc-active {
          background: rgba($mbsc-material-danger, .3);
        }
      }
    }

    &.mbsc-btn-warning.mbsc-btn {
      background: $mbsc-material-warning;
      color: $mbsc-material-light;

      &.mbsc-active {
        background: darken($mbsc-material-warning, 10%);
      }

      &.mbsc-btn-flat {
        color: $mbsc-material-warning;

        &.mbsc-active {
          background: rgba($mbsc-material-warning, .3);
        }
      }
    }

    &.mbsc-btn-info.mbsc-btn {
      background: $mbsc-material-info;
      color: $mbsc-material-light;

      &.mbsc-active {
        background: darken($mbsc-material-info, 10%);
      }

      &.mbsc-btn-flat {
        color: $mbsc-material-info;

        &.mbsc-active {
          background: rgba($mbsc-material-info, .3);
        }
      }
    }

    &.mbsc-btn-light.mbsc-btn {
      background: $mbsc-material-light;

      &.mbsc-active {
        background: darken($mbsc-material-light, 10%);
      }

      &.mbsc-btn-flat {
        color: darken($mbsc-material-light, 25%);

        &.mbsc-active {
          background: rgba($mbsc-material-light, .25);
        }
      }
    }

    &.mbsc-btn-dark.mbsc-btn {
      background: $mbsc-material-dark;

      &.mbsc-active {
        background: darken($mbsc-material-dark, 10%);
      }

      &.mbsc-btn-flat {
        color: $mbsc-material-dark;

        &.mbsc-active {
          background: rgba($mbsc-material-dark, .3);
        }
      }
    }

    /* Outline buttons */
    &.mbsc-btn-outline.mbsc-btn {
      box-shadow: none;
    }

    /* Outline buttons */
    &.mbsc-btn-outline.mbsc-btn {
      &.mbsc-btn-primary {
        border-color: $mbsc-material-primary;
        color: $mbsc-material-primary;

        &.mbsc-active {
          background: $mbsc-material-primary;
          color: $mbsc-material-light;
        }
      }

      &.mbsc-btn-secondary {
        border-color: $mbsc-material-secondary;
        color: $mbsc-material-secondary;

        &.mbsc-active {
          background: $mbsc-material-secondary;
          color: $mbsc-material-light;
        }
      }

      &.mbsc-btn-success {
        border-color: $mbsc-material-success;
        color: $mbsc-material-success;

        &.mbsc-active {
          background: $mbsc-material-success;
          color: $mbsc-material-light;
        }
      }

      &.mbsc-btn-danger {
        border-color: $mbsc-material-danger;
        color: $mbsc-material-danger;

        &.mbsc-active {
          background: $mbsc-material-danger;
          color: $mbsc-material-light;
        }
      }

      &.mbsc-btn-warning {
        border-color: $mbsc-material-warning;
        color: $mbsc-material-warning;

        &.mbsc-active {
          background: $mbsc-material-warning;
          color: $mbsc-material-light;
        }
      }

      &.mbsc-btn-info {
        border-color: $mbsc-material-info;
        color: $mbsc-material-info;

        &.mbsc-active {
          background: $mbsc-material-info;
          color: $mbsc-material-light;
        }
      }

      &.mbsc-btn-light {
        border-color: darken($mbsc-material-light, 25%);
        color: darken($mbsc-material-light, 25%);

        &.mbsc-active {
          background: darken($mbsc-material-light, 25%);
          color: darken($mbsc-material-light, 25%);
        }
      }

      &.mbsc-btn-dark {
        border-color: $mbsc-material-dark;
        color: $mbsc-material-dark;

        &.mbsc-active {
          background: $mbsc-material-dark;
          color: $mbsc-material-light;
        }
      }
    }

    /* button hover style */
    &.mbsc-no-touch {
      &.mbsc-btn-outline.mbsc-btn:not(:disabled):hover {
        box-shadow: none;
      }

      &.mbsc-btn-primary.mbsc-btn {
        &:not(:disabled):hover {
          background: darken($mbsc-material-primary, 10%);

          &.mbsc-btn-flat {
            background: lighten($mbsc-material-primary, 25%);
          }
        }
      }

      &.mbsc-btn-secondary.mbsc-btn {
        &:not(:disabled):hover {
          background: darken($mbsc-material-secondary, 10%);

          &.mbsc-btn-flat {
            background: lighten($mbsc-material-secondary, 25%);
          }
        }
      }

      &.mbsc-btn-success.mbsc-btn {
        &:not(:disabled):hover {
          background: darken($mbsc-material-success, 10%);

          &.mbsc-btn-flat {
            background: lighten($mbsc-material-success, 25%);
          }
        }
      }

      &.mbsc-btn-danger.mbsc-btn {
        &:not(:disabled):hover {
          background: darken($mbsc-material-danger, 10%);

          &.mbsc-btn-flat {
            background: lighten($mbsc-material-danger, 25%);
          }
        }
      }

      &.mbsc-btn-warning.mbsc-btn {
        &:not(:disabled):hover {
          background: darken($mbsc-material-warning, 10%);

          &.mbsc-btn-flat {
            background: lighten($mbsc-material-warning, 25%);
          }
        }
      }

      &.mbsc-btn-info.mbsc-btn {
        &:not(:disabled):hover {
          background: darken($mbsc-material-info, 10%);

          &.mbsc-btn-flat {
            background: lighten($mbsc-material-info, 25%);
          }
        }
      }

      &.mbsc-btn-light.mbsc-btn {
        &:not(:disabled):hover {
          background: darken($mbsc-material-light, 10%);

          &.mbsc-btn-flat {
            background: lighten($mbsc-material-light, 25%);
          }
        }
      }

      &.mbsc-btn-dark.mbsc-btn {
        &.mbsc-btn:not(:disabled):hover {
          background: darken($mbsc-material-dark, 10%);

          &.mbsc-btn-flat {
            background: lighten($mbsc-material-dark, 25%);
          }
        }
      }

      &.mbsc-btn-outline.mbsc-btn {
        &:not(:disabled):not(.mbsc-active):hover {
          background: transparent;
        }
      }
    }

    &.mbsc-active.mbsc-control {
      opacity: 1;
    }

    /* Switch */
    &.mbsc-ltr.mbsc-switch {
      padding: .9375em 4em .9375em 1em;
      line-height: 1.25em;
    }

    &.mbsc-rtl.mbsc-switch {
      padding: .9375em 1em .9375em 4em;
    }

    .mbsc-switch-track {
      right: 1.25em;
      width: 1.75em;
      height: .875em;
      padding: 0 .25em;
      margin-top: -.4375em;
      border-radius: 1.25em;
    }

    &.mbsc-rtl .mbsc-switch-track {
      right: auto;
      left: 1.25em;
    }

    &.mbsc-switch .mbsc-switch-handle {
      z-index: auto;
      top: .375em;
      left: .375em;
      width: 1.25em;
      height: 1.25em;
      margin: 0;
      border: 0;
      border-radius: 1.25em;
    }

    &.mbsc-rtl.mbsc-switch .mbsc-switch-handle {
      left: auto;
      right: .375em;
    }

    .mbsc-switch-handle:before {
      top: -.625em;
      left: -.625em;
      width: 2.5em;
      height: 2.5em;
    }

    .mbsc-switch-handle,
    .mbsc-active .mbsc-switch-handle {
      -webkit-transform: none;
      transform: none;
    }

    /* Switch color presets */
    &.mbsc-switch.mbsc-switch-primary input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-material-primary, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-material-primary;
      }
    }

    &.mbsc-switch.mbsc-switch-secondary input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-material-secondary, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-material-secondary;
      }
    }

    &.mbsc-switch.mbsc-switch-success input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-material-success, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-material-success;
      }
    }

    &.mbsc-switch.mbsc-switch-danger input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-material-danger, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-material-danger;
      }
    }

    &.mbsc-switch.mbsc-switch-warning input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-material-warning, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-material-warning;
      }
    }

    &.mbsc-switch.mbsc-switch-info input:checked {
      + .mbsc-switch-track {
        background: lighten($mbsc-material-info, 20%);
      }

      + .mbsc-switch-track .mbsc-switch-handle {
        background: $mbsc-material-info;
      }
    }

    /* Segmented control and Stepper */
    &.mbsc-segmented {
      padding: .75em;
    }

    .mbsc-segmented-content {
      height: 2.2857145em;
      margin: 0 -.07142857em;
      line-height: 2.2857145em;
      padding: 0 .428571em;
      -webkit-backface-visibility: hidden;
      /* fix android 4.1 flickering */
    }

    &.mbsc-ltr .mbsc-segmented-item:first-child .mbsc-segmented-content,
    &.mbsc-ltr .mbsc-stepper-minus .mbsc-segmented-content,
    &.mbsc-rtl .mbsc-stepper-plus .mbsc-segmented-content,
    &.mbsc-rtl .mbsc-segmented-item:last-child .mbsc-segmented-content {
      border-top-left-radius: .214286em;
      border-bottom-left-radius: .214286em;
    }

    &.mbsc-ltr .mbsc-stepper-plus .mbsc-segmented-content,
    &.mbsc-ltr .mbsc-segmented-item:last-child .mbsc-segmented-content,
    &.mbsc-rtl .mbsc-segmented-item:first-child .mbsc-segmented-content,
    &.mbsc-rtl .mbsc-stepper-minus .mbsc-segmented-content {
      border-top-right-radius: .214286em;
      border-bottom-right-radius: .214286em;
    }

    &.mbsc-stepper-cont {
      padding: 1.75em 12.875em 1.75em 1em;
    }

    &.mbsc-rtl.mbsc-stepper-cont {
      padding: 1.75em 1em 1.75em 12.875em;
    }

    .mbsc-stepper {
      margin-top: -1.125em;
    }

    .mbsc-stepper .mbsc-segmented-content {
      border-left: 0;
      border-right: 0;
    }

    .mbsc-stepper-control .mbsc-segmented-content {
      border: 0;
      height: 2.5714285em;
      line-height: 2.5714285em;
    }

    /* Segmented color presets */
    .mbsc-segmented-primary {
      &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
        background: $mbsc-material-primary;
      }
    }

    .mbsc-segmented-secondary {
      .mbsc-segmented-content {
        border-color: $mbsc-material-secondary;
        color: $mbsc-material-secondary;
      }

      &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
        background: $mbsc-material-secondary;
      }
    }

    .mbsc-segmented-success {
      .mbsc-segmented-content {
        border-color: $mbsc-material-success;
        color: $mbsc-material-success;
      }

      &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
        background: $mbsc-material-success;
      }
    }

    .mbsc-segmented-danger {
      .mbsc-segmented-content {
        border-color: $mbsc-material-danger;
        color: $mbsc-material-danger;
      }

      &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
        background: $mbsc-material-danger;
      }
    }

    .mbsc-segmented-warning {
      .mbsc-segmented-content {
        border-color: $mbsc-material-warning;
        color: $mbsc-material-warning;
      }

      &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
        background: $mbsc-material-warning;
      }
    }

    .mbsc-segmented-info {
      .mbsc-segmented-content {
        border-color: $mbsc-material-info;
        color: $mbsc-material-info;
      }

      &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
        background: $mbsc-material-info;
      }
    }

    /* Segmented color presets */
    &.mbsc-segmented {
      .mbsc-segmented-primary {
        .mbsc-segmented-content {
          border-color: $mbsc-material-primary;
          color: $mbsc-material-primary;
        }

        &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
          background: $mbsc-material-primary;
        }
      }

      .mbsc-segmented-secondary {
        .mbsc-segmented-content {
          border-color: $mbsc-material-secondary;
          color: $mbsc-material-secondary;
        }

        &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
          background: $mbsc-material-secondary;
        }
      }

      .mbsc-segmented-success {
        .mbsc-segmented-content {
          border-color: $mbsc-material-success;
          color: $mbsc-material-success;
        }

        &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
          background: $mbsc-material-success;
        }
      }

      .mbsc-segmented-danger {
        .mbsc-segmented-content {
          border-color: $mbsc-material-danger;
          color: $mbsc-material-danger;
        }

        &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
          background: $mbsc-material-danger;
        }
      }

      .mbsc-segmented-warning {
        .mbsc-segmented-content {
          border-color: $mbsc-material-warning;
          color: $mbsc-material-warning;
        }

        &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
          background: $mbsc-material-warning;
        }
      }

      .mbsc-segmented-info {
        .mbsc-segmented-content {
          border-color: $mbsc-material-info;
          color: $mbsc-material-info;
        }

        &.mbsc-segmented-item input:checked + .mbsc-segmented-content {
          background: $mbsc-material-info;
        }
      }
    }

    /* Segmented color presets */
    &.mbsc-stepper-primary.mbsc-stepper-cont {
      .mbsc-stepper .mbsc-segmented-content {
        background: $mbsc-material-primary;
        border-color: $mbsc-material-primary;
      }
    }

    &.mbsc-stepper-secondary.mbsc-stepper-cont {
      .mbsc-stepper .mbsc-segmented-content {
        background: $mbsc-material-secondary;
        border-color: $mbsc-material-secondary;
      }
    }

    &.mbsc-stepper-success.mbsc-stepper-cont {
      .mbsc-stepper .mbsc-segmented-content {
        background: $mbsc-material-success;
        border-color: $mbsc-material-success;
      }
    }

    &.mbsc-stepper-danger.mbsc-stepper-cont {
      .mbsc-stepper .mbsc-segmented-content {
        background: $mbsc-material-danger;
        border-color: $mbsc-material-danger;
      }
    }

    &.mbsc-stepper-warning.mbsc-stepper-cont {
      .mbsc-stepper .mbsc-segmented-content {
        background: $mbsc-material-warning;
        border-color: $mbsc-material-warning;
      }
    }

    &.mbsc-stepper-info.mbsc-stepper-cont {
      .mbsc-stepper .mbsc-segmented-content {
        background: $mbsc-material-info;
        color: $mbsc-material-light;
        border-color: $mbsc-material-info;
      }
    }

    &.mbsc-no-touch {

      /* Segmented hover color presets */
      &.mbsc-stepper-primary.mbsc-stepper-cont .mbsc-segmented-item:hover .mbsc-segmented-content {
        background: rgba($mbsc-material-primary, .7);
      }

      &.mbsc-stepper-secondary.mbsc-stepper-cont .mbsc-segmented-item:hover .mbsc-segmented-content {
        background: rgba($mbsc-material-secondary, .7);
      }

      &.mbsc-stepper-success.mbsc-stepper-cont .mbsc-segmented-item:hover .mbsc-segmented-content {
        background: rgba($mbsc-material-success, .7);
      }

      &.mbsc-stepper-danger.mbsc-stepper-cont .mbsc-segmented-item:hover .mbsc-segmented-content {
        background: rgba($mbsc-material-danger, .7);
      }

      &.mbsc-stepper-warning.mbsc-stepper-cont .mbsc-segmented-item:hover .mbsc-segmented-content {
        background: rgba($mbsc-material-warning, .7);
      }

      &.mbsc-stepper-info.mbsc-stepper-cont .mbsc-segmented-item:hover .mbsc-segmented-content {
        background: rgba($mbsc-material-info, .7);
      }
    }
  }

  @include mbsc-material-forms('material', $mbsc-material-colors);
}






@include exports("collapsible.windows") {
  .mbsc-windows .mbsc-collapsible {
    .mbsc-form-group-title.mbsc-collapsible-header {
      padding-left: .666667em;
      padding-right: 2em;
    }

    .mbsc-form-group-title .mbsc-collapsible-icon {
      font-size: .666667em;
    }
  }
}










@mixin mbsc-windows-rating($theme, $params) {
  @include exports("rating.#{$theme}.colors") {

    @include mbsc-windows-progress($theme, $params);

    $accent: map-get($params, accent);

    .mbsc-#{$theme}.mbsc-rating {
      .mbsc-progress-track {
        color: $accent;
      }
    }
  }
}



@include exports("rating.windows") {
  .mbsc-windows.mbsc-rating {
    input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
      opacity: .4;
    }

    /* Color presets */
    &.mbsc-rating-primary .mbsc-progress-track {
      color: $mbsc-windows-primary;
    }

    &.mbsc-rating-secondary .mbsc-progress-track {
      color: $mbsc-windows-secondary;
    }

    &.mbsc-rating-success .mbsc-progress-track {
      color: $mbsc-windows-success;
    }

    &.mbsc-rating-danger .mbsc-progress-track {
      color: $mbsc-windows-danger;
    }

    &.mbsc-rating-warning .mbsc-progress-track {
      color: $mbsc-windows-warning;
    }

    &.mbsc-rating-info .mbsc-progress-track {
      color: $mbsc-windows-info;
    }
  }

  @include mbsc-windows-rating(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}








@mixin mbsc-windows-popup($theme, $params) {
  @include exports("popup.#{$theme}.colors") {

    @include mbsc-windows-frame($theme, $params);
    $colors: mbsc-windows-colors($params);
    $frame-border: map-get($colors, frame-border);

    .mbsc-#{$theme} {
      &.mbsc-wdg.mbsc-fr-btn {
        background: $frame-border;
      }
    }
  }
}



@include exports("popup.windows") {
  .mbsc-windows.mbsc-wdg {
    .mbsc-fr-btn-cont {
      padding: 1em .875em;
      border-top: 0;
    }

    .mbsc-fr-btn {
      margin: 0 .125em;
      height: 2em;
      line-height: 2em;
    }
  }

  @include mbsc-windows-popup(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}





@mixin mbsc-windows-notifications($theme, $params) {
  @include exports("notifications.#{$theme}.colors") {

    @include mbsc-windows-popup($theme, $params);

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');

    $colors: mbsc-windows-colors($params);
    $dark-text: map-get($colors, 'dark-text');
    $light-text: map-get($colors, 'light-text');
    $input-hover: '';
    @if (lightness($background) > 50%) {
      $input-hover: darken($background, 55%);
    }
    @else {
      $input-hover: lighten($background, 55%);
    }

    .mbsc-#{$theme} {

      /* Snackbar */
      &.mbsc-snackbar .mbsc-snackbar-btn {
        color: $accent;
      }

      /* Toast */
      &.mbsc-toast .mbsc-toast-msg {
        background: $input-hover;
        color: get-contrast-color($input-hover);
      }

      /* Snackbar and Toast color presets */

      &.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
      &.mbsc-toast.mbsc-primary .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
      &.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-success .mbsc-fr-w,
      &.mbsc-toast.mbsc-success .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
      &.mbsc-toast.mbsc-danger .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
      &.mbsc-toast.mbsc-warning .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-info .mbsc-fr-w,
      &.mbsc-toast.mbsc-info .mbsc-toast-msg,
      &.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
      &.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
        color: $light-text;
      }

      &.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn {
        color: $dark-text;
      }
    }
  }
}



@include exports("notifications.windows") {
  .mbsc-windows {

    /* Toast */
    &.mbsc-toast .mbsc-fr-w,
    &.mbsc-snackbar .mbsc-fr-w {
      border: 0;
    }

    /* Snackbar and Toast color presets */
    &.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
    &.mbsc-toast.mbsc-primary .mbsc-toast-msg {
      background: $mbsc-windows-primary;
    }

    &.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
    &.mbsc-toast.mbsc-secondary .mbsc-toast-msg {
      background: $mbsc-windows-secondary;
    }

    &.mbsc-snackbar.mbsc-success .mbsc-fr-w,
    &.mbsc-toast.mbsc-success .mbsc-toast-msg {
      background: $mbsc-windows-success;
    }

    &.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
    &.mbsc-toast.mbsc-danger .mbsc-toast-msg {
      background: $mbsc-windows-danger;
    }

    &.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
    &.mbsc-toast.mbsc-warning .mbsc-toast-msg {
      background: $mbsc-windows-warning;
    }

    &.mbsc-snackbar.mbsc-info .mbsc-fr-w,
    &.mbsc-toast.mbsc-info .mbsc-toast-msg {
      background: $mbsc-windows-info;
    }
  }

  @include mbsc-windows-notifications(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}









// Theme specific variables - inherited from global variables

// General colors for the form
// Background
$mbsc-windows-form-background: $mbsc-form-background-light !default;
$mbsc-windows-dark-form-background: $mbsc-form-background-dark !default;
// Text
$mbsc-windows-form-text: $mbsc-form-text-light !default;
$mbsc-windows-dark-form-text: $mbsc-form-text-dark !default;
// Accent
$mbsc-windows-form-accent: $mbsc-form-accent-light !default;
$mbsc-windows-dark-form-accent: $mbsc-form-accent-dark !default;

// Button
// button background
$mbsc-windows-button-color: $mbsc-button-color-light !default;
$mbsc-windows-dark-button-color: $mbsc-button-color-dark !default;
// button text
$mbsc-windows-button-text: $mbsc-button-text-light !default;
$mbsc-windows-dark-button-text: $mbsc-button-text-dark !default;

// Form Group
// text
$mbsc-windows-form-group-title-text: $mbsc-form-group-title-text-light !default;
$mbsc-windows-dark-form-group-title-text: $mbsc-form-group-title-text-dark !default;

// add variables to light and dark color maps
$mbsc-windows-colors: map-merge($mbsc-windows-colors, (
  'form-background': $mbsc-windows-form-background,
  'form-text': $mbsc-windows-form-text,
  'form-accent': $mbsc-windows-form-accent,
  'button-color': $mbsc-windows-button-color,
  'button-text': $mbsc-windows-button-text,
  'form-group-title-text': $mbsc-windows-form-group-title-text,
));
$mbsc-windows-dark-colors: map-merge($mbsc-windows-dark-colors, (
  'form-background': $mbsc-windows-dark-form-background,
  'form-text': $mbsc-windows-dark-form-text,
  'form-accent': $mbsc-windows-dark-form-accent,
  'button-color': $mbsc-windows-dark-button-color,
  'button-text': $mbsc-windows-dark-button-text,
  'form-group-title-text': $mbsc-windows-dark-form-group-title-text,
));

@mixin mbsc-windows-forms($theme, $params) {
  @include exports("forms.#{$theme}.colors") {

    @include mbsc-windows-page($theme, $params);
    @include mbsc-windows-input($theme, $params);
    @include mbsc-windows-slider($theme, $params);
    @include mbsc-windows-rating($theme, $params);
    @include mbsc-windows-notifications($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');
    // form specific
    $background-param: map-get($params, 'form-background');
    $text-param: map-get($params, 'form-text');
    $accent-param: map-get($params, 'form-accent');
    // form component specific
    $button-text-param: map-get($params, 'button-text');
    $button-background-param: map-get($params, 'button-color');
    $group-title-param: map-get($params, 'form-group-title-text');
    // overwrite custom variables
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    $form-background: '';
    $checkbox-border: '';
    $button-border: '';
    $frame-border: '';
    $input-border: '';
    $input-hover: '';
    $border-color: '';
    $segmented-disabled: '';
    $button-active: '';
    $button-hover: '';
    @if (lightness($background) > 50%) {
      $form-background: lighten($background, 13%);
      $checkbox-border: desaturate(lighten($accent, 52%), 24%);
      $button-border: lighten($text, 33%);
      $frame-border: darken($background, 15%);
      $input-border: darken($background, 35%);
      $input-hover: darken($background, 55%);
      $border-color: darken($background, 19%);
      $segmented-disabled: darken($background, 17%);
      $button-active: darken($background, 45%);
      $button-hover: darken($background, 10%);
    }
    @else {
      $form-background: darken($background, 12%);
      $checkbox-border: saturate(darken($accent, 52%), 24%);
      $button-border: darken($text, 33%);
      $frame-border: lighten($background, 15%);
      $input-border: lighten($background, 35%);
      $input-hover: lighten($background, 55%);
      $border-color: lighten($background, 19%);
      $segmented-disabled: lighten($background, 19%);
      $button-active: lighten($background, 45%);
      $button-hover: lighten($background, 10%);
    }


    // overwrite custom variables
    $button-background: if($button-background-param, $button-background-param, $frame-border);
    $button-text: if($button-text-param, $button-text-param, if($button-background-param, get-contrast-color($button-background-param), $text));
    $button-active: if($button-background-param, $button-background-param, $button-active);
    $group-title: if($group-title-param, $group-title-param, $text);

    .mbsc-#{$theme} {

      &.mbsc-form {
        background-color: $form-background;
        color: $text;
      }

      &.mbsc-control-w {
        color: $text;
      }

      &.mbsc-form *::-moz-selection,
      &.mbsc-control-w *::-moz-selection,
      &.mbsc-form *::selection,
      &.mbsc-control-w *::selection {
        color: get-contrast-color($accent);
        background: rgba($accent, .9);
      }

      .mbsc-form-group-title {
        color: $group-title;
      }

      /* Checkbox, radio */
      .mbsc-checkbox-box,
      .mbsc-radio-box {
        border: .125em solid $text;
      }

      .mbsc-checkbox-box:after {
        border: .125em solid $checkbox-border;
        border-top: 0;
        border-right: 0;
      }

      &.mbsc-checkbox input:checked+.mbsc-checkbox-box {
        background: $accent;
        border-color: $accent;
      }

      &.mbsc-checkbox input.mbsc-active+.mbsc-checkbox-box {
        border-color: $input-hover;
        background: $input-hover;
      }

      /* Radio */
      .mbsc-radio-box:after {
        background: $text;
      }

      &.mbsc-radio input:checked+.mbsc-radio-box {
        border-color: $accent;
      }

      &.mbsc-radio input.mbsc-active+.mbsc-radio-box {
        border-color: rgba($text, .6);
      }

      &.mbsc-radio input.mbsc-active+.mbsc-radio-box:after {
        background: rgba($text, .6);
      }

      /* Buttons */
      &.mbsc-btn {
        background: $button-background; // $frame-border;
        border: .125em solid $button-background; // .125em solid $frame-border;
        color: $button-text;
      }

      &.mbsc-no-touch.mbsc-btn:not(.mbsc-btn-flat):not(:disabled):hover {
        border-color: $button-border;
      }

      &.mbsc-btn.mbsc-active {
        border-color: $button-border;
        background: $button-border;
      }

      &.mbsc-no-touch.mbsc-btn-flat:not(:disabled):hover {
        background: transparent;
        border-color: transparent;
      }

      &.mbsc-btn.mbsc-btn-flat.mbsc-active:not(:disabled) {
        background: rgba($button-background, .25); // rgba($frame-border, .25);
      }

      /* Outline buttons */
      &.mbsc-btn.mbsc-btn-outline {
        background: transparent;
        border: 0.125em solid $input-border;
        color: $input-border;

        &.mbsc-active {
          background: $button-active;
          border-color: $button-active;
          color: $button-text;
        }
      }

      /* button hover style */
      &.mbsc-no-touch.mbsc-btn.mbsc-btn-outline:not(:disabled):hover {
        border-color: $button-active;
      }

      .mbsc-switch-track {
        border: 2px solid $text;
      }

      .mbsc-switch-track .mbsc-switch-handle {
        background: $text;
      }

      &.mbsc-switch input:checked+.mbsc-switch-track {
        background: $accent;
        border-color: $accent;

        .mbsc-switch-handle {
          background: $background;
        }
      }

      &.mbsc-switch input:checked:disabled+.mbsc-switch-track {
        background: $text;
        border-color: $text;
      }

      &.mbsc-form .mbsc-switch input.mbsc-active+.mbsc-switch-track {
        background: $input-hover;
        border-color: $input-hover;

        .mbsc-switch-handle {
          background: $background;
        }
      }

      /* Segmented & Stepper */
      .mbsc-segmented-content {
        color: $text;
        background: $frame-border;
      }

      .mbsc-segmented-content.mbsc-stepper-val {
        background: transparent;
        border-top: 2px solid $frame-border;
        border-bottom: 2px solid $frame-border;
      }

      &.mbsc-ltr.mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item {
        border-right: 1px solid $border-color;
      }

      &.mbsc-rtl.mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item {
        border-left: 1px solid $border-color;
      }

      &.mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item:last-child,
      &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-plus,
      &.mbsc-stepper-val-left .mbsc-segmented input+.mbsc-segmented-item,
      &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control {
        border: 0;
      }

      &.mbsc-ltr.mbsc-segmented .mbsc-stepper-control+.mbsc-stepper-control {
        border-left: 1px solid $border-color;
      }

      &.mbsc-rtl.mbsc-segmented .mbsc-stepper-control+.mbsc-stepper-control {
        border-right: 1px solid $border-color;
      }

      &.mbsc-segmented .mbsc-segmented-item input:checked+.mbsc-segmented-content {
        background: $text;
        color: $form-background;
      }

      &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item input:checked+.mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item input.mbsc-active+.mbsc-segmented-content {
        background: $button-border;
      }

      .mbsc-stepper .mbsc-segmented-item.mbsc-active .mbsc-segmented-content {
        background: $button-border;
      }

      &.mbsc-segmented input:disabled~.mbsc-segmented-item .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
      .mbsc-stepper .mbsc-segmented-item.mbsc-disabled .mbsc-segmented-content,
      &.mbsc-segmented .mbsc-segmented-item input:disabled+.mbsc-segmented-content {
        background: $frame-border;
      }

      .mbsc-stepper input:disabled {
        color: $segmented-disabled;
        -webkit-text-fill-color: $segmented-disabled;
      }

      &.mbsc-segmented .mbsc-segmented-item input:disabled:checked+.mbsc-segmented-content {
        color: $form-background;
        background: $segmented-disabled;
      }

      .mbsc-stepper .mbsc-active.mbsc-disabled .mbsc-segmented-content {
        background: transparent;
      }

      .mbsc-stepper input {
        color: $text;
      }

      &.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
        background: $button-hover;
      }
    }
  }
}


@include exports("forms.windows") {
  .mbsc-windows {

    .mbsc-divider,
    .mbsc-form-group-title {
      font-size: 1.5em;
      padding: 0 .666667em;
      line-height: 2em;
    }

    /* Checkbox, switch */
    &.mbsc-checkbox .mbsc-err-msg,
    &.mbsc-switch .mbsc-err-msg,
    &.mbsc-radio .mbsc-err-msg {
      padding-top: 0;
    }

    /* Checkbox, radio */
    .mbsc-checkbox-box,
    .mbsc-radio-box {
      margin-top: -.6875em;
    }

    &.mbsc-checkbox input:disabled + .mbsc-checkbox-box,
    &.mbsc-checkbox input:disabled ~ .mbsc-label,
    &.mbsc-checkbox input:disabled ~ .mbsc-desc,
    &.mbsc-radio input:disabled + .mbsc-radio-box,
    &.mbsc-radio input:disabled ~ .mbsc-label,
    &.mbsc-radio input:disabled ~ .mbsc-desc,
    &.mbsc-switch input:disabled + .mbsc-switch-track,
    &.mbsc-switch input:disabled ~ .mbsc-label,
    &.mbsc-switch input:disabled ~ .mbsc-desc {
      opacity: .2;
    }

    /* Checkbox */
    &.mbsc-ltr.mbsc-checkbox {
      padding: 1.125em 1em 1.125em 2.875em;
    }

    &.mbsc-rtl.mbsc-checkbox {
      padding: 1.125em 2.875em 1.125em 1em;
    }

    .mbsc-checkbox-box {
      width: 1.3125em;
      height: 1.3125em;
      left: 1em;
    }

    &.mbsc-rtl .mbsc-checkbox-box {
      left: auto;
      right: 1em;
    }

    .mbsc-checkbox-box:after {
      top: 16%;
      left: 10%;
      width: .875em;
      height: .475em;
    }

    /* Checkbox color presets */
    &.mbsc-checkbox-primary.mbsc-checkbox input:checked + .mbsc-checkbox-box {
      background: $mbsc-windows-primary;
      border-color: $mbsc-windows-primary;
    }

    &.mbsc-checkbox-secondary.mbsc-checkbox input:checked + .mbsc-checkbox-box {
      background: $mbsc-windows-secondary;
      border-color: $mbsc-windows-secondary;
    }

    &.mbsc-checkbox-success.mbsc-checkbox input:checked + .mbsc-checkbox-box {
      background: $mbsc-windows-success;
      border-color: $mbsc-windows-success;
    }

    &.mbsc-checkbox-danger.mbsc-checkbox input:checked + .mbsc-checkbox-box {
      background: $mbsc-windows-danger;
      border-color: $mbsc-windows-danger;
    }

    &.mbsc-checkbox-warning.mbsc-checkbox input:checked + .mbsc-checkbox-box {
      background: $mbsc-windows-warning;
      border-color: $mbsc-windows-warning;
    }

    &.mbsc-checkbox-info.mbsc-checkbox input:checked + .mbsc-checkbox-box {
      background: $mbsc-windows-info;
      border-color: $mbsc-windows-info;
    }

    /* Radio */
    &.mbsc-ltr.mbsc-radio {
      padding: 1.125em 3.25em 1.125em 1em;
    }

    &.mbsc-rtl.mbsc-radio {
      padding: 1.125em 1em 1.125em 3.25em;
    }

    .mbsc-radio-box {
      right: 1.125em;
    }

    &.mbsc-rtl .mbsc-radio-box {
      right: auto;
      left: 1.125em;
    }

    /* Radio color presets */
    &.mbsc-radio-primary .mbsc-radio-box:after {
      background: $mbsc-windows-primary;
    }

    &.mbsc-radio-secondary .mbsc-radio-box:after {
      background: $mbsc-windows-secondary;
    }

    &.mbsc-radio-success .mbsc-radio-box:after {
      background: $mbsc-windows-success;
    }

    &.mbsc-radio-danger .mbsc-radio-box:after {
      background: $mbsc-windows-danger;
    }

    &.mbsc-radio-warning .mbsc-radio-box:after {
      background: $mbsc-windows-warning;
    }

    &.mbsc-radio-info .mbsc-radio-box:after {
      background: $mbsc-windows-info;
    }

    /* Buttons */
    &.mbsc-btn {
      margin: .5em .25em;
      padding: .3125em .5em;
      border-radius: 0;
    }

    .mbsc-btn-ic {
      padding-right: .5em;
    }

    .mbsc-btn-icon-only .mbsc-btn-ic {
      padding: 0;
    }

    &.mbsc-btn:disabled {
      opacity: .3;
    }

    &.mbsc-no-touch.mbsc-btn-flat:not(:disabled):hover {
      opacity: .7;
    }

    &.mbsc-btn.mbsc-btn-flat.mbsc-active:not(:disabled) {
      opacity: 1;
    }

    .mbsc-btn-group,
    .mbsc-btn-group-justified {
      margin: .5em .75em;
    }

    .mbsc-btn-group-block {
      margin: .5em 1em;
    }

    /* Button color presets*/
    &.mbsc-btn-primary.mbsc-btn {
      color: get-contrast-color($mbsc-windows-primary);
      background: $mbsc-windows-primary;
      border-color: $mbsc-windows-primary;

      &.mbsc-btn-flat {
        color: $mbsc-windows-primary;
        background: transparent;
        border-color: transparent;

        &.mbsc-active {
          background: rgba($mbsc-windows-primary, .25);
        }
      }
    }

    &.mbsc-btn-secondary.mbsc-btn {
      color: get-contrast-color($mbsc-windows-secondary);
      background: $mbsc-windows-secondary;
      border-color: $mbsc-windows-secondary;

      &.mbsc-btn-flat {
        color: $mbsc-windows-secondary;
        background: transparent;
        border-color: transparent;

        &.mbsc-active {
          background: rgba($mbsc-windows-secondary, .25);
        }
      }
    }

    &.mbsc-btn-success.mbsc-btn {
      color: get-contrast-color($mbsc-windows-success);
      background: $mbsc-windows-success;
      border-color: $mbsc-windows-success;

      &.mbsc-btn-flat {
        color: $mbsc-windows-success;
        background: transparent;
        border-color: transparent;

        &.mbsc-active {
          background: rgba($mbsc-windows-success, .25);
        }
      }
    }

    &.mbsc-btn-danger.mbsc-btn {
      color: get-contrast-color($mbsc-windows-danger);
      background: $mbsc-windows-danger;
      border-color: $mbsc-windows-danger;

      &.mbsc-btn-flat {
        color: $mbsc-windows-danger;
        background: transparent;
        border-color: transparent;

        &.mbsc-active {
          background: rgba($mbsc-windows-danger, .25);
        }
      }
    }

    &.mbsc-btn-warning.mbsc-btn {
      color: get-contrast-color($mbsc-windows-warning);
      background: $mbsc-windows-warning;
      border-color: $mbsc-windows-warning;

      &.mbsc-btn-flat {
        color: $mbsc-windows-warning;
        background: transparent;
        border-color: transparent;

        &.mbsc-active {
          background: rgba($mbsc-windows-warning, .25);
        }
      }
    }

    &.mbsc-btn-info.mbsc-btn {
      color: get-contrast-color($mbsc-windows-info);
      background: $mbsc-windows-info;
      border-color: $mbsc-windows-info;

      &.mbsc-btn-flat {
        color: $mbsc-windows-info;
        background: transparent;
        border-color: transparent;

        &.mbsc-active {
          background: rgba($mbsc-windows-info, .25);
        }
      }
    }

    &.mbsc-btn-light.mbsc-btn {
      color: get-contrast-color($mbsc-windows-light);
      background: darken($mbsc-windows-light, 25%);
      border-color: darken($mbsc-windows-light, 25%);

      &.mbsc-btn-flat {
        color: darken($mbsc-windows-light, 25%);
        background: transparent;
        border-color: transparent;

        &.mbsc-active {
          background: rgba(darken($mbsc-windows-light, 25%), .25);
        }
      }
    }

    &.mbsc-btn-dark.mbsc-btn {
      color: get-contrast-color($mbsc-windows-dark);
      background: $mbsc-windows-dark;
      border-color: $mbsc-windows-dark;

      &.mbsc-btn-flat {
        color: $mbsc-windows-dark;
        background: transparent;
        border-color: transparent;

        &.mbsc-active {
          background: rgba($mbsc-windows-dark, .25);
        }
      }
    }

    /* Outline buttons */
    &.mbsc-btn.mbsc-btn-outline.mbsc-active {
      opacity: 1;
    }

    /* Outline buttons */
    &.mbsc-btn.mbsc-btn-outline {
      &.mbsc-btn-primary {
        border-color: $mbsc-windows-primary;
        color: $mbsc-windows-primary;
        background: transparent;

        &.mbsc-active {
          background: $mbsc-windows-primary;
          color: $mbsc-windows-light;
        }
      }

      &.mbsc-btn-secondary {
        border-color: $mbsc-windows-secondary;
        color: $mbsc-windows-secondary;
        background: transparent;

        &.mbsc-active {
          background: $mbsc-windows-secondary;
          color: $mbsc-windows-light;
        }
      }

      &.mbsc-btn-success {
        border-color: $mbsc-windows-success;
        color: $mbsc-windows-success;
        background: transparent;

        &.mbsc-active {
          background: $mbsc-windows-success;
          color: $mbsc-windows-light;
        }
      }

      &.mbsc-btn-danger {
        border-color: $mbsc-windows-danger;
        color: $mbsc-windows-danger;
        background: transparent;

        &.mbsc-active {
          background: $mbsc-windows-danger;
          color: $mbsc-windows-light;
        }
      }

      &.mbsc-btn-warning {
        border-color: $mbsc-windows-warning;
        color: $mbsc-windows-warning;
        background: transparent;

        &.mbsc-active {
          background: $mbsc-windows-warning;
          color: $mbsc-windows-light;
        }
      }

      &.mbsc-btn-info {
        border-color: $mbsc-windows-info;
        color: $mbsc-windows-info;
        background: transparent;

        &.mbsc-active {
          background: $mbsc-windows-info;
          color: $mbsc-windows-light;
        }
      }

      &.mbsc-btn-light {
        border-color: darken($mbsc-windows-light, 25%);
        color: darken($mbsc-windows-light, 25%);
        background: transparent;

        &.mbsc-active {
          background: darken($mbsc-windows-light, 25%);
          color: $mbsc-windows-dark;
        }
      }

      &.mbsc-btn-dark {
        border-color: $mbsc-windows-dark;
        color: $mbsc-windows-dark;
        background: transparent;

        &.mbsc-active {
          background: $mbsc-windows-dark;
          color: $mbsc-windows-light;
        }
      }
    }

    /* button hover style */
    &.mbsc-no-touch {
      &.mbsc-btn-primary.mbsc-btn {
        &:not(.mbsc-btn-flat):not(:disabled):hover {
          border-color: darken($mbsc-windows-primary, 20%);
        }
      }

      &.mbsc-btn-secondary.mbsc-btn {
        &:not(.mbsc-btn-flat):not(:disabled):hover {
          border-color: darken($mbsc-windows-secondary, 20%);
        }
      }

      &.mbsc-btn-success.mbsc-btn {
        &:not(.mbsc-btn-flat):not(:disabled):hover {
          border-color: darken($mbsc-windows-success, 20%);
        }
      }

      &.mbsc-btn-danger.mbsc-btn {
        &:not(.mbsc-btn-flat):not(:disabled):hover {
          border-color: darken($mbsc-windows-danger, 20%);
        }
      }

      &.mbsc-btn-warning.mbsc-btn {
        &:not(.mbsc-btn-flat):not(:disabled):hover {
          border-color: darken($mbsc-windows-warning, 20%);
        }
      }

      &.mbsc-btn-info.mbsc-btn {
        &:not(.mbsc-btn-flat):not(:disabled):hover {
          border-color: darken($mbsc-windows-info, 20%);
        }
      }

      &.mbsc-btn-light.mbsc-btn {
        &:not(.mbsc-btn-flat):not(:disabled):hover {
          border-color: darken($mbsc-windows-light, 40%);
        }
      }

      &.mbsc-btn-dark.mbsc-btn {
        &:not(.mbsc-btn-flat):not(:disabled):hover {
          border-color: darken($mbsc-windows-dark, 30%);
        }
      }
    }

    /* button active style */
    .mbsc-btn-primary.mbsc-btn {
      &.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
        background: darken($mbsc-windows-primary, 20%);
        border-color: darken($mbsc-windows-primary, 20%);
      }
    }

    .mbsc-btn-secondary.mbsc-btn {
      &.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
        background: darken($mbsc-windows-secondary, 20%);
        border-color: darken($mbsc-windows-secondary, 20%);
      }
    }

    .mbsc-btn-success.mbsc-btn {
      &.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
        background: darken($mbsc-windows-success, 20%);
        border-color: darken($mbsc-windows-success, 20%);
      }
    }

    .mbsc-btn-danger.mbsc-btn {
      &.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
        background: darken($mbsc-windows-danger, 20%);
        border-color: darken($mbsc-windows-danger, 20%);
      }
    }

    .mbsc-btn-warning.mbsc-btn {
      &.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
        background: darken($mbsc-windows-warning, 20%);
        border-color: darken($mbsc-windows-warning, 20%);
      }
    }

    .mbsc-btn-info.mbsc-btn {
      &.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
        background: darken($mbsc-windows-info, 20%);
        border-color: darken($mbsc-windows-info, 20%);
      }
    }

    .mbsc-btn-light.mbsc-btn {
      &.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
        background: darken($mbsc-windows-light, 40%);
        border-color: darken($mbsc-windows-light, 40%);
      }
    }

    .mbsc-btn-dark.mbsc-btn {
      &.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
        background: darken($mbsc-windows-dark, 30%);
        border-color: darken($mbsc-windows-dark, 30%);
      }
    }

    /* Flat buttons */
    &.mbsc-btn.mbsc-btn-flat {
      background: transparent;
      border-color: transparent;
    }

    /* Switch */
    &.mbsc-ltr.mbsc-switch {
      padding: 1.125em 5.875em 1.125em 1em;
    }

    &.mbsc-rtl.mbsc-switch {
      padding: 1.125em 1em 1.125em 5.875em;
    }

    .mbsc-switch-track {
      right: 1.125em;
      width: 2.75em;
      height: 1.25em;
      margin-top: -0.645em;
      padding: 0 .5em;
      border-radius: 10px;
    }

    &.mbsc-rtl .mbsc-switch-track {
      right: auto;
      left: 1.125em;
    }

    .mbsc-switch-track .mbsc-switch-handle {
      z-index: 1;
      top: 50%;
      left: 50%;
      right: auto;
      height: .625em;
      width: .625em;
      border-radius: 10px;
      margin: -0.3125em 0 0 -0.3125em;
    }

    /* Checkbox color presets */
    &.mbsc-switch-primary.mbsc-switch input:checked + .mbsc-switch-track {
      background: $mbsc-windows-primary;
      border-color: $mbsc-windows-primary;
    }

    &.mbsc-switch-secondary.mbsc-switch input:checked + .mbsc-switch-track {
      background: $mbsc-windows-secondary;
      border-color: $mbsc-windows-secondary;
    }

    &.mbsc-switch-success.mbsc-switch input:checked + .mbsc-switch-track {
      background: $mbsc-windows-success;
      border-color: $mbsc-windows-success;
    }

    &.mbsc-switch-danger.mbsc-switch input:checked + .mbsc-switch-track {
      background: $mbsc-windows-danger;
      border-color: $mbsc-windows-danger;
    }

    &.mbsc-switch-warning.mbsc-switch input:checked + .mbsc-switch-track {
      background: $mbsc-windows-warning;
      border-color: $mbsc-windows-warning;
    }

    &.mbsc-switch-info.mbsc-switch input:checked + .mbsc-switch-track {
      background: $mbsc-windows-info;
      border-color: $mbsc-windows-info;
    }

    /* Segmented & Stepper */
    &.mbsc-segmented {
      padding: .75em 1em;
    }

    .mbsc-segmented-content {
      height: 2.285715em;
      line-height: 2.285715em;
      padding: 0 .4285715em;
    }

    .mbsc-segmented-content.mbsc-stepper-val {
      box-sizing: border-box;
    }

    &.mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item:last-child,
    &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-plus,
    &.mbsc-stepper-val-left .mbsc-segmented input + .mbsc-segmented-item,
    &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control {
      border: 0;
    }

    .mbsc-stepper {
      margin-top: -1em;
    }

    &.mbsc-ltr.mbsc-stepper-cont {
      padding: 1.75em 13em 1.75em 1em;
    }

    &.mbsc-rtl.mbsc-stepper-cont {
      padding: 1.75em 1em 1.75em 13em;
    }

    &.mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
    &.mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
    &.mbsc-stepper .mbsc-segmented-item.mbsc-disabled .mbsc-segmented-content,
    &.mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
      opacity: .3;
    }

    &.mbsc-segmented {

      /* Segmented color presets */
      .mbsc-segmented-primary {

        &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
        &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
          background: $mbsc-windows-primary;
        }
      }

      .mbsc-segmented-secondary {

        &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
        &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
          background: $mbsc-windows-secondary;
        }
      }

      .mbsc-segmented-success {

        &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
        &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
          background: $mbsc-windows-success;
        }
      }

      .mbsc-segmented-danger {

        &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
        &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
          background: $mbsc-windows-danger;
        }
      }

      .mbsc-segmented-warning {

        &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
        &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
          background: $mbsc-windows-warning;
        }
      }

      .mbsc-segmented-info {

        &.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
        &.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
          background: $mbsc-windows-info;
        }
      }
    }

    &.mbsc-no-touch {

      /* Segmented hover color presets*/
      &.mbsc-segmented-primary {
        &.mbsc-segmented-item:hover input:not(:disabled):not(.mbsc-active) + .mbsc-segmented-content {
          background: rgba($mbsc-windows-primary, .25);
        }
      }

      &.mbsc-segmented-secondary {
        &.mbsc-segmented-item:hover input:not(:disabled):not(.mbsc-active) + .mbsc-segmented-content {
          background: rgba($mbsc-windows-secondary, .25);
        }
      }

      &.mbsc-segmented-success {
        &.mbsc-segmented-item:hover input:not(:disabled):not(.mbsc-active) + .mbsc-segmented-content {
          background: rgba($mbsc-windows-success, .25);
        }
      }

      &.mbsc-segmented-danger {
        &.mbsc-segmented-item:hover input:not(:disabled):not(.mbsc-active) + .mbsc-segmented-content {
          background: rgba($mbsc-windows-danger, .25);
        }
      }

      &.mbsc-segmented-warning {
        &.mbsc-segmented-item:hover input:not(:disabled):not(.mbsc-active) + .mbsc-segmented-content {
          background: rgba($mbsc-windows-warning, .25);
        }
      }

      &.mbsc-segmented-info {
        &.mbsc-segmented-item:hover input:not(:disabled):not(.mbsc-active) + .mbsc-segmented-content {
          background: rgba($mbsc-windows-info, .25);
        }
      }

      /* Stepper hover color presets*/
      &.mbsc-stepper-primary {
        .mbsc-segmented .mbsc-segmented-item:not(.mbsc-disabled):hover .mbsc-segmented-content {
          background: darken($mbsc-windows-primary, 10%);
        }
      }

      &.mbsc-stepper-secondary {
        .mbsc-segmented .mbsc-segmented-item:not(.mbsc-disabled):hover .mbsc-segmented-content {
          background: darken($mbsc-windows-secondary, 10%)
        }
      }

      &.mbsc-stepper-success {
        .mbsc-segmented .mbsc-segmented-item:not(.mbsc-disabled):hover .mbsc-segmented-content {
          background: darken($mbsc-windows-success, 10%)
        }
      }

      &.mbsc-stepper-danger {
        .mbsc-segmented .mbsc-segmented-item:not(.mbsc-disabled):hover .mbsc-segmented-content {
          background: darken($mbsc-windows-danger, 10%)
        }
      }

      &.mbsc-stepper-warning {
        .mbsc-segmented .mbsc-segmented-item:not(.mbsc-disabled):hover .mbsc-segmented-content {
          background: darken($mbsc-windows-warning, 10%)
        }
      }

      &.mbsc-stepper-info {
        .mbsc-segmented .mbsc-segmented-item:not(.mbsc-disabled):hover .mbsc-segmented-content {
          background: darken($mbsc-windows-info, 10%)
        }
      }
    }

    /* Stepper color presets */
    &.mbsc-stepper-primary {
      .mbsc-segmented-content.mbsc-stepper-val {
        border-color: $mbsc-windows-primary;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control .mbsc-segmented-content {
        background: $mbsc-windows-primary;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active:not(.mbsc-disabled) .mbsc-segmented-content {
        background: darken($mbsc-windows-primary, 25%);
      }
    }

    &.mbsc-stepper-secondary {
      .mbsc-segmented-content.mbsc-stepper-val {
        border-color: $mbsc-windows-secondary;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control .mbsc-segmented-content {
        background: $mbsc-windows-secondary;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active:not(.mbsc-disabled) .mbsc-segmented-content {
        background: darken($mbsc-windows-secondary, 25%);
      }
    }

    &.mbsc-stepper-success {
      .mbsc-segmented-content.mbsc-stepper-val {
        border-color: $mbsc-windows-success;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control .mbsc-segmented-content {
        background: $mbsc-windows-success;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active:not(.mbsc-disabled) .mbsc-segmented-content {
        background: darken($mbsc-windows-success, 25%);
      }
    }

    &.mbsc-stepper-danger {
      .mbsc-segmented-content.mbsc-stepper-val {
        border-color: $mbsc-windows-danger;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control .mbsc-segmented-content {
        background: $mbsc-windows-danger;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active:not(.mbsc-disabled) .mbsc-segmented-content {
        background: darken($mbsc-windows-danger, 25%);
      }
    }

    &.mbsc-stepper-warning {
      .mbsc-segmented-content.mbsc-stepper-val {
        border-color: $mbsc-windows-warning;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control .mbsc-segmented-content {
        background: $mbsc-windows-warning;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active:not(.mbsc-disabled) .mbsc-segmented-content {
        background: darken($mbsc-windows-warning, 25%);
      }
    }

    &.mbsc-stepper-info {
      .mbsc-segmented-content.mbsc-stepper-val {
        border-color: $mbsc-windows-info;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control .mbsc-segmented-content {
        background: $mbsc-windows-info;
      }

      .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active:not(.mbsc-disabled) .mbsc-segmented-content {
        background: darken($mbsc-windows-info, 25%);
      }
    }
  }

  @include mbsc-windows-forms(windows, $mbsc-windows-colors);
}



// eventcalendar component








@mixin mbsc-ios-eventcalendar($theme, $params) {
  @include exports("eventcalendar.#{$theme}.colors") {

    @include mbsc-ios-calbase($theme, $params);
    @include mbsc-ios-listview-base($theme, $params);
    @include mbsc-ios-common($theme, $params);

    $background: map-get($params, 'background');

    $background-param: map-get($params, 'frame-background');
    $background: if($background-param, $background-param, $background);

    $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
    $background-alt: lighten($background-limited, 3%);
    $cont-background: lighten($background, 6%);

    $list-background: '';
    $border-color: '';
    $popup-border: '';
    $popup-shadow: '';
    $popup-background: '';
    @if (lightness($background) > 50%) {
      $border-color: darken($background-limited, 17%);
      $popup-border: $border-color;
      $list-background: adjust-hue(darken(saturate($background, 19%), 2%), calc(240% / 1%));
      $popup-shadow: rgba(0, 0, 0, .3);
      $popup-background: $background-alt;
    }
    @else {
      $border-color: lighten($background, 20%);
      $popup-border: lighten($border-color, 13%);
      $list-background: adjust-hue(lighten(desaturate($background, 19%), 10%), calc(240% / 1%));
      $popup-shadow: rgba(0, 0, 0, .8);
      $popup-background: lighten($background-limited, 17%);
    }

    .mbsc-#{$theme} {
      &.mbsc-ev-cal .mbsc-fr-w {
        background: $background;
      }

      &.mbsc-ev-cal.mbsc-fr-inline .mbsc-cal-picker {
        background: $background;
      }

      &.mbsc-ev-cal.mbsc-fr-inline .mbsc-cal-scroll-c {
        background: $background-alt;
      }

      &.mbsc-cal-events-popup {
        .mbsc-fr-w {
          border: 1px solid $popup-border;
        }

        .mbsc-fr-arr {
          background: $cont-background;
          border: 1px solid $popup-border;
        }
      }

      .mbsc-cal-events-i {
        background: $popup-background;
      }


      .mbsc-cal-event-color {
        background: #94b8dd;
      }

      .mbsc-cal-event-dur,
      .mbsc-cal-event-time {
        color: #8e8e8e;
      }

      /* Event listing */
      .mbsc-event-list {
        background: $list-background;
      }

      /* Desktop grid mode */
      &.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell {
        border-left: 1px solid $border-color;
      }
    }
  }
}





@include exports("eventcalendar") {
  .mbsc-cal-events-popup {
    .mbsc-fr-persp {
      pointer-events: none;
    }

    .mbsc-fr-popup {
      pointer-events: auto;
    }
  }

  .mbsc-cal-events {
    box-sizing: content-box;
    font-size: .857143em;
    max-width: 100%;
  }

  .mbsc-cal-events-i {
    max-height: 24em;
  }

  .mbsc-cal-events-sc {
    position: relative;
    -webkit-backface-visibility: hidden;
  }

  .mbsc-cal-event {
    line-height: 1.666667em;
    list-style: none;
    position: relative;
    white-space: nowrap;
    cursor: pointer;
  }

  .mbsc-ltr .mbsc-cal-events .mbsc-cal-event {
    padding-right: 5em;
    text-align: left;
  }

  .mbsc-rtl .mbsc-cal-events .mbsc-cal-event {
    padding-left: 5em;
    text-align: right;
  }

  .mbsc-cal-event-color {
    position: absolute;
    top: 0;
    left: 0;
    width: .5em;
    height: 100%;
  }

  .mbsc-cal-event-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mbsc-cal-event-time {
    display: inline-block;
    width: 5em;
    white-space: normal;
    line-height: 1.166667em;
    vertical-align: middle;
  }

  .mbsc-cal-event-dur {
    position: absolute;
    top: 50%;
    height: 2em;
    margin-top: -1em;
    line-height: 2em;
  }

  .mbsc-ltr .mbsc-cal-event-dur {
    right: 1em;
  }

  .mbsc-rtl .mbsc-cal-event-dur {
    left: 1em;
  }

  /* Event list styling */

  .mbsc-event-list {
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .mbsc-event-list-h {
    display: none;
  }

  .mbsc-event-list .mbsc-lv .mbsc-lv-gr-title {
    padding: .5em 1em;
    line-height: 1.5em;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 0;
  }

  .mbsc-event-list .mbsc-lv .mbsc-lv-item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: .875em;
    padding: .714286em 1em;
    cursor: pointer;
  }

  /* Empty view */

  .mbsc-event-list .mbsc-lv .mbsc-lv-gr-title.mbsc-event-empty {
    text-transform: none;
    font-size: 1em;
  }

  .mbsc-ltr .mbsc-event-list {
    text-align: left;
  }

  .mbsc-rtl .mbsc-event-list {
    text-align: right;
  }

  .mbsc-event-color,
  .mbsc-event-time {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .mbsc-event-time {
    width: 6em;
    font-size: .714286em;
    line-height: 1.6;
    min-height: 3.2em;
    opacity: .7;
  }

  .mbsc-ltr .mbsc-event-time {
    text-align: right;
  }

  .mbsc-rtl .mbsc-event-time {
    text-align: left;
  }

  .mbsc-event-color {
    width: 1.5px;
    margin: -0.285714em 0.714286em;
  }

  .mbsc-event-txt {
    line-height: 1.142857;
  }

  /* Desktop grid mode */

  .mbsc-ev-cal.mbsc-fr-md {
    .mbsc-cal-hdr {
      padding: .5em;
    }

    .mbsc-cal-scroll-c {
      margin-left: -1px;
    }

    .mbsc-cal-day-i {
      min-height: 4em;
    }

    .mbsc-cal-marks {
      top: 100%;
    }

    .mbsc-cal-txt,
    .mbsc-cal-txt-ph,
    .mbsc-cal-txt-more {
      font-size: .75em;
      height: 1.5em;
      line-height: 1.5em;
      padding: 0 .333334em;
      margin: 0 .333334em .166667em .333334em;
    }
  }

  /* Scrollbar */

  .mbsc-cal-events-i:hover .mbsc-sc-bar-c {
    opacity: 1;
  }
}


@include exports("eventcalendar.ios") {
  .mbsc-ios {
    &.mbsc-ev-cal.mbsc-fr-inline .mbsc-cal-hdr {
      padding: .5em 0;
    }

    &.mbsc-ev-cal.mbsc-fr-inline .mbsc-cal-picker {
      padding-top: 1.875em;
      padding-bottom: 0;
    }

    &.mbsc-cal-events-popup {
      .mbsc-fr-arr-w {
        margin: -1px 0;
      }
    }

    .mbsc-cal-events {
      width: 36.833334em;
    }

    .mbsc-cal-event {
      font-weight: bold;
      line-height: 1.833334em;
      margin-top: -1px;
      padding: 1.166667em;
    }

    .mbsc-cal-event:last-child:after {
      border: 0;
    }

    .mbsc-cal-event-color {
      top: 50%;
      margin-top: -.25em;
      width: .5em;
      height: .5em;
      border-radius: .5em;
    }

    .mbsc-ltr .mbsc-cal-event-color {
      left: 6.833334em;
    }

    .mbsc-rtl .mbsc-cal-event-color {
      right: 6.833334em;
    }

    .mbsc-cal-event-dur,
    .mbsc-cal-event-time {
      font-weight: normal;
    }

    .mbsc-ltr .mbsc-cal-event-time {
      margin-right: 1.916667em;
    }

    .mbsc-rtl .mbsc-cal-event-time {
      margin-left: 1.916667em;
    }

    /* Event listing */
    .mbsc-event-list {
      margin: 0;
      padding: 0 0 2em 0;
      background: #efeff4;
    }

    .mbsc-event-list .mbsc-event-day {
      font-weight: bold;
    }

    /* Empty view */
    .mbsc-event-empty {
      border-bottom: 0;
    }

    /* Desktop grid mode */
    &.mbsc-ev-cal.mbsc-fr-md {
      .mbsc-cal-days > div {
        padding: 0 .571428em;
        font-size: .875em;
      }

      .mbsc-cal-slide {
        padding: 0;
      }

      .mbsc-cal-day-date {
        margin: .1875em;
        margin-bottom: .375em;
      }

      .mbsc-cal-has-labels .mbsc-cal-day-i {
        min-height: 5em;
      }

      .mbsc-cal-marks {
        margin: -0.5em .875em 0 .875em;
      }

      .mbsc-ltr {

        .mbsc-cal-days,
        .mbsc-cal-day {
          text-align: right;
        }
      }

      .mbsc-rtl {

        .mbsc-cal-days,
        .mbsc-cal-day {
          text-align: left;
        }
      }
    }
  }

  @include mbsc-ios-eventcalendar(ios, $mbsc-ios-colors);
}




/* Event calendar */
.mbsc-bootstrap {
  .mbsc-cal-events {
    padding: 5px;
    position: relative;
    max-width: none;
    width: 400px;
  }

  .mbsc-cal-event-color {
    background: #fff;
  }
}









@mixin mbsc-mobiscroll-eventcalendar($theme, $params) {
  @include exports("eventcalendar.#{$theme}.colors") {

    @include mbsc-mobiscroll-calbase($theme, $params);
    @include mbsc-mobiscroll-listview-base($theme, $params);
    @include mbsc-mobiscroll-common($theme, $params);

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');
    $text: map-get($params, 'text');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    $event-border: lighten($text, 25%);
    $table-border: '';
    // Light background
    @if (lightness($background) > 50%) {
      $table-border: darken($background, 10%);
    }
    // Dark background
    @else {
      $table-border: lighten($background, 8%);
    }

    .mbsc-#{$theme} {
      &.mbsc-cal-events-popup {
        .mbsc-fr-w {
          border: .375em solid $event-border;
        }

        .mbsc-fr-arr {
          background: $event-border;
        }
      }

      .mbsc-cal-event-color {
        background: $accent;
      }

      .mbsc-cal-event-time,
      .mbsc-cal-event-dur {
        color: $accent;
      }

      /* Event listing */
      .mbsc-event-list .mbsc-lv-gr-title {
        background: $background;
        color: $accent;
      }

      .mbsc-event-color {
        background: rgba($text, .3);
      }

      /* Desktop grid mode */
      &.mbsc-ev-cal.mbsc-fr-md {
        .mbsc-cal-table {
          border-top: 1px solid $table-border;
        }

        .mbsc-cal-cell {
          border-left: 1px solid $table-border;
          border-bottom: 1px solid $table-border;
        }
      }
    }
  }
}



@include exports("eventcalendar.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-cal-events-popup {
      .mbsc-fr-w {
        border-radius: 0;
      }

      .mbsc-fr-bubble-top .mbsc-fr-arr {
        border-bottom-color: transparent;
      }
    }

    .mbsc-cal-events {
      width: 32em;
    }

    .mbsc-cal-event {
      padding: 1em 1em 1em 1.25em;
    }

    /* Desktop grid mode */
    &.mbsc-ev-cal.mbsc-fr-md {
      .mbsc-cal-c {
        padding: 0;
      }

      .mbsc-cal-days > div {
        border: 0;
        padding: 0 .785714em;
        font-size: .875em;
      }

      .mbsc-cal-picker {
        padding-top: 1.875em;
        padding-bottom: 0;
      }

      .mbsc-cal-day-date {
        margin: .230769em;
        margin-bottom: .461538em;
      }

      .mbsc-cal-has-labels .mbsc-cal-day-i {
        min-height: 5.125em;
      }

      .mbsc-cal-marks {
        margin: -.5em 1em 0 1em;
      }

      .mbsc-ltr {

        .mbsc-cal-days,
        .mbsc-cal-day {
          text-align: left;
        }
      }

      .mbsc-rtl {

        .mbsc-cal-days,
        .mbsc-cal-day {
          text-align: right;
        }
      }
    }
  }

  @include mbsc-mobiscroll-eventcalendar(mobiscroll, $mbsc-mobiscroll-colors);
}









@mixin mbsc-material-eventcalendar($theme, $params) {
  @include exports("eventcalendar.#{$theme}.colors") {

    @include mbsc-material-calbase($theme, $params);
    @include mbsc-material-listview-base($theme, $params);
    @include mbsc-material-common($theme, $params);

    $background: map-get($params, 'background');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $accent: if($accent-param, $accent-param, $accent);

    $gray-background: '';
    $white-text: #fff;

    $light-background: lighten($background, 4.8%);
    @if (lightness($background) > 50%) {
      $gray-background: darken($background, 23%);
    }
    @else {
      $gray-background: lighten($background, 17%);
    }
    .mbsc-#{$theme} {
      .mbsc-cal-events .mbsc-cal-event {
        color: $white-text;
      }

      .mbsc-cal-event-color {
        background: $accent;
      }

      .mbsc-cal-event.mbsc-focus,
      .mbsc-event-list .mbsc-lv-item.mbsc-focus {
        box-shadow: 0 .25em .5em 0 rgba(0, 0, 0, 0.4);
      }

      /* Event listing */

      .mbsc-event-list .mbsc-lv-item {
        color: $light-background;
      }

      .mbsc-event-color {
        background: $accent;
      }

      /* Desktop grid mode */
      &.mbsc-ev-cal.mbsc-fr-md {
        .mbsc-cal-table {
          border-top: 1px solid $gray-background;
        }

        .mbsc-cal-cell {
          border-left: 1px solid $gray-background;
          border-bottom: 1px solid $gray-background;
        }
      }
    }
  }
}



@include exports("eventcalendar.material") {
  .mbsc-material {
    .mbsc-cal-events {
      width: 30em;
    }

    .mbsc-cal-event {
      margin: .5em;
      padding: .5em;
    }

    .mbsc-cal-event-color {
      z-index: -2;
      width: 100%;
      border-radius: .214286em;
    }

    .mbsc-cal-event-time,
    .mbsc-cal-event-text {
      position: relative;
      z-index: 1;
    }

    /* Event listing */

    .mbsc-event-list .mbsc-lv {
      background: none;
    }

    .mbsc-event-list .mbsc-lv-item {
      margin: .5em;
      padding: .5em;
    }

    .mbsc-ltr .mbsc-event-time {
      text-align: left;
    }

    .mbsc-rtl .mbsc-event-time {
      text-align: right;
    }

    .mbsc-event-color {
      position: absolute;
      width: auto;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -2;
      margin: 0;
      border-radius: .214286em;
    }

    .mbsc-event-txt {
      padding: 0 1em;
    }

    /* Desktop grid mode */
    &.mbsc-ev-cal.mbsc-fr-md {
      .mbsc-cal-c {
        padding: 0;
      }

      .mbsc-cal-days > div {
        padding: 0 .714286em;
        font-size: .875em;
      }

      .mbsc-cal-picker {
        padding-top: 1.875em;
        padding-bottom: 0;
      }

      .mbsc-cal-day-date {
        margin: .230769em;
        margin-bottom: .461538em;
      }

      .mbsc-cal-has-labels .mbsc-cal-day-i {
        min-height: 5.375em;
      }

      .mbsc-cal .mbsc-cal-day .mbsc-cal-marks {
        // Rule must be strong to override hover transform
        margin: -0.5em 1.125em 0 1.125em;
        -webkit-transform: none;
        transform: none;
      }

      .mbsc-ltr {

        .mbsc-cal-days,
        .mbsc-cal-day {
          text-align: left;
        }
      }

      .mbsc-rtl {

        .mbsc-cal-days,
        .mbsc-cal-day {
          text-align: right;
        }
      }
    }
  }

  @include mbsc-material-eventcalendar(material, $mbsc-material-colors);
}









@mixin mbsc-windows-eventcalendar($theme, $params) {
  @include exports("eventcalendar.#{$theme}.colors") {

    @include mbsc-windows-calbase($theme, $params);
    @include mbsc-windows-listview-base($theme, $params);
    @include mbsc-windows-common($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $background-param: map-get($params, 'frame-background');
    $text-param: map-get($params, 'frame-text');
    $accent-param: map-get($params, 'frame-accent');

    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $accent: if($accent-param, $accent-param, $accent);

    $wheel-button: '';
    $frame-border: '';
    $form-background: '';
    @if (lightness($background) > 50%) {
      $form-background: lighten($background, 13%);
      $wheel-button: darken($background, 5%);
      $frame-border: darken($background, 15%);
    }
    @else {
      $form-background: darken($background, 12%);
      $wheel-button: lighten($background, 5%);
      $frame-border: lighten($background, 15%);
    }

    .mbsc-#{$theme} {
      &.mbsc-cal-events-popup {
        .mbsc-fr-w {
          border: .375em solid rgba($text, .8);
        }

        .mbsc-fr-arr {
          background: rgba($text, .8);
        }
      }

      .mbsc-cal-events {
        background: rgba($text, .8);
      }

      .mbsc-cal-event-color {
        background: $text;
      }

      /* Event listing */
      .mbsc-event-list .mbsc-lv-item {
        background: $form-background;
      }

      .mbsc-event-list .mbsc-lv-item:last-child {
        border-bottom: 1px solid $wheel-button;
      }

      .mbsc-event-list .mbsc-lv-gr-title {
        background: $form-background;
      }

      .mbsc-event-color {
        background: $accent;
      }

      /* Desktop grid mode */
      &.mbsc-ev-cal.mbsc-fr-md {
        .mbsc-cal-table {
          border-top: 1px solid $frame-border;
        }

        .mbsc-cal-cell {
          background: $form-background;
          border-left: 1px solid $frame-border;
          border-bottom: 1px solid $frame-border;
        }

        .mbsc-cal-day-diff .mbsc-cal-day-i {
          background: $background;
        }
      }
    }
  }
}



@include exports("eventcalendar.windows") {
  .mbsc-windows {
    .mbsc-cal-events {
      width: 31.5em;
    }

    .mbsc-cal-event {
      padding: 1em;
    }

    /* Event listing */
    .mbsc-event-list .mbsc-lv-gr-title {
      font-size: .9375em;
      font-weight: bold;
    }

    .mbsc-event-color {
      width: .5em;
      -webkit-box-ordinal-group: -1;
      -moz-box-ordinal-group: -1;
      -ms-flex-order: -1;
      -webkit-order: -1;
      order: -1;
    }

    .mbsc-ltr .mbsc-event-color {
      margin-left: -1em;
      margin-right: 0;
    }

    .mbsc-rtl .mbsc-event-color {
      margin-left: 0;
      margin-right: -1em;
    }

    .mbsc-event-time {
      margin: 0 1em;
    }

    .mbsc-ltr .mbsc-event-time {
      text-align: left;
    }

    .mbsc-rtl .mbsc-event-time {
      text-align: right;
    }

    &.mbsc-cal-events-popup {
      .mbsc-fr-arr-w {
        margin: 0;
      }

      .mbsc-fr-arr {
        border: 0;
      }
    }

    /* Desktop grid mode */
    &.mbsc-ev-cal.mbsc-fr-md {
      .mbsc-cal-c {
        padding: 0;
      }

      .mbsc-cal-days > div {
        padding: 0 .6em;
      }

      .mbsc-cal-picker {
        padding: 0;
        padding-top: 1.875em;
      }

      .mbsc-cal-day-date {
        padding: 0 .5em;
      }

      .mbsc-cal-has-labels .mbsc-cal-day-i {
        min-height: 4.75em;
      }

      .mbsc-cal-marks {
        margin: -.875em .625em 0 .625em;
      }

      .mbsc-ltr {

        .mbsc-cal-days,
        .mbsc-cal-day,
        .mbsc-cal-day-date {
          text-align: left;
        }
      }

      .mbsc-rtl {

        .mbsc-cal-days,
        .mbsc-cal-day,
        .mbsc-cal-day-date {
          text-align: right;
        }
      }
    }
  }

  @include mbsc-windows-eventcalendar(windows, $mbsc-windows-colors);
}



// measurement component






@mixin mbsc-ios-measurement($theme, $params) {
  @include exports("measurement.#{$theme}.colors") {
    @include mbsc-ios-scroller($theme, $params);
  }
}




@include exports("measurement") {
  .mbsc-msr .mbsc-sc-whl-gr {
    direction: ltr;
  }

  .mbsc-msr .mbsc-rtl .mbsc-sc-whl-w {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    order: 2;
  }

  .mbsc-msr .mbsc-rtl .mbsc-msr-whl-unit {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    order: 1;
  }
}


@include exports("measurement.ios") {
  @include mbsc-ios-measurement(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}










@mixin mbsc-mobiscroll-measurement($theme, $params) {
  @include exports("measurement.#{$theme}.colors") {
    @include mbsc-mobiscroll-scroller($theme, $params);
  }
}



@include exports("measurement.mobiscroll") {
  @include mbsc-mobiscroll-measurement(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}







@mixin mbsc-material-measurement($theme, $params) {
  @include exports("measurement.#{$theme}.colors") {
    @include mbsc-material-scroller($theme, $params);
  }
}



@include exports("measurement.material") {
  @include mbsc-material-measurement(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}







@mixin mbsc-windows-measurement($theme, $params) {
  @include exports("measurement.#{$theme}.colors") {
    @include mbsc-windows-scroller($theme, $params);
  }
}



@include exports("measurement.windows") {
  @include mbsc-windows-measurement(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}



// navigation component






@mixin mbsc-ios-scrollview($theme, $params) {
  @include exports("scrollview.#{$theme}.colors") {}
}




@include exports("scrollview") {
  .mbsc-scv-c {
    overflow: hidden;
    position: relative;
    font-size: 16px;
    font-family: arial, verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -ms-touch-action: pan-y;
    user-select: none;
    touch-action: pan-y;
    /*height: 100%;*/
  }

  .mbsc-scv,
  .mbsc-scv-sc {
    position: relative;
  }

  .mbsc-rtl {
    direction: rtl;
  }


  /* Flex */

  .mbsc-scv,
  .mbsc-scv-sc,
  .mbsc-scv-item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 100%;
  }

  // Needed by IE and iOS9
  .mbsc-scv,
  .mbsc-scv-item {
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .mbsc-scv.mbsc-comp {
    padding: 0;
    margin: 0 auto;
    list-style: none;
    line-height: normal;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }

  .mbsc-scv-item {
    height: auto;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    box-sizing: border-box;
  }

  /* Liquid layout */

  .mbsc-scv-liq,
  .mbsc-scv-liq .mbsc-scv-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
}


@include exports("scrollview.ios") {
  @include mbsc-ios-scrollview(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}





// Theme specific variables declaration - inherited from global variables

// background
$mbsc-ios-nav-background: $mbsc-nav-background-light !default;
$mbsc-ios-dark-nav-background: $mbsc-nav-background-dark !default;
// text
$mbsc-ios-nav-text: $mbsc-nav-text-light !default;
$mbsc-ios-dark-nav-text: $mbsc-nav-text-dark !default;
// accent
$mbsc-ios-nav-accent: $mbsc-nav-accent-light !default;
$mbsc-ios-dark-nav-accent: $mbsc-nav-accent-dark !default;

// add variables to light and dark color maps
$mbsc-ios-colors: map-merge($mbsc-ios-colors, (
  'nav-background': $mbsc-ios-nav-background,
  'nav-text': $mbsc-ios-nav-text,
  'nav-accent': $mbsc-ios-nav-accent,
));
$mbsc-ios-dark-colors: map-merge($mbsc-ios-dark-colors, (
  'nav-background': $mbsc-ios-dark-nav-background,
  'nav-text': $mbsc-ios-dark-nav-text,
  'nav-accent': $mbsc-ios-dark-nav-accent,
));

@mixin mbsc-ios-navigation-base($theme, $params) {
  @include exports("navigation-base.#{$theme}.colors") {

    @include mbsc-ios-scrollview($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    @if (map-get($params, 'button')) {
      $accent: map-get($params, 'button');
    }

    // get navigation specific customization
    $nav-background: map-get($params, 'nav-background');
    $nav-text: map-get($params, 'nav-text');
    $nav-accent: map-get($params, 'nav-accent');

    // overwrite variables with customized ones
    $background: if($nav-background, $nav-background, $background);
    $accent: if($nav-accent, $nav-accent, $accent);

    $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
    $border-color: '';
    $text-alt: '';
    @if (lightness($background) > 50%) {
      $border-color: darken($background-limited, 17%);
      $text-alt: lighten($text, 53%);
    }
    @else {
      $border-color: lighten($background, 20%);
      $text-alt: darken($text, 33%);
    }

    // overwrite variables with customized ones
    $text: if($nav-text, $nav-text, if($nav-background, get-contrast-color($nav-background), $text-alt));

    .mbsc-#{$theme} {
      &.mbsc-ms-c {
        background: $background;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
      }

      .mbsc-ms .mbsc-ms-item {
        color: $text;
      }

      &.mbsc-ms-nosel .mbsc-ms-item,
      .mbsc-ms .mbsc-ms-item-sel {
        color: $accent;
      }

      &.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
        background: rgba($accent, .15);
      }
    }
  }
}





@include exports("navigation-base") {
  .mbsc-ms-c {
    text-align: center;
  }

  .mbsc-ms-top.mbsc-ms-c,
  .mbsc-ms-bottom.mbsc-ms-c {
    z-index: 9999;
    position: fixed;
    left: 0;
    right: 0;
    height: auto;
  }

  .mbsc-ms-top.mbsc-ms-c .mbsc-ms,
  .mbsc-ms-bottom.mbsc-ms-c .mbsc-ms {
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
  }

  .mbsc-ms-top.mbsc-ms-c {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }

  .mbsc-ms-bottom.mbsc-ms-c {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .mbsc-ms-top.mbsc-scv-ctx,
  .mbsc-ms-bottom.mbsc-scv-ctx {
    position: absolute;
  }

  .mbsc-ms-top {
    top: 0;
  }

  .mbsc-ms-bottom {
    bottom: 0;
  }

  .mbsc-ms {
    text-align: center;
    white-space: nowrap;
  }

  .mbsc-ms-item {
    position: relative;
    margin: 0;
    font-size: 1em;
    line-height: 1.25;
    min-width: 4em;
    padding: .75em .5em;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    transition-property: color, background, border-color, text-shadow;
    transition-duration: 200ms;
  }

  .mbsc-ms-item:focus {
    outline: 0;
  }

  .mbsc-scv-hasw .mbsc-ms-item {
    white-space: normal;
    min-width: 0;
  }

  .mbsc-ms.mbsc-comp a.mbsc-ms-item {
    font-weight: normal; //color: inherit;
    text-decoration: none;
  }

  .mbsc-ms .mbsc-ms-item.mbsc-disabled {
    opacity: .5;
    cursor: not-allowed;
  }


  /* Icon styling */

  .mbsc-ms-icons .mbsc-ms-item {
    min-width: 6em;
    padding: 1.666667em .5em;
    font-size: .75em;
  }


  /* Icon + text styling */

  .mbsc-ms-icons.mbsc-ms-txt .mbsc-ms-item {
    padding-top: 3em;
    padding-bottom: .5em;
  }

  .mbsc-ms-ic:before {
    position: absolute;
    top: .25em;
    left: 0;
    right: 0;
    display: block;
    text-align: center;
    line-height: 1em;
  }

  .mbsc-ms-base .mbsc-ms-ic:before {
    font-size: 2em;
  }
}


@include exports("navigation-base.ios") {
  .mbsc-ios {
    .mbsc-form-group-title + .mbsc-ms-c {
      margin-top: -1px;
    }

    &.mbsc-ms-top {
      border-top: 0;
    }

    &.mbsc-ms-bottom {
      border-bottom: 0;
    }

    &.mbsc-ms-nosel .mbsc-ms-item.mbsc-active {
      opacity: .5;
    }
  }

  @include mbsc-ios-navigation-base(ios, $mbsc-ios-colors);
}







@mixin mbsc-ios-navigation($theme, $params) {
  @include exports("navigation.#{$theme}.colors") {
    @include mbsc-ios-navigation-base($theme, $params);
    @include mbsc-ios-popup($theme, $params);

    $background: map-get($params, 'background');

    // get navigation specific customization
    $nav-background: map-get($params, 'nav-background');
    // overwrite variables with customized ones
    $background: if($nav-background, $nav-background, $background);
    $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
    $border-color: '';
    @if (lightness($background) > 50%) {
      $border-color: darken($background-limited, 17%);
    }
    @else {
      $border-color: lighten($background, 20%);
    }

    .mbsc-#{$theme} {
      &.mbsc-ms-more .mbsc-ms-item:after {
        border-top: 1px solid $border-color;
      }
    }
  }
}





@include exports("navigation") {
  .mbsc-ms-badge {
    position: absolute;
    top: .25em;
    min-width: 1.666667em;
    height: 1.666667em;
    padding: 0 .25em;
    border-radius: 2em;
    line-height: 1.666667em;
    background: red;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
  }

  .mbsc-ltr .mbsc-ms-badge {
    left: 50%;
    margin-left: .5em;
  }

  .mbsc-rtl .mbsc-ms-badge {
    right: 50%;
    margin-right: .5em;
  }

  .mbsc-ms-txt:not(.mbsc-ms-icons) {
    .mbsc-ms-badge {
      font-size: .75em;
    }

    &.mbsc-ltr .mbsc-ms-badge {
      left: auto;
      right: 0;
    }

    &.mbsc-rtl .mbsc-ms-badge {
      left: 0;
      right: auto;
    }
  }

  /* Show more item */

  .mbsc-ms-item.mbsc-menu-item-ic {
    padding: 1.666667em .5em;
    font-size: .75em;

    &:before {
      top: 50%;
      margin-top: -.5em;
    }
  }

  .mbsc-ms-icons .mbsc-ms-item.mbsc-menu-item-ic:before {
    top: .25em;
    margin-top: 0;
  }


  /* Hamburger Menu icon */

  .mbsc-ms-hamburger.mbsc-ms-c.mbsc-ms-inline {
    display: inline-block;
    vertical-align: middle;
    background: none;
    border: 0;
  }


  /* Show More bubble */

  .mbsc-ms-more {
    .mbsc-ms {
      display: block;
    }

    .mbsc-ms-item {
      min-height: 2.857143em;
    }

    .mbsc-ms-item.mbsc-ms-ic:before {
      top: 50%;
      width: 2.333334em;
      margin-top: -.5em;
      line-height: 1em;
      font-size: 1.714286em;
    }

    .mbsc-ms-badge {
      top: 50%;
      font-size: .857143em;
      margin-top: -0.833333em;
    }

    /* LTR specific */
    .mbsc-ltr {
      .mbsc-ms {
        text-align: left;

        .mbsc-ms-ic {
          padding-left: 4em;
        }
      }

      .mbsc-ms-ic:before {
        right: auto;
      }

      .mbsc-ms-badge {
        left: auto;
        right: .5em;
        margin-left: 0;
      }
    }

    /* RTL specific */
    .mbsc-rtl {
      .mbsc-ms {
        text-align: right;

        .mbsc-ms-ic {
          padding-right: 4em;
        }
      }

      .mbsc-ms-ic:before {
        left: auto;
      }

      .mbsc-ms-badge {
        right: auto;
        left: .5em;
        margin-right: 0;
      }
    }

    /* Icons only */
    &.mbsc-ms-more-icons {
      .mbsc-fr-popup .mbsc-fr-w {
        min-width: 0;
      }

      .mbsc-wdg-c.mbsc-w-p {
        padding: 0;
      }

      .mbsc-ms .mbsc-ms-ic {
        padding-left: .714286em;
        padding-right: .714286em;
      }

      .mbsc-ms-ic:before {
        position: static;
        width: 1.5em;
        margin: 0;
      }
    }
  }
}


@include exports("navigation.ios") {
  .mbsc-ios {
    &.mbsc-ms-more {
      .mbsc-wdg-c.mbsc-w-p {
        padding: 0;
      }

      .mbsc-ms-item {
        padding: 1em;
      }

      .mbsc-ms-item:after {
        content: "";
        position: absolute;
        right: 0;
        left: 1em;
        bottom: 0;
      }

      .mbsc-ms-item:last-child:after {
        display: none;
      }

      .mbsc-ms-ic:after {
        left: 4em;
      }
    }

    /* Icons only */
    &.mbsc-ms-more-icons {
      .mbsc-ms-ic:after {
        left: 0;
      }
    }
  }

  @include mbsc-ios-navigation(ios, $mbsc-ios-colors);
}












@mixin mbsc-mobiscroll-scrollview($theme, $params) {
  @include exports("scrollview.#{$theme}.colors") {}
}



@include exports("scrollview.mobiscroll") {
  @include mbsc-mobiscroll-scrollview(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}





// Theme specific variables - inherited from global variables

// background
$mbsc-mobiscroll-nav-background: $mbsc-nav-background-light !default;
$mbsc-mobiscroll-dark-nav-background: $mbsc-nav-background-dark !default;
// text
$mbsc-mobiscroll-nav-text: $mbsc-nav-text-light !default;
$mbsc-mobiscroll-dark-nav-text: $mbsc-nav-text-dark !default;
// accent
$mbsc-mobiscroll-nav-accent: $mbsc-nav-accent-light !default;
$mbsc-mobiscroll-dark-nav-accent: $mbsc-nav-accent-dark !default;

// add variables to the light and dark color maps
$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'nav-background': $mbsc-mobiscroll-nav-background,
  'nav-text': $mbsc-mobiscroll-nav-text,
  'nav-accent': $mbsc-mobiscroll-nav-accent,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'nav-background': $mbsc-mobiscroll-dark-nav-background,
  'nav-text': $mbsc-mobiscroll-dark-nav-text,
  'nav-accent': $mbsc-mobiscroll-dark-nav-accent,
));

@mixin mbsc-mobiscroll-navigation-base($theme, $params) {
  @include exports("navigation-base.#{$theme}.colors") {

    @include mbsc-mobiscroll-scrollview($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $nav-background: map-get($params, 'nav-background');
    $nav-text: map-get($params, 'nav-text');
    $nav-accent: map-get($params, 'nav-accent');

    $background: if($nav-background, $nav-background, $background);
    $text: if($nav-text, $nav-text, if($nav-background, get-contrast-color($nav-background), $text));
    $accent: if($nav-accent, $nav-accent, $accent);

    $border: '';
    @if (lightness($background) > 50%) {
      $border: darken($background, 17%);
    }
    @else {
      $border: lighten($background, 17%);
    }

    .mbsc-#{$theme} {
      &.mbsc-ms-c {
        background: $background;
      }

      .mbsc-ms .mbsc-ms-item {
        color: $text;
      }

      &.mbsc-ms-c .mbsc-scv-sc .mbsc-ms .mbsc-ms-item.mbsc-active {
        background: rgba($accent, .3);
      }

      &.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
        background: rgba($accent, .1);
      }

      &.mbsc-ms-b {
        .mbsc-ms-item-sel {
          border-bottom-color: $accent;
        }

        &.mbsc-ms-top {
          border-bottom: 1px solid $accent;
        }

        &.mbsc-ms-bottom {
          border-top: 1px solid $accent;

          .mbsc-ms-item-sel {
            border-top-color: $accent;
            border-bottom-color: transparent;
          }
        }
      }

      &.mbsc-ms-a {
        &.mbsc-ms-top {
          border-bottom: 1px solid $border;
        }

        &.mbsc-ms-bottom {
          border-top: 1px solid $border;
        }

        .mbsc-ms-item-sel {
          color: $accent;
        }
      }
    }
  }
}



@include exports("navigation-base.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-ms-b {
      .mbsc-ms-item {
        border: 2px solid transparent;
        border-width: 2px 0;
      }
    }
  }

  @include mbsc-mobiscroll-navigation-base(mobiscroll, $mbsc-mobiscroll-colors);
}







@mixin mbsc-mobiscroll-navigation($theme, $params) {
  @include exports("navigation.#{$theme}.colors") {
    @include mbsc-mobiscroll-navigation-base($theme, $params);
    @include mbsc-mobiscroll-popup($theme, $params);
  }
}



@include exports("navigation.mobiscroll") {
  @include mbsc-mobiscroll-navigation(mobiscroll, $mbsc-mobiscroll-colors);
}







@mixin mbsc-material-scrollview($theme, $params) {
  @include exports("scrollview.#{$theme}.colors") {}
}




@include exports("scrollview.material") {
  @include mbsc-material-scrollview(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}





// Theme specific variables - inherited from global variables

// background
$mbsc-material-nav-background: $mbsc-nav-background-light !default;
$mbsc-material-dark-nav-background: $mbsc-nav-background-dark !default;
// text
$mbsc-material-nav-text: $mbsc-nav-text-light !default;
$mbsc-material-dark-nav-text: $mbsc-nav-text-dark !default;
// accent
$mbsc-material-nav-accent: $mbsc-nav-accent-light !default;
$mbsc-material-dark-nav-accent: $mbsc-nav-accent-dark !default;

// add variables to light and dark color maps
$mbsc-material-colors: map-merge($mbsc-material-colors, (
  'nav-background': $mbsc-material-nav-background,
  'nav-text': $mbsc-material-nav-text,
  'nav-accent': $mbsc-material-nav-accent,
));
$mbsc-material-dark-colors: map-merge($mbsc-material-dark-colors, (
  'nav-background': $mbsc-material-dark-nav-background,
  'nav-text': $mbsc-material-dark-nav-text,
  'nav-accent': $mbsc-material-dark-nav-accent,
));

@mixin mbsc-material-navigation-base($theme, $params) {
  @include exports("navigation-base.#{$theme}.colors") {

    @include mbsc-material-scrollview($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    $nav-background-param: map-get($params, 'nav-background');
    $nav-text-param: map-get($params, 'nav-text');
    $nav-accent-param: map-get($params, 'nav-accent');

    $background: if($nav-background-param, $nav-background-param, $background);
    $text: if($nav-text-param, $nav-text-param, if($nav-background-param, get-contrast-color($nav-background-param), $text));
    $accent: if($nav-accent-param, $nav-accent-param, $accent);

    $border: '';
    $ripple: '';
    @if (lightness($background) > 50%) {
      $border: lighten($text, 40%);
      $ripple: #000;
    }
    @else {
      $border: darken($text, 40%);
      $ripple: #fff;
    }

    .mbsc-#{$theme} {
      &.mbsc-ms-c {
        background: $background;
      }

      .mbsc-ms {
        .mbsc-ms-item {
          color: $text;

          /* Selected state */
          &.mbsc-ms-item-sel {
            color: $accent;
          }

          /* Active state */
          &.mbsc-active {
            background: rgba($ripple, .1);
          }
        }
      }

      &.mbsc-no-touch {

        /* Hover state */
        .mbsc-ms-item:not(.mbsc-disabled):hover {
          background: rgba($ripple, .1);
        }
      }

      &.mbsc-ms-top {
        border-bottom: 1px solid $border;
      }

      &.mbsc-ms-bottom {
        border-top: 1px solid $border;
      }

      /* Menu styling */
      &.mbsc-ms-a {
        .mbsc-ms-item {
          &.mbsc-ms-item-sel {
            color: $accent;
          }
        }
      }

      /* Tab styling */
      &.mbsc-ms-b {
        .mbsc-ms-item {
          &.mbsc-ms-item-sel {
            border-bottom-color: $accent;
          }
        }

        &.mbsc-ms-bottom {
          .mbsc-ms-item {
            &.mbsc-ms-item-sel {
              border-top-color: $accent;
              border-bottom-color: transparent;
            }
          }
        }
      }
    }
  }
}



@include exports("navigation-base.material") {
  .mbsc-material {
    .mbsc-ms .mbsc-ms-item {
      overflow: hidden;
    }

    /* Tab styling */
    &.mbsc-ms-b .mbsc-ms-item {
      border: 2px solid transparent;
      border-width: 2px 0;
    }
  }

  @include mbsc-material-navigation-base(material, $mbsc-material-colors);
}







@mixin mbsc-material-navigation($theme, $params) {
  @include exports("navigation.#{$theme}.colors") {
    @include mbsc-material-navigation-base($theme, $params);
    @include mbsc-material-popup($theme, $params);
  }
}



@include exports("navigation.material") {
  .mbsc-material {
    &.mbsc-ms-more {
      .mbsc-wdg-c.mbsc-w-p {
        padding: .5em 0;
      }

      .mbsc-ms-item {
        padding: .875em 1em;
      }
    }
  }

  @include mbsc-material-navigation(material, $mbsc-material-colors);
}







@mixin mbsc-windows-scrollview($theme, $params) {
  @include exports("scrollview.#{$theme}.colors") {}
}



@include exports("scrollview.windows") {
  @include mbsc-windows-scrollview(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}





// Theme specific variables - inherited from globa variables

// background
$mbsc-windows-nav-background: $mbsc-nav-background-light !default;
$mbsc-windows-dark-nav-background: $mbsc-nav-background-dark !default;
// text
$mbsc-windows-nav-text: $mbsc-nav-text-light !default;
$mbsc-windows-dark-nav-text: $mbsc-nav-text-dark !default;
// accent
$mbsc-windows-nav-accent: $mbsc-nav-accent-light !default;
$mbsc-windows-dark-nav-accent: $mbsc-nav-accent-dark !default;

// add variables to light and dark color maps
$mbsc-windows-colors: map-merge($mbsc-windows-colors, (
  'nav-background': $mbsc-windows-nav-background,
  'nav-text': $mbsc-windows-nav-text,
  'nav-accent': $mbsc-windows-nav-accent,
));
$mbsc-windows-dark-colors: map-merge($mbsc-windows-dark-colors, (
  'nav-background': $mbsc-windows-dark-nav-background,
  'nav-text': $mbsc-windows-dark-nav-text,
  'nav-accent': $mbsc-windows-dark-nav-accent,
));

@mixin mbsc-windows-navigation-base($theme, $params) {
  @include exports("navigation-base.#{$theme}.colors") {

    @include mbsc-windows-scrollview($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');

    // get custom params
    $nav-background: map-get($params, 'nav-background');
    $nav-text: map-get($params, 'nav-text');
    $nav-accent: map-get($params, 'nav-accent');

    // overwrite values with customized ones
    $background: if($nav-background, $nav-background, $background);
    $text: if($nav-text, $nav-text, if($nav-background, get-contrast-color($nav-background), $text));
    $accent: if($nav-accent, $nav-accent, $accent);

    $button-hover: '';
    $frame-border: '';
    @if(lightness($background) > 50%) {
      $button-hover: darken($background, 10%);
      $frame-border: darken($background, 15%);
    }
    @else {
      $button-hover: lighten($background, 10%);
      $frame-border: lighten($background, 15%);
    }

    .mbsc-#{$theme} {
      &.mbsc-ms-c {
        background: $background;
        color: $text;
      }

      .mbsc-ms-item.mbsc-active,
      &.mbsc-no-touch .mbsc-ms-item.mbsc-active:not(.mbsc-disabled):hover {
        background: $frame-border;
      }

      .mbsc-ms-item-sel,
      .mbsc-ms-item-sel.mbsc-ms-ic:before {
        color: $accent;
      }

      &.mbsc-ms-b .mbsc-ms-item.mbsc-ms-item-sel {
        border-bottom-color: $accent;
      }

      &.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
        background: $button-hover;
      }
    }
  }
}



@include exports("navigation-base.windows") {
  .mbsc-windows {
    &.mbsc-ms-c {
      font-family: Segoe UI, arial, verdana, sans-serif;
    }

    .mbsc-ms-item {
      padding: .4em .3em;
      font-size: 1.25em;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }

    .mbsc-ms-item.mbsc-ms-ic:before {
      font-size: 22px;
      top: .4090909em;
    }

    &.mbsc-ms-icons .mbsc-ms-item {
      font-size: .875em;
      line-height: .875em;
      padding: 1.428571em .428571em;
    }

    &.mbsc-ms-icons.mbsc-ms-txt .mbsc-ms-item {
      padding: 2.571428em .428571em .428571em .428571em;
      line-height: 1.428571;

      &:before {
        top: .4090909em;
      }
    }

    &.mbsc-ms-bottom .mbsc-ms-item {
      border: 0;
    }
  }

  @include mbsc-windows-navigation-base(windows, $mbsc-windows-colors);
}







@mixin mbsc-windows-navigation($theme, $params) {
  @include exports("navigation.#{$theme}.colors") {
    @include mbsc-windows-navigation-base($theme, $params);
    @include mbsc-windows-popup($theme, $params);

    $text: map-get($params, 'text');
    $accent: map-get($params, 'accent');
    
    // get customized params
    $nav-background: map-get($params, 'nav-background');
    $nav-text: map-get($params, 'nav-text');
    $nav-accent: map-get($params, 'nav-accent');

    // overwrite values with customized ones
    $text: if($nav-text, $nav-text, if($nav-background, get-contrast-color($nav-background), $text));
    $accent: if($nav-accent, $nav-accent, $accent);

    .mbsc-#{$theme} {

      /* Show more item */
      .mbsc-ms-item.mbsc-menu-item-ic {
        color: $text;
      }

      /* Show more bubble */
      &.mbsc-ms-more {
        .mbsc-ms-item {
          color: $text;

          &.mbsc-ms-item-sel {
            color: $accent;
          }
        }
      }
    }
  }
}



@include exports("navigation.windows") {
  .mbsc-windows {

    /* Show more item */
    .mbsc-ms-item.mbsc-menu-item-ic {
      padding: 1em .3em;
      min-width: 3em;

      &:before {
        top: .9545454em;
      }
    }

    .mbsc-ms-icons .mbsc-ms-item.mbsc-menu-item-ic:before {
      top: .4090909em;
    }

    /* Show more bubble */
    &.mbsc-ms-more {

      .mbsc-fr-w,
      .mbsc-wdg-c.mbsc-w-p {
        padding: 0;
      }

      .mbsc-ms-item {
        padding: 1em;
        font-size: 1em;

        &.mbsc-ms-ic {
          background: none;

          &:before {
            top: .9545454em;
          }
        }
      }
    }

    &.mbsc-ms-more-icons {
      .mbsc-ms-item {
        padding: .5em;

        &.mbsc-ms-ic:before {
          margin: 0;
        }
      }
    }

    .mbsc-ms-badge {
      font-size: .857142em;
    }

  }

  @include mbsc-windows-navigation(windows, $mbsc-windows-colors);
}



// scrollview component








// optionlist component






@mixin mbsc-ios-optionlist($theme, $params) {
  @include exports("optionlist.#{$theme}.colors") {
    @include mbsc-ios-navigation-base($theme, $params);
  }
}





@include exports("optionlist.ios") {
  @include mbsc-ios-optionlist(ios, (background: $mbsc-ios-background, text: $mbsc-ios-text, accent: $mbsc-ios-accent));
}




/* Navigation Base */
.mbsc-bootstrap {
  &.mbsc-ms-c {
    display: block;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-width: 1px 0;
    max-width: none;
    box-shadow: none;
  }

  &.mbsc-ms-inline {
    z-index: 0;
  }

  &.mbsc-ms-top {
    bottom: auto;
    border-top-width: 0;
  }

  &.mbsc-ms-bottom {
    top: auto;
    border-bottom-width: 0;
  }

  &.mbsc-ms-c .mbsc-ms-item {
    float: none;
    margin: 0;
    font-size: .75em;
    border-width: 0 0 0 1px;
    border-radius: 0;
  }

  &.mbsc-ms-ltr .mbsc-ms-item:first-child,
  &.mbsc-ms-rtl .mbsc-ms-item:last-child {
    border: 0;
  }
}









@mixin mbsc-mobiscroll-optionlist($theme, $params) {
  @include exports("optionlist.#{$theme}.colors") {
    @include mbsc-mobiscroll-navigation-base($theme, $params);
  }
}



@include exports("optionlist.mobiscroll") {
  @include mbsc-mobiscroll-optionlist(mobiscroll, (background: $mbsc-mobiscroll-background, text: $mbsc-mobiscroll-text, accent: $mbsc-mobiscroll-accent));
}







@mixin mbsc-material-optionlist($theme, $params) {
  @include exports("optionlist.#{$theme}.colors") {
    @include mbsc-material-navigation-base($theme, $params);
  }
}



@include exports("optionlist.material") {
  @include mbsc-material-optionlist(material, (background: $mbsc-material-background, text: $mbsc-material-text, accent: $mbsc-material-accent));
}







@mixin mbsc-windows-optionlist($theme, $params) {
  @include exports("optionlist.#{$theme}.colors") {
    @include mbsc-windows-navigation-base($theme, $params);
  }
}



@include exports("optionlist.windows") {
  @include mbsc-windows-optionlist(windows, (background: $mbsc-windows-background, text: $mbsc-windows-text, accent: $mbsc-windows-accent));
}



// cards component






// Theme specific variables - inherited from global

// background
$mbsc-ios-card-background: $mbsc-card-background-light !default;
$mbsc-ios-dark-card-background: $mbsc-card-background-dark !default;
// text
$mbsc-ios-card-text: $mbsc-card-text-light !default;
$mbsc-ios-dark-card-text: $mbsc-card-text-dark !default;

// add variables to color maps
$mbsc-ios-colors: map-merge($mbsc-ios-colors, (
  'card-background': $mbsc-ios-card-background,
  'card-text': $mbsc-ios-card-text,
));
$mbsc-ios-dark-colors: map-merge($mbsc-ios-dark-colors, (
  'card-background': $mbsc-ios-dark-card-background,
  'card-text': $mbsc-ios-dark-card-text,
));

@mixin mbsc-ios-cards($theme, $params) {
  @include exports("cards.#{$theme}.colors") {

    @include mbsc-ios-forms($theme, $params);

    // get basic params
    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    // get customized variables
    $background-param: map-get($params, 'card-background');
    $text-param: map-get($params, 'card-text');

    $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
    $card-background: '';
    @if ($background-param) {
      // overwrite variables with customized ones
      $background: $background-param;
      $card-background: $background-param;
      // recalc dependent color
      $background-limited: hsl(hue($background), saturation($background), max(lightness($background), 3%));
    }
    @else {
      $card-background: lighten($background-limited, 3%);
    }

    // overwrite variables with customized ones
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background), $text));

    $card-shadow: darken($background, 17%);
    $subtitle-color: rgba($text, 0.51);
    $border-color: '';
    @if (lightness($background) > 50%) {
      $border-color: darken($background-limited, 17%);
    }
    @else {
      $border-color: lighten($background, 20%);
    }


    .mbsc-#{$theme} {
      &.mbsc-card.mbsc-form
      // &.mbsc-card .mbsc-input,
      // &.mbsc-card .mbsc-progress
      {
        color: $text;
        background: $card-background;
      }

      .mbsc-card-subtitle {
        color: $subtitle-color;
      }

      &.mbsc-card.mbsc-form {
        box-shadow: 0 0 1px 1px rgba($card-shadow, .2);
      }

      &.mbsc-card .mbsc-lv-item {
        color: $text;
      }

      .mbsc-card-footer {
        border-color: $border-color;
      }

      &.mbsc-card .mbsc-input-box.mbsc-control-w,
      &.mbsc-card .mbsc-input-box.mbsc-control-w {
        border: 1px solid $border-color;
      }
    }
  }
}





@include exports("cards") {

  [mbsc-card]:not(.mbsc-card) {
    // Prevent FOUC
    visibility: hidden;
  }

  .mbsc-card {
    position: relative;
    z-index: 1;
    margin: .75em;
    border-radius: 2px;
    overflow: hidden;
  }

  .mbsc-card-header {
    padding: 1em;
    overflow: hidden;
  }

  .mbsc-card-content {
    padding: 1em;
    overflow: hidden;
  }

  .mbsc-card-content > p:first-child {
    margin-top: 0;
  }

  .mbsc-card-content > p:last-child {
    margin-bottom: 0;
  }

  .mbsc-card-content.mbsc-no-padding {
    padding: 0;
  }

  .mbsc-card-header + .mbsc-card-content {
    padding-top: 0;
  }

  .mbsc-card-footer {
    padding: 1em;
    border-top: 1px solid transparent;
    clear: both;
  }

  /* Card elemets */

  .mbsc-card h2.mbsc-card-title,
  .mbsc-card-title {
    font-size: 1.5em;
    font-weight: normal;
    margin: 0;
  }

  .mbsc-card h3.mbsc-card-subtitle,
  .mbsc-card-subtitle {
    font-size: 0.875em;
    font-weight: normal;
    margin: 0;
  }

  .mbsc-card h3.mbsc-card-subtitle + *,
  .mbsc-card-subtitle + *,
  .mbsc-card h2.mbsc-card-title + *,
  .mbsc-card-title + * {
    margin-top: .25em;
  }

  /* Card image styling */

  .mbsc-card video,
  .mbsc-card img:not(.mbsc-avatar):not(.mbsc-img-thumbnail) {
    display: block;
    width: 100%;
  }

  .mbsc-card.mbsc-ltr .mbsc-avatar {
    float: left;
    margin-right: .5em;
  }

  .mbsc-card.mbsc-rtl .mbsc-avatar {
    float: right;
    margin-left: .5em;
  }

  .mbsc-avatar ~ .mbsc-card-title,
  mbsc-avatar ~ .mbsc-card-title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
  }

  /* Form overrides */

  .mbsc-card .mbsc-card-footer .mbsc-btn {
    margin: -.6875em 0 -.6875em 0;
  }

  .mbsc-card .mbsc-card-content .mbsc-control-w {
    margin-left: 0;
    margin-right: 0;
  }

  /* Listview overrides */

  .mbsc-lv .mbsc-lv-item.mbsc-card {
    margin: .75em;
    padding: 0;
  }

  .mbsc-card.mbsc-form .mbsc-lv-cont {
    margin: 0;
  }

  .mbsc-card .mbsc-card-content .mbsc-lv-cont {
    margin: 0 -1em;
  }

  .mbsc-card-list .mbsc-lv-cont .mbsc-lv,
  .mbsc-lv-cont .mbsc-card-list.mbsc-lv {
    background: none;
  }

  .mbsc-card-list .mbsc-lv-item:not(.mbsc-card) {
    padding: 0;
    background: none;
  }
}


@include exports("cards.ios") {
  .mbsc-ios {
    &.mbsc-card.mbsc-form {
      margin: 1.5em 1em;
      border-radius: .5em;
    }

    &.mbsc-card .mbsc-btn-flat {
      padding: .625em;
    }

    .mbsc-card-footer .mbsc-btn {
      margin-top: -.625em;
      margin-bottom: -.625em;
    }

    &.mbsc-card .mbsc-btn-group-block {
      background: transparent;
    }

    &.mbsc-card .mbsc-ios {
      .mbsc-lv-item:first-child:before {
        border: 0
      }

      .mbsc-lv-item:last-child:after {
        border: 0;
      }
    }

    &.mbsc-card .mbsc-control-w:last-child:after {
      border: 0;
    }

    .mbsc-card-list .mbsc-lv-item:before,
    .mbsc-card-list .mbsc-lv-item:after {
      border: 0;
    }
  }

  // check if this is unnecessary
  .mbsc-card-list .mbsc-ios .mbsc-lv-item:before,
  .mbsc-card-list .mbsc-ios .mbsc-lv-item:after {
    border: 0;
  }

  @include mbsc-ios-cards(ios, $mbsc-ios-colors);
}










// Theme specific variables - inherited from global

// background
$mbsc-mobiscroll-card-background: $mbsc-card-background-light !default;
$mbsc-mobiscroll-dark-card-background: $mbsc-card-background-dark !default;
// text
$mbsc-mobiscroll-card-text: $mbsc-card-text-light !default;
$mbsc-mobiscroll-dark-card-text: $mbsc-card-text-dark !default;

// add variables to color maps
$mbsc-mobiscroll-colors: map-merge($mbsc-mobiscroll-colors, (
  'card-background': $mbsc-mobiscroll-card-background,
  'card-text': $mbsc-mobiscroll-card-text,
));
$mbsc-mobiscroll-dark-colors: map-merge($mbsc-mobiscroll-dark-colors, (
  'card-background': $mbsc-mobiscroll-dark-card-background,
  'card-text': $mbsc-mobiscroll-dark-card-text,
));

@mixin mbsc-mobiscroll-cards($theme, $params) {
  @include exports("cards.#{$theme}.colors") {

    @include mbsc-mobiscroll-forms($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    // get customized params
    $background-param: map-get($params, 'card-background');
    $text-param: map-get($params, 'card-text');
    // overwrite params with customized ones
    $background: if($background-param, $background-param, $background);
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    // calculated colors
    $subtitle-color: rgba($text, 0.6);
    $border: '';
    @if (lightness($background) > 50%) {
      $border: darken($background, 17%);
    }
    @else {
      $border: lighten($background, 17%);
    }

    .mbsc-#{$theme} {
      &.mbsc-card.mbsc-form {
        background: lighten($background, 4%);
        color: $text;
      }

      .mbsc-card-subtitle {
        color: $subtitle-color;
      }

      &.mbsc-card .mbsc-control-w {
        color: $text;
      }

      &.mbsc-card .mbsc-lv-item {
        background: lighten($background, 4%);
        color: $text;
      }

      .mbsc-card-footer {
        border-color: $border;
      }

      &.mbsc-card .mbsc-input.mbsc-input-outline .mbsc-label {
        background: lighten($background, 4%);
      }
    }
  }
}



@include exports("cards.mobiscroll") {
  .mbsc-mobiscroll {
    &.mbsc-card.mbsc-form {
      box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
    }
  }

  @include mbsc-mobiscroll-cards(mobiscroll, $mbsc-mobiscroll-colors);
}







// Theme specific variables - inherited from global variables

// bacgkround
$mbsc-material-card-background: $mbsc-card-background-light !default;
$mbsc-material-dark-card-background: $mbsc-card-background-dark !default;
// text
$mbsc-material-card-text: $mbsc-card-text-light !default;
$mbsc-material-dark-card-text: $mbsc-card-text-dark !default;

// add variables to color maps
$mbsc-material-colors: map-merge($mbsc-material-colors, (
  'card-background': $mbsc-material-card-background,
  'card-text': $mbsc-material-card-text,
));
$mbsc-material-dark-colors: map-merge($mbsc-material-dark-colors, (
  'card-background': $mbsc-material-dark-card-background,
  'card-text': $mbsc-material-dark-card-text,
));

@mixin mbsc-material-cards($theme, $params) {
  @include exports("cards.#{$theme}.colors") {

    @include mbsc-material-forms($theme, $params);

    // get base colors
    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    // get custom variables
    $background-param: map-get($params, 'card-background');
    $text-param: map-get($params, 'card-text');
    // overwrite colors with custom ones
    $light-background: lighten($background, 4.8%);
    $background: if($background-param, $background-param, $light-background);
    $border-origin: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), lighten($text, 7%)));
    // calculated colors
    $subtitle-color: rgba($text, 0.6);
    $border: '';

    @if (lightness($background) > 50%) {
      $border: lighten($border-origin, 40%);
    }
    @else {
      $border: darken($border-origin, 40%);
    }

    .mbsc-#{$theme} {
      &.mbsc-card.mbsc-form {
        background: $background;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
        color: $text;
      }

      .mbsc-card-subtitle {
        color: $subtitle-color;
      }

      &.mbsc-card .mbsc-control-w {
        color: $text;
      }

      &.mbsc-card .mbsc-lv-item,
      .mbsc-card-list .mbsc-card {
        background: $background;
        color: $text;
      }

      .mbsc-card-footer {
        border-color: $border;
      }

      &.mbsc-card .mbsc-input.mbsc-input-outline .mbsc-label {
        background: $background;
      }
    }
  }
}



@include exports("cards.material") {
  .mbsc-material {
    &.mbsc-card.mbsc-form {
      margin: .625em;
      border-radius: 2px;
    }

    .mbsc-card-footer .mbsc-btn {
      margin-top: -.7143em;
      margin-bottom: -.7143em;
    }

    .mbsc-card-list .mbsc-card {
      margin: .625em;
    }
  }

  @include mbsc-material-cards(material, $mbsc-material-colors);
}







// Theme specific variables - inherited from global

// background
$mbsc-windows-card-background: $mbsc-card-background-light !default;
$mbsc-windows-dark-card-background: $mbsc-card-background-dark !default;
// text
$mbsc-windows-card-text: $mbsc-card-text-light !default;
$mbsc-windows-dark-card-text: $mbsc-card-text-dark !default;

// add variables to color maps
$mbsc-windows-colors: map-merge($mbsc-windows-colors, (
  'card-background': $mbsc-windows-card-background,
  'card-text': $mbsc-windows-card-text,
));
$mbsc-windows-dark-colors: map-merge($mbsc-windows-dark-colors, (
  'card-background': $mbsc-windows-dark-card-background,
  'card-text': $mbsc-windows-dark-card-text,
));

@mixin mbsc-windows-cards($theme, $params) {
  @include exports("cards.#{$theme}.colors") {

    @include mbsc-windows-forms($theme, $params);

    $background: map-get($params, 'background');
    $text: map-get($params, 'text');
    // get custom params
    $background-param: map-get($params, 'card-background');
    $text-param: map-get($params, 'card-text');

    // overwrite params with custom
    $border-origin: if($background-param, $background-param, $background);
    $background: if($background-param, $background-param, lighten($background, 2.5%));
    $text: if($text-param, $text-param, if($background-param, get-contrast-color($background-param), $text));

    $cards-border: '';
    @if (lightness($background) > 50%) {
      $cards-border: darken($border-origin, 5%);
    }
    @else {
      $cards-border: lighten($border-origin, 12%);
    }
    .mbsc-#{$theme} {
      &.mbsc-card.mbsc-form {
        background: $background;
        color: $text;
        box-shadow: 0 0 1px 1px $cards-border;
      }

      &.mbsc-card .mbsc-control-w {
        color: $text;
      }

      &.mbsc-card .mbsc-lv-item {
        background: $background;
        color: $text;
      }

      .mbsc-card-footer {
        border-color: $cards-border;
      }
    }
  }
}



@include exports("cards.windows") {
  .mbsc-windows {
    &.mbsc-card.mbsc-form {
      border-radius: 0;
    }

    .mbsc-card-footer .mbsc-btn {
      margin-top: -.625em;
      margin-bottom: -.625em;
    }
  }

  @include mbsc-windows-cards(windows, $mbsc-windows-colors);
}



// popup component







// grid-layout component



@mixin mbsc-grid-sm() {
  .mbsc-col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-sm-0 {
    margin-left: 0;
  }

  .mbsc-offset-sm-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-sm-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-sm-3 {
    margin-left: 25%;
  }

  .mbsc-offset-sm-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-sm-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-sm-6 {
    margin-left: 50%;
  }

  .mbsc-offset-sm-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-sm-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-sm-9 {
    margin-left: 75%;
  }

  .mbsc-offset-sm-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-sm-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-sm-0 {
    left: auto;
  }

  .mbsc-push-sm-1 {
    left: 8.33333333%;
  }

  .mbsc-push-sm-2 {
    left: 16.66666667%;
  }

  .mbsc-push-sm-3 {
    left: 25%;
  }

  .mbsc-push-sm-4 {
    left: 33.33333333%;
  }

  .mbsc-push-sm-5 {
    left: 41.66666667%;
  }

  .mbsc-push-sm-6 {
    left: 50%;
  }

  .mbsc-push-sm-7 {
    left: 58.33333333%;
  }

  .mbsc-push-sm-8 {
    left: 66.66666667%;
  }

  .mbsc-push-sm-9 {
    left: 75%;
  }

  .mbsc-push-sm-10 {
    left: 83.33333333%;
  }

  .mbsc-push-sm-11 {
    left: 91.66666667%;
  }

  .mbsc-push-sm-12 {
    left: 100%;
  }

  .mbsc-pull-sm-0 {
    right: auto;
  }

  .mbsc-pull-sm-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-sm-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-sm-3 {
    right: 25%;
  }

  .mbsc-pull-sm-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-sm-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-sm-6 {
    right: 50%;
  }

  .mbsc-pull-sm-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-sm-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-sm-9 {
    right: 75%;
  }

  .mbsc-pull-sm-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-sm-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-sm-12 {
    right: 100%;
  }
}

@mixin mbsc-grid-md() {
  .mbsc-col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-md-0 {
    margin-left: 0;
  }

  .mbsc-offset-md-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-md-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-md-3 {
    margin-left: 25%;
  }

  .mbsc-offset-md-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-md-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-md-6 {
    margin-left: 50%;
  }

  .mbsc-offset-md-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-md-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-md-9 {
    margin-left: 75%;
  }

  .mbsc-offset-md-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-md-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-md-0 {
    left: auto;
  }

  .mbsc-push-md-1 {
    left: 8.33333333%;
  }

  .mbsc-push-md-2 {
    left: 16.66666667%;
  }

  .mbsc-push-md-3 {
    left: 25%;
  }

  .mbsc-push-md-4 {
    left: 33.33333333%;
  }

  .mbsc-push-md-5 {
    left: 41.66666667%;
  }

  .mbsc-push-md-6 {
    left: 50%;
  }

  .mbsc-push-md-7 {
    left: 58.33333333%;
  }

  .mbsc-push-md-8 {
    left: 66.66666667%;
  }

  .mbsc-push-md-9 {
    left: 75%;
  }

  .mbsc-push-md-10 {
    left: 83.33333333%;
  }

  .mbsc-push-md-11 {
    left: 91.66666667%;
  }

  .mbsc-push-md-12 {
    left: 100%;
  }

  .mbsc-pull-md-0 {
    right: auto;
  }

  .mbsc-pull-md-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-md-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-md-3 {
    right: 25%;
  }

  .mbsc-pull-md-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-md-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-md-6 {
    right: 50%;
  }

  .mbsc-pull-md-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-md-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-md-9 {
    right: 75%;
  }

  .mbsc-pull-md-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-md-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-md-12 {
    right: 100%;
  }
}

@mixin mbsc-grid-lg() {
  .mbsc-col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-lg-0 {
    margin-left: 0;
  }

  .mbsc-offset-lg-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-lg-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-lg-3 {
    margin-left: 25%;
  }

  .mbsc-offset-lg-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-lg-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-lg-6 {
    margin-left: 50%;
  }

  .mbsc-offset-lg-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-lg-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-lg-9 {
    margin-left: 75%;
  }

  .mbsc-offset-lg-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-lg-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-lg-0 {
    left: auto;
  }

  .mbsc-push-lg-1 {
    left: 8.33333333%;
  }

  .mbsc-push-lg-2 {
    left: 16.66666667%;
  }

  .mbsc-push-lg-3 {
    left: 25%;
  }

  .mbsc-push-lg-4 {
    left: 33.33333333%;
  }

  .mbsc-push-lg-5 {
    left: 41.66666667%;
  }

  .mbsc-push-lg-6 {
    left: 50%;
  }

  .mbsc-push-lg-7 {
    left: 58.33333333%;
  }

  .mbsc-push-lg-8 {
    left: 66.66666667%;
  }

  .mbsc-push-lg-9 {
    left: 75%;
  }

  .mbsc-push-lg-10 {
    left: 83.33333333%;
  }

  .mbsc-push-lg-11 {
    left: 91.66666667%;
  }

  .mbsc-push-lg-12 {
    left: 100%;
  }

  .mbsc-pull-lg-0 {
    right: auto;
  }

  .mbsc-pull-lg-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-lg-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-lg-3 {
    right: 25%;
  }

  .mbsc-pull-lg-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-lg-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-lg-6 {
    right: 50%;
  }

  .mbsc-pull-lg-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-lg-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-lg-9 {
    right: 75%;
  }

  .mbsc-pull-lg-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-lg-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-lg-12 {
    right: 100%;
  }
}

@mixin mbsc-grid-xl() {
  .mbsc-col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-offset-xl-0 {
    margin-left: 0;
  }

  .mbsc-offset-xl-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-xl-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-xl-3 {
    margin-left: 25%;
  }

  .mbsc-offset-xl-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-xl-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-xl-6 {
    margin-left: 50%;
  }

  .mbsc-offset-xl-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-xl-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-xl-9 {
    margin-left: 75%;
  }

  .mbsc-offset-xl-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-xl-11 {
    margin-left: 91.666667%;
  }

  .mbsc-push-xl-0 {
    left: auto;
  }

  .mbsc-push-xl-1 {
    left: 8.33333333%;
  }

  .mbsc-push-xl-2 {
    left: 16.66666667%;
  }

  .mbsc-push-xl-3 {
    left: 25%;
  }

  .mbsc-push-xl-4 {
    left: 33.33333333%;
  }

  .mbsc-push-xl-5 {
    left: 41.66666667%;
  }

  .mbsc-push-xl-6 {
    left: 50%;
  }

  .mbsc-push-xl-7 {
    left: 58.33333333%;
  }

  .mbsc-push-xl-8 {
    left: 66.66666667%;
  }

  .mbsc-push-xl-9 {
    left: 75%;
  }

  .mbsc-push-xl-10 {
    left: 83.33333333%;
  }

  .mbsc-push-xl-11 {
    left: 91.66666667%;
  }

  .mbsc-push-xl-12 {
    left: 100%;
  }

  .mbsc-pull-xl-0 {
    right: auto;
  }

  .mbsc-pull-xl-1 {
    right: 8.33333333%;
  }

  .mbsc-pull-xl-2 {
    right: 16.66666667%;
  }

  .mbsc-pull-xl-3 {
    right: 25%;
  }

  .mbsc-pull-xl-4 {
    right: 33.33333333%;
  }

  .mbsc-pull-xl-5 {
    right: 41.66666667%;
  }

  .mbsc-pull-xl-6 {
    right: 50%;
  }

  .mbsc-pull-xl-7 {
    right: 58.33333333%;
  }

  .mbsc-pull-xl-8 {
    right: 66.66666667%;
  }

  .mbsc-pull-xl-9 {
    right: 75%;
  }

  .mbsc-pull-xl-10 {
    right: 83.33333333%;
  }

  .mbsc-pull-xl-11 {
    right: 91.66666667%;
  }

  .mbsc-pull-xl-12 {
    right: 100%;
  }
}

@include exports("grid-layout") {

  .mbsc-grid,
  .mbsc-grid-unresp,
  .mbsc-grid-fixed {
    width: 100%;
    padding-right: 1em;
    padding-left: 1em;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;

    * {
      box-sizing: border-box;
    }
  }

  .mbsc-no-padding .mbsc-col,
  .mbsc-no-padding [class*="mbsc-col-"],
  .mbsc-form-grid .mbsc-col,
  .mbsc-form-grid [class*="mbsc-col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  .mbsc-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -1em;
    margin-left: -1em;
  }

  .mbsc-col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .mbsc-col-1,
  .mbsc-col-2,
  .mbsc-col-3,
  .mbsc-col-4,
  .mbsc-col-5,
  .mbsc-col-6,
  .mbsc-col-7,
  .mbsc-col-8,
  .mbsc-col-9,
  .mbsc-col-10,
  .mbsc-col-11,
  .mbsc-col-12,
  .mbsc-col,
  .mbsc-col-auto,
  .mbsc-col-sm-1,
  .mbsc-col-sm-2,
  .mbsc-col-sm-3,
  .mbsc-col-sm-4,
  .mbsc-col-sm-5,
  .mbsc-col-sm-6,
  .mbsc-col-sm-7,
  .mbsc-col-sm-8,
  .mbsc-col-sm-9,
  .mbsc-col-sm-10,
  .mbsc-col-sm-11,
  .mbsc-col-sm-12,
  .mbsc-col-sm,
  .mbsc-col-sm-auto,
  .mbsc-col-md-1,
  .mbsc-col-md-2,
  .mbsc-col-md-3,
  .mbsc-col-md-4,
  .mbsc-col-md-5,
  .mbsc-col-md-6,
  .mbsc-col-md-7,
  .mbsc-col-md-8,
  .mbsc-col-md-9,
  .mbsc-col-md-10,
  .mbsc-col-md-11,
  .mbsc-col-md-12,
  .mbsc-col-md,
  .mbsc-col-md-auto,
  .mbsc-col-lg-1,
  .mbsc-col-lg-2,
  .mbsc-col-lg-3,
  .mbsc-col-lg-4,
  .mbsc-col-lg-5,
  .mbsc-col-lg-6,
  .mbsc-col-lg-7,
  .mbsc-col-lg-8,
  .mbsc-col-lg-9,
  .mbsc-col-lg-10,
  .mbsc-col-lg-11,
  .mbsc-col-lg-12,
  .mbsc-col-lg,
  .mbsc-col-lg-auto,
  .mbsc-col-xl-1,
  .mbsc-col-xl-2,
  .mbsc-col-xl-3,
  .mbsc-col-xl-4,
  .mbsc-col-xl-5,
  .mbsc-col-xl-6,
  .mbsc-col-xl-7,
  .mbsc-col-xl-8,
  .mbsc-col-xl-9,
  .mbsc-col-xl-10,
  .mbsc-col-xl-11,
  .mbsc-col-xl-12,
  .mbsc-col-xl,
  .mbsc-col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 1em;
    padding-left: 1em;
  }

  .mbsc-col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .mbsc-col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .mbsc-col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mbsc-col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .mbsc-col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .mbsc-col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mbsc-col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .mbsc-col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .mbsc-col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mbsc-col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .mbsc-col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .mbsc-col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mbsc-col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .mbsc-offset-1 {
    margin-left: 8.333333%;
  }

  .mbsc-offset-2 {
    margin-left: 16.666667%;
  }

  .mbsc-offset-3 {
    margin-left: 25%;
  }

  .mbsc-offset-4 {
    margin-left: 33.333333%;
  }

  .mbsc-offset-5 {
    margin-left: 41.666667%;
  }

  .mbsc-offset-6 {
    margin-left: 50%;
  }

  .mbsc-offset-7 {
    margin-left: 58.333333%;
  }

  .mbsc-offset-8 {
    margin-left: 66.666667%;
  }

  .mbsc-offset-9 {
    margin-left: 75%;
  }

  .mbsc-offset-10 {
    margin-left: 83.333333%;
  }

  .mbsc-offset-11 {
    margin-left: 91.666667%;
  }

  .mbsc-grid-sm {
    &.mbsc-grid-fixed {
      max-width: 540px;
    }

    @include mbsc-grid-sm();
  }

  @media (min-width: 576px) {
    .mbsc-grid {
      &.mbsc-grid-fixed {
        max-width: 540px;
      }

      @include mbsc-grid-sm();
    }
  }

  .mbsc-grid-md {
    &.mbsc-grid-fixed {
      max-width: 720px;
    }

    @include mbsc-grid-md();
  }

  @media (min-width: 768px) {
    .mbsc-grid {
      &.mbsc-grid-fixed {
        max-width: 720px;
      }

      @include mbsc-grid-md();
    }
  }

  .mbsc-grid-lg {
    &.mbsc-grid-fixed {
      max-width: 960px;
    }

    @include mbsc-grid-lg();
  }

  @media (min-width: 992px) {
    .mbsc-grid {
      &.mbsc-grid-fixed {
        max-width: 960px;
      }

      @include mbsc-grid-lg();
    }
  }

  .mbsc-grid-xl {
    &.mbsc-grid-fixed {
      max-width: 1140px;
    }

    @include mbsc-grid-xl();
  }

  @media (min-width: 1200px) {
    .mbsc-grid {
      &.mbsc-grid-fixed {
        max-width: 1140px;
      }

      @include mbsc-grid-xl();
    }
  }

  .mbsc-align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .mbsc-align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .mbsc-align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .mbsc-justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .mbsc-justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .mbsc-justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .mbsc-justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  .mbsc-justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
}















// Theme builder function for ios theme

@mixin mbsc-ios-theme($theme, $colors) {
    @include mbsc-ios-select($theme, $colors);
    @include mbsc-ios-datetime($theme, $colors);
    @include mbsc-ios-image($theme, $colors);
    @include mbsc-ios-treelist($theme, $colors);
    @include mbsc-ios-calendar($theme, $colors);
    @include mbsc-ios-timespan($theme, $colors);
    @include mbsc-ios-timer($theme, $colors);
    @include mbsc-ios-color($theme, $colors);
    @include mbsc-ios-range($theme, $colors);
    @include mbsc-ios-scroller($theme, $colors);
    @include mbsc-ios-listview($theme, $colors);
    @include mbsc-ios-number($theme, $colors);
    @include mbsc-ios-numpad($theme, $colors);
    @include mbsc-ios-forms($theme, $colors);
    @include mbsc-ios-eventcalendar($theme, $colors);
    @include mbsc-ios-measurement($theme, $colors);
    @include mbsc-ios-navigation($theme, $colors);
    @include mbsc-ios-scrollview($theme, $colors);
    @include mbsc-ios-optionlist($theme, $colors);
    @include mbsc-ios-cards($theme, $colors);
    @include mbsc-ios-popup($theme, $colors);
}

// Theme builder function for bootstrap theme

@mixin mbsc-bootstrap-theme($theme, $colors) {
    @include mbsc-bootstrap-select($theme, $colors);
    @include mbsc-bootstrap-datetime($theme, $colors);
    @include mbsc-bootstrap-image($theme, $colors);
    @include mbsc-bootstrap-treelist($theme, $colors);
    @include mbsc-bootstrap-calendar($theme, $colors);
    @include mbsc-bootstrap-timespan($theme, $colors);
    @include mbsc-bootstrap-timer($theme, $colors);
    @include mbsc-bootstrap-color($theme, $colors);
    @include mbsc-bootstrap-range($theme, $colors);
    @include mbsc-bootstrap-scroller($theme, $colors);
    @include mbsc-bootstrap-listview($theme, $colors);
    @include mbsc-bootstrap-number($theme, $colors);
    @include mbsc-bootstrap-numpad($theme, $colors);
    @include mbsc-bootstrap-forms($theme, $colors);
    @include mbsc-bootstrap-eventcalendar($theme, $colors);
    @include mbsc-bootstrap-measurement($theme, $colors);
    @include mbsc-bootstrap-navigation($theme, $colors);
    @include mbsc-bootstrap-scrollview($theme, $colors);
    @include mbsc-bootstrap-optionlist($theme, $colors);
    @include mbsc-bootstrap-cards($theme, $colors);
    @include mbsc-bootstrap-popup($theme, $colors);
}

// Theme builder function for mobiscroll theme

@mixin mbsc-mobiscroll-theme($theme, $colors) {
    @include mbsc-mobiscroll-select($theme, $colors);
    @include mbsc-mobiscroll-datetime($theme, $colors);
    @include mbsc-mobiscroll-image($theme, $colors);
    @include mbsc-mobiscroll-treelist($theme, $colors);
    @include mbsc-mobiscroll-calendar($theme, $colors);
    @include mbsc-mobiscroll-timespan($theme, $colors);
    @include mbsc-mobiscroll-timer($theme, $colors);
    @include mbsc-mobiscroll-color($theme, $colors);
    @include mbsc-mobiscroll-range($theme, $colors);
    @include mbsc-mobiscroll-scroller($theme, $colors);
    @include mbsc-mobiscroll-listview($theme, $colors);
    @include mbsc-mobiscroll-number($theme, $colors);
    @include mbsc-mobiscroll-numpad($theme, $colors);
    @include mbsc-mobiscroll-forms($theme, $colors);
    @include mbsc-mobiscroll-eventcalendar($theme, $colors);
    @include mbsc-mobiscroll-measurement($theme, $colors);
    @include mbsc-mobiscroll-navigation($theme, $colors);
    @include mbsc-mobiscroll-scrollview($theme, $colors);
    @include mbsc-mobiscroll-optionlist($theme, $colors);
    @include mbsc-mobiscroll-cards($theme, $colors);
    @include mbsc-mobiscroll-popup($theme, $colors);
}

// Theme builder function for material theme

@mixin mbsc-material-theme($theme, $colors) {
    @include mbsc-material-select($theme, $colors);
    @include mbsc-material-datetime($theme, $colors);
    @include mbsc-material-image($theme, $colors);
    @include mbsc-material-treelist($theme, $colors);
    @include mbsc-material-calendar($theme, $colors);
    @include mbsc-material-timespan($theme, $colors);
    @include mbsc-material-timer($theme, $colors);
    @include mbsc-material-color($theme, $colors);
    @include mbsc-material-range($theme, $colors);
    @include mbsc-material-scroller($theme, $colors);
    @include mbsc-material-listview($theme, $colors);
    @include mbsc-material-number($theme, $colors);
    @include mbsc-material-numpad($theme, $colors);
    @include mbsc-material-forms($theme, $colors);
    @include mbsc-material-eventcalendar($theme, $colors);
    @include mbsc-material-measurement($theme, $colors);
    @include mbsc-material-navigation($theme, $colors);
    @include mbsc-material-scrollview($theme, $colors);
    @include mbsc-material-optionlist($theme, $colors);
    @include mbsc-material-cards($theme, $colors);
    @include mbsc-material-popup($theme, $colors);
}

// Theme builder function for windows theme

@mixin mbsc-windows-theme($theme, $colors) {
    @include mbsc-windows-select($theme, $colors);
    @include mbsc-windows-datetime($theme, $colors);
    @include mbsc-windows-image($theme, $colors);
    @include mbsc-windows-treelist($theme, $colors);
    @include mbsc-windows-calendar($theme, $colors);
    @include mbsc-windows-timespan($theme, $colors);
    @include mbsc-windows-timer($theme, $colors);
    @include mbsc-windows-color($theme, $colors);
    @include mbsc-windows-range($theme, $colors);
    @include mbsc-windows-scroller($theme, $colors);
    @include mbsc-windows-listview($theme, $colors);
    @include mbsc-windows-number($theme, $colors);
    @include mbsc-windows-numpad($theme, $colors);
    @include mbsc-windows-forms($theme, $colors);
    @include mbsc-windows-eventcalendar($theme, $colors);
    @include mbsc-windows-measurement($theme, $colors);
    @include mbsc-windows-navigation($theme, $colors);
    @include mbsc-windows-scrollview($theme, $colors);
    @include mbsc-windows-optionlist($theme, $colors);
    @include mbsc-windows-cards($theme, $colors);
    @include mbsc-windows-popup($theme, $colors);
}

@mixin mbsc-custom-theme($theme, $base-theme, $colors) {
    @if $base-theme==ios {
        @include mbsc-ios-theme($theme, $colors);
    }
    @if $base-theme==bootstrap {
        @include mbsc-bootstrap-theme($theme, $colors);
    }
    @if $base-theme==mobiscroll {
        @include mbsc-mobiscroll-theme($theme, $colors);
    }
    @if $base-theme==material {
        @include mbsc-material-theme($theme, $colors);
    }
    @if $base-theme==windows {
        @include mbsc-windows-theme($theme, $colors);
    }
}

@include mbsc-mobiscroll-theme(mobiscroll-dark, $mbsc-mobiscroll-dark-colors);

@include mbsc-material-theme(material-dark, $mbsc-material-dark-colors);

@include mbsc-ios-theme(ios-dark, $mbsc-ios-dark-colors);

@include mbsc-windows-theme(windows-dark, $mbsc-windows-dark-colors);

